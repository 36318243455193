import { z } from 'zod';

export const KidValidatorSchema = z.object({
  firstName: z
    .string()
    .trim()
    .min(1)
    .transform((val) => val.replace(/\s+/g, ' ')),
  lastName: z
    .string()
    .trim()
    .min(1)
    .transform((val) => val.replace(/\s+/g, ' ')),
  birthdate: z.string().optional(),
  language: z.string().optional(),
  defaultCultureId: z.string().optional(),
  defaultGradeId: z.string().optional(),
  password: z.string().optional(),
  passcode: z.string().optional(),
  emailParentOne: z.string().email().optional(),
  emailParentTwo: z.string().email().optional(),
});

export type KidValidator = z.infer<typeof KidValidatorSchema>;
