import type React from 'react';
import { useState } from 'react';

export const useToggleState = (
  initialState: boolean,
): [boolean, () => void, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [state, setState] = useState<boolean>(initialState);

  const toggle = (): void => {
    setState((prev) => !prev);
  };

  return [state, toggle, setState];
};
