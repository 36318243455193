import { basePath, signUpPaths } from 'app/constants/url/shared';
import { create } from 'zustand';

export type TBacklink = {
  href: string;
  label: string;
  target: '_self' | '_blank';
};

export const BackLinkValues = {
  CLEVERKIDS_WEBSITE: {
    href: 'https://cleverkids.io',
    label: 'backToCleverKidsWebsite',
    target: '_blank',
  },
  SELECT_ROLE: {
    href: basePath,
    label: 'selectRole',
    target: '_self',
  },
  SIGN_UP: {
    href: signUpPaths.index,
    label: 'backToSignUpPage',
    target: '_self',
  },
} as const;

type PublicRoutesBacklinkStoreType = {
  backlinkValue: TBacklink;
  setBacklinkValue: (value: TBacklink) => void;
};

export const usePublicRoutesBacklinkStore = create<PublicRoutesBacklinkStoreType>((set) => ({
  backlinkValue: BackLinkValues.CLEVERKIDS_WEBSITE,
  setBacklinkValue: (value: TBacklink) => set({ backlinkValue: value }),
}));
