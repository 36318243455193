import {
  type FilesQuery,
  type FilterSearchQuery,
  type KidBooksQuery,
  type PaginatedSearchQuery,
  type VideosQuery,
} from 'data/validators/query-params';
import { type CONFIG_NAME } from 'data/enums';
import { type LocaleQuery } from 'data/validators/query-params/locale.query';

export function queryKeyBuilder(...args: unknown[]): unknown[] {
  return args.filter((arg) => arg != null);
}

export const booksForKidKeys = {
  all: () => ['books'] as const,
  lists: () => [...booksForKidKeys.all(), 'list'] as const,
  current: () => [...booksForKidKeys.all(), 'current'] as const,
  list: (kidId: string, params?: KidBooksQuery) => [...booksForKidKeys.lists(), kidId, params] as const,
  details: () => [...booksForKidKeys.all(), 'detail'] as const,
  detail: (id: string) => [...booksForKidKeys.details(), id] as const,
};

export const towersDomainTypeKeys = {
  kid: (id: string) => ['towersDomainTypeForKid', id] as const,
};

export const kidPositionKeys = {
  position: () => ['kidPosition'] as readonly unknown[],
  tower: (id: string) => ['kidPositionByTower', id] as const,
  towerBox: (id: string) => ['kidPositionByTowerBox', id] as const,
  towerDomain: (id: string) => ['kidPositionByTowerDomain', id] as const,
  towerField: (id: string) => ['kidPositionByTowerField', id] as const,
  learningDidacticBox: (id: string) => ['kidPositionByLearningDidacticBox', id] as const,
  learningDomain: (id: string) => ['kidPositionByLearningDomain', id] as const,
  learningField: (id: string) => ['kidPositionByLearningField', id] as const,
  learningBox: (id: string) => ['kidPositionByLearningBox', id] as const,
  action: (id: string) => ['kidPositionByAction', id] as const,
  kid: (id: string) => ['kidPositionByKid', id] as const,
  validate: () => [...kidPositionKeys.position(), 'validate'] as const,
};

export const tokenKeys = {
  token: () => ['token'] as const,
  refresh: () => [...tokenKeys.token(), 'refresh'] as const,
  resetPassword: () => ['resetPassword'] as const,
  resetPasscode: () => ['resetPasscode'] as const,
  confirmRegistration: () => ['confirmRegistration'] as const,
  refreshToken: (userId: string) => [...tokenKeys.token(), 'refreshToken', userId] as const,
};

export const videosKeys = {
  all: () => ['videos'] as const,
  withParams: (params: Omit<VideosQuery, 'offset'>) => [...videosKeys.all(), params] as const,
  video: (videoId: string) => [...videosKeys.all(), videoId] as const,
  updateMediaLink: () => [...videosKeys.all(), 'updateMediaLink'] as const,
  mutation: () => [...videosKeys.all(), 'mutation'] as const,
};

export const bookPackagesKeys = {
  all: (params: Omit<PaginatedSearchQuery, 'offset'>) => ['book-package', params] as const,
};

export const filesKeys = {
  common: () => ['files'] as const,
  all: (params: FilesQuery) => [...filesKeys.common(), params] as const,
  folder: (folderId: string, params: FilesQuery) => [...filesKeys.common(), 'folder', folderId, params] as const,
  file: (fileId: string) => [...filesKeys.common(), 'file', fileId] as const,
  saveFile: () => [...filesKeys.common(), 'saveFile'] as const,
};

export const subscriptionKeys = {
  all: () => ['subscriptions'] as const,
  subscription: (subscriptionId: string) => [...subscriptionKeys.all(), 'subscription', subscriptionId] as const,
  userSubscriptions: () => [...subscriptionKeys.all(), 'userSubscriptions'],
  billingPortalUrl: (subscriptionId: string, params?: LocaleQuery) =>
    queryKeyBuilder('subscriptions', 'billingPortalUrl', subscriptionId, params),
  collectionMethod: () => [...subscriptionKeys.all(), 'collectionMethod'] as const,
};

export const learningCultures = {
  all: (params?: Partial<FilterSearchQuery>) => ['learning-cultures', params] as const,
};

export const memberOnboardingKeys = (() => {
  const base = ['memberOnboarding'];
  return {
    base: () => base,
    one: (membershipId?: string) => queryKeyBuilder(...base, membershipId),
    update: () => queryKeyBuilder(...base, 'update'),
  };
})();

export const configKeys = (() => {
  const base = ['config'] as const;
  return {
    all: () => [...base, 'all'],
    one: (configName: CONFIG_NAME) => [...base, configName],
    mutation: () => [...base, 'mutation'],
  };
})();
