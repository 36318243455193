/* eslint-disable consistent-return */
import { secondStore } from 'data/stores/kid/seconds-store';
import { useEffect } from 'react';

export const useTrackTime = (restartDependencies: unknown[], enabled = true): { seconds: number } => {
  useEffect(() => {
    if (!enabled) return;
    const intervalId = setInterval(() => {
      secondStore.setState((prevState) => ({ ...prevState, seconds: prevState.seconds + 1 }));
    }, 1000);

    return () => {
      clearInterval(intervalId);
      secondStore.setState((prevState) => ({ ...prevState, seconds: 1 }));
    };
  }, restartDependencies);

  return secondStore.getState();
};
