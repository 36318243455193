import React from 'react';
import { matchPath, Redirect } from 'react-router-dom';
import { signOutPath } from 'app/constants/url/shared';
import { Unauthorized } from 'data/utils/errors/Unauthorized';

type State = {
  hasError: boolean;
};

type Props = {
  children: React.ReactNode;
};

const WHITELISTED_PATHS = [signOutPath];

export class AuthenticationBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(error: unknown): { hasError: boolean } {
    if (!(error instanceof Unauthorized)) {
      return { hasError: false };
    }

    const url = new URL(window.location.href);
    return { hasError: !AuthenticationBoundary.isWhitelisted(url) };
  }

  private static isWhitelisted(url: URL): boolean {
    return WHITELISTED_PATHS.some((path) => {
      const match = matchPath(url.pathname, {
        path,
        exact: true,
      });
      return match !== null;
    });
  }

  componentDidCatch(error: unknown) {
    if (!(error instanceof Unauthorized)) {
      throw error;
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (!hasError) {
      return children;
    }

    const url = new URL(window.location.href);

    if (AuthenticationBoundary.isWhitelisted(url)) {
      return children;
    }

    this.setState({ hasError: false });
    return (
      <Redirect
        to={{
          pathname: signOutPath,
        }}
      />
    );
  }
}
