import * as Sentry from '@sentry/react';
import { type Breadcrumb, type EventHint } from '@sentry/react';
import history from 'app/history';
import { AxiosError } from 'axios';
import { matchPath } from 'react-router-dom';
import { BrowserTracing } from '@sentry/tracing';

import * as sharedPaths from 'app/constants/url/shared';
import * as adminPaths from 'app/constants/url/admin';
import * as kidPaths from 'app/constants/url/kid';
import { type CaptureContext } from '@sentry/types';
import { RuntimeError } from 'app/utils/error';
import { ZodError } from 'zod';
import { Unauthorized } from 'data/utils/errors/Unauthorized';
import { MODE, SENTRY_DSN } from 'app/constants/env';

type RouteConfig = {
  path: string;
};

type SentryPointerEvent = PointerEvent & {
  path: (HTMLElement | Document)[];
};
const IGNORE =
  /Loading chunk [\d]+ failed|server error|not found|aborterror|network error|no error message|network request failed|request failed with status code 401|ResizeObserver loop completed with undelivered notifications/i;

enum PRIORITY {
  LOW,
  MEDIUM,
  HIGH,
}

const TRACKABLE_ATTRIBUTES = {
  [PRIORITY.HIGH]: ['data-sentry-breadcrumb', 'id'],
  [PRIORITY.MEDIUM]: ['data-name', 'name', 'data-intercom-target'],
  [PRIORITY.LOW]: ['data-testid', 'data-interactive-tour'],
} as const;

const attributesMap = new Map<string, PRIORITY>();
Object.keys(TRACKABLE_ATTRIBUTES).forEach((key) => {
  TRACKABLE_ATTRIBUTES[key].forEach((attr) => {
    attributesMap.set(attr, Number(key) as PRIORITY);
  });
});

const ALLOWED_ENVIRONMENTS = ['production', 'staging'];
const IS_ENABLED = ALLOWED_ENVIRONMENTS.includes(MODE);

export class ErrorService {
  public static captureException(error: unknown, captureContext?: CaptureContext): string {
    return Sentry.captureException(error, captureContext);
  }

  public static captureMessage(message: string, captureContext?: CaptureContext) {
    Sentry.captureMessage(message, captureContext);
  }

  public init() {
    if (IS_ENABLED) {
      const routes = this.generateRouteConfig();
      Sentry.init({
        dsn: SENTRY_DSN,
        environment: MODE,
        debug: MODE === 'staging',
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0,
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes, matchPath),
          }),
          new Sentry.Replay({
            maskAllText: true,
            maskAllInputs: true,
            blockAllMedia: true,
          }),
        ],
        tracesSampleRate: 1,
        beforeBreadcrumb: (breadcrumb, hint) => {
          if (breadcrumb.category === 'ui.click' && hint?.event instanceof PointerEvent) {
            return this.getClickEventBreadcrumbs(breadcrumb, hint.event as SentryPointerEvent);
          }

          return breadcrumb;
        },
        beforeSend: (event, hint) => {
          const eventCopy = { ...event };
          const error = hint?.originalException as EventHint['originalException'];
          const filterStatuses = [400, 401];
          if (error instanceof AxiosError && error.isAxiosError) {
            // don't trace errors if status code is filter statuses
            // and when error message matches ignore regex
            if (error.message.match(IGNORE) || (error.response && filterStatuses.includes(error.response.status))) {
              return null;
            }

            eventCopy.extra = {
              ...eventCopy.extra,
              ...error,
            };
          }

          if (error instanceof RuntimeError) {
            eventCopy.extra = {
              ...eventCopy.extra,
              payload: error.payload,
            };
          }
          if (error instanceof ZodError) {
            eventCopy.extra = {
              ...eventCopy.extra,
              validationError: {
                message: error.message,
              },
            };
          }

          if ((error instanceof Error && error.message.match(IGNORE)) || error instanceof Unauthorized) {
            return null;
          }

          return eventCopy;
        },
      });
    }
  }

  private generateRouteConfig = (paths = [sharedPaths, adminPaths, kidPaths]): RouteConfig[] =>
    paths.reduce<RouteConfig[]>((acc, currentRolePaths) => {
      const rolePaths = Object.values(currentRolePaths).reduce((accumulator: RouteConfig[], currentPath) => {
        let path: any = currentPath;
        if (typeof path === 'function') {
          const argumentsLength = path.length;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          path = path(...Array(argumentsLength).fill(':id'));
        } else if (path && typeof path === 'object') {
          path = this.generateRouteConfig([path]);
          accumulator.push(...path);
          return accumulator;
        }
        accumulator.push({ path });
        return accumulator;
      }, []);
      acc.push(...rolePaths);
      return acc;
    }, []);

  private generateBreadcrumb = (el: HTMLElement, attribute: string) =>
    `${el.tagName.toLowerCase()}[${attribute}="${el.getAttribute(attribute)}"]`;

  private extractTrackableElementsFromClickPath = (event: SentryPointerEvent) => {
    const eventPath = event.composedPath();
    const documentIndex = eventPath.findIndex((el) => el instanceof Document);
    // is document in first half of path
    const isFromParentToChild = documentIndex <= eventPath.length / 2;
    const path = !isFromParentToChild ? eventPath.reverse() : eventPath;

    const distinguishableElements = path.filter((el) => {
      // do not track document
      if (!(el instanceof HTMLElement)) {
        return false;
      }

      if (el.tagName.toLowerCase() === 'body') {
        return false;
      }
      // do not track react root element
      if (el.id === 'root' && el.parentElement === document.body) {
        return false;
      }

      return el.getAttributeNames().some((attribute) => {
        const isTrackable = attributesMap.has(attribute);
        const hasDataAttribute = attribute.startsWith('data-');
        return isTrackable || hasDataAttribute;
      });
    });

    return distinguishableElements.map((el: HTMLElement) => {
      const trackableAttributes = el.getAttributeNames().filter((attr) => attributesMap.has(attr));

      if (trackableAttributes.length === 0) {
        return {
          element: el,
          // cast to 'string' because there is definitely a data attribute in the element, as we filtered it out before
          attribute: el.getAttributeNames().find((attr) => attr.startsWith('data-')) as string,
        };
      }

      const attribute = trackableAttributes.reduce((acc, currentAttr) => {
        const currentPriority = attributesMap.get(currentAttr);
        const accPriority = attributesMap.get(acc);
        if (currentPriority && accPriority) {
          return currentPriority > accPriority ? currentAttr : acc;
        }
        return currentAttr;
      });

      return {
        element: el,
        attribute,
      };
    });
  };

  /**
   * The purpose of this function is to generate breadcrumbs that are more readable.
   * By default, Sentry captures element's classes, that is not very convenient as they are minified and hashed.
   * So, to get more information about the user path, we need add additional data to the breadcrumbs.
   * There is a whitelist of attributes that we can use to create breadcrumbs.
   * In case the element has no attributes from the whitelist, we use any data attribute that the element has.
   *
   * So, instead of "div.jss123 > span.jss456", we create "div[id="sidebar"] > span.jss456"
   */
  private getClickEventBreadcrumbs = (breadcrumb: Breadcrumb, event: SentryPointerEvent) => {
    const elements = this.extractTrackableElementsFromClickPath(event);

    if (elements.length === 0) {
      return breadcrumb;
    }

    const message = elements.reduce(
      (acc, { element, attribute }) =>
        acc.length
          ? `${acc} > ${this.generateBreadcrumb(element, attribute)}`
          : this.generateBreadcrumb(element, attribute),
      '',
    );

    return {
      ...breadcrumb,
      message: `${message} > ${breadcrumb.message}`,
    };
  };
}
