import {
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
  useMutation,
  type UseMutationOptions,
  useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query';
import {
  deleteProfilePicture,
  fetchAllUsers,
  fetchUser,
  removeUser,
  setProfilePicture,
  syncWithMarketingProvider,
  updatePasscode,
  updatePassword,
  updateUser,
} from 'data/api/users';
import { type AxiosAPIError, type ListEntity } from 'data/utils/types';
import { type AxiosError } from 'axios';
import { type IError, type UserEntity } from 'data/types/entities';
import { type PasswordValidator } from 'data/validators/body/password.validator';
import { type PasscodeValidator } from 'data/validators/body/passcode.validator';
import { queryKeyBuilder } from 'data/utils/hookKeys';
import { type PaginatedSearchQuery } from 'data/validators/query-params/paginated-search.query';
import { type EditUserValidator } from 'data/validators/body/user/edit-user.validator';

export const userKeys = (() => {
  const base = 'users';

  return {
    base: () => queryKeyBuilder(base),
    all: (params?: PaginatedSearchQuery) => queryKeyBuilder(base, 'all', params),
    details: (userId: string) => queryKeyBuilder(base, 'details', userId),
    setAvatar: () => queryKeyBuilder(base, 'set-avatar'),
    removeUser: () => queryKeyBuilder(base, 'remove'),
    updatePassword: () => queryKeyBuilder(base, 'update-password'),
    updatePasscode: () => queryKeyBuilder(base, 'update-passcode'),
    syncWithMarketingProvider: () => queryKeyBuilder(base, 'sync-with-marketing-provider'),
  };
})();

export function useFetchUser(
  userId: string,
  options?: Omit<UseQueryOptions<UserEntity, AxiosError<IError>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery({
    queryKey: userKeys.details(userId),
    queryFn: () => fetchUser(userId),
    ...options,
  });
}

export function useFetchAllUsers(
  params?: PaginatedSearchQuery,
  options?: Omit<UseQueryOptions<ListEntity<UserEntity>, AxiosError<IError>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery({
    queryKey: userKeys.all(params),
    queryFn: () => fetchAllUsers(params),
    ...options,
  });
}

export function useFetchInfiniteAllUsers(
  params: Omit<PaginatedSearchQuery, 'offset'>,
  options?: Omit<UseInfiniteQueryOptions<ListEntity<UserEntity>, AxiosError<IError>>, 'queryKey' | 'queryFn'>,
) {
  return useInfiniteQuery(
    userKeys.all(params),
    ({ pageParam = { limit: 10, offset: 0, ...params } }) => fetchAllUsers(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextOffset = allPages.reduce((prev, next) => prev + next.items.length, 0);
        if (lastPage.count - nextOffset > 0) {
          return { limit: 10, offset: nextOffset, ...params };
        }
        return undefined;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export type UpdateUserVariables = {
  userId: string;
  body: EditUserValidator;
};

export const useUpdateUser = (
  options?: Omit<
    UseMutationOptions<UserEntity, AxiosAPIError<EditUserValidator>, UpdateUserVariables, unknown>,
    'mutationFn'
  >,
) => useMutation(({ userId, body }: UpdateUserVariables) => updateUser(userId, body), { ...options });

type UseSetAvatarOptions = Omit<UseMutationOptions<void, AxiosError<IError>, UseSetAvatarArgs, unknown>, 'mutationFn'>;

interface UseSetAvatarArgs {
  userId: string;
  file: File;
}

export function useSetProfilePicture(options?: UseSetAvatarOptions) {
  return useMutation({
    mutationKey: userKeys.setAvatar(),
    mutationFn: ({ userId, file }: UseSetAvatarArgs) => setProfilePicture(userId, file),
    ...options,
  });
}

export function useDeleteProfilePicture(options?: UseMutationOptions<void, AxiosError<IError>, { userId: string }>) {
  return useMutation({
    mutationFn: ({ userId }: { userId: string }) => deleteProfilePicture(userId),
    ...options,
  });
}

type UseRemoveUserOptions = Omit<
  UseMutationOptions<unknown, AxiosError<IError>, string, unknown>,
  'mutationKey' | 'mutationFn'
>;
export const useRemoveUser = (options?: UseRemoveUserOptions) =>
  useMutation({
    mutationKey: userKeys.removeUser(),
    mutationFn: (userId: string) => removeUser(userId),
    ...options,
  });

type UseUpdatePasswordParams = {
  userId: string;
  body: PasswordValidator;
};

type UseUpdatePasswordOptions = Omit<
  UseMutationOptions<UserEntity, AxiosAPIError<PasswordValidator>, UseUpdatePasswordParams, unknown>,
  'mutationKey' | 'mutationFn'
>;

export const useUpdatePassword = (options?: UseUpdatePasswordOptions) =>
  useMutation({
    mutationKey: userKeys.updatePassword(),
    mutationFn: ({ userId, body }: UseUpdatePasswordParams) => updatePassword(userId, body),
    ...options,
  });

type UseUpdatePasscodeParams = {
  userId: string;
  body: PasscodeValidator;
};

type UseUpdatePasscodeOptions = Omit<
  UseMutationOptions<UserEntity, AxiosAPIError<PasscodeValidator>, UseUpdatePasscodeParams, unknown>,
  'mutationKey' | 'mutationFn'
>;

export const useUpdatePasscode = (options?: UseUpdatePasscodeOptions) =>
  useMutation({
    mutationKey: userKeys.updatePasscode(),
    mutationFn: ({ userId, body }: UseUpdatePasscodeParams) => updatePasscode(userId, body),
    ...options,
  });

type UseSyncWithMarketingProviderParams = {
  userId: string;
};

type UseSyncWithMarketingProviderOptions = Omit<
  UseMutationOptions<UserEntity, AxiosError<IError>, UseSyncWithMarketingProviderParams, unknown>,
  'mutationKey' | 'mutationFn'
>;

export const useSyncWithMarketingProvider = (options?: UseSyncWithMarketingProviderOptions) =>
  useMutation({
    mutationKey: userKeys.syncWithMarketingProvider(),
    mutationFn: ({ userId }: UseSyncWithMarketingProviderParams) => syncWithMarketingProvider(userId),
    useErrorBoundary: false,
    ...options,
  });
