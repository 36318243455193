export const buttonVariantSizeCompoundVariants = [
  {
    variant: 'outlined',
    size: 'large',
    class: 'p-4',
  },
  {
    variant: 'contained',
    size: 'medium',
    class: 'py-[14px] shadow-[0_8px_0_-0] active:translate-y-[8px] mb-2',
  },
  {
    variant: 'outlined',
    size: 'medium',
    class: 'py-[10px]',
  },
  {
    variant: 'contained',
    size: 'small',
    class: 'px-3 shadow-[0_4px_0_-0] active:translate-y-[4px] mb-1',
  },
  {
    variant: 'outlined',
    size: 'small',
    class: 'px-2',
  },
  {
    variant: 'contained',
    size: 'extraSmall',
    class: 'px-[10px] shadow-[0_4px_0_-0] active:translate-y-[4px] mb-1',
  },
  {
    variant: 'outlined',
    size: 'extraSmall',
    class: 'px-[6px]',
  },
] as const;
