import { makeStyles } from '@mui/styles';
import { type AppTheme } from '@coach/ui/utils/appTheme';

export const useChipStyles = makeStyles<AppTheme>((theme) => ({
  root: {
    margin: 8,
    backgroundColor: '#F4F8FB',
    border: 'none',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.light} `,
    },
    '&:focus': {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.light,
    },
  },
  chipWithoutDelete: {
    margin: 8,
    backgroundColor: '#F4F8FB',
    border: 'none',
  },
}));
