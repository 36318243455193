import React from 'react';
import { NoActiveGroupError } from 'app/utils/error/NoActiveGroupError';
import { matchPath, Redirect } from 'react-router-dom';
import { commonUrlParams, groupsPath, joinPath } from 'app/constants/url/shared';

type State = {
  hasError: boolean;
};

type Props = {
  children: React.ReactNode;
};

const WHITELISTED_PATHS = [groupsPath, joinPath];

export class ActiveGroupBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(error: unknown): { hasError: boolean } {
    if (!(error instanceof NoActiveGroupError)) {
      return { hasError: false };
    }

    const url = new URL(window.location.href);
    return { hasError: !ActiveGroupBoundary.isWhitelisted(url) };
  }

  private static isWhitelisted(url: URL): boolean {
    return WHITELISTED_PATHS.some((path) => {
      const match = matchPath(url.pathname, {
        path,
        exact: true,
      });
      return match !== null;
    });
  }

  componentDidCatch(error: unknown) {
    if (!(error instanceof NoActiveGroupError)) {
      throw error;
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (!hasError) {
      return children;
    }

    const url = new URL(window.location.href);

    if (ActiveGroupBoundary.isWhitelisted(url)) {
      return children;
    }
    const backlink = url.pathname + url.search;
    const searchParams = new URLSearchParams();
    searchParams.set(commonUrlParams.redirect, backlink);
    this.setState({ hasError: false });

    return (
      <Redirect
        to={{
          pathname: groupsPath,
          search: searchParams.toString(),
        }}
      />
    );
  }
}
