import React from 'react';
import PropTypes from 'prop-types';
import TableBody from '@mui/material/TableBody';

export const Content = ({ children, ...props }) => <TableBody {...props}>{children}</TableBody>;

Content.propTypes = {
  children: PropTypes.node,
};

Content.defaultProps = {
  children: null,
};
