import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Text } from '../text';
import useStyles from './styles';

export const KidsTabs = ({ activeTab, onChange, tabs, className }) => {
  const s = useStyles();

  const handleClick = (value) => () => {
    onChange(value);
  };

  return (
    <div className={cx(s.root, className)}>
      {tabs.map(({ value, label, icon }) => (
        <button
          key={`${value}-${label}`}
          type="button"
          onClick={handleClick(value)}
          className={cx(s.tab, activeTab === value ? s.tab_active : '')}
        >
          {icon}
          <Text component="span" color="white" size={21}>
            {label}
          </Text>
        </button>
      ))}
    </div>
  );
};

KidsTabs.propTypes = {
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  className: PropTypes.string,
};

KidsTabs.defaultProps = {
  className: null,
};
