import React from 'react';
import cx from 'classnames';
import './styles.css';

interface KidLoaderProps {
  className?: string;
}

const Loader = ({ className }: KidLoaderProps) => (
  <div className={cx('loaderContainer', className)}>
    <div className="elem elem1" />
    <div className="elem elem2" />
    <div className="elem elem3" />
    <div className="elem elem4" />
  </div>
);

export default Loader;
