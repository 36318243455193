import { fromJS } from 'immutable';
import * as constants from 'data/constants/learning-cultures';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const learningCultureProgress = new LoadingProgress('learningCulture');
export const learningCulturesPagination = new Pagination('learningCultures');
export const learningCultureCreationProgress = new LoadingProgress('learningCultureCreationProgress');
export const learningCultureRemovalProgress = new LoadingProgress('learningCultureRemovalProgress');
export const learningCultureTowers = new LoadingProgress('learningCultureTowers');
export const learningCultureGrades = new LoadingProgress('learningCultureGrades');
export const learningCultureGradesPagination = new Pagination('learningCultureGrades');
export const learningCultureKidFields = new LoadingProgress('learningCultureKidFields');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { learningCultures },
    } = payload;
    newState.mergeDeepIn(['entities'], fromJS(learningCultures));
  });

const loadLearningCultures = (state, action) =>
  state.withMutations((newState) => {
    const {
      count,
      limit,
      result,
      entities: { learningCultures },
    } = action.payload;
    newState.setIn(['entities'], fromJS(learningCultures));
    learningCulturesPagination.set(newState, count, limit, fromJS(result));
    learningCultureProgress.setLoaded(newState);
  });

const loadLearningCulture = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
  });

const loadLearningCulturesTowers = (state, action) =>
  state.withMutations((newState) => {
    const {
      entities: { towers },
      result,
    } = action.payload;
    newState.setIn(['towers'], fromJS(towers));
    newState.setIn(['towersId'], fromJS(result));
    learningCultureTowers.setLoaded(newState);
  });

const loadKidFieldsWithProgresses = (state, action) =>
  state.withMutations((newState) => {
    const {
      entities: { towers },
      result,
    } = action.payload;
    newState.setIn(['kidFields'], fromJS(towers));
    newState.setIn(['kidFieldsId'], fromJS(result));
    learningCultureKidFields.setLoaded(newState);
  });

const createLearningCulture = (state, action) =>
  state.withMutations((newState) => {
    const { result } = action.payload;
    mergeData(newState, action.payload);
    newState.set('learningCulturesPageItems', state.get('learningCulturesPageItems')?.concat(fromJS(result)));
    newState.set('learningCulturesTotalItems', state.get('learningCulturesTotalItems') + 1);
    learningCultureCreationProgress.setLoaded(newState);
  });

const editLearningCulture = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    learningCultureProgress.setLoaded(newState);
  });

const deleteLearningCulture = (state, action) =>
  state.withMutations((newState) => {
    const { learningCultureId } = action;
    newState.deleteIn(['entities', learningCultureId]);
    newState.set(
      'learningCulturesPageItems',
      state.get('learningCulturesPageItems').filter((el) => el !== learningCultureId),
    );
    newState.set('learningCulturesTotalItems', state.get('learningCulturesTotalItems') - 1);
    learningCultureRemovalProgress.setLoaded(newState);
  });

const loadLearningCultureGrades = (state, action) =>
  state.withMutations((newState) => {
    const {
      count,
      result,
      entities: { grades },
    } = action.payload;
    newState.setIn(['grades'], fromJS(grades));
    learningCultureGradesPagination.set(newState, count, null, fromJS(result));
    learningCultureGrades.setLoaded(newState);
  });

const initialState = fromJS({
  entities: {},
});

const clear = (state) =>
  state.withMutations((newState) => {
    learningCulturesPagination.clear(newState);
    learningCultureProgress.clear(newState);
    learningCultureProgress.clear(newState);
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
      return learningCultureProgress.setLoading(state);
    case constants.LOAD_KID_FIELDS_START:
      return learningCultureKidFields.setLoading(state);
    case constants.LOAD_KID_FIELDS_SUCCESS:
      return loadKidFieldsWithProgresses(state, action);
    case constants.LOAD_KID_FIELDS_FAILED:
      return learningCultureKidFields.setLoadFailed(state);
    case constants.LOAD_TOWERS_START:
      return learningCultureTowers.setLoading(state);
    case constants.LOAD_GRADES_START:
      return learningCultureGrades.setLoading(state);
    case constants.LOAD_CULTURE_SUCCESS:
      return loadLearningCulture(state, action);
    case constants.CREATION_START:
      return learningCultureCreationProgress.setLoading(state);
    case constants.REMOVING_START:
      return learningCultureRemovalProgress.setLoading(state);
    case constants.LOAD_SUCCESS:
      return loadLearningCultures(state, action);
    case constants.LOAD_GRADES_SUCCESS:
      return loadLearningCultureGrades(state, action);
    case constants.LOAD_TOWERS_SUCCESS:
      return loadLearningCulturesTowers(state, action);
    case constants.CREATION_SUCCESS:
      return createLearningCulture(state, action);
    case constants.EDITING_SUCCESS:
      return editLearningCulture(state, action);
    case constants.REMOVING_SUCCESS:
      return deleteLearningCulture(state, action);
    case constants.CREATION_FAILED:
      return learningCultureCreationProgress.setLoadFailed(state);
    case constants.LOAD_FAILED:
      return learningCultureProgress.setLoadFailed(state, action);
    case constants.LOAD_TOWERS_FAILED:
      return learningCultureTowers.setLoadFailed(state);
    case constants.LOAD_GRADES_FAILED:
      return learningCultureGrades.setLoadFailed(state);
    case constants.REMOVING_FAILED:
      return learningCultureRemovalProgress.setLoadFailed(state);
    case constants.CLEAR:
      return clear(state);
    case constants.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
