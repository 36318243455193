import api from 'data/axios/react-query-api';
import {
  type GroupEntity,
  type GroupListEntity,
  type KidDetailEntity,
  type RoleEntity,
  type SubscriptionEntity,
  type UrlEntity,
} from 'data/types/entities';
import { type ListEntity } from 'data/utils/types';
import { type KidsQuery, type PaginatedSearchQuery } from 'data/validators/query-params';
import { type AddKidValidator } from 'data/validators';
import { type GroupPricesEntity } from 'data/types/entities/group-prices.entity';
import { type GroupValidator } from 'data/validators/body/groups/group.validator';
import { type MembershipListEntity } from 'data/types/entities/membership-list.entity';
import { type MembershipQuery } from 'data/validators/query-params/membership.query';
import { type GroupInviteEntity } from 'data/types/entities/group/group-invite.entity';
import { type InvitesQuery } from 'data/validators/query-params/invites.query';
import { type BookLicenseListEntity } from 'data/types/entities/books/book-license-list.entity';
import { type CoachMembershipEntity } from 'data/types/entities/memberships/coach-membership.entity';
import { type GroupTrialEntity } from 'data/types/entities/group/group-trial.entity';
import { type CreateGroupValidator } from 'data/validators/body/groups/create-group.validator';
import { type GroupMetadataEntity } from 'data/types/entities/group/group-metadata.entity';
import { type MEMBERSHIP_TYPE } from 'data/enums';

export const fetchAllGroups = (params: PaginatedSearchQuery) =>
  api.get<ListEntity<GroupListEntity>>('/groups', { params });

export const addKidToGroup = (groupId: string, body: AddKidValidator): Promise<RoleEntity> =>
  api.post(`/groups/${groupId}/kids`, body);

export const fetchGroup = (id: string): Promise<GroupEntity> => api.get(`/groups/${id}`);

export const fetchGroupMetadata = (id: string): Promise<GroupMetadataEntity> => api.get(`/groups/${id}/metadata`);

export const fetchGroupSubscription = (groupId: string): Promise<SubscriptionEntity> =>
  api.get(`/groups/${groupId}/subscription`);

export const removeKidFromGroup = (groupId: string, kidId: string) => api.delete(`/groups/${groupId}/kids/${kidId}`);

export const fetchGroupPricing = (groupId: string) => api.get<GroupPricesEntity>(`/groups/${groupId}/pricing`);

export const createGroup = (body: CreateGroupValidator) => api.post<GroupEntity>('/groups', body);

export const removeGroup = (groupId: string) => api.delete<null>(`/groups/${groupId}`);

export const editGroup = (groupId: string, body: Partial<GroupValidator>) =>
  api.patch<GroupEntity>(`/groups/${groupId}`, body);

export const fetchAllGroupKids = (groupId: string, params: KidsQuery = {}) =>
  api.get<ListEntity<KidDetailEntity>>(`/groups/${groupId}/kids`, { params });

export const fetchGroupMembers = (groupId: string, params?: MembershipQuery) =>
  api.get<ListEntity<MembershipListEntity>>(`/groups/${groupId}/memberships`, { params });

export const fetchCurrentGroupMember = (groupId: string) =>
  api.get<CoachMembershipEntity>(`/groups/${groupId}/membership`);

export const fetchGroupInvites = (groupId: string, params?: InvitesQuery) =>
  api.get<ListEntity<GroupInviteEntity>>(`/groups/${groupId}/invites`, { params });

export const deleteGroupMembership = (groupId: string, body: { roleId: string }) =>
  api.delete<null>(`/groups/${groupId}/memberships`, { data: body });

export const fetchStripeCustomerUrl = (groupId: string) => api.get<UrlEntity>(`/groups/${groupId}/stripe/customer`);

export const fetchGroupBookLicenses = (groupId: string) =>
  api.get<ListEntity<BookLicenseListEntity>>(`/groups/${groupId}/booklicenses`);

export const fetchGroupTrial = (groupId: string) => api.get<GroupTrialEntity>(`/groups/${groupId}/trial`);

export const updateMembershipType = (groupId: string, membershipId: string, type: MEMBERSHIP_TYPE) =>
  api.patch(`/groups/${groupId}/memberships/${membershipId}/type`, { type });
