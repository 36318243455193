import React from 'react';
import MUISvgIcon, { type SvgIconClassKey, type SvgIconTypeMap as MUISvgIconTypeMap } from '@mui/material/SvgIcon';
import { cva, type VariantProps } from 'class-variance-authority';
import cx from 'app/utils/helpers/cx';
import useBreakpoints from 'app/utils/hooks/useBreakpoints';
import { getBreakpointValue } from 'app/utils/helpers/get-breakpoint-value';
import { type OverridableComponent, type OverrideProps } from 'ui/types';
import { type BreakpointValues } from 'app/constants/breakpoints';

const svgIconStyles = cva('', {
  variants: {
    fontSize: {
      inherit: 'font-size-inherit',
      large: 'text-5xl',
      medium: 'text-[32px]',
      small: 'text-2xl',
      extraSmall: 'text-base',
    },
  },
  defaultVariants: {
    fontSize: 'medium',
  },
});

type FontSize = VariantProps<typeof svgIconStyles>['fontSize'];
type FontSizeBreakpointValues = BreakpointValues<FontSize>;
type SvgIconTypeMapProps = Omit<VariantProps<typeof svgIconStyles>, 'fontSize'> &
  Omit<MUISvgIconTypeMap['props'], 'fontSize'> & {
    fontSize?: FontSizeBreakpointValues | FontSize;
  };

export type SvgIconTypeMap<C extends React.ElementType = 'svg'> = {
  props: SvgIconTypeMapProps;
  defaultComponent: C;
  classKey: SvgIconClassKey;
};

export type SvgIconProps<C extends React.ElementType = SvgIconTypeMap['defaultComponent']> = OverrideProps<
  SvgIconTypeMap<C>,
  C
>;

const SvgIcon: OverridableComponent<SvgIconTypeMap> = React.forwardRef(function SvgIcon<C extends React.ElementType>(
  { fontSize, className, ...props }: SvgIconProps<C>,
  ref: React.ForwardedRef<SVGSVGElement>,
) {
  const { active } = useBreakpoints();
  const isFontSizeObject = !!fontSize && typeof fontSize === 'object';
  const fontSizeValue = isFontSizeObject ? getBreakpointValue<FontSize>(fontSize, active) : fontSize;
  const styles = svgIconStyles({ fontSize: fontSizeValue });

  return <MUISvgIcon ref={ref} className={cx(styles, className)} {...props} />;
});

export default SvgIcon;
