import { useAuthData } from 'data/hooks/utils/useAuthData';
import { useHistory } from 'react-router-dom';
import { baseRolePaths } from 'app/constants/url/shared';
import { useActions, useSupportRedirectPath } from 'data/stores/auth-store';
import { type LocationDescriptor } from 'history';

export const useLeaveSupportMode = () => {
  const history = useHistory();
  const { role } = useAuthData();

  const redirectPath = useSupportRedirectPath() || baseRolePaths[role];
  const { setSupportAccessToken, setSupportRedirectPath } = useActions();

  const leaveSupportMode = (redirect: LocationDescriptor = redirectPath) => {
    if (!redirect) {
      console.error('Invalid redirect URL');
      return;
    }

    history.push(redirect);
    setSupportAccessToken(undefined);
    setSupportRedirectPath(undefined);
  };

  return {
    leaveSupportMode,
  };
};
