import { TEST } from 'app/constants/env';

interface Credentials {
  id: string;
  password: string;
  name?: string;
  iconURL?: string;
}

export const saveCredentials = async ({ id, password, name, iconURL }: Credentials) => {
  // experimental API - not supported in all browsers
  if (!window.PasswordCredential || TEST) return;

  // @ts-expect-error TS(2351): This expression is not constructable.
  const cred = new PasswordCredential({
    id,
    password,
    name,
    iconURL,
  });

  await navigator.credentials.store(cred);
};

export function hasTouchScreen(): boolean {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}
