const prefix = (type) => `eureka/booklicenses/${type}`;

export const CREATE_START = prefix('CREATE_START');
export const CREATE_SUCCESS = prefix('CREATE_SUCCESS');
export const CREATE_FAILED = prefix('CREATE_FAILED');

export const GET_ALL_START = prefix('GET_ALL_START');
export const GET_ALL_SUCCESS = prefix('GET_ALL_SUCCESS');
export const GET_ALL_FAILED = prefix('GET_ALL_FAILED');

export const GET_START = prefix('GET_START');
export const GET_SUCCESS = prefix('GET_SUCCESS');
export const GET_FAILED = prefix('GET_FAILED');

export const UPDATE_START = prefix('UPDATE_START');
export const UPDATE_SUCCESS = prefix('UPDATE_SUCCESS');
export const UPDATE_FAILED = prefix('UPDATE_FAILED');

export const REMOVE_START = prefix('REMOVE_START');
export const REMOVE_SUCCESS = prefix('REMOVE_SUCCESS');
export const REMOVE_FAILED = prefix('REMOVE_FAILED');
