const prefix = (type) => `eureka/grades/${type}`;

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');
export const EDITING_SUCCESS = prefix('EDITING_SUCCESS');
export const LOAD_GRADE_SUCCESS = prefix('LOAD_GRADE_SUCCESS');
export const EDITING_START = prefix('EDITING_START');
export const EDITING_FAILED = prefix('EDITING_FAILED');
export const CREATION_START = prefix('CREATION_START');
export const CREATION_SUCCESS = prefix('CREATION_SUCCESS');
export const CREATION_FAILED = prefix('CREATION_FAILED');
export const REMOVING_START = prefix('REMOVING_START');
export const REMOVING_SUCCESS = prefix('REMOVING_SUCCESS');
export const REMOVING_FAILED = prefix('REMOVING_FAILED');
export const DRAGANDDROP_START = prefix('DRAGANDDROP_START');
export const DRAGANDDROP_SUCCESS = prefix('DRAGANDDROP_SUCCESS');
export const DRAGANDDROP_FAILED = prefix('DRAGANDDROP_FAILED');
export const CLEAR = prefix('CLEAR');
export const CLEAR_FIELDS = prefix('CLEAR_FIELDS');
export const CLEAR_STATE = prefix('CLEAR_STATE');
export const LOAD_DOMAINS_START = prefix('LOAD_DOMAINS_START');
export const LOAD_DOMAINS_SUCCESS = prefix('LOAD_DOMAINS_SUCCESS');
export const LOAD_DOMAINS_FAILED = prefix('LOAD_DOMAINS_FAILED');
export const LOAD_KID_GRADES_START = prefix('LOAD_KID_GRADES_START');
export const LOAD_KID_GRADES_SUCCESS = prefix('LOAD_KID_GRADES_SUCCESS');
export const LOAD_KID_GRADES_FAILED = prefix('LOAD_KID_GRADES_FAILED');
export const LOAD_GRADES_FIELD_DOMAINS_START = prefix('LOAD_GRADES_FIELD_DOMAINS_START');
export const LOAD_GRADES_FIELD_DOMAINS_SUCCESS = prefix('LOAD_GRADES_FIELD_DOMAINS_SUCCESS');
export const LOAD_GRADES_FIELD_DOMAINS_FAILED = prefix('LOAD_GRADES_FIELD_DOMAINS_FAILED');
