const prefix = (type) => `eureka/actions/${type}`;

export const FETCH_ACTIONS_START = prefix('FETCH_ACTIONS_START');
export const FETCH_ACTIONS_SUCCESS = prefix('FETCH_ACTIONS_SUCCESS');
export const FETCH_ACTIONS_FAILED = prefix('FETCH_ACTIONS_FAILED');

export const DUPLICATE_AND_EDIT_START = prefix('DUPLICATE_AND_EDIT_START');
export const DUPLICATE_AND_EDIT_SUCCESS = prefix('DUPLICATE_AND_EDIT_SUCCESS');
export const DUPLICATE_AND_EDIT_FAILED = prefix('DUPLICATE_AND_EDIT_FAILED');

export const REMOVE_ACTION_START = prefix('REMOVE_ACTION_START');
export const REMOVE_ACTION_SUCCESS = prefix('REMOVE_ACTION_SUCCESS');
export const REMOVE_ACTION_FAILED = prefix('REMOVE_ACTION_FAILED');

export const REMOVE_FMM_START = prefix('REMOVE_FMM_START');
export const REMOVE_FMM_SUCCESS = prefix('REMOVE_FMM_SUCCESS');
export const REMOVE_FMM_FAILED = prefix('REMOVE_FMM_FAILED');

export const CREATE_FMM_START = prefix('CREATE_FMM_START');
export const CREATE_FMM_SUCCESS = prefix('CREATE_FMM_SUCCESS');
export const CREATE_FMM_FAILED = prefix('CREATE_FMM_FAILED');

export const LOAD_QUESTION_FILES_START = prefix('QUESTION_FILES_LOAD_START');
export const LOAD_QUESTION_FILES_SUCCESS = prefix('QUESTION_FILES_LOAD_SUCCESS');
export const LOAD_QUESTION_FILES_FAILED = prefix('QUESTION_FILES_LOAD_FAILED');

export const UPLOAD_QUESTION_FILES_START = prefix('UPLOAD_QUESTION_FILES_START');
export const UPLOAD_QUESTION_FILES_SUCCESS = prefix('UPLOAD_QUESTION_FILES_SUCCESS');
export const UPLOAD_QUESTION_FILES_FAILED = prefix('UPLOAD_QUESTION_FILES_FAILED');

export const REMOVE_QUESTION_FILE_START = prefix('REMOVE_QUESTION_FILE_START');
export const REMOVE_QUESTION_FILE_SUCCESS = prefix('REMOVE_QUESTION_FILE_SUCCESS');
export const REMOVE_QUESTION_FILE_FAILED = prefix('REMOVE_QUESTION_FILE_FAILED');

export const FETCH_ACTION_START = prefix('FETCH_ACTION_START');
export const FETCH_ACTION_SUCCESS = prefix('FETCH_ACTION_SUCCESS');
export const FETCH_ACTION_FAILED = prefix('FETCH_ACTION_FAILED');

export const EDITING_ACTION_START = prefix('EDITING_ACTION_START');
export const EDITING_ACTION_SUCCESS = prefix('EDITING_ACTION_SUCCESS');
export const EDITING_ACTION_FAILED = prefix('EDITING_ACTION_FAILED');

export const FINISH_ACTION_START = prefix('FINISH_ACTION_START');
export const FINISH_ACTION_SUCCESS = prefix('FINISH_ACTION_SUCCESS');
export const FINISH_ACTION_FAILED = prefix('FINISH_ACTION_FAILED');

export const FETCH_ACTION_STATISTICS_START = prefix('FETCH_ACTION_STATISTICS_START');
export const FETCH_ACTION_STATISTICS_SUCCESS = prefix('FETCH_ACTION_STATISTICS_SUCCESS');
export const FETCH_ACTION_STATISTICS_FAILED = prefix('FETCH_ACTION_STATISTICS_FAILED');

export const FETCH_ACTION_COUNT = prefix('FETCH_ACTION_COUNT');

export const CLEAR_ARCHIVE = prefix('CLEAR_ARCHIVE');
export const CLEAR_STATE = prefix('CLEAR_STATE');
