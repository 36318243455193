import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EDUCATION_STRATEGY } from 'data/enums/question';
import { useProgressStoreActions } from 'data/stores/progress-store';
import { PROGRESS_HELPERS } from './useProgress';

const useValidate = () => {
  const { towerId } = useParams<{ learningLineId?: string; towerId?: string }>();
  const educationStrategy = towerId ? EDUCATION_STRATEGY.TOWER : EDUCATION_STRATEGY.LEARNING_LINE;
  const { setValidationMutation } = useProgressStoreActions();

  const mutation = PROGRESS_HELPERS.validation[educationStrategy]();

  useEffect(() => {
    setValidationMutation(mutation);
  }, [mutation.status]);

  return mutation;
};

export default useValidate;
