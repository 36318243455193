import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import { type AppTheme } from '@coach/ui/utils/appTheme';

const useStyles = makeStyles<AppTheme>((theme) => ({
  table: {
    minWidth: 750,
    '& thead a th': {
      fontSize: 14,
    },
    '& tbody a th': {
      fontSize: 16,
    },
    '& thead tr th': {
      fontSize: 14,
    },
    '& tbody tr th': {
      fontSize: 16,
    },
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  stripped: {
    '& tbody a:nth-child(even)': {
      background: `${theme.palette.backgroundColor} !important`,
    },
    '& tbody tr:nth-child(even)': {
      background: `${theme.palette.backgroundColor} !important`,
    },
  },
  hover: {
    '& tbody a:hover': {
      background: `${theme.palette.primary.light} !important`,
    },
    '& tbody tr:hover': {
      background: `${theme.palette.primary.light} !important`,
    },
  },
  bordered: {
    '& td, th': {
      border: `1px solid ${theme.palette.borderColor}`,
      borderTop: 'none',
    },
    '& a td, a th': {
      borderLeft: 'none',
      borderRight: 'none',
    },
    '& tr td, tr th': {
      borderLeft: 'none',
      borderRight: 'none',
    },
  },
}));

export const CustomTable = ({ children, className }) => {
  const s = useStyles();

  return (
    <div className={s.tableWrapper}>
      <Table className={cx(s.table, s.hover, s.stripped, s.bordered, className)} aria-labelledby="tableTitle">
        {children}
      </Table>
    </div>
  );
};

CustomTable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

CustomTable.defaultProps = {
  children: null,
  className: null,
};
