import { APP_VERSION, DEV, TEST } from 'app/constants/env';
import { z } from 'zod';
import { MILLISECONDS } from 'app/constants/data';
import { RuntimeError } from 'app/utils/error';
import { ErrorService } from 'app/utils/services/error-tracking';

const STORAGE_KEY = 'version-manager';

const schema = z.object({
  reloads: z.array(
    z.object({
      timestamp: z.number(), // timestamp in milliseconds
    }),
  ),
  expiresAt: z.number().default(0),
});

type Schema = z.infer<typeof schema>;

const TTL = MILLISECONDS.DAY;
const HOUR_FREQUENCY_LIMIT = 10;

class VersionManager {
  private isReloadRequired = false;

  public setVersion(version: string | undefined) {
    if (!version || this.isReloadRequired) {
      return;
    }

    const value = this.getValue();
    this.checkTTL(value);

    this.isReloadRequired = APP_VERSION !== version;
  }

  public sync() {
    if (!this.isReloadRequired) {
      return;
    }

    if (!this.shouldReload()) {
      const error = new RuntimeError('App reload frequency is too high', {
        reloads: this.getValue()?.reloads,
        APP_VERSION,
      });
      console.error(error);
      ErrorService.captureException(error);
      return;
    }

    this.logReload();
    window.location.reload();
  }

  private shouldReload(): boolean {
    if (TEST || DEV) {
      return false;
    }
    const value = this.getValue();
    if (!value) {
      return true;
    }

    const { reloads } = value;
    const now = Date.now();
    let reloadsInLastHour = 0;

    for (const reload of reloads) {
      if (now - reload.timestamp < MILLISECONDS.HOUR) {
        reloadsInLastHour += 1;
      }
    }

    return reloadsInLastHour < HOUR_FREQUENCY_LIMIT;
  }

  private logReload(): void {
    const value = this.getValue();

    if (!value) {
      const newValue: Schema = {
        reloads: [{ timestamp: Date.now() }],
        expiresAt: Date.now() + TTL,
      };
      localStorage.setItem(STORAGE_KEY, JSON.stringify(newValue));
      return;
    }

    value.reloads.push({ timestamp: Date.now() });

    localStorage.setItem(STORAGE_KEY, JSON.stringify(value));
  }

  private getValue(): Schema | undefined {
    const value = localStorage.getItem(STORAGE_KEY);

    if (!value) {
      return undefined;
    }

    try {
      const res = schema.safeParse(JSON.parse(value));
      if (res.success) {
        return res.data;
      }
      return undefined;
    } catch (error) {
      return undefined;
    }
  }

  private checkTTL(value: Schema | undefined): void {
    if (!value) {
      return;
    }

    const { expiresAt } = value;
    const now = Date.now();
    if (expiresAt > now) {
      return;
    }

    localStorage.removeItem(STORAGE_KEY);
  }
}

export const versionManager = new VersionManager();
