import { type GroupsQuery, type KidsQuery, type KidsReportsQuery, type RolesQuery } from 'data/validators/query-params';
import { type GroupEntity, type RoleEntity } from 'data/types/entities';
import { type ListEntity } from 'data/utils/types';
import api from 'data/axios/react-query-api';
import { type RoleQuery } from 'data/validators/query-params/role.query';
import { type RoleDetailsEntity } from 'data/types/entities/role-details.entity';

export const fetchAllRoles = (params: RolesQuery = {}): Promise<ListEntity<RoleEntity>> =>
  api.get('/roles', { params });

export function fetchRole<P extends RoleQuery>(roleId: string, params?: P): Promise<RoleDetailsEntity<P['populate']>> {
  return api.get(`/roles/${roleId}`, { params });
}

export const deleteRole = (roleId: string) => api.delete<null>(`/roles/${roleId}`);

export const fetchRolesForCurrentCoach = (params: RolesQuery): Promise<ListEntity<RoleEntity>> =>
  api.get(`/roles/coach`, { params });

export const fetchAllGroupsByCoach = (roleId: string, params: GroupsQuery): Promise<ListEntity<GroupEntity>> =>
  api.get(`/roles/${roleId}/groups`, { params });

export const fetchAllKidsByCoach = (roleId: string, params?: KidsQuery): Promise<ListEntity<RoleEntity>> =>
  api.get(`/roles/coach/${roleId}/kids`, { params });

export const fetchKidsReportsByCoach = (roleId: string, params?: KidsReportsQuery): Promise<ListEntity<RoleEntity>> =>
  api.get(`/roles/${roleId}/kids/reports`, { params });
