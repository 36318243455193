import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { type TokenData, TokenDataSchema } from 'data/types/token-data';
import { RuntimeError } from 'app/utils/error';
import { jwtDecode } from 'jwt-decode';
import { ErrorService } from 'app/utils/services/error-tracking';
import CookieService from 'app/utils/services/cookies';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'app/constants/store';
import { isPublicPath } from 'app/constants/url/shared';
import { PROD } from 'app/constants/env';
import { ROLE } from 'data/enums/role';
import { type ExtractState, type ISOStringDate } from '../utils/types';

type Options = {
  cookie?: boolean;
};

type TokenOptions = Options & {
  expiresAt?: ISOStringDate;
};

type AuthStore = {
  accessToken: string | undefined;
  accessTokenData: TokenData | undefined;
  supportAccessToken: string | undefined;
  supportRedirectPath: string | undefined;
  refreshToken: string | undefined;

  actions: {
    setAccessToken: (accessToken: string | undefined, options?: TokenOptions) => void;
    setRefreshToken: (refreshToken: string | undefined, options?: TokenOptions) => void;
    setSupportAccessToken: (supportAccessToken: string | undefined) => void;
    setSupportRedirectPath: (supportRedirectPath: string | undefined) => void;
    init: () => void;
    clearTokens: (options?: Options) => void;
  };
};

/**
 * @returns TokenData or throws an error
 */
export const decodeAccessToken = (accessToken: string) => TokenDataSchema.parse(jwtDecode<TokenData>(accessToken));

export const catchDecodingError = (error: unknown) => {
  console.error({ error });
  ErrorService.captureException(new RuntimeError('Failed to decode access token', { error }));
};

/**
 * Authentication store that handles all the necessary data for authentication
 * It's a vanilla zustand store, so it's not connected to React and can be used anywhere.
 *
 * Note: the state is temporary, so it will be lost after reloading the page.
 * Because of that, you also need to have a long-lived storage for the tokens, like cookies or localStorage.
 *
 * This store is not exported directly, we only export getters and hooks,
 * so the user can't accidentally subscribe to the whole store
 *
 * The reason to export both getters and hooks is that hooks can subscribe to the store changes,
 * and re-render the component, which is not possible with getters
 */
const authStore = createStore<AuthStore>()(
  devtools(
    persist(
      (set, get) => ({
        accessToken: undefined,
        refreshToken: undefined,
        accessTokenData: undefined,
        /**
         * It's possible to log in as another user via admin panel for support purposes.
         * So, when we send the post request to log in as the target user, we get the access token in the response.
         * This token is necessary to make requests to the API on behalf of the target user.
         * However, we do not need to store the JWT token in app cookies as it's not an initial user token.
         * For this reason, we store the token inside the application state, as it's temporary.
         * So, after reloading the page, the token will be lost and the admin can return to their panel.
         * But it persists in sessionStorage for FAMILY-KID relationship
         */
        supportAccessToken: undefined,
        supportRedirectPath: undefined,
        actions: {
          setAccessToken: (accessToken, options) => {
            const accessTokenData = (() => {
              try {
                return accessToken ? decodeAccessToken(accessToken) : undefined;
              } catch (error) {
                if (!isPublicPath(window.location.pathname)) {
                  catchDecodingError(error);
                }
                return undefined;
              }
            })();

            if (options?.cookie) {
              CookieService.set(ACCESS_TOKEN_KEY, accessToken, {
                expires: options.expiresAt ? new Date(options.expiresAt) : undefined,
              });
            }

            set({
              accessToken,
              accessTokenData,
            });
          },
          setRefreshToken: (refreshToken, options) => {
            if (options?.cookie) {
              CookieService.set(REFRESH_TOKEN_KEY, refreshToken, {
                expires: options.expiresAt ? new Date(options.expiresAt) : undefined,
              });
            }
            set({
              refreshToken,
            });
          },
          setSupportAccessToken: (supportAccessToken: string | undefined) => set({ supportAccessToken }),
          setSupportRedirectPath: (supportRedirectPath: string | undefined) => set({ supportRedirectPath }),
          init: () => {
            const { setAccessToken, setRefreshToken } = get().actions;
            setAccessToken(CookieService.get(ACCESS_TOKEN_KEY));
            setRefreshToken(CookieService.get(REFRESH_TOKEN_KEY));
          },
          clearTokens: (options) => {
            if (options?.cookie) {
              CookieService.remove(ACCESS_TOKEN_KEY, { path: '/' });
              CookieService.remove(REFRESH_TOKEN_KEY, { path: '/' });
            }

            set({
              accessToken: undefined,
              accessTokenData: undefined,
              refreshToken: undefined,
              supportAccessToken: undefined,
              supportRedirectPath: undefined,
            });
          },
        },
      }),
      {
        name: 'auth-store',
        storage: createJSONStorage(() => sessionStorage),
        partialize: (state) => {
          const { supportAccessToken, accessTokenData } = state;
          if (!supportAccessToken || !accessTokenData) return undefined;

          const supportAccessTokenData = decodeAccessToken(supportAccessToken);
          const shouldPersist = accessTokenData.role === ROLE.COACH && supportAccessTokenData.role === ROLE.KID;

          return { supportAccessToken: shouldPersist ? supportAccessToken : undefined };
        },
      },
    ),
    {
      name: 'auth-store',
      enabled: !PROD,
    },
  ),
);

type Params<U> = Parameters<typeof useStore<typeof authStore, U>>;

// Selectors
const accessTokenSelector = (state: ExtractState<typeof authStore>) => state.accessToken;
const accessTokenDataSelector = (state: ExtractState<typeof authStore>) => state.accessTokenData;
const refreshTokenSelector = (state: ExtractState<typeof authStore>) => state.refreshToken;
const supportAccessTokenSelector = (state: ExtractState<typeof authStore>) => state.supportAccessToken;
const supportRedirectPathSelector = (state: ExtractState<typeof authStore>) => state.supportRedirectPath;
const actionsSelector = (state: ExtractState<typeof authStore>) => state.actions;

// getters
export const getAccessToken = () => accessTokenSelector(authStore.getState());
export const getAccessTokenData = () => accessTokenDataSelector(authStore.getState());
export const getSupportAccessToken = () => supportAccessTokenSelector(authStore.getState());
export const getSupportRedirectPath = () => supportRedirectPathSelector(authStore.getState());

/**
 * @returns the active token data
 *
 * Active token is the support token if it exists, otherwise it's the initial user token
 */
export const getTokenData = () => {
  /**
   * Support token has a higher priority than the initial user token.
   */
  const supportAccessToken = getSupportAccessToken();
  if (supportAccessToken) {
    try {
      return decodeAccessToken(supportAccessToken);
    } catch (error) {
      catchDecodingError(error);
    }
  }

  return getAccessTokenData();
};
export const getRefreshToken = () => refreshTokenSelector(authStore.getState());
export const getActions = () => actionsSelector(authStore.getState());

function useAuthStore<U>(selector: Params<U>[1], equalityFn?: Params<U>[2]) {
  return useStore(authStore, selector, equalityFn);
}

// Hooks
export const useAccessToken = () => useAuthStore(accessTokenSelector);
export const useAccessTokenData = () => useAuthStore(accessTokenDataSelector);
export const useSupportAccessToken = () => useAuthStore(supportAccessTokenSelector);
export const useSupportRedirectPath = () => useAuthStore(supportRedirectPathSelector);
export const useTokenData = () => useAuthStore(getTokenData);
export const useRefreshToken = () => useAuthStore(refreshTokenSelector);
export const useActions = () => useAuthStore(actionsSelector);

export const subscribeToAuthStore = authStore.subscribe;
