import { kidSignInPaths } from 'app/constants/url/kid';
import { getAccessTokenData, getActions } from 'data/stores/auth-store';
import clearUserCookies from 'data/utils/helpers/clear-user-cookies';
import { signInPaths } from 'app/constants/url/shared';
import { match } from 'ts-pattern';
import { ROLE } from 'data/enums';

const { clearTokens } = getActions();

export const logout = () => {
  const tokenData = getAccessTokenData();
  clearUserCookies();
  clearTokens();

  if ([signInPaths.index, kidSignInPaths.index].includes(window.location.pathname)) return;

  const redirect = match(tokenData?.role)
    .with(ROLE.KID, () => kidSignInPaths.index)
    .with(ROLE.COACH, () => signInPaths.index)
    .with(ROLE.ADMIN, () => signInPaths.index)
    .otherwise(() => signInPaths.index);

  window.location.href = redirect;
};
