import { z } from 'zod';

export const JumpConfigValidator = z.object({
  consecutiveAnswerAmount: z.coerce.number(),
  percentageAnswerAmount: z.coerce.number(),
  goodPercentage: z.coerce.number(),
  badPercentage: z.coerce.number(),
  timingAnswerAmount: z.coerce.number(),
  goodTiming: z.coerce.number(),
  minimumAmountOfQuestionsInBox: z.coerce.number(),
  minimumAmountOfQuestionBoxes: z.coerce.number(),
  invalidAttempts: z.coerce.number(),
});

export type JumpConfig = z.infer<typeof JumpConfigValidator>;
