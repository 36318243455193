import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    marginLeft: -8,
    width: 65,
    height: 36,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiSelect-select': {
      color: '#fff',
      fontWeight: 400,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
  },
}));
