import { queryKeyBuilder } from 'data/utils/hookKeys';
import { useMutation, type UseMutationOptions, useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { fetchFeatureFlags, fetchFeatureFlagsConfig, updateFeatureFlagsConfig } from 'data/api/features';
import { type AxiosError } from 'axios';
import { type IError } from 'data/types';
import { type FeatureFlags } from 'data/types/entities/feature-flags.entity';
import { type AxiosAPIError } from 'data/utils/types';

export const featureKeys = (() => {
  const base = 'features';

  return {
    base: () => base,
    flags: () => queryKeyBuilder(base, 'flags'),
    config: () => queryKeyBuilder(base, 'config'),
  };
})();

export function useFetchFeatureFlags(
  options?: Omit<UseQueryOptions<Record<string, boolean>, AxiosError<IError>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery({
    queryKey: featureKeys.flags(),
    queryFn: () => fetchFeatureFlags(),
    ...options,
  });
}

export function useFetchFeatureFlagsConfig(
  options?: Omit<UseQueryOptions<FeatureFlags, AxiosError<IError>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery({
    queryKey: featureKeys.config(),
    queryFn: () => fetchFeatureFlagsConfig(),
    ...options,
  });
}

export function useUpdateFeatureFlagsConfig(
  options?: Omit<UseMutationOptions<void, AxiosAPIError<FeatureFlags>, FeatureFlags>, 'queryKey' | 'queryFn'>,
) {
  return useMutation({
    mutationFn: (data: FeatureFlags) => updateFeatureFlagsConfig(data),
    ...options,
  });
}
