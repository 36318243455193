interface GetFullNameParams {
  firstName?: string;
  lastName?: string;
  defaultName?: string;
}

export const getFullKidName = ({ firstName, lastName, defaultName }: GetFullNameParams) => {
  if (!firstName && lastName?.length) {
    return `Mr or Miss ${lastName}`;
  }

  if (firstName?.length && !lastName) {
    return firstName;
  }

  if (!firstName && !lastName) {
    return defaultName || '';
  }

  return `${firstName} ${lastName}`;
};
