import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useKidSidebarStore = create(
  persist(
    () => ({
      isSidebarOpen: false,
    }),
    {
      name: 'kid-sidebar',
    },
  ),
);

// actions
export const setIsSidebarOpen = (isSidebarOpen: boolean) => useKidSidebarStore.setState(() => ({ isSidebarOpen }));

// hooks
export const useIsSidebarOpen = () => useKidSidebarStore((state) => state.isSidebarOpen);

export default useKidSidebarStore;
