import { z } from 'zod';

export const InviteValidatorSchema = z
  .object({
    email: z.string().email().optional(),
    userId: z.string().uuid().optional(),
  })
  .refine((data) => data.email || data.userId, {
    message: 'email or userId should be provided for the invite',
  });

export type InviteValidator = z.infer<typeof InviteValidatorSchema>;
