import React from 'react';
import type { ValueOf } from 'app/utils/types';
import { create } from 'zustand';
import cx from 'classnames';
import { useMobileViewportHeightCorrection } from 'app/utils/hooks/useMobileViewportHeightCorrection';
import styles from './styles.module.css';

type Gradient = 'public' | 'kid' | 'coach';

const gradientCSSVariable = {
  public: '--public-gradient',
  kid: '--kid-gradient',
  coach: '--coach-gradient',
} as const;

type PublicGradient = {
  gradient: ValueOf<typeof gradientCSSVariable>;
  setGradient: (gradient: Gradient) => void;
};
export const usePublicGradientStore = create<PublicGradient>((set) => ({
  gradient: gradientCSSVariable.public,
  setGradient: (gradient: Gradient) => set({ gradient: gradientCSSVariable[gradient] }),
}));

type PublicLayoutProps = {
  children: React.ReactNode;
};

export const PublicLayout = ({ children }: PublicLayoutProps) => {
  const gradientVariable = usePublicGradientStore((state) => state.gradient);
  useMobileViewportHeightCorrection();

  return (
    <div
      id="public-layout"
      style={{
        background: `var(${gradientVariable})`,
      }}
      className={cx('min-h-safe-screen', styles.publicLayout)}
      data-route="public"
    >
      {children}
    </div>
  );
};
