import { useMediaQuery } from 'app/utils/hooks/use-media-query';
import { breakpoints as baseBreakpoints, type Breakpoints as BreakpointsSet } from 'app/constants/breakpoints';

export interface Breakpoints {
  isBase: boolean;
  isXs: boolean;
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  isXl: boolean;
  is2Xl: boolean;
  active: BreakpointsSet;
}

export default function useBreakpoints() {
  const breakpoints: Breakpoints = {
    isBase: useMediaQuery({ minWidth: baseBreakpoints.base }),
    isXs: useMediaQuery({ minWidth: baseBreakpoints.xs }),
    isSm: useMediaQuery({ minWidth: baseBreakpoints.sm }),
    isMd: useMediaQuery({ minWidth: baseBreakpoints.md }),
    isLg: useMediaQuery({ minWidth: baseBreakpoints.lg }),
    isXl: useMediaQuery({ minWidth: baseBreakpoints.xl }),
    is2Xl: useMediaQuery({ minWidth: baseBreakpoints['2xl'] }),
    active: 'base',
  };

  if (breakpoints.isXs) breakpoints.active = 'xs';
  if (breakpoints.isSm) breakpoints.active = 'sm';
  if (breakpoints.isMd) breakpoints.active = 'md';
  if (breakpoints.isLg) breakpoints.active = 'lg';
  if (breakpoints.isXl) breakpoints.active = 'xl';
  if (breakpoints.is2Xl) breakpoints.active = '2xl';

  return breakpoints;
}
