import { ROLE } from 'data/enums/role';
import { z } from 'zod';

export const TokenDataSchema = z.object({
  userId: z.string(),
  roleId: z.string(),
  role: z.nativeEnum(ROLE),
  roles: z.array(z.nativeEnum(ROLE)),
  iat: z.number().optional(),
  // seconds
  exp: z.number().optional(),
});

export const PreLoginTokenDataSchema = TokenDataSchema.omit({ role: true, roleId: true });

export type TokenData = z.infer<typeof TokenDataSchema>;
export type PreLoginTokenData = z.infer<typeof PreLoginTokenDataSchema>;
