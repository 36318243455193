import { ROLE } from 'data/enums/role';
import { z } from 'zod';

export const SignInValidatorSchema = z
  .object({
    identifier: z.string(),
    password: z.string().min(4).optional(),
    passcode: z.string().optional(),
    roleType: z.nativeEnum(ROLE).optional(),
  })
  .refine((data) => data.password !== undefined || data.passcode !== undefined, {
    message: 'Password or passcode must be provided',
    path: ['password'],
  })
  .refine((data) => data.password !== undefined || data.passcode !== undefined, {
    message: 'Password or passcode must be provided',
    path: ['passcode'],
  });

export type SignInValidator = z.infer<typeof SignInValidatorSchema>;
