import { type AppTheme } from '@coach/ui/utils/appTheme';
import { makeStyles } from '@mui/styles';

export const useTabsStyles = makeStyles<AppTheme>((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.boxShadows.tab,
    '& .Mui-selected': {
      backgroundColor: theme.palette.secondary.main,
      color: `${theme.palette.text.white} !important`,
    },
    '& .MuiTab-root': {
      borderRadius: 18,
      padding: '11px 16px 11px 16px',
      height: 36,
      color: theme.palette.secondary.main,
      minHeight: 0,
      maxWidth: 200,
      minWidth: '0 !important',
    },
  },
  tab: {
    '& .MuiTabs-root': {
      display: 'inline-block',
      borderRadius: '18px',
      height: '36px',
      minHeight: 0,
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
}));

export const useTabStyles = makeStyles<AppTheme>(() => ({
  tab: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .MuiSvgIcon-root': {
      margin: '0px 4px 0px 0px !important',
      fontSize: '1rem',
    },
  },
}));

export const useTabsFormStyles = makeStyles<AppTheme>(() => ({
  tabs: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 24,
  },
}));

export const useTabFormStyles = makeStyles<AppTheme>((theme) => ({
  tab: {
    width: '18.09%',
    fontWeight: 100,
    height: 84,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '12px 12px 0 0',
    backgroundColor: theme.palette.primary.light,
    padding: 24,
    border: 'none',
    fontFamily: 'Open Sans',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    '&.active': {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.boxShadows.primary,
    },
  },
}));
