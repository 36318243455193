const prefix = (type) => `eureka/files/${type}`;

export const FETCH_ROOT_START = prefix('FETCH_ROOT_START');
export const FETCH_ROOT_SUCCESS = prefix('FETCH_ROOT_SUCCESS');
export const FETCH_ROOT_FAILED = prefix('FETCH_ROOT_FAILED');

export const FETCH_CHILDREN_START = prefix('FETCH_CHILDREN_START');
export const FETCH_CHILDREN_SUCCESS = prefix('FETCH_CHILDREN_SUCCESS');
export const FETCH_CHILDREN_FAILED = prefix('FETCH_CHILDREN_FAILED');

export const FETCH_REQUESTS_START = prefix('FETCH_REQUESTS_START');
export const FETCH_REQUESTS_SUCCESS = prefix('FETCH_REQUESTS_SUCCESS');
export const FETCH_REQUESTS_FAILED = prefix('FETCH_REQUESTS_FAILED');

export const EDIT_START = prefix('EDIT_START');
export const EDIT_SUCCESS = prefix('EDIT_SUCCESS');
export const EDIT_FAILED = prefix('EDIT_FAILED');

export const REMOVAL_START = prefix('REMOVAL_START');
export const REMOVAL_SUCCESS = prefix('REMOVAL_SUCCESS');
export const REMOVAL_FAILED = prefix('REMOVAL_FAILED');

export const FETCH_ENTITY_START = prefix('FETCH_ENTITY_START');
export const FETCH_ENTITY_SUCCESS = prefix('FETCH_ENTITY_SUCCESS');
export const FETCH_ENTITY_FAILED = prefix('FETCH_ENTITY_FAILED');

export const CLEAR_FILES = prefix('CLEAR_FILES');
