/* eslint-disable prefer-destructuring */

// DO NOT USE import.meta.env.PROD as it doesn't rely on mode that is passed
export const PROD = import.meta.env.MODE === 'production';

export const LOGGER_LEVEL = import.meta.env.PUBLIC_LOGGER_LEVEL;

// DO NOT USE import.meta.env.DEV as it doesn't rely on mode that is passed
export const DEV = import.meta.env.MODE === 'development';

export const TEST = import.meta.env.MODE === 'test';

export const AES_DECRYPTION_KEY = import.meta.env.PUBLIC_AES_DECRYPTION_KEY;

export const COM_WS_URL = import.meta.env.PUBLIC_COM_URL;

export const MODE = import.meta.env.MODE;

export const API_BASE_URL = (() => {
  /**
   * To properly resolve the relative references in the URL, the base should end with '/'
   * https://developer.mozilla.org/en-US/docs/Web/API/URL_API/Resolving_relative_references
   */
  let base = import.meta.env.PUBLIC_API_BASE_URL;
  if (!base.endsWith('/')) {
    base += '/';
  }
  return base;
})();

export const GCLOUD_BUCKET = import.meta.env.PUBLIC_GCLOUD_BUCKET;

export const SENTRY_DSN = import.meta.env.PUBLIC_SENTRY_DSN;

export const APP_VERSION = import.meta.env.PUBLIC_APP_VERSION;
window.appVersion = APP_VERSION;
