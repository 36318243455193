/* eslint-disable @typescript-eslint/no-use-before-define */
import { type QuestionHistoryState } from 'app/pages/kid/questions/[educationStrategy]/[id]/page';
import { EDUCATION_STRATEGY } from 'data/enums';
import { useFirstValidated, usePosition } from 'data/stores/progress-store';
import { MILLISECONDS } from 'app/constants/data';
import { prepareLearningLinePositionInput, prepareTowerPositionInput } from './helpers';
import { useLearningLineNext, useLearningLinePosition, useValidateLearningLine } from './learningLines';
import { useGeneralProgressEffects, useJumpConfiguration } from './shared';
import { useTowerNext, useTowerPosition, useValidateTower } from './tower';
import useValidate from './useValidate';

interface UseProgressArgs {
  learningLineId?: string;
  towerId?: string;
  historyState?: QuestionHistoryState;
  cultureLanguageId?: string;
}

export const PROGRESS_HELPERS = {
  position: {
    [EDUCATION_STRATEGY.LEARNING_LINE]: useLearningLinePosition,
    [EDUCATION_STRATEGY.TOWER]: useTowerPosition,
  },
  next: {
    [EDUCATION_STRATEGY.LEARNING_LINE]: useLearningLineNext,
    [EDUCATION_STRATEGY.TOWER]: useTowerNext,
  },
  validation: {
    [EDUCATION_STRATEGY.LEARNING_LINE]: useValidateLearningLine,
    [EDUCATION_STRATEGY.TOWER]: useValidateTower,
  },
  positionInput: {
    [EDUCATION_STRATEGY.LEARNING_LINE]: prepareLearningLinePositionInput,
    [EDUCATION_STRATEGY.TOWER]: prepareTowerPositionInput,
  },
};

interface UseBaseProgressArgs extends Omit<UseProgressArgs, 'learningLineId' | 'towerId'> {
  id?: string;
  educationStrategy: EDUCATION_STRATEGY;
}

export const useProgress = ({ educationStrategy, id, historyState }: UseBaseProgressArgs) => {
  const enabled = !!id;

  const { data: jumpConfig } = useJumpConfiguration({
    enabled,
    staleTime: 20 * MILLISECONDS.MINUTE,
    cacheTime: 20 * MILLISECONDS.MINUTE,
  });

  const firstValidated = useFirstValidated();
  const position = usePosition();
  const { isLoading: isValidating, isSuccess: isValidateSuccess } = useValidate();

  // Get initial position
  const { data: nextContext, isFetching: isPositionLoading } = PROGRESS_HELPERS.position[educationStrategy]({
    id,
    historyState,
  });

  // Get next position
  PROGRESS_HELPERS.next[educationStrategy]({
    id,
    historyState,
    questionId: position?.question.id,
    learningBoxId: position?.learningBox?.id,
    towerBoxId: position?.towerBox?.id,
    enabled: enabled && firstValidated,
  });

  useGeneralProgressEffects({
    enabled,
    nextContext,
    isValidating,
    isValidateSuccess,
    jumpConfig,
    isPositionLoading,
  });
};
