import { type AppTheme } from '@coach/ui';
import { makeStyles } from '@mui/styles';

export const useDragCardComponentStyles = makeStyles<AppTheme>((theme) => ({
  text: {
    flex: '1 1 auto',
    display: 'flex',
    flexFlow: 'column',
    textAlign: 'left',
    justifyContent: 'center',
    color: theme.palette.primary.dark,
  },
  title: {
    hyphens: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  leftIcon: {
    marginLeft: -20,
  },
  rightIcons: {
    marginRight: -10,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

export const useSwapCardComponentStyles = makeStyles<AppTheme>((theme) => ({
  hover: {
    '& .MuiButtonBase-root': {
      visibility: 'hidden !important',
    },
  },
  root: {
    '&:hover': {
      '& .MuiButtonBase-root': {
        visibility: 'visible !important',
      },
    },
    padding: '20px 16px 20px 20px',
    position: 'relative',
    display: 'flex',
    height: 90,
    width: 260,
    borderRadius: 12,
    marginTop: 20,
    boxShadow: theme.boxShadows.pressed,
  },
  text: {
    flex: '1 1 auto',
    display: 'flex',
    flexFlow: 'column',
    textAlign: 'left',
    justifyContent: 'center',
    color: theme.palette.primary.dark,
  },
  title: {
    display: 'block',
    whiteSpace: 'nowrap',
    width: '12.5em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  shorterTitle: {
    width: '10.5em',
  },
  edit: {
    position: 'absolute',
    right: 12,
    top: 18,
  },
}));

export const useTowerDimensionCardComponentStyles = makeStyles<AppTheme>((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row',
    marginTop: 20,
    height: 90,
    width: 260,
    borderRadius: 12,
    boxShadow: theme.boxShadows.primary,
    backgroundColor: theme.palette.text.white,
    justifyContent: 'center',
  },
  title: {
    alignSelf: 'center',
  },
}));
