import { useMutation, type UseMutationOptions, useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { jwtDecode } from 'jwt-decode';
import { tokenKeys } from 'data/utils/hookKeys';
import { confirmRegistration, fetchRefreshToken, refreshAccessToken, resetPasscode, resetPassword } from 'data/api';
import { type IError, type RoleEntity, type TokenData, type TokenEntity, type UserEntity } from 'data/types';
import { type ROLE } from 'data/enums';
import { type ResetPasscodeValidator, type ResetPasswordValidator } from 'data/validators/body';
import { type AxiosError, type AxiosResponse } from 'axios';
import { type APIValidationError } from 'data/utils/types';
import CookieService from 'app/utils/services/cookies';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'app/constants/store';

type UseTokenRefreshOptions = Omit<
  UseMutationOptions<AxiosResponse<RoleEntity>, unknown, UseTokenRefreshMutationArgs, unknown>,
  'mutationKey' | 'mutationFn'
>;

interface UseTokenRefreshMutationArgs {
  role?: ROLE;
  refreshToken?: string;
}

export const useTokenRefresh = (options?: UseTokenRefreshOptions) =>
  useMutation(
    tokenKeys.refresh(),
    ({ role, refreshToken }: UseTokenRefreshMutationArgs) => {
      const creds = (() => {
        if (role) return { role };

        const access = CookieService.get(ACCESS_TOKEN_KEY);
        return jwtDecode<TokenData>(access || '');
      })();

      const refresh = refreshToken || CookieService.get(REFRESH_TOKEN_KEY);

      return refreshAccessToken({ role: creds.role, refreshToken: refresh || '' });
    },
    options,
  );

interface ResetPasswordMutationArguments {
  token: string;
  body: ResetPasswordValidator;
}

type UseResetPasswordOptions = Omit<
  UseMutationOptions<
    UserEntity,
    AxiosError<APIValidationError<ResetPasswordValidator>>,
    ResetPasswordMutationArguments,
    unknown
  >,
  'mutationKey' | 'mutationFn'
>;

export const useResetPassword = (options?: UseResetPasswordOptions) =>
  useMutation(
    tokenKeys.resetPassword(),
    ({ token, body }: ResetPasswordMutationArguments) => resetPassword(token, body),
    options,
  );

interface ResetPasscodeMutationArguments {
  token: string;
  body: ResetPasscodeValidator;
}

type UseResetPasscodeOptions = Omit<
  UseMutationOptions<
    UserEntity,
    AxiosError<APIValidationError<ResetPasscodeValidator>>,
    ResetPasscodeMutationArguments
  >,
  'mutationKey' | 'mutationFn'
>;

export const useResetPasscode = (options?: UseResetPasscodeOptions) =>
  useMutation(tokenKeys.resetPasscode(), ({ token, body }) => resetPasscode(token, body), options);

interface ConfirmRegistrationMutationArguments {
  token: string;
}

type UseConfirmRegistrationOptions = Omit<
  UseMutationOptions<unknown, AxiosError, ConfirmRegistrationMutationArguments, unknown>,
  'mutationKey' | 'mutationFn'
>;

export const useConfirmRegistration = (options?: UseConfirmRegistrationOptions) =>
  useMutation(
    tokenKeys.confirmRegistration(),
    ({ token: string }: ConfirmRegistrationMutationArguments) => confirmRegistration(string),
    options,
  );

type FetchRefreshTokenOptions = Omit<UseQueryOptions<TokenEntity, AxiosError<IError>>, 'queryKey' | 'queryFn'>;

export const useFetchRefreshToken = (userId: string, options?: FetchRefreshTokenOptions) =>
  useQuery<TokenEntity>(tokenKeys.refreshToken(userId), () => fetchRefreshToken(userId), options);
