const prefix = (type) => `eureka/schools/${type}`;

export const LOAD_BOOK_LICENSES_START = prefix('LOAD_BOOK_LICENSES_START');
export const LOAD_BOOK_LICENSES_SUCCESS = prefix('LOAD_BOOK_LICENSES_SUCCESS');
export const LOAD_BOOK_LICENSES_FAILED = prefix('LOAD_BOOK_LICENSES_FAILED');

export const UPDATE_BOOK_LICENSE_SUCCESS = prefix('UPDATE_BOOK_LICENSE_SUCCESS');
export const UPDATE_BOOK_LICENSE_START = prefix('UPDATE_BOOK_LICENSE_START');
export const UPDATE_BOOK_LICENSE_FAILED = prefix('UPDATE_BOOK_LICENSE_FAILED');

export const LOAD_BOOKS_FAILED = prefix('LOAD_BOOKS_FAILED');
export const LOAD_BOOKS_SUCCESS = prefix('LOAD_BOOKS_SUCCESS');
export const LOAD_BOOKS_START = prefix('LOAD_BOOKS_START');
