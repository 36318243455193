import { HttpClient } from 'data/axios/http-client';
import { API_BASE_URL } from 'app/constants/env';
import { Storage } from './storage';

const storage = new Storage();

const api = new HttpClient(storage, {
  baseURL: API_BASE_URL,
});
export default api;
