import { COACH_SORT, GROUP, GROUP_SORT, INVITE_SORT, KID_SORT, ROLE_SORT, SORT_DIRECTION } from 'data/enums';
import { z, type ZodArray, type ZodSchema } from 'zod';
import { LICENSE_SORT } from 'data/enums/sort/license';
import { type QueryParamConfig } from 'serialize-query-params/src/types';
import { type UUID } from 'data/utils/types';

const isPrimitive = (value: unknown): value is string | number | boolean =>
  typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean';

export function customQueryParamFactory<T extends ZodSchema>(schema: T) {
  return {
    encode(value: unknown): string | null | undefined {
      if (schema) {
        const result = schema.safeParse(value);
        if (result.success) {
          return result.data;
        }
        return undefined;
      }

      if (isPrimitive(value)) {
        return encodeURIComponent(value);
      }

      return undefined;
    },

    decode(value: string | (string | null)[] | null | undefined): z.infer<T> | undefined | null {
      if (schema) {
        const result = schema.safeParse(value);
        if (result.success) {
          return result.data;
        }
        return undefined;
      }

      return value;
    },
  };
}

export function customQueryParamArrayFactory<T extends ZodArray<any>>(schema: T) {
  return {
    encode(value: unknown): (string | null)[] | null | undefined {
      if (schema) {
        const result = schema.safeParse(value);
        if (result.success) {
          return result.data;
        }
        return undefined;
      }

      if (!Array.isArray(value) && isPrimitive(value)) {
        return [encodeURIComponent(value)];
      }

      if (Array.isArray(value) && value.every(isPrimitive)) {
        return value.map((v) => encodeURIComponent(v));
      }

      return undefined;
    },

    decode(value: string | (string | null)[] | null | undefined): z.infer<T> | undefined | null {
      let arr: (string | null | undefined)[] = [];
      if (Array.isArray(value)) {
        arr = value;
      } else {
        arr = [value];
      }
      if (schema) {
        const result = schema.safeParse(arr);
        if (result.success) {
          return result.data;
        }
        return undefined;
      }

      return arr;
    },
  };
}

export const SortDirectionParam = customQueryParamFactory(z.nativeEnum(SORT_DIRECTION));

export const KidSortParam = customQueryParamFactory(z.nativeEnum(KID_SORT));

export const CoachSortParam = customQueryParamFactory(z.nativeEnum(COACH_SORT));

export const RoleSortParam = customQueryParamFactory(z.nativeEnum(ROLE_SORT));

export const InviteSortParam = customQueryParamFactory(z.nativeEnum(INVITE_SORT));

export const GroupSortParam = customQueryParamFactory(z.nativeEnum(GROUP_SORT));

export const GroupTypeParam = customQueryParamArrayFactory(z.array(z.nativeEnum(GROUP)));

export const LicenseSortParam = customQueryParamFactory(z.nativeEnum(LICENSE_SORT));

export const UUIDArrayParam = customQueryParamArrayFactory(z.array(z.string())) as QueryParamConfig<
  UUID[] | null | undefined,
  UUID[] | null | undefined
>;
