import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import {
  acceptAdminInvite,
  acceptGroupInvite,
  acceptKidInvite,
  createAdminInvite,
  createGroupInvite,
  createKidInvite,
  deleteInvite,
  fetchAdminInvites,
  fetchInvite,
  fetchInvites,
} from 'data/api/invites';
import { type AdminInviteEntity, type IError, type InviteEntity, type UserInviteEntity } from 'data/types/entities';
import { queryKeyBuilder } from 'data/utils/hookKeys';
import { type UserInvitesQuery } from 'data/validators/query-params/user-invites.query';
import { type AxiosAPIError, type ListEntity } from 'data/utils/types';
import { type AxiosError, type AxiosResponse } from 'axios';
import { type InviteValidator } from 'data/validators/body/invite.validator';
import { type GroupInviteValidator } from 'data/validators/body/group-invite.validator';
import { type AcceptKidInviteValidator } from 'data/validators/body/accept-kid-invite.validator';
import { type InviteFromTokenEntity } from 'data/types/entities/invite/invite-from-token.entity';
import { Storage } from 'data/axios/storage';
import { useRef } from 'react';
import { authKeys, roleKeys, userKeys } from 'data/hooks';

export const inviteKeys = (() => {
  const base = 'invite';

  return {
    base: () => queryKeyBuilder(base),
    list: (params?: UserInvitesQuery) => queryKeyBuilder(base, params),
    token: (token: string) => queryKeyBuilder(base, token),
    admin: (params?: UserInvitesQuery) => queryKeyBuilder(base, 'admin', params),
  };
})();

export function useFetchInvites(
  params?: UserInvitesQuery,
  options?: Omit<UseQueryOptions<ListEntity<UserInviteEntity>, AxiosError<IError>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery({
    queryKey: inviteKeys.list(),
    queryFn: () => fetchInvites(params),
    ...options,
  });
}

export function useFetchAdminInvites(
  params?: UserInvitesQuery,
  options?: Omit<UseQueryOptions<ListEntity<AdminInviteEntity>, AxiosError<IError>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery({
    queryKey: inviteKeys.admin(params),
    queryFn: () => fetchAdminInvites(params),
    ...options,
  });
}

export function useFetchInvite(
  token: string,
  options?: Omit<UseQueryOptions<InviteFromTokenEntity, AxiosError<IError>>, 'queryFn' | 'queryKey'>,
) {
  return useQuery<InviteFromTokenEntity>({
    queryKey: inviteKeys.token(token),
    queryFn: () => fetchInvite(token),
    ...options,
  });
}

type CreateGroupInviteParams = {
  groupId: string;
  body: GroupInviteValidator;
};

export function useCreateGroupInvite(
  options?: Omit<
    UseMutationOptions<InviteEntity, AxiosAPIError<GroupInviteValidator>, CreateGroupInviteParams>,
    'mutationFn'
  >,
) {
  return useMutation({
    mutationFn: ({ groupId, body }: CreateGroupInviteParams) => createGroupInvite(groupId, body),
    ...options,
  });
}

type CreateKidInviteParams = {
  kidId: string;
  body: InviteValidator;
};

export function useCreateKidInvite(
  options?: Omit<UseMutationOptions<InviteEntity, AxiosAPIError<InviteValidator>, CreateKidInviteParams>, 'mutationFn'>,
) {
  return useMutation({
    mutationFn: ({ kidId, body }: CreateKidInviteParams) => createKidInvite(kidId, body),
    ...options,
  });
}

type CreateAdminInviteParams = {
  body: InviteValidator;
};

export function useCreateAdminInvite(
  options?: Omit<
    UseMutationOptions<InviteEntity, AxiosAPIError<InviteValidator>, CreateAdminInviteParams>,
    'mutationFn'
  >,
) {
  return useMutation({
    mutationFn: ({ body }: CreateAdminInviteParams) => createAdminInvite(body),
    ...options,
  });
}

type AcceptGroupInviteParams = {
  inviteId: string;
};

export function useAcceptGroupInvite(
  options?: Omit<UseMutationOptions<void, AxiosError<IError>, AcceptGroupInviteParams>, 'mutationFn'>,
) {
  return useMutation({
    mutationFn: ({ inviteId }: AcceptGroupInviteParams) => acceptGroupInvite(inviteId),
    ...options,
  });
}

type AcceptKidInviteParams = {
  inviteId: string;
  body: AcceptKidInviteValidator;
};

export function useAcceptKidInvite(
  options?: Omit<
    UseMutationOptions<void, AxiosAPIError<AcceptKidInviteValidator>, AcceptKidInviteParams>,
    'mutationFn'
  >,
) {
  return useMutation({
    mutationFn: ({ inviteId, body }: AcceptKidInviteParams) => acceptKidInvite(inviteId, body),
    ...options,
  });
}

type AcceptAdminInviteParams = {
  inviteId: string;
};

export function useAcceptAdminInvite(
  options?: Omit<UseMutationOptions<AxiosResponse<null>, AxiosError<IError>, AcceptAdminInviteParams>, 'mutationFn'>,
) {
  const storage = useRef(new Storage());
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ inviteId }: AcceptAdminInviteParams) => acceptAdminInvite(inviteId),
    ...options,
    onSuccess(response, variables, ctx) {
      const accessToken = response.headers['x-auth'];
      const refreshToken = response.headers['x-refresh-token'];

      storage.current.accessToken = accessToken;
      storage.current.refreshToken = refreshToken;

      const data = storage.current.getAccessTokenData();

      if (!data) {
        throw new Error('Failed to parse the access token');
      }

      void queryClient.invalidateQueries(roleKeys.profile(data.roleId));
      void queryClient.invalidateQueries(userKeys.details(data.userId));
      void queryClient.invalidateQueries(authKeys.user());
      void queryClient.invalidateQueries(inviteKeys.list());

      if (typeof options?.onSuccess === 'function') {
        options.onSuccess(response, variables, ctx);
      }
    },
  });
}

type DeleteInviteParams = {
  inviteId: string;
};

export function useDeleteInvite(
  options?: Omit<UseMutationOptions<void, AxiosError<IError>, DeleteInviteParams>, 'mutationFn'>,
) {
  return useMutation({
    mutationFn: ({ inviteId }: DeleteInviteParams) => deleteInvite(inviteId),
    ...options,
  });
}
