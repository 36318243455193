export const LOCAL_STORAGE_LANGUAGE_KEY = 'language';
export const ACCESS_TOKEN_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';

/**
 * This key is required by analytics service. For app, we can use data from the access token.
 */
export const USER_ID_KEY = 'user_id';

export const COOKIE_PREFERENCES_KEY = 'consent-policy';

/**
 * It's needed to prompt the user to verify their email address.
 * However, we don't want to prompt the user every time they refresh the page,
 * so we can create a delay.
 */
export const EMAIL_VERIFICATION_DELAY_KEY = 'emailVerificationDelay';

export const PARENT_SIDEBAR_EXPANDED_KEY = 'parentSidebarExpanded';

export const EXERCISE_DAILY_GOAL_KEY = 'exerciseDailyGoal';
export const TIME_DAILY_GOAL_KEY = 'timeDailyGoal';
