import { useTokenData } from 'data/hooks';
import { Unauthorized } from 'data/utils/errors/Unauthorized';

export const useAuthData = () => {
  const tokenData = useTokenData();

  if (!tokenData) {
    throw new Unauthorized();
  }

  return tokenData;
};
