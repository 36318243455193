import { type AppTheme } from '@coach/ui';
import { makeStyles } from '@mui/styles';
import { BUTTON_COLORS } from './button';

export const useFabStyles = makeStyles<AppTheme>((theme) => ({
  root: {
    height: 40,
    width: 40,
    boxShadow: 'none',
    '& svg': {
      width: 20,
      height: 20,
      color: 'white',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    '&:active': {
      color: 'rgba(255, 255, 255, 0.3)',
    },
  },
  [BUTTON_COLORS.GREEN]: {
    backgroundColor: theme.palette.secondary.main,
  },
  [BUTTON_COLORS.SECONDARY]: {
    backgroundColor: theme.palette.secondary.main,
  },
}));
export const useButtonStyles = makeStyles<AppTheme>((theme) => ({
  btn: {
    height: 36,
    margin: 8,
    padding: '5px 16px',
  },
  disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    borderColor: 'rgba(0, 0, 0, 0.26) !important',
  },
  toolTip: {
    display: 'block',
  },
  [BUTTON_COLORS.GREEN]: {
    borderRadius: 50,
    boxShadow: 'none',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [BUTTON_COLORS.SECONDARY]: {
    borderRadius: 50,
    boxShadow: 'none',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [BUTTON_COLORS.BLUE]: {
    borderRadius: 50,
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  [BUTTON_COLORS.PRIMARY]: {
    borderRadius: 50,
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  [BUTTON_COLORS.PINK]: {
    borderRadius: 50,
    boxShadow: 'none',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  [BUTTON_COLORS.WHITE_B]: {
    borderRadius: 50,
    boxShadow: 'none',
    backgroundColor: theme.palette.whiteB.light,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.whiteB.main,
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  [BUTTON_COLORS.WHITE_P]: {
    borderRadius: 50,
    boxShadow: 'none',
    backgroundColor: 'inherit',
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.whiteP.main,
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  [BUTTON_COLORS.WHITE_OUTLINED]: {
    borderRadius: 50,
    boxShadow: 'none',
    backgroundColor: theme.palette.whiteB.light,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: theme.palette.whiteB.main,
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  [BUTTON_COLORS.WHITE_G]: {
    borderRadius: 50,
    boxShadow: 'none',
    backgroundColor: theme.palette.whiteG.light,
    color: theme.palette.text.green,
    '&:hover': {
      backgroundColor: theme.palette.whiteG.light,
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  [BUTTON_COLORS.WHITE]: {
    borderRadius: 50,
    boxShadow: 'none',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey.main,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 6,
  },
}));

const iconColors = {
  blue: 'blue',
  pink: 'pink',
};

export const useIconStyles = makeStyles<AppTheme>((theme) => ({
  root: {
    boxShadow: 'none',
  },
  toolTip: {
    display: 'inline',
  },
  [iconColors.blue]: {
    color: theme.palette.grey.main,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
  },
  [iconColors.pink]: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.lighter,
    },
  },
}));
