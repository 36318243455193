import React from 'react';
import { NavLink, type NavLinkProps } from 'react-router-dom';
import ListItem, { type ListItemProps } from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Text } from '@coach/ui/components/text';
import cx from 'classnames';

import useSidebarStyles from './sidebar-jss';

interface ListItemCustomProps extends Omit<NavLinkProps, 'component'> {
  children: React.ReactNode;
  icon: React.ReactNode;
}

const SubItem = React.forwardRef(function SubItem<C extends React.ElementType = 'a'>(
  { className, children, icon, to, ...props }: ListItemProps<C, ListItemCustomProps>,
  ref: React.Ref<HTMLLIElement>,
) {
  const classes = useSidebarStyles();

  return (
    <ListItem
      ref={ref}
      className={cx(classes.nested, className)}
      activeClassName={classes.active}
      component={NavLink}
      to={to}
      {...props}
    >
      <>
        {icon && <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>}
        <Text className={classes.primary} color="black" size={12}>
          {children}
        </Text>
      </>
    </ListItem>
  );
});

export { SubItem };
