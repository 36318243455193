const prefix = (type) => `eureka/towers/${type}`;

export const FETCH_TOWER_START = prefix('FETCH_TOWER_START');
export const FETCH_TOWER_SUCCESS = prefix('FETCH_TOWER_SUCCESS');
export const FETCH_TOWER_FAILED = prefix('FETCH_TOWER_FAILED');

export const CREATE_TOWER_START = prefix('CREATE_TOWER_START');
export const CREATE_TOWER_SUCCESS = prefix('CREATE_TOWER_SUCCESS');
export const CREATE_TOWER_FAILED = prefix('CREATE_TOWER_FAILED');

export const REMOVING_TOWER_START = prefix('REMOVING_TOWER_START');
export const REMOVING_TOWER_SUCCESS = prefix('REMOVING_TOWER_SUCCESS');
export const REMOVING_TOWER_FAILED = prefix('REMOVING_TOWER_FAILED');

export const EDITING_TOWER_START = prefix('EDITING_TOWER_START');
export const EDITING_TOWER_SUCCESS = prefix('EDITING_TOWER_SUCCESS');
export const EDITING_TOWER_FAILED = prefix('EDITING_TOWER_FAILED');

export const CREATE_TOWER_BOX_START = prefix('CREATE_TOWER_BOX_START');
export const CREATE_TOWER_BOX_SUCCESS = prefix('CREATE_TOWER_BOX_SUCCESS');
export const CREATE_TOWER_BOX_FAILED = prefix('CREATE_TOWER_BOX_FAILED');

export const CREATE_TOWER_DIMENSION_START = prefix('CREATE_TOWER_DIMENSION_START');
export const CREATE_TOWER_DIMENSION_SUCCESS = prefix('CREATE_TOWER_DIMENSION_SUCCESS');
export const CREATE_TOWER_DIMENSION_FAILED = prefix('CREATE_TOWER_DIMENSION_FAILED');

export const UPDATE_TOWER_DIMENSION_START = prefix('UPDATE_TOWER_DIMENSION_START');
export const UPDATE_TOWER_DIMENSION_SUCCESS = prefix('UPDATE_TOWER_DIMENSION_SUCCESS');
export const UPDATE_TOWER_DIMENSION_FAILED = prefix('UPDATE_TOWER_DIMENSION_FAILED');

export const DELETE_TOWER_DIMENSION_START = prefix('DELETE_TOWER_DIMENSION_START');
export const DELETE_TOWER_DIMENSION_SUCCESS = prefix('DELETE_TOWER_DIMENSION_SUCCESS');
export const DELETE_TOWER_DIMENSION_FAILED = prefix('DELETE_TOWER_DIMENSION_FAILED');

export const FETCH_TOWER_BOX_START = prefix('FETCH_TOWER_BOX_START');
export const FETCH_TOWER_BOX_SUCCESS = prefix('FETCH_TOWER_BOX_SUCCESS');
export const FETCH_TOWER_BOX_FAILED = prefix('FETCH_TOWER_BOX_FAILED');

export const EDITING_TOWER_BOX_START = prefix('EDITING_TOWER_BOX_START');
export const EDITING_TOWER_BOX_SUCCESS = prefix('EDITING_TOWER_BOX_SUCCESS');
export const EDITING_TOWER_BOX_FAILED = prefix('EDITING_TOWER_BOX_FAILED');

export const REMOVING_TOWER_BOX_START = prefix('REMOVING_TOWER_BOX_START');
export const REMOVING_TOWER_BOX_SUCCESS = prefix('REMOVING_TOWER_BOX_SUCCESS');
export const REMOVING_TOWER_BOX_FAILED = prefix('REMOVING_TOWER_BOX_FAILED');

export const LINK_QUESTION_BOX_START = prefix('LINK_QUESTION_BOX_START');
export const LINK_QUESTION_BOX_SUCCESS = prefix('LINK_QUESTION_BOX_SUCCESS');
export const LINK_QUESTION_BOX_FAILED = prefix('LINK_QUESTION_BOX_FAILED');

export const UNLINK_QUESTION_BOX_START = prefix('UNLINK_QUESTION_BOX_START');
export const UNLINK_QUESTION_BOX_SUCCESS = prefix('UNLINK_QUESTION_BOX_SUCCESS');
export const UNLINK_QUESTION_BOX_FAILED = prefix('UNLINK_QUESTION_BOX_FAILED');

export const LOAD_TOWER_BOXES_START = prefix('LOAD_TOWER_BOXES_START');
export const LOAD_TOWER_BOXES_SUCCESS = prefix('LOAD_TOWER_BOXES_SUCCESS');
export const LOAD_TOWER_BOXES_FAILED = prefix('LOAD_TOWER_BOXES_FAILED');

export const UPLOAD_PHOTO_START = prefix('UPLOAD_PHOTO_START');
export const UPLOAD_PHOTO_SUCCESS = prefix('UPLOAD_PHOTO_SUCCESS');
export const UPLOAD_PHOTO_FAILED = prefix('UPLOAD_PHOTO_FAILED');

export const FETCH_KID_DOMAIN_START = prefix('FETCH_KID_DOMAIN_START');
export const FETCH_KID_DOMAIN_SUCCESS = prefix('FETCH_KID_DOMAIN_SUCCESS');
export const FETCH_KID_DOMAIN_FAILED = prefix('FETCH_KID_DOMAIN_FAILED');

export const FETCH_KID_TOWER_START = prefix('FETCH_KID_TOWER_START');
export const FETCH_KID_TOWER_SUCCESS = prefix('FETCH_KID_TOWER_SUCCESS');
export const FETCH_KID_TOWER_FAILED = prefix('FETCH_KID_TOWER_FAILED');

export const FETCH_TOWER_BY_KID_START = prefix('FETCH_TOWER_BY_KID_START');
export const FETCH_TOWER_BY_KID_SUCCESS = prefix('FETCH_TOWER_BY_KID_SUCCESS');
export const FETCH_TOWER_BY_KID_FAILED = prefix('FETCH_TOWER_BY_KID_FAILED');

export const FETCH_KID_TOWER_BOX_START = prefix('FETCH_KID_TOWER_BOX_START');
export const FETCH_KID_TOWER_BOX_SUCCESS = prefix('FETCH_KID_TOWER_BOX_SUCCESS');
export const FETCH_KID_TOWER_BOX_FAILED = prefix('FETCH_KID_TOWER_BOX_FAILED');

export const MOVE_CHILD_TO_TOWER_BOX_START = prefix('MOVE_CHILD_TO_TOWER_BOX_START');
export const MOVE_CHILD_TO_TOWER_BOX_SUCCESS = prefix('MOVE_CHILD_TO_TOWER_BOX_SUCCESS');
export const MOVE_CHILD_TO_TOWER_BOX_FAILED = prefix('MOVE_CHILD_TO_TOWER_BOX_FAILED');

export const MOVE_CHILD_TO_TOWER_START = prefix('MOVE_CHILD_TO_TOWER_START');
export const MOVE_CHILD_TO_TOWER_SUCCESS = prefix('MOVE_CHILD_TO_TOWER_SUCCESS');
export const MOVE_CHILD_TO_TOWER_FAILED = prefix('MOVE_CHILD_TO_TOWER_FAILED');

export const UPDATE_TOWER_ROW_POSITION_START = prefix('UPDATE_TOWER_ROW_POSITION_START');
export const UPDATE_TOWER_ROW_POSITION_SUCCESS = prefix('UPDATE_TOWER_ROW_POSITION_SUCCESS');
export const UPDATE_TOWER_ROW_POSITION_FAILED = prefix('UPDATE_TOWER_ROW_POSITION_FAILED');

export const UPDATE_TOWER_COLUMN_POSITION_START = prefix('UPDATE_TOWER_COLUMN_POSITION_START');
export const UPDATE_TOWER_COLUMN_POSITION_SUCCESS = prefix('UPDATE_TOWER_COLUMN_POSITION_SUCCESS');
export const UPDATE_TOWER_COLUMN_POSITION_FAILED = prefix('UPDATE_TOWER_COLUMN_POSITION_FAILED');

export const CLEAR_STATE = prefix('CLEAR_STATE');
