import api from 'data/axios/react-query-api';
import { type RoleEntity, type UrlEntity, type UserEntity } from 'data/types/entities';
import { type AUTH_PROVIDER_TYPE } from 'data/enums';
import { type EmailValidator, type SignInValidator, type SignUpValidator } from 'data/validators/body';
import { type SSOAuthorizationValidator } from 'data/validators/body/sso-authorization.validator';
import { type AuthRoleEntity } from 'data/types/entities/auth/auth-role.entity';

export const signUp = (body: SignUpValidator): Promise<AuthRoleEntity> => api.post(`/authentication/sign_up`, body);

export const signIn = (body: SignInValidator): Promise<AuthRoleEntity> => api.post('/authentication/sign_in', body);

export const signOut = (): Promise<null> => api.post('/authentication/sign_out');

export const authorizeWithSSO = (
  provider: AUTH_PROVIDER_TYPE,
  body: SSOAuthorizationValidator,
  language?: string,
): Promise<AuthRoleEntity> =>
  api.post(`/authentication/sso/${provider}/authorize`, body, {
    params: {
      language,
    },
  });

export const sendResetPasswordEmail = async (values: EmailValidator): Promise<null> =>
  api.post(`/authentication/passwords/reset`, values);

export const sendResetPasscodeEmail = async (values: EmailValidator): Promise<null> =>
  api.post(`/authentication/passcodes/reset`, values);

export const generatePasswordResetURL = (userId: string): Promise<UrlEntity> =>
  api.post(`/authentication/passwords/generate_reset_url`, { userId });

export const generatePasscodeResetURL = (userId: string): Promise<UrlEntity> =>
  api.post(`/authentication/passcodes/generate_reset_url`, { userId });

export const impersonateRole = (roleId: string): Promise<RoleEntity> =>
  api.post(`/authentication/impersonate`, { roleId });

export const getAuthenticatedUser = (): Promise<UserEntity> => api.get('/authentication/user');
