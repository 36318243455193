import { type LinkShareEntity, type TodoEntity } from 'data/types/entities';
import { type FileRequest } from 'app/pages/tasks/new/types';

export const SANITIZERS = {
  linkShares: (links: Omit<LinkShareEntity, 'id'>[]) => links.filter((link) => !!link.url),
  todos: (todos: Pick<TodoEntity, 'title'>[]) => todos.filter((todo) => !!todo.title),
  fileShares: (files: File[]) => files,
  /**
   * @description - there is no sense to send files that are not attached to any folder as it will be rejected by the API
   */
  fileRequests: (fileRequests: FileRequest[]) => fileRequests.filter((fileRequest) => !!fileRequest.folder.id),
  questionBoxesIds: (ids: string[]) => ids,
};

interface Config {
  fileShares?: File[];
  todos?: Pick<TodoEntity, 'title'>[];
  linkShares?: Omit<LinkShareEntity, 'id'>[];
  fileRequests?: FileRequest[];
  questionBoxesIds?: string[];

  [key: string]: any;
}

/**
 * Should return false in case there is at least one array value that is not empty.
 *
 * @param {Config} config
 * @returns {boolean}
 */
export const areWhatStepTasksEmpty = (config: Config): boolean =>
  !Object.entries(config).some(([key, value]): boolean => {
    /**
     * @description - if array is empty, there is no need to sanitize it
     */
    if (Array.isArray(value) && value.length === 0) {
      return false;
    }

    /**
     * @description - if array is not empty, we need to sanitize it to avoid counting mock data
     */
    if (SANITIZERS[key] && Array.isArray(value)) {
      // @ts-expect-error TS(2349): This expression is not callable.
      return !!SANITIZERS[key](value).length;
    }
    if (Array.isArray(value)) {
      return !!value.length;
    }
    return false;
  });

export const hasWhitespace = (val: string) => {
  const capturedGroups = /(?<whitespace>\s)/.exec(val)?.groups;
  return !!capturedGroups && 'whitespace' in capturedGroups;
};
