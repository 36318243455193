import React, { useEffect, useState } from 'react';
import { useActiveGroupId } from 'data/stores/group-store';
import { useAuthData, useFetchGroupTrial } from 'data/hooks';
import { MILLISECONDS } from 'app/constants/data';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import cx from 'app/utils/helpers/cx';
import { Button } from '@coach/ui';
import { useBanner } from 'data/atoms/banner';
import { ROLE } from 'data/enums';
import { match, P } from 'ts-pattern';
import * as O from 'effect/Option';
import { pipe } from 'effect';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { settingsPaths } from 'app/constants/url/shared';
import { settingsTabSearch } from 'app/pages/settings/helpers';

const formatterOptions = {
  style: 'long',
} as const;

function getDiff(date: string): O.Option<{ value: number; unit: 'day' | 'hour' }> {
  const now = new Date().getTime();
  const end = new Date(date).getTime();
  if (end < now) {
    return O.none();
  }
  const diff = end - now;
  const unit = diff > MILLISECONDS.DAY ? 'day' : 'hour';
  const value = Math.ceil(diff / (unit === 'day' ? MILLISECONDS.DAY : MILLISECONDS.HOUR));
  return O.some({
    value,
    unit,
  });
}

const MAX_DAYS = 7;

export function TrialBanner() {
  const { isOpen, close } = useBanner();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [formatter, setFormatter] = useState(new Intl.RelativeTimeFormat('en', formatterOptions));

  useEffect(() => {
    if (Intl.RelativeTimeFormat.supportedLocalesOf(language).length === 0) {
      setFormatter(new Intl.RelativeTimeFormat('en', formatterOptions));
      return;
    }
    setFormatter(new Intl.RelativeTimeFormat(language, formatterOptions));
  }, [language]);

  const groupId = useActiveGroupId({ fallback: false });
  const { role } = useAuthData();

  const { data: groupTrial } = useFetchGroupTrial(groupId as string, {
    enabled: !!groupId && role !== ROLE.ADMIN,
    useErrorBoundary: false,
    retry: 3,
    staleTime: 1 * MILLISECONDS.MINUTE,
  });

  const diff = match(groupTrial?.trialEnd)
    .with(P.string, getDiff)
    .otherwise(() => O.none());

  const formatted = pipe(
    diff,
    O.filterMap((diff) => {
      if (diff.unit === 'day' && diff.value > MAX_DAYS) {
        return O.none();
      }
      return O.some(diff);
    }),
    O.map(({ value, unit }) => formatter.format(value, unit)),
  );

  const hidden = !isOpen || O.isNone(formatted);

  /* @ts-ignore */
  if (diff?.value?.value > 6) return null;

  return (
    <div
      data-name="banner"
      className={cx('relative mb-6 w-full rounded-b bg-white px-10 py-2 shadow-md @container', hidden && 'hidden')}
    >
      <IconButton
        className="absolute right-1 top-1 @md:top-1/2 @md:-translate-y-1/2 md:top-1/2 md:-translate-y-1/2 lg:right-2"
        size="small"
        onClick={() => close()}
      >
        <CloseIcon />
      </IconButton>
      <div className="mx-auto flex flex-wrap items-center justify-center gap-x-4 gap-y-2 text-center @sm:justify-center @xl:justify-between md:justify-between md:gap-x-8 md:gap-y-2">
        <div className="balance w-full text-center @md:w-max lg:w-max">
          {O.isSome(formatted) && (
            <Typography component="span" className="mr-1 text-base">
              <Trans i18nKey="trialPeriodWillExpire" tOptions={{ period: formatted.value }}>
                Your <strong className="font-medium">trial period</strong> will expire{' '}
                <time className="font-medium" dateTime={groupTrial?.trialEnd ?? undefined}>
                  {formatted.value}
                </time>
              </Trans>
            </Typography>
          )}
        </div>
        <Button
          component={Link}
          to={{
            pathname: settingsPaths.index,
            search: settingsTabSearch.subscription,
          }}
          color="blue"
          size="small"
          className="m-0 h-8 min-w-min flex-1 whitespace-nowrap text-sm @xl:flex-none"
        >
          {t('buyKidLicenses')}
        </Button>
      </div>
    </div>
  );
}
