/* eslint-disable @typescript-eslint/no-use-before-define */
import { decryptAnswer, replaceCommaByDotInNumbers, trimSpacesBetweenDigits } from 'app/utils/helpers';
import { TEXT_QUESTION_TYPE } from 'data/enums';
import { secondStore } from 'data/stores/kid/seconds-store';
import { getProgressStore } from 'data/stores/progress-store';
import {
  type AnswerCountState,
  type AnswerInputFormValues,
  type JumpConfiguration,
  type JumpState,
  type LearningLinePositionContext,
  type PositionContext,
  type QuestionEntity,
  type QuestionPosition,
  type TowerPositionContext,
} from 'data/types';

interface PrepareLearningLinePositionInputArgs {
  nextContext?: LearningLinePositionContext;
  cultureLanguageId?: string;
}

export const prepareLearningLinePositionInput = ({
  nextContext,
  cultureLanguageId,
}: PrepareLearningLinePositionInputArgs) => {
  const { position, jump, jumpState } = getProgressStore();
  return {
    questionId: position?.question.id,
    questionBoxId: position?.question.questionBox.id,
    learningBoxId: position?.learningBox?.id,
    didacticBoxId: position?.learningBox?.didacticBoxId,
    questionBoxProgressId: !jumpState?.qBoxChanged ? nextContext?.questionBoxProgress?.id : undefined,
    learningBoxProgressId: !jumpState?.lBoxChanged ? nextContext?.learningBoxProgress?.id : undefined,
    didacticBoxProgressId: !jumpState?.dBoxChanged ? nextContext?.didacticBoxProgress?.id : undefined,
    learningLineProgressId: nextContext?.learningLineProgress?.id,
    progressId: nextContext?.learningLineProgress?.progressId,
    languageId: position?.question?.questionBox?.language?.id || cultureLanguageId,
    jump,
  };
};

interface PrepareTowerPositionInputArgs {
  nextContext?: TowerPositionContext;
  cultureLanguageId?: string;
}

export const prepareTowerPositionInput = ({ nextContext, cultureLanguageId }: PrepareTowerPositionInputArgs) => {
  const { position, jump, jumpState } = getProgressStore();
  return {
    questionId: position?.question.id,
    questionBoxId: position?.question.questionBox.id,
    towerBoxId: position?.towerBox?.id,
    questionBoxProgressId: !jumpState?.qBoxChanged ? nextContext?.questionBoxProgress?.id : undefined,
    towerBoxProgressId: !jumpState?.tBoxChanged ? nextContext?.towerBoxProgress?.id : undefined,
    towerProgressId: nextContext?.towerProgress?.id,
    progressId: nextContext?.towerProgress?.progressId,
    languageId: position?.question?.questionBox?.language?.id || cultureLanguageId,
    jump,
  };
};

interface GetChosenPositionArgs {
  nextContext?: PositionContext;
  answerCountState: AnswerCountState;
  setAnswerCountState: (setter: (answerCountState: AnswerCountState) => AnswerCountState) => void;
  valid?: boolean;
  jumpConfig?: JumpConfiguration;
  isFirstRender?: boolean;
}

interface GetChosenPositionResult {
  position?: QuestionPosition;
  positionChanges?: JumpState;
  jump?: number;
}

export const getChosenPosition = ({
  nextContext,
  valid,
  answerCountState,
  setAnswerCountState,
  jumpConfig,
  isFirstRender = false,
}: GetChosenPositionArgs): GetChosenPositionResult => {
  const {
    actions: { setIsFinished },
  } = getProgressStore();

  if (isFirstRender) {
    return {
      position: nextContext?.position,
    };
  }

  const jump = getJump(
    answerCountState,
    setAnswerCountState,
    jumpConfig,
    nextContext?.position?.learningBox?.questionBoxCount || nextContext?.position?.towerBox?.questionBoxCount,
  );

  if (!valid && jump === 0) {
    return {
      position: undefined,
    };
  }
  const position = getNextPosition(jump, nextContext);
  setIsFinished(!position);

  const dBoxChanged = nextContext?.position.learningBox?.didacticBoxId !== position?.learningBox?.didacticBoxId;
  const lBoxChanged = dBoxChanged || nextContext?.position.learningBox?.id !== position?.learningBox?.id;
  const tBoxChanged = nextContext?.position.towerBox?.id !== position?.towerBox?.id;
  const qBoxChanged =
    lBoxChanged || tBoxChanged || nextContext?.position.question.questionBox.id !== position?.question.questionBox.id;

  return {
    position,
    positionChanges: {
      dBoxChanged,
      lBoxChanged,
      qBoxChanged,
      tBoxChanged,
    },
    jump,
  };
};

/**
 * Calculates if a jump should occur based on configuration and state.
 * @param {AnswerCountState} answerCountState - AnswerCount state kept in session storage
 * @param {Dispatch<SetStateAction<AnswerCountState>>} setAnswerCountState - Dispatch function to update answerCountState
 * @param {JumpConfiguration} jumpConfig - Configuration fetched from the API which contains parameters to calculate jump
 * @param {Number} questionBoxCount - Number of questionBoxes in current LearningBox / TowerBox.
 * @returns {Number} Returns the value of the jump.
 */
const getJump = (
  answerCountState: AnswerCountState,
  setAnswerCountState: (setter: (answerCountState: AnswerCountState) => AnswerCountState) => void,
  jumpConfig?: JumpConfiguration,
  questionBoxCount?: number,
): number => {
  if (!jumpConfig) {
    return 0;
  }

  const isPositiveJumpPossible = Boolean(
    questionBoxCount &&
      (answerCountState.qBoxCount >= jumpConfig.minimumAmountOfQuestionBoxes ||
        answerCountState.qBoxCount >= questionBoxCount),
  );

  if (
    isPositiveJumpPossible &&
    answerCountState.consecutiveCorrectCount > 0 &&
    answerCountState.consecutiveCorrectCount % jumpConfig.consecutiveAnswerAmount === 0
  ) {
    return 1;
  }

  if (
    answerCountState.consecutiveIncorrectCount > 0 &&
    answerCountState.consecutiveIncorrectCount % jumpConfig.consecutiveAnswerAmount === 0
  ) {
    return -1;
  }

  if (
    answerCountState?.correctAnswerCount > 0 &&
    answerCountState?.correctAnswerCount % jumpConfig.percentageAnswerAmount === 0
  ) {
    const firstTimeRightPercentage = (answerCountState?.firstTimeRightCount / jumpConfig.percentageAnswerAmount) * 100;
    if (isPositiveJumpPossible && firstTimeRightPercentage >= jumpConfig?.goodPercentage) {
      return 1;
    }
    if (firstTimeRightPercentage <= jumpConfig?.badPercentage) {
      return -1;
    }
  }

  if (
    isPositiveJumpPossible &&
    answerCountState?.correctAnswerCount > 0 &&
    answerCountState.correctAnswerCount % jumpConfig.timingAnswerAmount === 0
  ) {
    setAnswerCountState((prevState) => ({ ...prevState, timeSpentLastQuestions: 0 }));
    if (answerCountState.timeSpentLastQuestions < jumpConfig.goodTiming) {
      return 1;
    }
  }

  return 0;
};

const getNextPosition = (jump: number, nextContext?: PositionContext): QuestionPosition | undefined => {
  if (jump > 0) {
    return nextContext?.jumps.find((x) => x.jump === jump) || nextContext?.next;
  }
  if (jump < 0) {
    return nextContext?.jumps.find((x) => x.jump === jump) || nextContext?.position;
  }
  return nextContext?.next || nextContext?.jumps.find((x) => x.jump === 1);
};

interface GetCorrectAnswerArgs {
  jumpConfig?: JumpConfiguration;
  attempts: number;
  question?: QuestionEntity;
}

export const getCorrectAnswer = ({ jumpConfig, attempts, question }: GetCorrectAnswerArgs): string | undefined =>
  jumpConfig && attempts > jumpConfig.invalidAttempts && question?.encryptedSolution
    ? decryptAnswer(question.encryptedSolution)
    : undefined;

interface GetPrepareAnswerInputArgs {
  values: AnswerInputFormValues;
  attempts: number;
}

export const prepareAnswerInput = ({ values, attempts }: GetPrepareAnswerInputArgs) => ({
  ...values,
  rawAnswer:
    // @ts-expect-error TS(2339): Property 'FILL_BLANKS_QUESTION' does not exist on ... Remove this comment to see the full error message
    values.type === TEXT_QUESTION_TYPE.FILL_BLANKS_QUESTION
      ? values.rawAnswer?.trim()
      : values.rawAnswer && replaceCommaByDotInNumbers(trimSpacesBetweenDigits(values.rawAnswer)),
  seconds: secondStore.getState().seconds,
  attempts,
});
