import { makeStyles } from '@mui/styles';
import { type AppTheme } from '@coach/ui/utils/appTheme';

const checkBoxColors = {
  green: 'green',
  blue: 'blue',
  grey: 'grey',
  red: 'red',
};

export const useCheckBoxStyles = makeStyles<AppTheme>((theme) => ({
  root: {
    '& .Mui-checked': {
      [checkBoxColors.green]: {
        color: theme.palette.secondary.main,
      },
      [checkBoxColors.blue]: {
        color: theme.palette.primary.main,
      },
      [checkBoxColors.grey]: {
        color: theme.palette.grey.main,
      },
      [checkBoxColors.red]: {
        color: theme.palette.error.main,
      },
    },
  },
  disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
}));
