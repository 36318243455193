import React from 'react';
import { type VariantProps } from 'class-variance-authority';
import cx from 'app/utils/helpers/cx';
import { type BreakpointValues } from 'app/constants/breakpoints';
import useBreakpoints from 'app/utils/hooks/useBreakpoints';
import getBreakpointValue from 'app/utils/helpers/get-breakpoint-value/get-breakpoint-value';
import { buttonStyles } from './styles/button-styles';

type Size = VariantProps<typeof buttonStyles>['size'];
type SizeBreakpointValues = BreakpointValues<Size>;

export type IconButtonProps<C extends React.ElementType = 'button'> = Omit<React.ComponentPropsWithoutRef<C>, 'color'> &
  Omit<VariantProps<typeof buttonStyles>, 'size'> & {
    size?: Size | SizeBreakpointValues;
    component?: C;
  };

const IconButton = React.forwardRef(function IconButton<C extends React.ElementType = 'button'>(
  { variant, size, color, shape, className, disabled, component, ...props }: IconButtonProps<C>,
  ref: React.Ref<HTMLButtonElement>,
) {
  const Component = component ?? 'button';
  const { active } = useBreakpoints();
  const isSizeObject = !!size && typeof size === 'object';
  const sizeValue = isSizeObject ? getBreakpointValue<Size>(size, active) : size;
  const buttonClasses = buttonStyles({ color, size: sizeValue, variant, shape });

  return (
    <Component
      ref={ref}
      disabled={disabled}
      className={cx(buttonClasses, className, disabled && 'opacity-60')}
      {...props}
    />
  );
});

export { IconButton };
