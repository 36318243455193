import React from 'react';
import cx from 'app/utils/helpers/cx';
import * as Separator from '@radix-ui/react-separator';
import { setIsSidebarOpen, useIsSidebarOpen } from 'data/stores/kid/sidebar-store';
import { Sheet, SheetContent, SheetClose, SheetHeader } from '@kid/ui/components/sheet';
import { NavigationMenu, NavigationMenuList } from '@kid/ui/components/navigation-menu';
import useBreakpoints from 'app/utils/hooks/useBreakpoints';

interface KidSidebarProps {
  classes?: {
    content?: string;
    header?: string;
    close?: string;
    separator?: string;
    nav?: string;
  };
  children: React.ReactNode;
}

export const KidSidebar = ({ classes, children }: KidSidebarProps) => {
  const isSidebarOpen = useIsSidebarOpen();
  const { isMd } = useBreakpoints();

  if (!isMd) {
    return (
      <Sheet open={isSidebarOpen}>
        <SheetContent
          side="left"
          onInteractOutside={() => setIsSidebarOpen(false)}
          className={cx('flex h-full w-[275px] flex-col gap-0 bg-emerald-700 p-0', classes?.content)}
        >
          <SheetHeader className={cx('h-16', classes?.header)}>
            <SheetClose className={cx('ml-auto', classes?.close)} onClick={() => setIsSidebarOpen(false)} />
          </SheetHeader>
          <Separator.Root
            orientation="horizontal"
            className={cx('h-px w-full flex-grow-0 bg-black/10', classes?.separator)}
          />

          <NavigationMenu orientation="vertical" className={cx('flex flex-1 flex-col [&>div]:flex-1', classes?.nav)}>
            <NavigationMenuList>{children}</NavigationMenuList>
          </NavigationMenu>
        </SheetContent>
      </Sheet>
    );
  }

  return (
    <NavigationMenu
      orientation="vertical"
      className={cx(
        'fixed bottom-0 left-0 top-0 hidden h-full w-[var(--sidebar-width)] flex-col overflow-auto bg-emerald-700 md:flex [&>div]:flex-1',
        classes?.nav,
      )}
    >
      <NavigationMenuList>{children}</NavigationMenuList>
    </NavigationMenu>
  );
};
