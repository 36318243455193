import { DIGITOOL_TYPE, ROLE, VIDEO_TYPES, VIDEO_VISIBILITY } from 'data/enums';
import { z } from 'zod';

export const VideoValidatorSchema = z.object({
  name: z.string(),
  link: z.string(),
  type: z.nativeEnum(VIDEO_TYPES).optional(),
  visibility: z.nativeEnum(VIDEO_VISIBILITY).optional(),
  description: z.string().optional(),
  showTo: z.array(z.nativeEnum(ROLE)).optional(),
  digitools: z.array(z.nativeEnum(DIGITOOL_TYPE)).optional(),
});

export type VideoValidator = z.infer<typeof VideoValidatorSchema>;
