import React from 'react';
import { HeadItem, icons, Sidebar, SubItem } from '@coach/ui';
import { useTranslation } from 'react-i18next';
import {
  booksPath,
  childrenPath,
  digitoolsPath,
  documentsPath,
  exercisesPath,
  learningLinesPath,
  settingsPaths,
  videosPath,
} from 'app/constants/url/shared';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ConnectIcon from '@icons/coach/Connect';
import AssignmentIcon from '@mui/icons-material/Assignment';

export function FamilySidebar() {
  const { t } = useTranslation();

  return (
    <Sidebar className="overflow-x-hidden">
      <HeadItem name={t('children')} to={childrenPath} icon={icons.child} data-name="sidebar-children" />

      <HeadItem name={t('library')} icon={<LibraryBooksIcon />} data-name="sidebar-library">
        <SubItem to={learningLinesPath} icon={<ConnectIcon fontSize="small" />} data-name="sidebar-learning-lines">
          {t('learningLines')}
        </SubItem>

        <SubItem to={exercisesPath} icon={<AssignmentIcon fontSize="small" />} data-name="sidebar-exercises">
          {t('exercises')}
        </SubItem>

        <SubItem to={booksPath} icon={<MenuBookIcon />} data-name="sidebar-books">
          {t('digitalBooks')}
        </SubItem>

        <SubItem to={digitoolsPath} icon={icons.videoGames} data-name="sidebar-digitools">
          {t('digitools')}
        </SubItem>

        <SubItem to={videosPath} icon={icons.video} data-name="sidebar-videos">
          {t('videos')}
        </SubItem>
      </HeadItem>

      <HeadItem name={t('documents')} to={documentsPath} icon={icons.openFolder} data-name="sidebar-documents" />

      <HeadItem name={t('settings')} to={settingsPaths.index} icon={<SettingsIcon />} data-name="sidebar-settings" />
    </Sidebar>
  );
}
