import axios, {
  AxiosError,
  AxiosHeaders,
  type AxiosInstance,
  type AxiosResponse,
  type CreateAxiosDefaults,
  type RawAxiosRequestConfig,
} from 'axios';
import { type PreLoginTokenData, type TokenData } from 'data/types/token-data';
import { MILLISECONDS } from 'app/constants/data';
import { HTTP_REQUEST_METHOD } from 'data/enums/http-request-method';
import { commonUrlParams, maintenancePath } from 'app/constants/url/shared';
import { isMaintenanceMode } from 'data/utils/helpers/api';
import { versionManager } from 'app/utils/version-manager';
import { type ROLE, STATUS_CODE } from 'data/enums';
import { Queue, type QueueEvent } from './queue';

type AxiosHeaderValue = AxiosHeaders | string | string[] | number | boolean | null;

interface RawAxiosHeaders {
  [key: string]: AxiosHeaderValue;
}

export const isImpersonationURL = (url: string) => /\/authentication\/impersonate/.test(url);
export const isSignInURL = (url: string): boolean => /\/authentication\/sign_in/.test(url);
export const isRefreshURL = (url: string): boolean => /tokens\/refresh/.test(url);
export const isSignUpURL = (url: string): boolean => /\/authentication\/sign_up/.test(url);
export const isSsoURL = (url: string): boolean => /\/authentication\/sso/.test(url);

const requestMethods = ['GET', 'POST', 'PUT', 'PATCH', 'HEAD', 'DELETE'] as const;
type RequestMethod = (typeof requestMethods)[number];

export type HttpClientQueueItem = {
  method: RequestMethod;
  url: string;
  options?: RawAxiosRequestConfig;
  resolve: (value: AxiosResponse) => void;
  reject: (reason?: any) => void;
};

export interface IStorage {
  set accessToken(value: string | undefined);

  set supportAccessToken(value: string | undefined);

  set refreshToken(value: string | undefined);

  get accessToken(): string | undefined;

  get refreshToken(): string | undefined;

  getTokenExpiration(): number | undefined; // seconds

  getAccessTokenData(): (PreLoginTokenData | TokenData) | undefined;

  clear(): void;
}

export class HttpClient {
  readonly #queue: Queue<HttpClientQueueItem>;

  #status: 'idle' | 'running' | 'stopped';

  private storage: IStorage;

  private readonly axios: AxiosInstance;

  constructor(storage: IStorage, config: CreateAxiosDefaults) {
    this.storage = storage;
    this.#queue = new Queue<HttpClientQueueItem>();
    this.#status = 'idle';
    this.axios = axios.create(config);
    this.axios.interceptors.response.use(this.responseInterceptor.bind(this), this.responseErrorInterceptor.bind(this));
    this.#queue.on('enqueue', this.subscriber.bind(this));
  }

  public get status() {
    return this.#status;
  }

  private set status(value: 'idle' | 'running' | 'stopped') {
    this.#status = value;
    if (value === 'idle' && !this.#queue.isEmpty()) {
      void this.run();
    }
  }

  public subscribeToQueue(event: QueueEvent, callback: (data: HttpClientQueueItem) => void): () => void {
    return this.#queue.on(event as any, callback);
  }

  public async post<ResponseData = unknown, RequestData = unknown>(
    url: string,
    data?: RequestData,
    config?: RawAxiosRequestConfig<RequestData>,
  ): Promise<ResponseData> {
    const response = await this.enqueue('POST', url, {
      data,
      ...config,
    });

    return response.data;
  }

  public async get<ResponseData = unknown, RequestData = unknown>(
    url: string,
    config?: RawAxiosRequestConfig<RequestData>,
  ): Promise<ResponseData> {
    const response = await this.enqueue('GET', url, config);

    return response.data;
  }

  public async put<ResponseData = unknown, RequestData = unknown>(
    url: string,
    data?: RequestData,
    config?: RawAxiosRequestConfig<RequestData>,
  ): Promise<ResponseData> {
    const response = await this.enqueue('PUT', url, {
      data,
      ...config,
    });

    return response.data;
  }

  public async patch<ResponseData = unknown, RequestData = unknown>(
    url: string,
    data?: RequestData,
    config?: RawAxiosRequestConfig<RequestData>,
  ): Promise<ResponseData> {
    const response = await this.enqueue('PATCH', url, {
      data,
      ...config,
    });

    return response.data;
  }

  public async delete<ResponseData = unknown, RequestData = unknown>(
    url: string,
    config?: RawAxiosRequestConfig<RequestData>,
  ): Promise<ResponseData> {
    const response = await this.enqueue('DELETE', url, config);

    return response.data;
  }

  public async request<
    ResponseData = unknown,
    RequestData = unknown,
    Response extends AxiosResponse<ResponseData, RequestData> = AxiosResponse<ResponseData, RequestData>,
  >(config: RawAxiosRequestConfig<RequestData>): Promise<Response> {
    const { method = 'GET', url, ...options } = config;

    if (!url) {
      throw new Error('Url is required');
    }

    const response = await this.enqueue(method as RequestMethod, url, options);

    return response as Response;
  }

  private enqueue(method: RequestMethod, url: string, options?: RawAxiosRequestConfig): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      this.#queue.enqueue({
        method,
        url,
        options,
        resolve,
        reject,
      });
    });
  }

  private async run() {
    while (!this.#queue.isEmpty()) {
      const item = this.#queue.dequeue();

      if (item == null) {
        continue;
      }

      const hasRefreshToken = this.storage.refreshToken != null;
      const tokenData = this.storage.getAccessTokenData() ?? {};
      if (hasRefreshToken && this.shouldRefreshAccessToken() && 'role' in tokenData) {
        await this.refreshToken(tokenData.role as ROLE);
      }

      this.executeRequest(item).then(item.resolve, item.reject);
    }
    this.status = 'idle';
  }

  private subscriber() {
    if (this.status !== 'idle') {
      return;
    }

    this.status = 'running';
    void this.run();
  }

  private async executeRequest(item: HttpClientQueueItem) {
    const { method, options, url } = item;

    const initialHeaders = this.getAuthHeaders();
    const headers = this.constructHeaders(
      initialHeaders,
      options?.headers as RawAxiosHeaders | AxiosHeaders | undefined,
    );

    return this.axios(url, {
      method,
      ...options,
      headers,
    });
  }

  private responseInterceptor(response: AxiosResponse<unknown>): AxiosResponse<unknown> {
    const {
      request,
      headers,
      config: { method },
    } = response;

    const version = headers['x-app-version'];

    versionManager.setVersion(version);

    const requestMethod = method?.toUpperCase();

    if (isImpersonationURL(request.responseURL)) {
      this.storage.supportAccessToken = headers['x-auth'];
      return response;
    }

    if (
      (isSsoURL(request.responseURL) && requestMethod === HTTP_REQUEST_METHOD.POST) ||
      isSignUpURL(request.responseURL) ||
      isSignInURL(request.responseURL) ||
      (requestMethod === HTTP_REQUEST_METHOD.POST && isRefreshURL(request.responseURL))
    ) {
      // Not every response has a refresh token data
      const refreshToken = headers['x-refresh-token'];
      const accessToken = headers['x-auth'];

      this.storage.accessToken = accessToken;
      this.storage.refreshToken = refreshToken;
    }

    return response;
  }

  private async responseErrorInterceptor(error: Error): Promise<Error> {
    console.error(error);
    if (!(error instanceof AxiosError) || !error?.isAxiosError) {
      return Promise.reject(error);
    }

    const { response } = error;

    const url = new URL(window.location.href);
    if (isMaintenanceMode(error) && !url.pathname.includes(maintenancePath)) {
      const redirectPath = new URL(maintenancePath, window.location.origin);
      redirectPath.searchParams.set(commonUrlParams.redirect, url.pathname);
      window.location.href = redirectPath.toString();
    } else if (
      /**
       * If the refresh token endpoint returns 401 then the refresh token has expired
       * If the response status is 404 then the token in invalid
       */
      (response?.status === STATUS_CODE.UNAUTHORIZED || response?.status === STATUS_CODE.NOT_FOUND) &&
      isRefreshURL(response?.request?.responseURL)
    ) {
      await this.signOut();
    }

    return Promise.reject(error);
  }

  private async refreshToken(role: ROLE): Promise<void> {
    const headers = this.constructHeaders(this.getAuthHeaders());

    let response: AxiosResponse;
    try {
      response = await this.axios({
        url: '/tokens/refresh',
        method: 'POST',
        headers,
        data: {
          roleType: role,
        },
      });
    } catch (error) {
      await this.signOut();
      return;
    }

    this.storage.accessToken = response.headers['x-auth'];
  }

  private async signOut(): Promise<void> {
    const headers = this.constructHeaders(this.getAuthHeaders());

    try {
      await this.axios({
        url: '/authentication/sign_out',
        method: 'POST',
        headers,
      });
    } finally {
      this.storage.clear();
    }
  }

  private constructHeaders(headersInit?: RawAxiosHeaders, axiosHeadersInit?: RawAxiosHeaders | AxiosHeaders) {
    const headers = new AxiosHeaders(headersInit);
    if (axiosHeadersInit == null) {
      return headers;
    }

    if (axiosHeadersInit instanceof AxiosHeaders) {
      for (const key in axiosHeadersInit) {
        const value = axiosHeadersInit.get(key);
        if (value) {
          headers.set(key, value);
        }
      }
    } else if (Array.isArray(axiosHeadersInit)) {
      for (const [key, value] of axiosHeadersInit) {
        headers.set(key, value);
      }
    } else {
      for (const key of Object.keys(axiosHeadersInit)) {
        const value = axiosHeadersInit[key];
        if (value) {
          headers.set(key.toString(), value);
        }
      }
    }

    return headers;
  }

  private getAuthHeaders(): AxiosHeaders {
    const headers = new AxiosHeaders();
    if (this.storage.accessToken != null) {
      headers.set('x-auth', this.storage.accessToken);
    }
    if (this.storage.refreshToken != null) {
      headers.set('x-refresh-token', this.storage.refreshToken);
    }

    return headers;
  }

  private shouldRefreshAccessToken() {
    if (this.storage.refreshToken == null) {
      return false;
    }
    const expiration = (this.storage.getTokenExpiration() ?? 0) * MILLISECONDS.SECOND;
    const buffer = 15 * MILLISECONDS.SECOND;
    const now = Date.now();
    const isExpired = now + buffer > expiration;
    return isExpired;
  }
}
