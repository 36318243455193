import { type ValueOf } from 'app/utils/types';

export enum INVITE_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export const INVITE_TYPE = {
  ADMIN: 'admin',
  GROUP: 'group',
  KID: 'kid',
} as const;

export type InviteType = ValueOf<typeof INVITE_TYPE>;
