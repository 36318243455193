export class RuntimeError<P = unknown> extends Error {
  public payload: unknown;

  protected jsonPayload: unknown;

  constructor(message: string, payload?: P) {
    super(message);
    this.name = 'RuntimeError';
    this.payload = payload;
    this.jsonPayload = this.processPayload(payload);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RuntimeError);
    }
  }

  public toJSON(): object {
    return {
      name: this.name,
      message: this.message,
      cause: this.cause,
      payload: this.jsonPayload,
      stack: this.stack,
    };
  }

  public toString(): string {
    return `${this.name}: ${this.message}. Payload: ${JSON.stringify(this.jsonPayload)}`;
  }

  private processPayload(payload: unknown) {
    const isObject = typeof payload === 'object' && payload != null;
    try {
      if (isObject && 'toJSON' in payload && typeof payload.toJSON === 'function') {
        return payload.toJSON();
      }
      if (payload instanceof Error) {
        return payload.toString();
      }

      if (Array.isArray(payload)) {
        return payload.map(this.processPayload.bind(this));
      }

      if (isObject) {
        const result: Record<string, unknown> = {};
        Object.keys(payload).forEach((key) => {
          result[key] = this.processPayload(payload[key]);
        });
        return result;
      }
    } catch (error) {
      let message: string;
      if (error instanceof Error) {
        message = error.toString();
      } else {
        message = JSON.stringify(error);
      }
      const err = new Error(`Error processing payload. ${message}. Payload: ${JSON.stringify(payload)}`);
      console.error(err);
    }

    return payload;
  }
}
