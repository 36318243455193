import { z } from 'zod';

export const EditUserValidatorSchema = z.object({
  language: z.string().optional(),
  firstName: z
    .string()
    .trim()
    .transform((val) => val.replace(/\s+/g, ' '))
    .optional(),
  lastName: z
    .string()
    .trim()
    .transform((val) => val.replace(/\s+/g, ' '))
    .optional(),
  email: z.string().trim().email().optional(),
  username: z.string().trim().optional(),
  birthdate: z.string().optional(),
  phone: z.string().optional(),
  allowSupport: z.boolean().optional(),
});

export type EditUserValidator = z.infer<typeof EditUserValidatorSchema>;
