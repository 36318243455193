import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';

export const Row = ({ children, ...props }) => <TableRow {...props}>{children}</TableRow>;

Row.propTypes = {
  children: PropTypes.node,
};

Row.defaultProps = {
  children: null,
};
