import { useEffect } from 'react';
import useEventListener from 'app/utils/hooks/useEventListener';
import throttle from 'lodash/throttle';

const FRAME_TIME_FOR_60_FPS = 16;

/**
 * @description This hook is used to correct the viewport height on mobile devices.
 * And solve the problem when 100vh doesn’t fit the mobile browser screen.
 * First we get the viewport height and we multiple it by 1% to get a value for a vh unit:
 *     const vh = window.innerHeight * 0.01;
 * Then we set the value in the --vh custom property to the root of the document:
 *     document.documentElement.style.setProperty('--vh', `${vh}px`);
 * And then we can use this custom property in our CSS like this:
 *     height: calc(var(--vh, 1vh) * 100);
 */

export const useMobileViewportHeightCorrection = () => {
  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    return () => {
      document.documentElement.style.removeProperty('--vh');
    };
  }, []);

  useEventListener(
    'resize',
    throttle(() => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, FRAME_TIME_FOR_60_FPS),
  );
};
