const prefix = (type) => `eureka/questions/${type}`;

export const CLEAR = prefix('CLEAR');
export const REMOVING_START = prefix('REMOVING_START');
export const REMOVING_SUCCESS = prefix('REMOVING_SUCCESS');
export const REMOVING_FAILED = prefix('REMOVING_FAILED');

export const EDIT_START = prefix('EDIT_START');
export const EDIT_SUCCESS = prefix('EDIT_SUCCESS');
export const EDIT_FAILED = prefix('EDIT_FAILED');
export const CLEAR_STATE = prefix('CLEAR_STATE');

export const ADD_TO_CLIPBOARD_START = prefix('ADD_TO_CLIPBOARD_START');
export const ADD_TO_CLIPBOARD_SUCCESS = prefix('ADD_TO_CLIPBOARD_SUCCESS');
export const ADD_TO_CLIPBOARD_FAILED = prefix('ADD_TO_CLIPBOARD_FAILED');

export const FETCH_CLIPBOARD_START = prefix('FETCH_CLIPBOARD_START');
export const FETCH_CLIPBOARD_SUCCESS = prefix('FETCH_CLIPBOARD_SUCCESS');
export const FETCH_CLIPBOARD_FAILED = prefix('FETCH_CLIPBOARD_FAILED');

export const DELETE_QUESTION_FROM_CLIPBOARD_START = prefix('DELETE_QUESTION_FROM_CLIPBOARD_START');
export const DELETE_QUESTION_FROM_CLIPBOARD_SUCCESS = prefix('DELETE_QUESTION_FROM_CLIPBOARD_SUCCESS');
export const DELETE_QUESTION_FROM_CLIPBOARD_FAILED = prefix('DELETE_QUESTION_FROM_CLIPBOARD_FAILED');

export const SWAP_QUESTIONS_START = prefix('SWAP_QUESTIONS_START');
export const SWAP_QUESTIONS_SUCCESS = prefix('SWAP_QUESTIONS_SUCCESS');
export const SWAP_QUESTIONS_FAILED = prefix('SWAP_QUESTIONS_FAILED');
