export enum VIDEO_TYPES {
  INSTRUCTION = 'instruction',
  AUTOMATIZATION = 'automatization',
  INSPIRATION = 'inspiration',
  EXPLANATION = 'explanation',
  LEARN_TO_LEARN = 'learnToLearn',
}

export enum VIDEO_VISIBILITY {
  PUBLIC = 'public',
  UNLISTED = 'unlisted',
  PRIVATE = 'private',
}
