/* eslint-disable no-shadow */
import { fromJS, List, type Map } from 'immutable';
import * as constants from 'data/constants/actions';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';
import { type ActionStatisticsEntity } from 'data/types/entities/statistics';
import { getFullKidName } from 'data/utils/helpers/get-full-kid-name';
import { pipe } from 'effect/Function';

export const actionsPagination = new Pagination('actions');
export const actionsProgress = new LoadingProgress('actionsProgress');
export const actionStatisticsProgress = new LoadingProgress('actionStatisticsProgress');
export const removalProgress = new LoadingProgress('actionsRemovalProgress');
export const actionProgress = new LoadingProgress('actionProgress');
export const actionEditProgress = new LoadingProgress('actionEditProgress');
export const removeFMMProgress = new LoadingProgress('removeFMMProgress');
export const createFMMProgress = new LoadingProgress('createFMMProgress');
export const questionFilesProgress = new LoadingProgress('questionFilesProgress');
export const questionFileUploadProgress = new LoadingProgress('questionFileUploadProgress');
export const questionFileRemovalProgress = new LoadingProgress('questionFileRemovalProgress');

const updateActionProgresses = (idArray, entities) => {
  const newEntities = { ...entities };
  const ids = Array.isArray(idArray) ? idArray : [idArray];
  ids.forEach((id) => {
    const currentAction = newEntities.actions[id];
    const { todos, ...rest } = currentAction;
    const sortedTodos = todos?.sort((a, b) => a.position - b.position);
    const newData = { ...rest, todos: sortedTodos };
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { actionProgress } = newData;
    if (actionProgress) {
      const { finishedTodos, finishedFileRequests, clickedLinkShares, downloadedFileShares, questionBoxProgress } =
        actionProgress;
      finishedTodos.forEach((finishedTodo) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { todos } = currentAction;
        const newTodos = todos.map((todo) => {
          if (finishedTodo.id === todo.id) return { ...todo, done: true };
          return todo;
        });
        currentAction.todos = newTodos;
      });
      finishedFileRequests.forEach((finishedFileRequest) => {
        const { fileRequests } = currentAction;
        const newFileRequests = fileRequests.map((fileRequest) => {
          if (finishedFileRequest.id === fileRequest.id) return { ...fileRequest, done: true };
          return fileRequest;
        });
        currentAction.fileRequests = newFileRequests;
      });
      clickedLinkShares.forEach((clickedLinkShare) => {
        const { linkShares } = currentAction;
        const newLinkShares = linkShares.map((linkShare) => {
          if (clickedLinkShare.id === linkShare.id) return { ...linkShare, done: true };
          return linkShare;
        });
        currentAction.linkShares = newLinkShares;
      });
      downloadedFileShares.forEach((downloadedFileShare) => {
        const { fileShares } = currentAction;
        const newFileShares = fileShares.map((fileShare) => {
          if (downloadedFileShare.id === fileShare.id) return { ...fileShare, done: true };
          return fileShare;
        });
        currentAction.fileShares = newFileShares;
      });
      if (questionBoxProgress?.done) {
        const { questionBoxes } = currentAction;
        currentAction.questionBoxes = questionBoxes.map((qBox) => ({ ...qBox, done: true }));
      }
    }
  });
  return newEntities;
};

const loadActions = (state, action) =>
  state.withMutations((newState) => {
    const { entities, result, limit, count, active } = action.payload;
    const updatedEntities = updateActionProgresses(result, entities);
    if (active === false) {
      if (newState.get('archive')) {
        newState.mergeIn(['archive'], fromJS(updatedEntities.actions));
        newState.mergeIn(['archiveIds'], fromJS(result));
      } else {
        newState.setIn(['archive'], fromJS(updatedEntities.actions));
        newState.setIn(['archiveIds'], fromJS(result));
      }
      newState.setIn(['archiveTotal'], fromJS(count));
    } else {
      newState.mergeIn(['entities'], fromJS(updatedEntities.actions));
      newState.setIn(['actionIds'], fromJS(result));
    }
    if (count && limit) {
      actionsPagination.set(newState, count, limit, fromJS(result));
    }
    actionsProgress.setLoaded(newState);
  });

const loadAction = (state, action) =>
  state.withMutations((newState) => {
    const { data, actionId, active } = action.payload;
    const { todos, ...rest } = data;
    const sortedTodos = todos.sort((a, b) => a.position - b.position);
    const newData = { ...rest, todos: sortedTodos };
    if (active) {
      newState.mergeIn(['entities', actionId], fromJS(newData));
      actionProgress.setLoaded(newState);
    } else {
      newState.mergeIn(['archive', actionId], fromJS(newData));
    }
  });

type Status = 'UNSTARTED' | 'ONGOING' | 'FINISHED';
type Kid = { name: string; id: string };
export type KidsStatuses = Record<Status, { kids: Kid[]; count: number }>;

interface LoadStatisticsAction {
  type: typeof constants.FETCH_ACTION_STATISTICS_SUCCESS;
  payload: {
    actionId: string;
    data: ActionStatisticsEntity;
  };
}

const loadStatistics = (state: Map<string, unknown>, action: LoadStatisticsAction) =>
  state.withMutations((newState) => {
    const { data, actionId } = action.payload;
    const { kidsWithStatus } = data;
    const kidsStatuses: KidsStatuses = {
      UNSTARTED: { kids: [], count: 0 },
      ONGOING: { kids: [], count: 0 },
      FINISHED: { kids: [], count: 0 },
    };
    // eslint-disable-next-line no-unused-expressions
    kidsWithStatus?.forEach((status) => {
      kidsStatuses[status.actionStatus].kids.push({
        name: getFullKidName({ firstName: status.user?.firstName, lastName: status.user?.lastName }),
        id: status.id,
      });
      kidsStatuses[status.actionStatus].count += 1;
    });

    const newData = { ...data, kidsStatuses };
    newState.setIn(['entities', actionId, 'statistics'], fromJS(newData));
    actionStatisticsProgress.setLoaded(newState);
  });

const removeAction = (state, action) =>
  state.withMutations((newState) => {
    const { actionId } = action.payload;
    newState.deleteIn(['entities', actionId]);
    newState.updateIn(['actionIds'], (ids) => ids?.filter((item) => item !== actionId));
    removalProgress.setLoaded(newState);
  });

const editAction = (state, action) =>
  state.withMutations((newState) => {
    const { data, actionId } = action.payload;
    const { todos, ...rest } = data;
    const sortedTodos = todos.sort((a, b) => a.position - b.position);
    const newData = { ...rest, todos: sortedTodos };
    newState.mergeIn(['entities', actionId], fromJS(newData));
    actionProgress.setLoaded(newState);
  });

const clearArchive = (state) =>
  state.withMutations((newState) => {
    newState.delete('archive');
    newState.delete('archiveIds');
  });

const removeFMM = (state, action) =>
  state.withMutations((newState) => {
    const { questionId, questionBoxId, actionId, fmmId } = action.payload;
    const questionBoxIndex = newState
      .getIn(['entities', actionId, 'questionBoxes'])
      ?.findIndex?.((qB) => qB.get('id') === questionBoxId);
    const questionIndex = newState
      .getIn(['entities', actionId, 'questionBoxes', questionBoxIndex, 'questions'])
      ?.findIndex?.((q) => q.get('id') === questionId);
    if ([questionBoxIndex, questionIndex].every((i) => Number.isInteger(i) && i !== -1)) {
      newState.updateIn(
        ['entities', actionId, 'questionBoxes', questionBoxIndex, 'questions', questionIndex, 'mistakes'],
        (mistakes) => mistakes.filter((mistake) => mistake.get('id') !== fmmId),
      );
    }
    removeFMMProgress.setLoaded(newState);
  });

const createFMM = (state, action) =>
  state.withMutations((newState) => {
    const { mistake, questionBoxId, questionId, actionId } = action.payload;
    const questionBoxIndex = newState
      .getIn(['entities', actionId, 'questionBoxes'])
      ?.findIndex((qB) => qB.get('id') === questionBoxId);
    const questionIndex = newState
      .getIn(['entities', actionId, 'questionBoxes', questionBoxIndex, 'questions'])
      ?.findIndex((q) => q.get('id') === questionId);
    if (questionIndex >= 0) {
      const mistakes = newState
        .getIn(['entities', actionId, 'questionBoxes', questionBoxIndex, 'questions', questionIndex, 'mistakes'])
        .push(fromJS(mistake));
      newState.setIn(
        ['entities', actionId, 'questionBoxes', questionBoxIndex, 'questions', questionIndex, 'mistakes'],
        mistakes,
      );
      createFMMProgress.setLoaded(newState);
    }
  });

const loadQuestionFiles = (state, action) =>
  state.withMutations((newState) => {
    const { actionId, questionId, questionBoxId, file } = action.payload;
    const questionBoxIndex = newState
      .getIn(['entities', actionId, 'questionBoxes'])
      ?.findIndex((qB) => qB.get('id') === questionBoxId);
    const questionIndex = newState
      .getIn(['entities', actionId, 'questionBoxes', questionBoxIndex, 'questions'])
      ?.findIndex((q) => q.get('id') === questionId);

    const newFiles = pipe(
      questionIndex,
      (idx): List<unknown> | undefined =>
        newState.getIn(['entities', actionId, 'questionBoxes', questionBoxIndex, 'questions', idx, 'files']),
      (maybeList) => maybeList ?? List(),
      (list) => list.push(fromJS(file)),
    );
    newState.setIn(
      ['entities', actionId, 'questionBoxes', questionBoxIndex, 'questions', questionIndex, 'files'],
      newFiles,
    );

    questionFilesProgress.setLoaded(newState);
    questionFileUploadProgress.setLoaded(newState);
  });

const removeQuestionFile = (state, action) =>
  state.withMutations((newState) => {
    const { actionId, questionBoxId, questionId, fileId } = action.payload;
    const questionBoxIndex = newState
      .getIn(['entities', actionId, 'questionBoxes'])
      ?.findIndex((qB) => qB.get('id') === questionBoxId);
    const questionIndex = newState
      .getIn(['entities', actionId, 'questionBoxes', questionBoxIndex, 'questions'])
      ?.findIndex((q) => q.get('id') === questionId);

    if (questionIndex >= 0) {
      newState.updateIn(
        ['entities', actionId, 'questionBoxes', questionBoxIndex, 'questions', questionIndex, 'files'],
        (files) => files.filter((file) => file.get('id') !== fileId),
      );
    }
    questionFileRemovalProgress.setLoaded(newState);
  });

const loadActionCount = (state, action) =>
  state.withMutations((newState) => {
    const {
      data: { count, items },
    } = action.payload;
    const actionsWithQuestionBoxesCount = items?.reduce((acc, item) => {
      let res = acc;
      if (item.type === 'quick' && item.questionBoxes?.length > 0) {
        res += 1;
      }
      return res;
    }, 0);
    newState.setIn(['actionsCount'], fromJS(count - actionsWithQuestionBoxesCount));
    newState.setIn(['quickTasksCount'], fromJS(actionsWithQuestionBoxesCount));
  });

const duplicateAndEditAction = (state, action) =>
  state.withMutations((newState) => {
    const {
      duplicateData: { todos: duplicatingTodos, ...duplicaRest },
      editingData: { todos: editingTodos, ...editingRest },
      actionId,
    } = action.payload;
    const sortedDuplicatingTodos = duplicatingTodos.sort((a, b) => a.position - b.position);
    const sortedEditingTodos = duplicatingTodos.sort((a, b) => a.position - b.position);
    const newData = { ...duplicaRest, ...editingRest, todos: { ...sortedDuplicatingTodos, ...sortedEditingTodos } };

    newState.mergeIn(['entities', actionId], fromJS(newData));
    actionProgress.setLoaded(newState);
  });

const initialState = fromJS({
  entities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_ACTIONS_START:
      return actionsProgress.setLoading(state);
    case constants.FETCH_ACTION_START:
    case constants.DUPLICATE_AND_EDIT_START:
      return actionProgress.setLoading(state);
    case constants.EDITING_ACTION_START:
      return actionEditProgress.setLoaded(state);
    case constants.FETCH_ACTION_SUCCESS:
      return loadAction(state, action);
    case constants.DUPLICATE_AND_EDIT_SUCCESS:
      return duplicateAndEditAction(state, action);
    case constants.FETCH_ACTIONS_SUCCESS:
      return loadActions(state, action);
    case constants.CREATE_FMM_START:
      return createFMMProgress.setLoading(state);
    case constants.CREATE_FMM_SUCCESS:
      return createFMM(state, action);
    case constants.CREATE_FMM_FAILED:
      return createFMMProgress.setLoadFailed(state);
    case constants.REMOVE_FMM_START:
      return removeFMMProgress.setLoading(state);
    case constants.REMOVE_FMM_FAILED:
      return removeFMMProgress.setLoadFailed(state);
    case constants.REMOVE_FMM_SUCCESS:
      return removeFMM(state, action);
    case constants.EDITING_ACTION_SUCCESS:
      return editAction(state, action);
    case constants.FETCH_ACTIONS_FAILED:
      return actionsProgress.setLoadFailed(state);
    case constants.FETCH_ACTION_FAILED:
    case constants.DUPLICATE_AND_EDIT_FAILED:
      return actionProgress.setLoadFailed(state);
    case constants.EDITING_ACTION_FAILED:
      return actionEditProgress.setLoadFailed(state);
    case constants.REMOVE_ACTION_START:
    case constants.FINISH_ACTION_START:
      return removalProgress.setLoading(state);
    case constants.FETCH_ACTION_COUNT:
      return loadActionCount(state, action);
    case constants.REMOVE_ACTION_SUCCESS:
    case constants.FINISH_ACTION_SUCCESS:
      return removeAction(state, action);
    // return finishAction(state, action);
    case constants.REMOVE_ACTION_FAILED:
    case constants.FINISH_ACTION_FAILED:
      return removalProgress.setLoadFailed(state);
    case constants.LOAD_QUESTION_FILES_START:
      return questionFilesProgress.setLoading(state);
    case constants.LOAD_QUESTION_FILES_FAILED:
      return questionFilesProgress.setLoadFailed(state);
    case constants.UPLOAD_QUESTION_FILES_START:
      return questionFileUploadProgress.setLoading(state);
    case constants.UPLOAD_QUESTION_FILES_FAILED:
      return questionFileUploadProgress.setLoadFailed(state);
    case constants.UPLOAD_QUESTION_FILES_SUCCESS:
    case constants.LOAD_QUESTION_FILES_SUCCESS:
      return loadQuestionFiles(state, action);
    case constants.REMOVE_QUESTION_FILE_START:
      return questionFileRemovalProgress.setLoading(state);
    case constants.REMOVE_QUESTION_FILE_FAILED:
      return questionFileRemovalProgress.setLoadFailed(state);
    case constants.REMOVE_QUESTION_FILE_SUCCESS:
      return removeQuestionFile(state, action);
    case constants.FETCH_ACTION_STATISTICS_START:
      return actionStatisticsProgress.setLoading(state);
    case constants.FETCH_ACTION_STATISTICS_SUCCESS:
      return loadStatistics(state, action);
    case constants.FETCH_ACTION_STATISTICS_FAILED:
      return actionStatisticsProgress.setLoadFailed(state);
    case constants.CLEAR_ARCHIVE:
      return clearArchive(state);
    case constants.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
