import React from 'react';
import { Button, BUTTON_COLORS, Text } from '@coach/ui';
import { Trans, useTranslation } from 'react-i18next';

const ErrorPage = () => {
  const { t } = useTranslation();

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div>
      <Text size={24} color="blue">
        <Trans i18nKey="somethingWentWrong">
          Sorry, something went wrong.
          <br />
          Please refresh the page or try again later.
        </Trans>
      </Text>
      <Button color={BUTTON_COLORS.BLUE} className="mt-8" onClick={reloadPage} icon="refresh">
        {t('refreshPage')}
      </Button>
    </div>
  );
};

export default ErrorPage;
