export enum ACTION_TYPE {
  QUICK = 'quick',
  SIMPLE = 'simple',
}

export enum ACTION_STATUS {
  ALL = 'ALL',
  UNSTARTED = 'UNSTARTED',
  ONGOING = 'ONGOING',
  FINISHED = 'FINISHED',
}

export enum SCHEDULE_TYPE {
  DURATION = 'duration',
  PLAN = 'plan',
}
