import { fromJS } from 'immutable';
import * as questionBoxConstants from 'data/constants/question-boxes';
import LoadingProgress from 'data/utils/reducers/loading';
import * as learningConstants from 'data/constants/learning-boxes';
import * as constants from 'data/constants/questions';

export const questionsProgres = new LoadingProgress('questionBoxesProgres');
export const clipboardProgress = new LoadingProgress('clipboardProgress');

const mergeData = (state, action) =>
  state.withMutations((newState) => {
    const { questions } = action.payload.entities;
    newState.mergeIn(['questions'], fromJS(questions));
    questionsProgres.setLoaded(newState);
  });

const mergeQuestionsData = (state, action) =>
  state.withMutations((newState) => {
    const { questions } = action.payload.entities;
    newState.mergeIn(['questions'], fromJS(questions));
  });

const loadQuestions = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action);
  });

const loadClipboard = (state, action) =>
  state.withMutations((newState) => {
    const { payload } = action;
    newState.mergeIn(['clipboard'], fromJS(payload));
    clipboardProgress.setLoaded(newState);
  });

const deleteQuestionsFromClipboard = (state, action) =>
  state.withMutations((newState) => {
    const { payload } = action;
    newState.updateIn(['clipboard', 'questions'], (list) =>
      list.filter((listItem) => payload.indexOf(listItem.get('id')) === -1),
    );
  });

const initialState = fromJS({
  questions: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.EDIT_START:
      return questionsProgres.setLoading(state);
    case constants.FETCH_CLIPBOARD_START:
      return clipboardProgress.setLoading(state);
    case constants.FETCH_CLIPBOARD_SUCCESS:
      return loadClipboard(state, action);
    case constants.FETCH_CLIPBOARD_FAILED:
      return clipboardProgress.setLoadFailed(state);
    case learningConstants.LOAD_LEARNING_SUCCESS:
      return loadQuestions(state, action);
    case questionBoxConstants.LOAD_QUESTION_SUCCESS:
      return mergeQuestionsData(state, action);
    case constants.DELETE_QUESTION_FROM_CLIPBOARD_SUCCESS:
      return deleteQuestionsFromClipboard(state, action);
    case constants.ADD_TO_CLIPBOARD_SUCCESS:
      return loadClipboard(state, action);
    case constants.SWAP_QUESTIONS_SUCCESS:
      return loadClipboard(state, action);
    case constants.EDIT_SUCCESS:
      return mergeData(state, action);
    case constants.EDIT_FAILED:
      return questionsProgres.setLoadFailed(state);
    case constants.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
