import api from 'data/axios/react-query-api';
import { type AdminInviteEntity, type InviteEntity, type UserInviteEntity } from 'data/types/entities';
import { type GroupInviteValidator } from 'data/validators/body/group-invite.validator';
import { type InviteValidator } from 'data/validators/body/invite.validator';
import { type AcceptKidInviteValidator } from 'data/validators/body/accept-kid-invite.validator';
import { type ListEntity } from 'data/utils/types';
import { type UserInvitesQuery } from 'data/validators/query-params/user-invites.query';
import { type InviteFromTokenEntity } from 'data/types/entities/invite/invite-from-token.entity';
import { type AxiosResponse } from 'axios';

export const fetchInvites = (params?: UserInvitesQuery) =>
  api.get<ListEntity<UserInviteEntity>>('/invites', { params });

export const fetchAdminInvites = (params: UserInvitesQuery = {}) =>
  api.get<ListEntity<AdminInviteEntity>>('/invites/admin', { params });

export const fetchInvite = (token: string): Promise<InviteFromTokenEntity> => api.get(`/invites/tokens/${token}`);

/**
 * Invite a user to a group
 */
export const createGroupInvite = (groupId: string, body: GroupInviteValidator) =>
  api.post<InviteEntity>(`/invites/groups/${groupId}`, body);

/**
 * Invite a user to a kid
 */
export const createKidInvite = (kidId: string, body: InviteValidator) =>
  api.post<InviteEntity>(`/invites/kids/${kidId}`, body);

/**
 * Invite an admin
 */
export const createAdminInvite = (body: InviteValidator) => api.post<InviteEntity>(`/invites/admin`, body);

export const acceptGroupInvite = (inviteId: string) => api.post(`/invites/${inviteId}/groups/accept`);
export const acceptKidInvite = (inviteId: string, body: AcceptKidInviteValidator) =>
  api.post(`/invites/${inviteId}/kids/accept`, body);

export const acceptAdminInvite = (inviteId: string): Promise<AxiosResponse<null>> =>
  api.request({
    method: 'POST',
    url: `/invites/${inviteId}/admin/accept`,
  });

export const deleteInvite = (inviteId: string) => api.delete(`/invites/${inviteId}`);
