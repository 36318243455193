import { type AppTheme } from '@coach/ui';
import { makeStyles } from '@mui/styles';
import { type Property } from 'csstype';

export const textColors = {
  green: 'green',
  blue: 'blue',
  blueDark: 'blueDark',
  pink: 'pink',
  greyLight: 'greyLight',
  greyDark: 'greyDark',
  greyMain: 'greyMain',
  black: 'black',
  white: 'white',
  red: 'red',
} as const;

export type TextColor = (typeof textColors)[keyof typeof textColors];

export interface TextFontProps {
  size?: Property.FontSize | number;
  weight?: 'bold' | 'normal' | 'lighter' | 'bolder' | 'inherit' | 'initial' | 'unset' | number;
}

export const useTextStyles = makeStyles<AppTheme, TextFontProps>((theme) => ({
  text: {
    fontSize: (props) => props.size,
    fontWeight: (props) => props.weight,
  },
  [textColors.blueDark]: {
    color: theme.palette.primary.dark,
  },
  [textColors.green]: {
    color: theme.palette.secondary.main,
  },
  [textColors.blue]: {
    color: theme.palette.primary.main,
  },
  [textColors.pink]: {
    color: theme.palette.error.main,
  },
  [textColors.greyLight]: {
    color: theme.palette.base['500'],
  },
  [textColors.greyDark]: {
    color: theme.palette.grey.main,
  },
  [textColors.black]: {
    color: theme.palette.text.black,
  },
  [textColors.greyMain]: {
    color: theme.palette.grey.main,
  },
  [textColors.red]: {
    color: theme.palette.error.main,
  },
  [textColors.white]: {
    color: theme.palette.text.white,
  },
}));
