import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { type AppTheme } from '@coach/ui/utils/appTheme';

export const formColors = {
  blue: 'blue',
  red: 'red',
};

export const useSearchStyles = makeStyles<AppTheme>((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: 18.5,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(0.75, 1, 0.75, 6),
    transition: theme.transitions.create('width'),
    width: '100%',
    fontWeight: 400,
    [theme.breakpoints.up('sm')]: {
      width: 156,
      '&:focus': {
        width: 200,
      },
    },
  },
}));

export const useTextAreaStyles = makeStyles<AppTheme>((theme) => ({
  root: {
    borderRadius: 6,
    fontSize: 16,

    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey.light,
      },
    },

    '& .MuiInputBase-input': {
      minHeight: 48,
    },

    '& .MuiInputLabel-root': {
      '&.Mui-focused': { color: theme.palette.grey.main },
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '10px 12px',
    },

    '& *': {
      fontWeight: 400,
    },
  },
}));
export const useFormStyles = makeStyles<AppTheme>((theme) => ({
  root: {
    borderRadius: 6,
    margin: 30,
    height: 37,
    width: 180,

    '& *': {
      fontWeight: 400,
    },

    '& .MuiInputBase-input.Mui-disabled': {
      color: 'black',
    },
    '& .MuiInputLabel-shrink': {
      top: '0px !important',
    },
    '& .MuiInputBase-root': {
      flexShrink: 0,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey.light,
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: 0,
      paddingLeft: 12,
      height: 37,
      width: '100%',
    },
    '& .MuiInputLabel-root': {
      top: -10,
      '&.Mui-focused': { color: theme.palette.grey.main },
    },
    '& .MuiInputLabel-root::first-letter': {
      textTransform: 'capitalize',
    },
  },
  [formColors.blue]: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-root': {
      '&.Mui-focused': { color: theme.palette.primary.main },
    },
    '& .MuiMenuItem-selected': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [formColors.red]: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.error.main,
      },
    },
    '& .MuiInputLabel-root': {
      '&.Mui-focused': { color: theme.palette.error.main },
    },
  },
  closeIcon: {
    '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeLarge': {
      right: 'unset',
      width: 34,
      height: 'auto',
      position: 'absolute',
      pointerEvents: 'none',
    },
  },
}));

export const useSelectStyles = makeStyles<AppTheme>((theme) => ({
  root: {
    height: 38,
    width: 180,
    textAlign: 'left',
    '& .MuiOutlinedInput-input': {
      paddingTop: 0,
      paddingLeft: 0,
    },
    '& .MuiOutlinedInput-root': {
      height: 37,
    },
    '& .MuiOutlinedInput-inputSelect': {
      paddingRight: '0px ',
      paddingLeft: '16px ',
      paddingTop: '20px ',
    },
    '& .MuiSelect-select': {
      paddingLeft: 12,
      display: 'flex',
      alignItems: 'center',
      '&:focus': {
        backgroundColor: '#FAFAFA',
      },
    },
  },
  disabled: {
    opacity: 0.5,
  },
  option: {
    display: 'flex !important',
    height: 37,
    minWidth: 180,
    '&:focus': {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
  },
  menu: {
    paddingRight: 0,
  },
  multiSelectWrapper: {
    '& .MuiInputLabel-shrink': {
      top: '-7px !important',
    },
    '& .MuiInputLabel-root': {
      left: 14,
      top: -12,
      zIndex: 2,
    },
  },
  multiSelect: {
    '& div': {
      padding: '9.5px 14px',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: '#FFFFFF',
    },
  },
}));
