import 'core-js/actual';
import 'app/i18n';

import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';

import { Provider as ReduxProvider, store } from 'data';
import { GlobalErrorBoundary } from 'app/utils/error-boundaries/global';
import { ErrorService } from 'app/utils/services/error-tracking';
import { TranslationProvider } from 'app/utils/translation-provider';
import { QueryClientProvider } from '@tanstack/react-query';

import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import 'app/styles/normalize.css';
import 'app/styles/global.css';
import 'react-toastify/dist/ReactToastify.css';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { TitleProvider } from 'app/components/core/title';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CookieProvider } from 'app/utils/CookieProvider';
import { GlobalErrorPage } from 'app/components/shared/error-page/global';
import { queryClient } from 'app/query-client';
import AuthenticationProvider from 'app/utils/authentication-provider';
import { toast, ToastContainer } from 'react-toastify';
import { getActions } from 'data/stores/auth-store';
import Router from 'app/routing/router';
import { MarketingProvider } from 'app/containers/public/marketing-provider';
import { AuthenticationBoundary } from 'app/utils/error-boundaries/authentication';
import { createTheme, StyledEngineProvider, type ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { appTheme } from '@coach/ui/utils/appTheme';
import Loader from 'app/components/core/preloader';
import { Container } from './app/container';

getActions().init();

const errorService = new ErrorService();
errorService.init();

const theme = createTheme(appTheme as ThemeOptions);

function App() {
  return (
    <GlobalErrorBoundary FallbackComponent={GlobalErrorPage}>
      <AuthenticationBoundary>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <DndProvider options={HTML5toTouch}>
              <ReduxProvider store={store}>
                <QueryParamProvider adapter={ReactRouter5Adapter}>
                  <StyledEngineProvider injectFirst>
                    <AuthenticationProvider>
                      <TranslationProvider>
                        <TitleProvider>
                          <CookieProvider>
                            <MarketingProvider>
                              <Container>
                                <ThemeProvider theme={theme}>
                                  <Suspense fallback={<Loader />}>
                                    <Router />
                                  </Suspense>
                                </ThemeProvider>
                              </Container>
                            </MarketingProvider>
                            <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
                          </CookieProvider>
                        </TitleProvider>
                      </TranslationProvider>
                    </AuthenticationProvider>
                  </StyledEngineProvider>
                </QueryParamProvider>
              </ReduxProvider>
            </DndProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </BrowserRouter>
      </AuthenticationBoundary>
    </GlobalErrorBoundary>
  );
}

const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);

root.render(<App />);
