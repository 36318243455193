import React from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowIcon from '@mui/icons-material/ChevronRight';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import AutorenewIcon from '@mui/icons-material/AutorenewOutlined';
import BookIcon from '@mui/icons-material/BookOutlined';
import BoxesIcon from '@mui/icons-material/InboxOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Circles from '@mui/icons-material/SupervisedUserCircle';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import DateRangeIcon from '@mui/icons-material/DateRangeOutlined';
import Description from '@mui/icons-material/Description';
import DoneIcon from '@mui/icons-material/Done';
import DragIndicatorIcon from '@mui/icons-material/DragIndicatorOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Facebook from '@mui/icons-material/Facebook';
import Folder from '@mui/icons-material/Folder';
import GetAppIcon from '@mui/icons-material/GetApp';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InboxIcon from '@mui/icons-material/Inbox';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InsertChart from '@mui/icons-material/InsertChartOutlined';
import InsertDrive from '@mui/icons-material/InsertDriveFile';
import InsertFileDriveIcon from '@mui/icons-material/InsertDriveFileOutlined';
import InsertPhoto from '@mui/icons-material/InsertPhotoOutlined';
import Instagram from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import LearningCultureIcon from '@mui/icons-material/AccountBalanceOutlined';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LinkedIn from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MenuIcon from '@mui/icons-material/Menu';
import MusicVideo from '@mui/icons-material/MusicVideo';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import OpenFolder from '@mui/icons-material/FolderOpen';
import OpenInNewWindowIcon from '@mui/icons-material/OpenInNewOutlined';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import PlayArrow from '@mui/icons-material/PlayArrow';
import RemoveIcon from '@mui/icons-material/Remove';
import School from '@mui/icons-material/AccountBalance';
import SchoolIcon from '@mui/icons-material/SchoolOutlined';
import SecurityIcon from '@mui/icons-material/Security';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import SharedFolder from '@mui/icons-material/FolderShared';
import ShareIconOutlined from '@mui/icons-material/ShareOutlined';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import Stars from '@mui/icons-material/Stars';
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import StopIcon from '@mui/icons-material/Stop';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import TocIcon from '@mui/icons-material/Toc';
import Video from '@mui/icons-material/VideocamOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import YouTube from '@mui/icons-material/YouTube';

import PlayOutlined from '@icons/coach/svg/play-outlined.svg';
import ArrowLeft from './svg/arrow-left.svg';
import Audio from './svg/audio.svg';
import Catalogue from './svg/catalogue.svg';
import Camera from './svg/camera.svg';
import Check from './svg/check.svg';
import CheckSm from './svg/check-small.svg';
import Child from './svg/child.svg';
import Chrome from './svg/chrome.svg';
import ChromeSm from './svg/chrome-small.svg';
import CircleClock from './svg/circle-clock.svg';
import CircleGroups from './svg/circles.svg';
import CleverKids from './svg/clever-kids.svg';
import Clock from './svg/clock.svg';
import Close from './svg/close.svg';
import Copy from './svg/copy.svg';
import Delete from './svg/delete.svg';
import DeleteLarge from './svg/delete-large.svg';
import DeleteMedium from './svg/delete-medium.svg';
import Done from './svg/done.svg';
import Dots from './svg/dots.svg';
import Download from './svg/download.svg';
import Draft from './svg/draft.svg';
import Duplicate from './svg/duplicate.svg';
import Edit from './svg/edit.svg';
import EditIcon from './svg/edit-icon.svg';
import EditMedium from './svg/edit-medium.svg';
import EmptyFolder from './svg/emptyFolder.svg';
import Enter from './svg/enter.svg';
import EraseLarge from './svg/erase-large.svg';
import Eraser from './svg/eraser.svg';
import Eye from './svg/eye.svg';
import EyeCrossed from './svg/eye-crossed.svg';
import FacebookRoundSm from './svg/facebook-round-sm.svg';
import Family from './svg/family.svg';
import File from './svg/file.svg';
import Firefox from './svg/firefox.svg';
import FirefoxSm from './svg/firefox-small.svg';
import FolderIcon from './svg/folder.svg';
import Google from './svg/google.svg';
import Groups from './svg/groups.svg';
import Image from './svg/image.svg';
import InternetExplorer from './svg/internetExplorer.svg';
import KeySm from './svg/key-sm.svg';
import License from './svg/license.svg';
import LinkIcon from './svg/link.svg';
import Logo from './svg/logotype.svg';
import LogoIcon from './svg/logo-icon.svg';
import LogoMain from './svg/logo-main.svg';
import LogoMainLarge from './svg/logo-main-large.svg';
import LogotypeMedium from './svg/logotype-medium.svg';
import Minus from './svg/minus.svg';
import MonitorSm from './svg/monitor-sm.svg';
import Package from './svg/package.svg';
import Pause from './svg/pause.svg';
import Pending from './svg/pending.svg';
import People from './svg/people.svg';
import Percent from './svg/percent.svg';
import PercentLarge from './svg/percent-large.svg';
import Plan from './svg/plan.svg';
import Play from './svg/play.svg';
import PlayIconBig from './svg/play-big.svg';
import PlayNext from './svg/play-next.svg';
import PlaySm from './svg/play-sm.svg';
import Plus from './svg/plus.svg';
import Profile from './svg/profile.svg';
import Progress from './svg/progress.svg';
import Psychology from './svg/psychology.svg';
import QuestionBox from './svg/question-box.svg';
import QuestionMark from './svg/question-mark.svg';
import Safari from './svg/safari.svg';
import SafariSm from './svg/safari-small.svg';
import Save from './svg/save.svg';
import SchoolHat from './svg/school.svg';
import Search from './svg/search.svg';
import SeparateBook from './svg/separate-book.svg';
import ShareIcon from './svg/share.svg';
import SharedFile from './svg/shared-file.svg';
import SidebarAlert from './svg/sidebar-alert.svg';
import SolutionBook from './svg/solution-book.svg';
import SolutionBookM from './svg/solution-book-m.svg';
import Speaker from './svg/speaker.svg';
import Statistics from './svg/statistics.svg';
import StopShare from './svg/stopShare.svg';
import Subscription from './svg/subscription.svg';
import Task from './svg/task.svg';
import Telegram from './svg/telegram.svg';
import TelegramSm from './svg/telegram-sm.svg';
import TransferIcon from './svg/transfer.svg';
import TrelloSm from './svg/trello-sm.svg';
import Undo from './svg/undo.svg';
import Upload from './svg/upload.svg';
import Visibility from './svg/visibility.svg';
import VisibilityOff from './svg/visibility-off.svg';

export const icons = {
  add: <AddIcon />,
  addCircle: <AddCircleOutlineIcon />,
  arrow: <ArrowIcon />,
  arrowBack: <ArrowBack />,
  arrowForwardIos: <ArrowForwardIosIcon />,
  arrowLeft: <ArrowLeft />,
  assignment: <AssignmentIcon />,
  audio: <AudiotrackIcon />,
  audioFile: <Audio />,
  book: <BookIcon />,
  boxes: <BoxesIcon />,
  calendar: <DateRangeIcon />,
  calendarChecked: <EventAvailableIcon />,
  camera: <Camera />,
  cancel: <CancelIcon />,
  catalogue: <Catalogue className="MuiSvgIcon-root" />,
  chart: <InsertChart />,
  check: <CheckCircleIcon />,
  checkCircle: <CheckCircleOutlineIcon />,
  checkIcon: <Check />,
  checkSm: <CheckSm />,
  chevronLeft: <ChevronLeftIcon />,
  child: <Child width={24} height={24} className="MuiSvgIcon-root" />,
  childSm: <Child width={16} height={16} className="MuiSvgIcon-root" />,
  chrome: <Chrome />,
  chromeSm: <ChromeSm />,
  circleClock: <CircleClock className="MuiSvgIcon-root" />,
  circleGroups: <CircleGroups className="MuiSvgIcon-root" />,
  circles: <Circles />,
  cleverKids: <CleverKids />,
  clock: <Clock />,
  close: <CloseIcon />,
  closeRed: <Close />,
  cloudUpload: <CloudUploadIcon />,
  copy: <Copy />,
  dashboard: <DashboardIcon />,
  delete: <Delete />,
  deleteL: <DeleteLarge />,
  deleteMedium: <DeleteMedium />,
  description: <Description />,
  done: <DoneIcon />,
  doneSecond: <Done className="MuiSvgIcon-root" />,
  dots: <Dots />,
  download: <GetAppIcon />,
  downloadIcon: <Download />,
  draft: <Draft className="MuiSvgIcon-root" />,
  drag: <DragIndicatorIcon />,
  duplicate: <Duplicate className="MuiSvgIcon-root" />,
  edit: <EditIcon />,
  editIcon: <Edit />,
  editMedium: <EditMedium />,
  email: <AlternateEmailIcon />,
  emptyFolder: <EmptyFolder className="MuiSvgIcon-root" />,
  enter: <Enter />,
  eraseL: <EraseLarge />,
  eraser: <Eraser />,
  error: <ErrorIcon />,
  exitToApp: <ExitToAppIcon />,
  expandMore: <ExpandMoreIcon />,
  eye: <Eye className="MuiSvgIcon-root" />,
  eyeCrossed: <EyeCrossed className="MuiSvgIcon-root" />,
  facebook: <Facebook />,
  facebookRoundSm: <FacebookRoundSm />,
  family: <Family className="MuiSvgIcon-root" />,
  file: <File />,
  firefox: <Firefox />,
  firefoxSm: <FirefoxSm />,
  folder: <Folder />,
  folderIcon: <FolderIcon />,
  google: <Google />,
  group: <GroupIcon />,
  groups: <Groups className="MuiSvgIcon-root" />,
  help: <HelpOutlineIcon />,
  image: <Image />,
  inbox: <InboxIcon />,
  info: <InfoOutlinedIcon />,
  inProgress: <Progress className="MuiSvgIcon-root" />,
  insertDrive: <InsertDrive />,
  insertFile: <InsertFileDriveIcon />,
  instagram: <Instagram />,
  internetExplorer: <InternetExplorer />,
  key: <VpnKeyIcon />,
  keyIconSm: <KeySm />,
  language: <LanguageIcon fill="#fff" />,
  learningCulture: <LearningCultureIcon />,
  library: <LibraryBooksIcon />,
  license: <License className="MuiSvgIcon-root" />,
  link: <LinkIcon className="MuiSvgIcon-root" viewBox="0 0 24 24" />,
  linkedIn: <LinkedIn />,
  logo: <Logo />,
  logoIcon: <LogoIcon />,
  logoMain: <LogoMain />,
  logoMainLarge: <LogoMainLarge />,
  logotypeMedium: <LogotypeMedium />,
  mapPoint: <PersonPinCircleIcon />,
  menu: <MenuIcon />,
  menuBook: <MenuBookIcon />,
  minus: <Minus />,
  monitorSm: <MonitorSm />,
  music: <MusicVideo />,
  navigateNext: <NavigateNextIcon />,
  newFolder: <CreateNewFolderIcon />,
  newFolderOutlined: <CreateNewFolderOutlinedIcon />,
  openFolder: <OpenFolder />,
  openInNewWindow: <OpenInNewWindowIcon />,
  package: <Package className="MuiSvgIcon-root" />,
  pause: <Pause className="MuiSvgIcon-root" viewBox="0 0 40 40" />,
  pending: <Pending className="MuiSvgIcon-root" />,
  people: <People className="MuiSvgIcon-root" />,
  percent: <Percent />,
  percentL: <PercentLarge />,
  photo: <InsertPhoto />,
  plan: <Plan className="MuiSvgIcon-root" />,
  play: <PlayArrow />,
  playIconBig: <PlayIconBig />,
  playNext: <PlayNext className="MuiSvgIcon-root" />,
  playOutlined: <PlayOutlined className="MuiSvgIcon-root" />,
  playSm: <PlaySm />,
  playStart: <Play className="MuiSvgIcon-root" viewBox="0 0 40 40" />,
  plus: <Plus />,
  profile: <Profile />,
  psychology: <Psychology className="MuiSvgIcon-root" />,
  questionBox: <QuestionBox className="MuiSvgIcon-root" />,
  questionMark: <QuestionMark className="MuiSvgIcon-root" />,
  refresh: <AutorenewIcon />,
  remove: <RemoveIcon />,
  safari: <Safari />,
  safariSm: <SafariSm />,
  save: <Save />,
  school: <SchoolIcon />,
  schoolHat: <SchoolHat />,
  schools: <School />,
  search: <Search />,
  security: <SecurityIcon />,
  send: <SendIcon />,
  sendEnvelope: <MailOutlineIcon />,
  settings: <SettingsIcon />,
  separateBook: <SeparateBook className="MuiSvgIcon-root" viewBox="0 0 31 30" />,
  share: <ShareIcon />,
  sharedFile: <SharedFile className="MuiSvgIcon-root" />,
  sharedFolder: <SharedFolder />,
  shareOutlined: <ShareIconOutlined />,
  sidebarAlert: <SidebarAlert />,
  solution: <LibraryAddCheckIcon />,
  solutionBook: <SolutionBook className="MuiSvgIcon-root" viewBox="0 0 31 30" />,
  solutionBookM: <SolutionBookM />,
  speaker: <Speaker />,
  stars: <Stars />,
  starsOutlined: <StarsOutlinedIcon />,
  statistics: <Statistics className="MuiSvgIcon-root" />,
  stop: <StopIcon />,
  stopShare: <StopShare className="MuiSvgIcon-root" />,
  subscription: <Subscription className="MuiSvgIcon-root" />,
  swap: <SwapHorizIcon />,
  task: <Task className="MuiSvgIcon-root" />,
  telegram: <Telegram className="MuiSvgIcon-root" />,
  telegramSm: <TelegramSm />,
  tok: <TocIcon />,
  transfer: <TransferIcon className="MuiSvgIcon-root" />,
  trelloSm: <TrelloSm />,
  undo: <Undo />,
  /**
   * @description the fill: none; property is added owing to the specific icon structure
   */

  upload: <Upload style={{ fill: 'none' }} className="MuiSvgIcon-root" viewBox="0 0 16 16" />,
  video: <Video />,
  videoGames: <SportsEsportsIcon />,
  visibility: <Visibility />,
  visibilityMui: <VisibilityIcon />,
  visibilityOff: <VisibilityOff />,
  youtube: <YouTube />,
};

export type Icons = typeof icons;
