import { type z } from 'zod';
import * as E from 'effect/Either';
import { pipe } from 'effect';

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isArrayBuffer(value: unknown): value is ArrayBuffer {
  return value instanceof ArrayBuffer;
}

export function decodeJSON<Schema extends z.ZodType>(str: string, schema: Schema): E.Either<z.infer<Schema>, Error> {
  try {
    const data = pipe(str, JSON.parse, schema.parse);
    return E.right(data);
  } catch (error) {
    return E.left(error);
  }
}
