import { fromJS } from 'immutable';
import * as constants from 'data/constants/kid';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

const initialState = fromJS({
  entities: {},
});

export const kidsProgress = new LoadingProgress('kids');
export const kidsPagination = new Pagination('kids');
export const progressesPagination = new Pagination('progresses');
export const progressesProgress = new LoadingProgress('progresses');
export const kidProgress = new LoadingProgress('kidProgress');
export const kidRemovalProgress = new LoadingProgress('kidRemovalProgress');
export const kidLicenseProgress = new LoadingProgress('kidLicenseProgress');
export const kidLicenseRemovalProgress = new LoadingProgress('kidLicenseRemovalProgress');
export const kidReportProgress = new LoadingProgress('kidReportProgress');
export const kidReportByTowersProgress = new LoadingProgress('kidReportByTowersProgress');

const loadKidReport = (state, action) =>
  state.withMutations((newState) => {
    newState.setIn(['kidReport'], action.payload);
    kidReportProgress.setLoaded(newState);
  });

const loadKidReportByTowers = (state, action) =>
  state.withMutations((newState) => {
    newState.setIn(['kidReportByTowers'], action.payload);
    kidReportByTowersProgress.setLoaded(newState);
  });

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { kids },
    } = payload;
    newState.setIn(['entities'], fromJS(kids));
  });

const loadKids = (state, action) =>
  state.withMutations((newState) => {
    const { limit, count, result } = action.payload;
    mergeData(newState, action.payload);
    kidsPagination.set(newState, count, limit, fromJS(result));
    kidsProgress.setLoaded(newState);
  });

const loadKid = (state, action) =>
  state.withMutations((newState) => {
    const {
      entities: { kids },
    } = action.payload;
    if (newState.get('entities')) {
      newState.mergeIn(['entities'], fromJS(kids || {}));
    } else {
      newState.set('entities', fromJS(kids || {}));
    }
    kidProgress.setLoaded(newState);
  });

const loadProgresses = (state, action) =>
  state.withMutations((newState) => {
    const {
      entities: { progresses },
      result,
      limit,
      count,
    } = action.payload;
    newState.setIn(['progresses'], fromJS(progresses));
    newState.setIn(['progressesIds'], fromJS(result));
    progressesPagination.set(newState, count, limit, fromJS(result));
    progressesProgress.setLoaded(newState);
  });

const removeLicense = (state, action) =>
  state.withMutations((newState) => {
    const { kidId } = action.payload;
    newState.deleteIn(['entities', kidId, 'license']);
    kidLicenseRemovalProgress.setLoaded(newState);
  });

const loadLicense = (state, action) =>
  state.withMutations((newState) => {
    const { kidId, license } = action.payload;

    newState.setIn(['entities', kidId, 'license'], fromJS(license));
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_KID_REPORT_START:
      return kidReportProgress.setLoading(state);
    case constants.REMOVING_START:
      return kidRemovalProgress.setLoading(state);
    case constants.LOAD_KIDS_START:
      return kidsProgress.setLoading(state);
    case constants.FETCH_PROGRESSES_START:
      return progressesProgress.setLoading(state);
    case constants.FETCH_KID_START:
      return kidProgress.setLoading(state);
    case constants.FETCH_KID_REPORT_SUCCESS:
      return loadKidReport(state, action);
    case constants.FETCH_PROGRESSES_SUCCESS:
      return loadProgresses(state, action);
    case constants.FETCH_PROGRESSES_FAILED:
      return progressesProgress.setLoadFailed(state);
    case constants.DELETE_LICENSE_START:
      return kidLicenseRemovalProgress.setLoading(state);
    case constants.DELETE_LICENSE_FAILED:
      return kidLicenseRemovalProgress.setLoadFailed(state);
    case constants.DELETE_LICENSE_SUCCESS:
      return removeLicense(state, action);

    case constants.CREATE_LICENSE_START:
      return kidLicenseProgress.setLoading(state);
    case constants.CREATE_LICENSE_FAILED:
      return kidLicenseProgress.setLoadFailed(state);
    case constants.REMOVING_SUCCESS:
      return kidRemovalProgress.setLoaded(state);
    case constants.LOAD_KIDS_SUCCESS:
      return loadKids(state, action);
    case constants.FETCH_KID_SUCCESS:
      return loadKid(state, action);
    case constants.CREATE_LICENSE_SUCCESS:
      return loadLicense(state, action);
    case constants.FETCH_KID_REPORT_FAILED:
      return kidReportProgress.setLoadFailed(state);
    case constants.REMOVING_FAILED:
      return kidRemovalProgress.setLoadFailed(state);
    case constants.LOAD_KIDS_FAILED:
      return kidsProgress.setLoadFailed(state);
    case constants.FETCH_KID_FAILED:
      return kidProgress.setLoadFailed(state);
    case constants.CLEAR_PROGRESS:
      return initialState;
    case constants.FETCH_KID_REPORT_BY_TOWERS_START:
      return kidReportByTowersProgress.setLoading(state);
    case constants.FETCH_KID_REPORT_BY_TOWERS_SUCCESS:
      return loadKidReportByTowers(state, action);
    case constants.FETCH_KID_REPORT_BY_TOWERS_FAILED:
      return kidReportByTowersProgress.setLoadFailed(state);
    default:
      return state;
  }
};
