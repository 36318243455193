import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import translationNl from 'zod-i18n-map/locales/nl/zod.json';
import translationFr from 'zod-i18n-map/locales/fr/zod.json';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';

i18n
  .use(intervalPlural)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    partialBundledLanguages: true,
    resources: {
      nl: {
        zod: translationNl,
      },
      fr: {
        zod: translationFr,
      },
    },
    ns: ['translation'],
    defaultNS: 'translation',
    returnNull: false,
    fallbackLng: 'en',
    returnEmptyString: false,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      customHeaders: {
        // Seems to cause issues in case the translation file is cached
        'Cache-Control': 'no-cache',
      },
    },
  });

z.setErrorMap(zodI18nMap);

export default i18n;
