import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import { type AppTheme } from '@coach/ui/utils/appTheme';

const useContentBoxStyles = makeStyles<AppTheme>((theme) => ({
  box: {
    borderRadius: '12px !important',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 3px 0 rgba(142,142,142,0.2)',
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
}));

export const ContentBox = ({ children, className, ...props }) => {
  const s = useContentBoxStyles();

  return (
    <Paper className={cx(s.box, className)} {...props}>
      {children}
    </Paper>
  );
};

ContentBox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

ContentBox.defaultProps = {
  children: null,
  className: null,
};
