import { type FC, useContext, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { TitleContext } from '.';

type TitleAnchorProps = {
  tab: string;
};

export const TitleAnchor: FC<TitleAnchorProps> = ({ tab }) => {
  const { addTab, removeTab } = useContext(TitleContext);

  useEffect(() => {
    const id = uuid();

    addTab({ name: tab, id });
    return () => {
      removeTab({ name: tab, id });
    };
  }, []);

  return null;
};
