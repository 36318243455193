import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, USER_ID_KEY } from 'app/constants/store';
import CookieService from 'app/utils/services/cookies';

const clearUserCookies = () => {
  CookieService.remove(ACCESS_TOKEN_KEY, { path: '/' });
  CookieService.remove(REFRESH_TOKEN_KEY, { path: '/' });
  CookieService.remove(USER_ID_KEY, { path: '/' });
};

export default clearUserCookies;
