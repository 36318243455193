import React, { type PropsWithChildren, useEffect, useState } from 'react';
import { useFetchUser } from 'data/hooks/queries';
import { ROLE } from 'data/enums/role';
import { useTokenData } from 'data/stores/auth-store';
import { MarketingEventsQueue } from 'app/utils/services/marketing';
import { type INTERCOM_EVENT, useIntercomEvents } from 'data/hooks/utils/useIntercomEvents';
import { type GTM_EVENT, useGTMEvents } from 'data/hooks/utils/useGTMEvents';
import { intercomApiKey } from 'app/constants/data';
import { PROD } from 'app/constants/env';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import CookieService from 'app/utils/services/cookies';
import { USER_ID_KEY } from 'app/constants/store';

export const intercomEventQueue = new MarketingEventsQueue<INTERCOM_EVENT>();
export const gtmEventQueue = new MarketingEventsQueue<GTM_EVENT>();

const MarketingWrapper = ({ children }: PropsWithChildren) => {
  const [isIntercomLaunched, setIntercomLaunched] = useState(false);
  const { boot, shutdown } = useIntercom();
  const tokenData = useTokenData();

  const intercomTracker = useIntercomEvents();
  const gtmTracker = useGTMEvents();

  const emptyIntercomQueue = () => {
    while (!intercomEventQueue.isEmpty()) {
      const event = intercomEventQueue.dequeue();
      if (!event) return;
      intercomTracker.trackEvent(event.name, event.metadata);
    }
  };

  const emptyGtmQueue = () => {
    while (!gtmEventQueue.isEmpty()) {
      const event = gtmEventQueue.dequeue();
      if (!event) return;
      gtmTracker.trackEvent(event.name, event.metadata);
    }
  };

  useFetchUser(tokenData?.userId as string, {
    enabled: !!tokenData?.userId,
    onSuccess: (data) => {
      if (!PROD || !tokenData || tokenData?.role === ROLE.KID) return;

      boot({
        userId: tokenData.userId,
        email: data.email,
      });
      emptyIntercomQueue();
      setIntercomLaunched(true);
    },
  });

  useEffect(() => {
    if (tokenData?.role === ROLE.KID) {
      shutdown();
    }

    return () => shutdown();
  }, [tokenData?.role]);

  intercomEventQueue.subscribe(() => {
    if (!isIntercomLaunched || !PROD) return;
    emptyIntercomQueue();
  });

  gtmEventQueue.subscribe(() => {
    if (!CookieService.get(USER_ID_KEY) || !PROD) return;
    emptyGtmQueue();
  });

  return <>{children}</>;
};

export const MarketingProvider = ({ children }: PropsWithChildren) => (
  <IntercomProvider appId={intercomApiKey}>
    <MarketingWrapper>{children}</MarketingWrapper>
  </IntercomProvider>
);
