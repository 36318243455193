import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import { type AppTheme } from '@coach/ui/utils/appTheme';

const avatarPropTypes = {
  className: PropTypes.string,
  email: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.string,
  imgSrc: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  // @ts-expect-error TS(2339): Property 'boolean' does not exist on type 'typeof ... Remove this comment to see the full error message
  showBadge: PropTypes.boolean,
};

const avatarSizes = {
  small: 'small',
  big: 'big',
};

const useAvatarStyles = makeStyles<AppTheme>((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    position: 'relative',
  },
  root: {
    position: 'relative',
  },
  avatarFallback: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  [avatarSizes.small]: {
    width: 40,
    height: 40,
    fontSize: 14,
  },
  [avatarSizes.big]: {
    width: 100,
    height: 100,
    fontSize: 32,
  },
  dot: {
    background: '#EC407A',
    width: 10,
    height: 10,
    borderRadius: '50%',
    position: 'absolute',
    border: '1px solid #FFF',
    right: 0,
    bottom: 0,
  },
}));

export const CustomAvatar = ({ className, email, firstName, lastName, imgSrc, size, showBadge, ...props }) => {
  const s = useAvatarStyles(props);

  const contentHandler = () => {
    if (email) {
      return email.charAt(0).toUpperCase();
    }
    return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
  };

  return (
    <div className={s.root}>
      <Avatar className={cx(s.avatar, s[size], className)} src={imgSrc} {...props}>
        {!imgSrc && <div className={cx(s.avatarFallback, s.avatarFallback[size])}>{contentHandler()}</div>}
      </Avatar>
      {showBadge && <div className={s.dot} />}
    </div>
  );
};

CustomAvatar.propTypes = avatarPropTypes;

CustomAvatar.defaultProps = {
  email: null,
  className: null,
  style: null,
  imgSrc: null,
  firstName: 'Eureka',
  lastName: 'User',
  size: 'small',
  showBadge: false,
};
