import React from 'react';
import { useMobileViewportHeightCorrection } from 'app/utils/hooks/useMobileViewportHeightCorrection';
import cx from 'app/utils/helpers/cx';
import { useWinterMode } from 'data/atoms/feature-flags';
import styles from './styles.module.css';

interface KidLayoutProps {
  content: React.ReactNode;
  sidebar: React.ReactNode;
  header: React.ReactNode;
}

const KidLayout = ({ content, sidebar, header }: KidLayoutProps) => {
  useMobileViewportHeightCorrection();
  const isWinterMode = useWinterMode();

  return (
    <main id="kid-layout" className="relative flex min-h-safe-screen w-full flex-row overflow-x-hidden">
      {sidebar}
      <div className={cx('flex-1 overflow-auto bg-emerald-600', isWinterMode && styles.winterMode)}>
        {header}
        {content}
      </div>
    </main>
  );
};

export default KidLayout;
