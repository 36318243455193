/**
 * This is a straight rip-off of the React.js ReactPropTypes.js proptype validators,
 * modified to make it possible to validate Immutable.js data.
 *     ImmutableTypes.listOf is patterned after React.PropTypes.arrayOf, but for Immutable.List
 *     ImmutableTypes.shape  is based on React.PropTypes.shape, but for any Immutable.Iterable
 */

import Immutable from 'immutable';
import { PROD } from 'app/constants/env';

const ANONYMOUS = '<<anonymous>>';

function getPropType(propValue) {
  const propType = typeof propValue;
  if (Array.isArray(propValue)) {
    return 'array';
  }
  if (propValue instanceof RegExp) {
    // Old webkits (at least until Android 4.0) return 'function' rather than
    // 'object' for typeof a RegExp. We'll normalize this here so that /bla/
    // passes PropTypes.object.
    return 'object';
  }
  // @ts-expect-error TS(2339): Property 'Iterable' does not exist on type 'typeof... Remove this comment to see the full error message
  if (propValue instanceof Immutable.Iterable) {
    return `Immutable. ${propValue.toSource().split(' ')[0]}`;
  }
  return propType;
}

function createChainableTypeChecker(validate) {
  function checkType(isRequired, props, propName, componentName, location, propFullName, ...rest) {
    const propFullNameRes = propFullName || propName;
    const componentNameRes = componentName || ANONYMOUS;
    if (props[propName] == null) {
      const locationName = location;
      if (isRequired) {
        return new Error(`Required ${locationName} ${propFullNameRes} was not specified in ${componentNameRes}.`);
      }
    }

    return validate(props, propName, componentNameRes, location, propFullNameRes, ...rest);
  }

  const chainedCheckType = checkType.bind(null, false);
  chainedCheckType.isRequired = checkType.bind(null, true);

  return chainedCheckType;
}

function createImmutableTypeChecker(immutableClassName, immutableClassTypeValidator) {
  function validate(props, propName, componentName, location, propFullName) {
    const propValue = props[propName];
    if (!immutableClassTypeValidator(propValue)) {
      const propType = getPropType(propValue);
      return new Error(
        `Invalid ${location} \`${propFullName}\` of type \`${propType}\` ` +
          `supplied to \`${componentName}\`, expected \`${immutableClassName}\`.`,
      );
    }
    return null;
  }

  return createChainableTypeChecker(validate);
}

function createIterableSubclassTypeChecker(subclassName, validator) {
  return createImmutableTypeChecker(
    `Iterable.${subclassName}`,
    // @ts-expect-error TS(2339): Property 'Iterable' does not exist on type 'typeof... Remove this comment to see the full error message
    (propValue) => Immutable.Iterable.isIterable(propValue) && validator(propValue),
  );
}

function createIterableTypeChecker(typeChecker, immutableClassName, immutableClassTypeValidator) {
  function validate(props, propName, componentName, location, propFullName, ...rest) {
    const propValue = props[propName];
    if (!immutableClassTypeValidator(propValue)) {
      const locationName = location;
      const propType = getPropType(propValue);
      return new Error(
        `Invalid ${locationName} \`${propFullName}\` of type ` +
          `\`${propType}\` supplied to \`${componentName}\`, expected an Immutable.js ${immutableClassName}.`,
      );
    }

    if (typeof typeChecker !== 'function') {
      return new Error(
        `Invalid typeChecker supplied to \`${componentName}\` ` +
          `for propType \`${propFullName}\`, expected a function.`,
      );
    }
    const propValues = propValue.valueSeq().toArray();
    for (let i = 0, len = propValues.length; i < len; i += 1) {
      const error = typeChecker(propValues, i, componentName, location, `${propFullName}[${i}]`, ...rest);
      if (error instanceof Error) {
        return error;
      }
    }
    return null;
  }

  return createChainableTypeChecker(validate);
}

function createKeysTypeChecker(typeChecker) {
  function validate(props, propName, componentName, location, propFullName, ...rest) {
    const propValue = props[propName];
    if (typeof typeChecker !== 'function') {
      return new Error(
        `Invalid keysTypeChecker (optional second argument) supplied to \`${componentName}\` ` +
          `for propType \`${propFullName}\`, expected a function.`,
      );
    }
    const keys = propValue.keySeq().toArray();
    for (let i = 0, len = keys.length; i < len; i += 1) {
      const error = typeChecker(keys, i, componentName, location, `${propFullName} -> key(${keys[i]})`, ...rest);
      if (error instanceof Error) {
        return error;
      }
    }
    return null;
  }

  return createChainableTypeChecker(validate);
}

function createListOfTypeChecker(typeChecker) {
  return createIterableTypeChecker(typeChecker, 'List', Immutable.List.isList);
}

function createMapOfTypeCheckerFactory(
  valuesTypeChecker,
  keysTypeChecker,
  immutableClassName,
  immutableClassTypeValidator,
) {
  function validate(...args) {
    return (
      createIterableTypeChecker(valuesTypeChecker, immutableClassName, immutableClassTypeValidator)(...args) ||
      (keysTypeChecker && createKeysTypeChecker(keysTypeChecker)(...args))
    );
  }

  return createChainableTypeChecker(validate);
}

function createMapOfTypeChecker(valuesTypeChecker, keysTypeChecker) {
  return createMapOfTypeCheckerFactory(valuesTypeChecker, keysTypeChecker, 'Map', Immutable.Map.isMap);
}

function createOrderedMapOfTypeChecker(valuesTypeChecker, keysTypeChecker) {
  return createMapOfTypeCheckerFactory(
    valuesTypeChecker,
    keysTypeChecker,
    'OrderedMap',
    Immutable.OrderedMap.isOrderedMap,
  );
}

function createSetOfTypeChecker(typeChecker) {
  return createIterableTypeChecker(typeChecker, 'Set', Immutable.Set.isSet);
}

function createOrderedSetOfTypeChecker(typeChecker) {
  return createIterableTypeChecker(typeChecker, 'OrderedSet', Immutable.OrderedSet.isOrderedSet);
}

function createStackOfTypeChecker(typeChecker) {
  return createIterableTypeChecker(typeChecker, 'Stack', Immutable.Stack.isStack);
}

function createIterableOfTypeChecker(typeChecker) {
  // @ts-expect-error TS(2339): Property 'Iterable' does not exist on type 'typeof... Remove this comment to see the full error message
  return createIterableTypeChecker(typeChecker, 'Iterable', Immutable.Iterable.isIterable);
}

function createRecordOfTypeChecker(recordKeys) {
  function validate(props, propName, componentName, location, propFullName, ...rest) {
    const propValue = props[propName];
    if (!(propValue instanceof Immutable.Record)) {
      const propType = getPropType(propValue);
      const locationName = location;
      return new Error(
        `Invalid ${locationName} \`${propFullName}\` of type \`${propType}\` ` +
          `supplied to \`${componentName}\`, expected an Immutable.js Record.`,
      );
    }
    Object.keys(recordKeys).forEach((key) => {
      const checker = recordKeys[key];
      if (!checker) {
        return null;
        // continue;
      }
      // @ts-expect-error TS(2339): Property 'toObject' does not exist on type '{}'.
      const mutablePropValue = propValue.toObject();
      // @ts-expect-error TS(2731): Implicit conversion of a 'symbol' to a 'string' wi... Remove this comment to see the full error message
      const error = checker(mutablePropValue, key, componentName, location, `${propFullName}.${key}`, ...rest);
      if (error) {
        return error;
      }
      return null;
    });
    return null;
  }

  return createChainableTypeChecker(validate);
}

// there is some irony in the fact that shapeTypes is a standard hash and not an immutable collection
function createShapeTypeChecker(
  shapeTypes,
  immutableClassName = 'Iterable',
  // @ts-expect-error TS(2339): Property 'Iterable' does not exist on type 'typeof... Remove this comment to see the full error message
  immutableClassTypeValidator = Immutable.Iterable.isIterable,
) {
  function validate(props, propName, componentName, location, propFullName, ...rest) {
    const propValue = props[propName];
    if (!immutableClassTypeValidator(propValue)) {
      const propType = getPropType(propValue);
      const locationName = location;
      return new Error(
        `Invalid ${locationName} \`${propFullName}\` of type \`${propType}\` ` +
          `supplied to \`${componentName}\`, expected an Immutable.js ${immutableClassName}.`,
      );
    }
    const mutablePropValue = propValue.toObject();
    Object.keys(shapeTypes).forEach((key) => {
      const checker = shapeTypes[key];
      if (!checker) {
        return null;
      }
      // @ts-expect-error TS(2731): Implicit conversion of a 'symbol' to a 'string' wi... Remove this comment to see the full error message
      const error = checker(mutablePropValue, key, componentName, location, `${propFullName}.${key}`, ...rest);
      if (error) {
        return error;
      }
      return null;
    });
    return null;
  }

  return createChainableTypeChecker(validate);
}

function createShapeChecker(shapeTypes) {
  return createShapeTypeChecker(shapeTypes);
}

function createMapContainsChecker(shapeTypes) {
  return createShapeTypeChecker(shapeTypes, 'Map', Immutable.Map.isMap);
}

function createOrderedMapContainsChecker(shapeTypes) {
  return createShapeTypeChecker(shapeTypes, 'OrderedMap', Immutable.OrderedMap.isOrderedMap);
}

const ImmutablePropTypes = (() => {
  if (!PROD) {
    return {
      listOf: createListOfTypeChecker,
      mapOf: createMapOfTypeChecker,
      orderedMapOf: createOrderedMapOfTypeChecker,
      setOf: createSetOfTypeChecker,
      orderedSetOf: createOrderedSetOfTypeChecker,
      stackOf: createStackOfTypeChecker,
      iterableOf: createIterableOfTypeChecker,
      recordOf: createRecordOfTypeChecker,
      shape: createShapeChecker,
      contains: createShapeChecker,
      mapContains: createMapContainsChecker,
      orderedMapContains: createOrderedMapContainsChecker,
      // Primitive Types
      list: createImmutableTypeChecker('List', Immutable.List.isList),
      map: createImmutableTypeChecker('Map', Immutable.Map.isMap),
      orderedMap: createImmutableTypeChecker('OrderedMap', Immutable.OrderedMap.isOrderedMap),
      set: createImmutableTypeChecker('Set', Immutable.Set.isSet),
      orderedSet: createImmutableTypeChecker('OrderedSet', Immutable.OrderedSet.isOrderedSet),
      stack: createImmutableTypeChecker('Stack', Immutable.Stack.isStack),
      seq: createImmutableTypeChecker('Seq', Immutable.Seq.isSeq),
      record: createImmutableTypeChecker('Record', (isRecord) => isRecord instanceof Immutable.Record),
      // @ts-expect-error TS(2339): Property 'Iterable' does not exist on type 'typeof... Remove this comment to see the full error message
      iterable: createImmutableTypeChecker('Iterable', Immutable.Iterable.isIterable),
    };
  }

  const productionTypeChecker = () => {
    throw new Error('ImmutablePropTypes type checking code is stripped in production.');
  };

  productionTypeChecker.isRequired = productionTypeChecker;
  const getProductionTypeChecker = () => productionTypeChecker;

  return {
    listOf: getProductionTypeChecker,
    mapOf: getProductionTypeChecker,
    orderedMapOf: getProductionTypeChecker,
    setOf: getProductionTypeChecker,
    orderedSetOf: getProductionTypeChecker,
    stackOf: getProductionTypeChecker,
    iterableOf: getProductionTypeChecker,
    recordOf: getProductionTypeChecker,
    shape: getProductionTypeChecker,
    contains: getProductionTypeChecker,
    mapContains: getProductionTypeChecker,
    orderedMapContains: getProductionTypeChecker,
    // Primitive Types
    list: productionTypeChecker,
    map: productionTypeChecker,
    orderedMap: productionTypeChecker,
    set: productionTypeChecker,
    orderedSet: productionTypeChecker,
    stack: productionTypeChecker,
    seq: productionTypeChecker,
    record: productionTypeChecker,
    iterable: productionTypeChecker,
  };
})();

// @ts-expect-error TS(2339): Property 'Iterable' does not exist on type 'typeof... Remove this comment to see the full error message
ImmutablePropTypes.iterable.indexed = createIterableSubclassTypeChecker('Indexed', Immutable.Iterable.isIndexed);
// @ts-expect-error TS(2339): Property 'Iterable' does not exist on type 'typeof... Remove this comment to see the full error message
ImmutablePropTypes.iterable.keyed = createIterableSubclassTypeChecker('Keyed', Immutable.Iterable.isKeyed);

export default ImmutablePropTypes;
