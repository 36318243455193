import { cva } from 'class-variance-authority';
import { buttonVariantSizeShapeCompoundVariants } from './button-variant-size-shape-compound-variants';
import { buttonVariantColorCompoundVariants } from './button-variant-color-compound-variants';
import { buttonVariantSizeCompoundVariants } from './button-variant-size-compound-varians';

export const buttonStyles = cva(
  'active:shadow-none cursor-pointer border-none inline-flex justify-center items-center',
  {
    variants: {
      shape: {
        circle: 'rounded-full',
        square: '',
      },
      color: {
        purple: '',
        blue: '',
        yellow: '',
        yellowDark: '',
        red: '',
        gray: '',
        green: '',
        greenDark: '',
        white: '',
      },
      size: {
        large: 'text-5xl',
        medium: 'text-[32px]',
        small: 'text-2xl',
        extraSmall: 'text-base',
      },
      variant: {
        outlined: 'border-solid border-4 text-neutral-800 bg-transparent',
        contained: '',
      },
    },
    compoundVariants: [
      ...buttonVariantSizeShapeCompoundVariants,
      ...buttonVariantColorCompoundVariants,
      ...buttonVariantSizeCompoundVariants,
    ],
    defaultVariants: {
      size: 'medium',
      color: 'blue',
      shape: 'square',
      variant: 'contained',
    },
  },
);
