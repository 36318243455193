import { atom } from 'jotai';
import { useAtom } from 'jotai/react';

export const isBannerOpenAtom = atom(true);

export function useBanner() {
  const [isOpen, setOpen] = useAtom(isBannerOpenAtom);

  const close = () => setOpen(false);
  const open = () => setOpen(true);
  const toggle = () => setOpen((prev) => !prev);

  return {
    isOpen,
    close,
    open,
    toggle,
  };
}
