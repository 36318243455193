import React from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import cx from 'classnames';
import { icons } from '../icons/icons';
import { useLinkIconStyles, useLinkStyles } from './styles';

const LinkPropTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

export const LinkComponent = ({ className, to, children, ...props }) => {
  const s = useLinkStyles();

  return (
    <Link className={cx(className, s.root)} href={to} {...props}>
      {children}
    </Link>
  );
};

LinkComponent.defaultProps = {
  className: null,
};

LinkComponent.propTypes = LinkPropTypes;

const LinkIconPropTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export const LinkIcon = ({ className, color, icon, to, ...props }) => {
  const s = useLinkIconStyles();
  return (
    <LinkComponent to={to} className={cx(className, s.root)} {...props}>
      {icons[icon]}
    </LinkComponent>
  );
};

LinkIcon.propTypes = LinkIconPropTypes;
LinkIcon.defaultProps = {
  className: null,
  color: 'blue',
};
