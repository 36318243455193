import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ChevronRightIcon from '@icons/kid/ChevronRight';
import ChevronLeftIcon from '@icons/kid/ChevronLeft';
import { usePaginationStyles } from './styles';
import { IconButton } from '../icon-button/icon-button';

export const Pagination = ({ total, perPage, currentPage, setPage }) => {
  const paginationPage = currentPage + 1;
  const maxPages = Math.ceil(total / perPage);
  let leftSide = paginationPage === maxPages ? paginationPage - 2 : paginationPage - 1;
  if (leftSide <= 0) leftSide = 1;
  let rightSide = paginationPage === 1 ? paginationPage + 2 : paginationPage + 1;
  if (rightSide > maxPages) rightSide = maxPages;
  const s = usePaginationStyles();

  const paginationItems = [...Array(rightSide + 1 - leftSide)].map((_, index) => {
    const page = leftSide + index;
    return (
      <button
        key={page}
        className={cx(s.page, paginationPage === page && s.active)}
        onClick={() => {
          setPage(page - 1);
        }}
      >
        {page}
      </button>
    );
  });

  const nextPage = () => {
    if (paginationPage < maxPages) {
      setPage(paginationPage);
    }
  };

  const prevPage = () => {
    if (paginationPage > 1) {
      setPage(paginationPage - 2);
    }
  };

  return (
    <div className={s.pagination}>
      <IconButton color="gray" size="extraSmall" onClick={prevPage}>
        <ChevronLeftIcon fontSize="inherit" />
      </IconButton>
      {paginationItems}
      <IconButton color="gray" size="extraSmall" onClick={nextPage}>
        <ChevronRightIcon fontSize="inherit" />
      </IconButton>
    </div>
  );
};

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};
