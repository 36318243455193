import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { TitleContext } from './context';

export const Title = () => {
  const { t } = useTranslation();
  const { title } = useContext(TitleContext);

  const pageTitle = title
    .map((item) => {
      const translated = t(item.name);
      const isFirstLetterLowerCase = translated[0] === translated[0].toLowerCase();
      /**
       * It's important to capitalize only the first letter of the title.
       * We can't use capitalize from lodash, as it will make all the other letters lowercase, which is not what we want.
       *
       * Example:
       * _.capitalize('CleverKids');
       * // => 'Cleverkids'
       */
      return isFirstLetterLowerCase ? translated[0].toUpperCase() + translated.slice(1) : translated;
    })
    .join(' | ');

  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  );
};
