import { schema } from 'normalizr';

export const learningCulture = new schema.Entity('learningCultures');
export const user = new schema.Entity('users');
export const grade = new schema.Entity('grades');
export const domain = new schema.Entity('domains');
export const field = new schema.Entity('fields');
export const videos = new schema.Entity('videos');

export const bookPackage = new schema.Entity('bookPackages');
export const book = new schema.Entity('books');
export const question = new schema.Entity('questions');

export const questionBox = new schema.Entity('questionBoxes');

export const learningBox = new schema.Entity('learningBoxes', { questionBoxes: [questionBox] });

export const didacticBox = new schema.Entity('didacticBoxes', { learningBoxes: [learningBox] });

export const gradeField = new schema.Entity('gradeFields');

export const kid = new schema.Entity('kids');
export const admin = new schema.Entity('admins');
export const school = new schema.Entity('schools');
export const file = new schema.Entity('files');
export const tower = new schema.Entity('towers');
export const action = new schema.Entity('actions');
export const membership = new schema.Entity('memberships');
export const booklicense = new schema.Entity('booklicenses');
export const word = new schema.Entity('words');
export const progress = new schema.Entity('progresses');
export const report = new schema.Entity('reports');
