import { z } from 'zod';

export const StripeConfigValidator = z.object({
  // days
  trialPeriod: z.object({
    family: z.coerce.number(),
    school: z.coerce.number(),
    practice: z.coerce.number(),
  }),
  prices: z.object({
    monthly: z.string(),
    yearly: z.string(),
  }),
});

export type StripeConfig = z.infer<typeof StripeConfigValidator>;
