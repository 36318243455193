/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import InputBase, { type InputBaseProps } from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import { icons } from '@coach/ui';
import cx from 'app/utils/helpers/cx';
import { useTranslation } from 'react-i18next';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { useFormStyles, useSearchStyles, useTextAreaStyles } from './styles';

export type FormProps = Omit<TextFieldProps, 'color'> & {
  color?: string;
};

export const Form = React.forwardRef(function Form(
  { className, color = 'blue', ...props }: FormProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const s = useFormStyles();
  return (
    <TextField ref={ref} variant="outlined" margin="normal" className={cx(s.root, s[color], className)} {...props} />
  );
});

export const PasswordInput = React.forwardRef(function PasswordInput(props: TextFieldProps, ref: React.Ref<any>) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  return (
    <TextField
      ref={ref}
      size="small"
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
});

export const InputWithDeleteIcon = ({ onDelete, ...props }) => {
  const s = useFormStyles();
  return (
    <Form
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onDelete} className={s.closeIcon}>
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

InputWithDeleteIcon.defaultProps = {
  onDelete: PropTypes.func.isRequired,
};

const TextAreaPropTypes = {
  color: PropTypes.string,
};

export const TextArea = ({ className, color, error, ...props }) => {
  const s = useFormStyles();
  const textAreaEl = useRef(null);
  const classes = useTextAreaStyles();
  const onChangeHandler = () => {
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    const computed = window.getComputedStyle(textAreaEl.current);
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    textAreaEl.current.style.height = 'inherit';
    const height =
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      textAreaEl.current.scrollHeight -
      parseInt(computed.getPropertyValue('padding-top'), 10) -
      parseInt(computed.getPropertyValue('padding-bottom'), 10);
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    textAreaEl.current.style.height = `${height}px`;
  };
  return (
    <TextField
      inputRef={textAreaEl}
      onChange={onChangeHandler}
      multiline
      variant="outlined"
      className={cx(s[color], classes.root, className)}
      error={!!error}
      helperText={error}
      {...props}
    />
  );
};

TextArea.defaultProps = {
  color: 'blue',
};

TextArea.propTypes = TextAreaPropTypes;

type SearchProps = Omit<InputBaseProps, 'classes'> & {
  inputStyle?: object;
  classes?: {
    root?: string;
    icon?: string;
  };
  inputClasses?: InputBaseProps['classes'];
};

export const Search = (props: SearchProps) => {
  const styles = useSearchStyles();
  const { t } = useTranslation();
  const { placeholder = t('search'), className, inputStyle, classes, inputClasses, inputProps, ...other } = props;

  return (
    <div className={cx(styles.search, className, classes?.root)}>
      <div className={cx(styles.searchIcon, classes?.icon)}>{icons.search}</div>
      <InputBase
        placeholder={placeholder}
        classes={{
          ...inputClasses,
          root: cx(styles.inputRoot, inputClasses?.root),
          input: cx(styles.inputInput, inputClasses?.input),
        }}
        inputProps={{ 'aria-label': 'Search', style: inputStyle, ...inputProps }}
        {...other}
      />
    </div>
  );
};
