import { fromJS } from 'immutable';
import * as constants from 'data/constants/books';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const booksPagination = new Pagination('books');
export const booksProgress = new LoadingProgress('booksProgress');
export const bookRemovalProgress = new LoadingProgress('bookRemovalProgress');
export const bookProgress = new LoadingProgress('bookProgress');
export const bookEditProgress = new LoadingProgress('bookEditProgress');
export const bookPackagesProgress = new LoadingProgress('bookPackagesProgress');
export const bookPackagesPagination = new Pagination('bookPackagesPagination');
export const bookPackageProgress = new LoadingProgress('bookPackageProgress');
export const bookPackageRemovalProgress = new LoadingProgress('bookPackageRemovalProgress');
export const bookPackageEditProgress = new LoadingProgress('bookPackageEditProgress');
export const bookRemovalFromBookPackageProgress = new LoadingProgress('bookRemovalFromBookPackageProgress');
export const booksFromBookPackageProgress = new LoadingProgress('bookFromBookPackageProgress');
export const booksFromBookPackagePagination = new Pagination('booksFromBookPackagePagination');
export const bookDownloadingProgress = new LoadingProgress('bookDownloadingProgress');

const mergeBooks = (state, action) =>
  state.withMutations((newState) => {
    const {
      entities: { books },
    } = action.payload;
    newState.mergeIn(['entities'], fromJS(books));
  });

const mergeBookPackages = (state, action) =>
  state.withMutations((newState) => {
    const {
      entities: { bookPackages },
    } = action.payload;
    newState.mergeIn(['bookPackages'], fromJS(bookPackages));
  });

const loadBooks = (state, action) =>
  state.withMutations((newState) => {
    const { count, limit, result, forKid, isLoadMore } = action.payload;
    if (forKid) {
      const {
        entities: { books },
      } = action.payload;
      if (isLoadMore) {
        newState.mergeIn(['entities'], fromJS(books));
        newState.mergeIn(['booksIds'], fromJS(result));
      } else {
        newState.setIn(['entities'], fromJS(books));
        newState.setIn(['booksIds'], fromJS(result));
      }
    } else {
      mergeBooks(newState, action);
    }
    booksPagination.set(newState, count, limit, fromJS(result));
    booksProgress.setLoaded(newState);
  });

const loadBook = (state, action) =>
  state.withMutations((newState) => {
    mergeBooks(newState, action);
    bookProgress.setLoaded(newState);
  });

const editBook = (state, action) =>
  state.withMutations((newState) => {
    mergeBooks(newState, action);
    bookEditProgress.setLoaded(newState);
  });

const deleteBook = (state, action) =>
  state.withMutations((newState) => {
    const { bookId } = action.payload;
    newState.deleteIn(['entities', bookId]);
    newState.set(
      'booksPageItems',
      state.get('booksPageItems').filter((el) => el !== bookId),
    );
    newState.set('booksTotalItems', state.get('booksTotalItems') - 1);
    bookRemovalProgress.setLoaded(newState);
  });

// const clearBooks = state =>
//   state.withMutations(newState => {
//     newState.delete('entities');
//     newState.delete('booksIds');
//   });

const initialState = fromJS({});
const loadBookPackages = (state, action) =>
  state.withMutations((newState) => {
    const { count, result } = action.payload;
    mergeBookPackages(newState, action);
    bookPackagesPagination.set(newState, count, null, fromJS(result));
    bookPackagesProgress.setLoaded(newState);
  });

const loadBookPackage = (state, action) =>
  state.withMutations((newState) => {
    mergeBookPackages(newState, action);
    bookPackageProgress.setLoaded(newState);
  });

const deleteBookPackage = (state, action) =>
  state.withMutations((newState) => {
    const { bookPackageId } = action.payload;
    newState.deleteIn(['bookPackages', bookPackageId]);
    newState.set(
      'bookPackagesPaginationPageItems',
      state.get('bookPackagesPaginationPageItems').filter((el) => el !== bookPackageId),
    );
    newState.set('bookPackagesPaginationTotalItems', state.get('bookPackagesPaginationTotalItems') - 1);
    bookPackageRemovalProgress.setLoaded(newState);
  });

const editBookPackage = (state, action) =>
  state.withMutations((newState) => {
    mergeBookPackages(newState, action);
    bookPackageEditProgress.setLoaded(newState);
  });

const deleteBookFromBookPackage = (state, action) =>
  state.withMutations((newState) => {
    const bookId = action.payload;
    newState.deleteIn(['booksFromBookPackage', bookId]);
    newState.set(
      'booksFromBookPackagePaginationPageItems',
      state.get('booksFromBookPackagePaginationPageItems').filter((el) => el !== bookId),
    );
    newState.set('booksFromBookPackagePaginationTotalItems', state.get('booksFromBookPackagePaginationTotalItems') - 1);
    bookRemovalFromBookPackageProgress.setLoaded(newState);
  });

const loadBooksFromBookPackage = (state, action) =>
  state.withMutations((newState) => {
    const {
      count,
      result,
      entities: { books },
    } = action.payload;
    newState.mergeIn(['booksFromBookPackage'], fromJS(books));
    booksFromBookPackagePagination.set(newState, count, null, fromJS(result));
    booksFromBookPackageProgress.setLoaded(newState);
  });

const loadBookUrl = (state, action) =>
  state.withMutations((newState) => {
    const { url } = action.payload;
    newState.setIn(['bookUrl'], fromJS(url));
    bookDownloadingProgress.setLoaded(newState);
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_BOOKS_START:
    case constants.FETCH_BOOKS_BY_COACH_START:
      return booksProgress.setLoading(state);
    case constants.FETCH_BOOKS_SUCCESS:
    case constants.FETCH_BOOKS_BY_COACH_SUCCESS:
      return loadBooks(state, action);
    case constants.FETCH_BOOKS_FAILED:
    case constants.FETCH_BOOKS_BY_COACH_FAILED:
      return booksProgress.setLoadFailed(state);
    case constants.DELETE_BOOK_START:
      return bookRemovalProgress.setLoading(state);
    case constants.DELETE_BOOK_SUCCESS:
      return deleteBook(state, action);
    case constants.DELETE_BOOK_FAILED:
      return bookRemovalProgress.setLoadFailed(state);
    case constants.FETCH_BOOK_START:
      return bookProgress.setLoading(state);
    case constants.EDIT_BOOK_START:
      return bookEditProgress.setLoading(state);
    case constants.FETCH_BOOK_SUCCESS:
      return loadBook(state, action);
    case constants.EDIT_BOOK_SUCCESS:
      return editBook(state, action);
    case constants.FETCH_BOOK_FAILED:
      return bookProgress.setLoadFailed(state);
    case constants.EDIT_BOOK_FAILED:
      return bookEditProgress.setLoadFailed(state);
    case constants.CLEAR_BOOKS:
      return initialState;
    case constants.FETCH_BOOK_PACKAGES_START:
      return bookPackagesProgress.setLoading(state);
    case constants.FETCH_BOOK_PACKAGES_SUCCESS:
      return loadBookPackages(state, action);
    case constants.FETCH_BOOK_PACKAGES_FAILED:
      return bookPackagesProgress.setLoadFailed(state);
    case constants.FETCH_BOOK_PACKAGE_START:
      return bookPackageProgress.setLoading(state);
    case constants.FETCH_BOOK_PACKAGE_SUCCESS:
      return loadBookPackage(state, action);
    case constants.FETCH_BOOK_PACKAGE_FAILED:
      return bookPackageProgress.setLoadFailed(state);
    case constants.DELETE_BOOK_PACKAGE_START:
      return bookPackageRemovalProgress.setLoading(state);
    case constants.DELETE_BOOK_PACKAGE_SUCCESS:
      return deleteBookPackage(state, action);
    case constants.DELETE_BOOK_PACKAGE_FAILED:
      return bookPackageRemovalProgress.setLoadFailed(state);
    case constants.EDIT_BOOK_PACKAGE_START:
      return bookPackageEditProgress.setLoading(state);
    case constants.EDIT_BOOK_PACKAGE_SUCCESS:
      return editBookPackage(state, action);
    case constants.EDIT_BOOK_PACKAGE_FAILED:
      return bookPackageEditProgress.setLoadFailed(state);
    case constants.DELETE_BOOK_FROM_BOOK_PACKAGE_START:
      return bookRemovalFromBookPackageProgress.setLoading(state);
    case constants.DELETE_BOOK_FROM_BOOK_PACKAGE_SUCCESS:
      return deleteBookFromBookPackage(state, action);
    case constants.DELETE_BOOK_FROM_BOOK_PACKAGE_FAILED:
      return bookRemovalFromBookPackageProgress.setLoadFailed(state);
    case constants.FETCH_BOOKS_FROM_BOOK_PACKAGE_START:
      return booksFromBookPackageProgress.setLoading(state);
    case constants.FETCH_BOOKS_FROM_BOOK_PACKAGE_SUCCESS:
      return loadBooksFromBookPackage(state, action);
    case constants.FETCH_BOOKS_FROM_BOOK_PACKAGE_FAILED:
      return booksFromBookPackageProgress.setLoadFailed(state);
    case constants.DOWNLOAD_BOOK_WATERMARKED_START:
      return bookDownloadingProgress.setLoading(state);
    case constants.DOWNLOAD_BOOK_WATERMARKED_SUCCESS:
      return loadBookUrl(state, action);
    case constants.DOWNLOAD_BOOK_WATERMARKED_FAILED:
      return bookDownloadingProgress.setLoadFailed(state);
    default:
      return state;
  }
};
