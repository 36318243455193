import { z } from 'zod';

export const PopulatedRoleSchema = z.object({
  memberships: z.boolean().optional(),
  culture: z.boolean().optional(),
});

export const RoleQuerySchema = z.object({
  populate: PopulatedRoleSchema.optional(),
});

export type PopulatedRole = z.infer<typeof PopulatedRoleSchema>;

export type RoleQuery = z.infer<typeof RoleQuerySchema>;
