import { atom } from 'jotai';
import { getTokenData } from 'data/stores/auth-store';
import { Client, Message } from 'data/com/client/index.js';
import { COM_WS_URL } from 'app/constants/env';
import { ErrorService } from 'app/utils/services/error-tracking';
import { RuntimeError } from 'app/utils/error';
import api from 'data/axios/react-query-api';
import { logger } from 'data/logger';
import { MILLISECONDS } from 'app/constants/data';

async function reconnect() {
  const tokenData = getTokenData();
  if (!tokenData) {
    return new URLSearchParams();
  }

  const { token } = await api.get<{ token: string }>('/ws-token');

  return new URLSearchParams({ token });
}

const WS_URL = new URL('/ws', COM_WS_URL);

export const connection = Client.createConnection({
  url: WS_URL,
  logger,
  onReconnect: reconnect,
  reconnectAttempts: 5,
});

const intervalID = window.setInterval(() => {
  Client.refreshReconnectAttempts(connection);
}, 30 * MILLISECONDS.SECOND);

window.addEventListener('beforeunload', () => {
  window.clearInterval(intervalID);
  Client.close(connection);
});

Client.addEventListener(connection, 'error', (event) => {
  ErrorService.captureException(new RuntimeError('socket connection error', { event }));
});

export const messagePubSub = new Message.MessagePubSub();

Client.addEventListener(connection, 'message', messagePubSub.handleMessage.bind(messagePubSub));

export const connectionAtom = atom(connection);

export const messagePubSubAtom = atom(messagePubSub);
