import React from 'react';
import Popover from '@mui/material/Popover';
import PropTypes from 'prop-types';

const Popup = ({
  anchorEl,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  children,
  className,
  onClose,
  ...props
}) => (
  <Popover
    open={!!anchorEl}
    anchorEl={anchorEl}
    // @ts-expect-error TS(2322): Type '{ vertical: string; horizontal: string; }' i... Remove this comment to see the full error message
    anchorOrigin={anchorOrigin}
    // @ts-expect-error TS(2322): Type '{ vertical: string; horizontal: string; }' i... Remove this comment to see the full error message
    transformOrigin={transformOrigin}
    onClose={onClose}
    className={className}
    {...props}
  >
    {children}
  </Popover>
);

Popup.propTypes = {
  anchorEl: PropTypes.node.isRequired,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.number,
  }),
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.number,
  }),
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

Popup.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'rightt',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  children: null,
  className: null,
};

export default Popup;
