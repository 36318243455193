import json2mq from 'json2mq';
import useMediaQueryMUI from '@mui/material/useMediaQuery';
import { type MediaQueryAllQueryable, type MediaQuerySettings } from './types';

type Settings = string | MediaQuerySettings | MediaQueryAllQueryable[];

const parseQuery = (settings: Settings) => {
  if (typeof settings === 'string') return settings;
  if ('query' in settings) return settings.query;
  return json2mq(settings);
};

export function useMediaQuery(settings: Settings): boolean {
  return useMediaQueryMUI(parseQuery(settings));
}
