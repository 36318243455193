import { useEffect } from 'react';
import { getActiveGroupId, useGroupActions } from 'data/stores/group-store';
import { useTokenData } from 'data/stores/auth-store';
import { useFetchAllGroupsByCoach } from 'data/hooks';
import { GROUP, ROLE } from 'data/enums';
import { MILLISECONDS } from 'app/constants/data';
import { RuntimeError } from 'app/utils/error';
import { ErrorService } from 'app/utils/services/error-tracking';
import { type ListEntity } from 'data/utils/types';
import { type GroupEntity } from 'data/types';

export function useCoachGroups(): {
  data: ListEntity<GroupEntity> | undefined;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
} {
  const { setActiveGroupId, setGroups, setActiveGroupType } = useGroupActions();
  const tokenData = useTokenData();

  const isAuthenticated = !!tokenData;
  const isCoach = tokenData?.role === ROLE.COACH;

  const { data, isInitialLoading, isSuccess, isError, refetch } = useFetchAllGroupsByCoach(
    tokenData?.roleId ?? '',
    {
      limit: 20,
      groupTypes: [GROUP.SCHOOL, GROUP.PRACTICE, GROUP.FAMILY],
    },
    {
      enabled: isAuthenticated && isCoach,
      staleTime: 3 * MILLISECONDS.MINUTE,
      onSuccess: function handleGroupSuccess(groupList) {
        const groups = groupList.items.map((item) => ({
          id: item.id,
          type: item.discriminator,
          name: item.name,
        }));

        const activeGroupId = getActiveGroupId();
        setGroups(groups);

        const g = groups.find((group) => group.id === activeGroupId);

        if (g) {
          setActiveGroupType(g.type);
          return;
        }

        if (groups.length > 0) {
          setActiveGroupId(groups[0].id);
        } else {
          setActiveGroupId(undefined);
        }
      },
      onError: function handleGroupsError(error) {
        const err = new RuntimeError("Failed to fetch coach's groups", {
          coachId: tokenData?.roleId,
          role: tokenData?.role,
          error,
        });

        console.error(err);
        ErrorService.captureException(err);
      },
    },
  );

  useEffect(() => {
    if (!tokenData?.role) return;

    refetch();
  }, [tokenData?.role]);

  return {
    data,
    isLoading: isInitialLoading,
    isSuccess,
    isError,
  };
}
