import { fromJS } from 'immutable';
import * as constants from 'data/constants/booklicenses';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const bookLicensesProgress = new LoadingProgress('bookLicensesProgress');
export const bookLicensesPagination = new Pagination('bookLicenses');
export const bookLicenseProgress = new LoadingProgress('bookLicenseProgress');
export const bookLicensesRemovalProgress = new LoadingProgress('bookLicensesRemovalProgress');

const initialState = fromJS({
  entities: {},
  ids: [],
});

const merge = (state, action) =>
  state.withMutations((newState) => {
    const {
      entities: { booklicenses },
      result,
    } = action.payload;

    newState.mergeDeepIn(['entities'], fromJS(booklicenses));
    newState.mergeIn(['ids'], fromJS(result));
  });

const loadLicense = (state, action) =>
  state.withMutations((newState) => {
    merge(newState, action);
    const {
      entities: { booklicenses },
      result: licenseId,
    } = action.payload;
    const newLicense = fromJS(booklicenses[licenseId]);
    if (newState.getIn(['entities', licenseId])) {
      newState.mergeDeepIn(['entities', licenseId], newLicense);
    } else {
      newState.setIn(['entities', licenseId], newLicense);
    }

    bookLicenseProgress.setLoaded(newState);
  });

const loadLicenses = (state, action) =>
  state.withMutations((newState) => {
    const {
      payload: { count, limit, result },
    } = action;
    merge(newState, action);
    bookLicensesPagination.set(newState, count, limit, fromJS(result));
    bookLicensesProgress.setLoaded(newState);
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_START:
    case constants.UPDATE_START:
      return bookLicenseProgress.setLoading(state);
    case constants.CREATE_SUCCESS:
    case constants.UPDATE_SUCCESS:
      return loadLicense(state, action);
    case constants.CREATE_FAILED:
    case constants.UPDATE_FAILED:
      return bookLicenseProgress.setLoadFailed(state);
    case constants.GET_ALL_START:
      return bookLicensesProgress.setLoading(state);
    case constants.GET_ALL_FAILED:
      return bookLicensesProgress.setLoadFailed(state);
    case constants.GET_ALL_SUCCESS:
      return loadLicenses(state, action);
    case constants.REMOVE_START:
      return bookLicensesRemovalProgress.setLoading(state);
    case constants.REMOVE_FAILED:
      return bookLicensesRemovalProgress.setLoadFailed(state);
    case constants.REMOVE_SUCCESS:
      return bookLicensesRemovalProgress.setLoaded(state);
    default:
      return state;
  }
};
