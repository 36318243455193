import { settingsPaths } from 'app/constants/url/shared';

const getSearchParam = (key: string, value: string) => {
  const search = new URLSearchParams();
  search.append(key, value);
  return search.toString();
};

export const tabMapper = {
  generalInfo: 'general',
  subscription: 'subscription',
  teamMembers: 'members',
  invites: 'invites',
};

export const settingsTabSearch = {
  subscription: getSearchParam(settingsPaths.params.tab, tabMapper.subscription.toString()),
};
