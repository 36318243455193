import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { type AppTheme } from '@coach/ui/utils/appTheme';

const drawerWidth = 248;

const useSidebarStyles = makeStyles<AppTheme>((theme) => ({
  drawerPaper: {
    position: 'relative',
    height: '100%',
    overflow: 'initial',
    border: 'none',
    background: 'none',
    color: `${theme.palette.text.primary} !important`,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  opened: {
    color: `${theme.palette.primary.main} !important`,
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 4,
      height: 48,
      top: 0,
      left: 0,
      background: alpha(theme.palette.primary.main, 0.5),
    },
  },
  drawerPaperClose: {
    width: 50,
    position: 'absolute',
    overflowX: 'hidden',
    background: theme.palette.background.paper,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '& $user': {
      justifyContent: 'flex-start',
    },
    '& $bigAvatar': {
      width: 40,
      height: 40,
    },
    '& nav': {
      display: 'none',
    },
    '&:hover': {
      width: drawerWidth,
      boxShadow: theme.shadows[6],
      '& nav': {
        display: 'block',
      },
    },
    '& $brand': {
      display: 'none',
    },
    '& $profile': {
      flexDirection: 'row',
      top: theme.spacing(6),
      padding: theme.spacing(0.5),
      textAlign: 'left',
      '& button': {
        width: 'auto',
      },
    },
    '& $avatar': {
      marginRight: theme.spacing(3),
    },
    '& $menuContainer': {
      '&$menuContainer': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  drawerInner: {
    // Make the items inside not wrap when transitioning:
    height: '100%',
    // position: 'fixed',
    backgroundColor: alpha(theme.palette.background.paper, 0.95),
    // boxShadow: theme.shade.light,
  },
  drawerHeader: {
    padding: '0',
    zIndex: 1,
    position: 'relative',
    ...theme.mixins.toolbar,
  },
  brandBar: {
    transition: theme.transitions.create(['width', 'margin', 'background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&:after': {
      transition: theme.transitions.create(['box-shadow'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  nested: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    margin: `${theme.spacing(1)} 0`,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(3),
    },
    '& .MuiListItemIcon-root': {
      minWidth: 32,
    },
  },
  child: {
    '& a': {
      paddingLeft: theme.spacing(6),
    },
  },
  dense: {
    marginLeft: -15,
    '& > $title:first-child': {
      margin: '0',
    },
    '& $head': {
      paddingLeft: theme.spacing(10),
    },
  },
  active: {
    backgroundColor: theme.palette.borderColor,
    '& $primary': {
      color: theme.palette.primary.dark,
    },
    '& $icon svg': {
      fill: theme.palette.primary.dark,
      color: theme.palette.primary.dark,
    },
    '&:hover, &:focus': {
      backgroundColor: theme.palette.borderColor,
    },
  },
  nolist: {
    listStyle: 'none',
  },
  primary: {
    whiteSpace: 'nowrap',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    fill: theme.palette.primary.dark,
    minWidth: 1,
  },
  iconAlert: {
    minWidth: 36,
  },
  iconed: {},
  head: {
    padding: `${theme.spacing(1)} 0`,
    margin: `${theme.spacing(1)} 0`,
    borderRadius: `0 ${theme.spacing(3)} ${theme.spacing(3)} 0`,
    paddingLeft: theme.spacing(3),
    color: theme.palette.text.primary,
    '&$iconed': {
      paddingLeft: theme.spacing(3),
    },
    '& svg[class^="MuiSvgIcon"]': {
      left: -10,
      position: 'relative',
    },
  },
  expandIcon: {
    fill: theme.palette.grey.main,
  },
  brand: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 64,
    position: 'relative',
    textDecoration: 'none',
    fontSize: 16,
    margin: 0,
    fontWeight: 500,
    color: theme.palette.text.primary,
    '& img': {
      width: 30,
      marginRight: 10,
    },
  },
  menuContainer: {
    overflow: 'auto',
    height: 'calc(100% - 64px)',
    backgroundColor: '#fff',
    width: drawerWidth,
    position: 'relative',
    display: 'block',
    padding: `${theme.spacing(5)} 0`,
    '&$withProfile': {
      paddingTop: theme.spacing(18),
    },
    paddingTop: 0,
    '&$landingNav': {
      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(5),
      },
      [theme.breakpoints.down('lg')]: {
        height: 'calc(100% - 164px)',
        paddingTop: theme.spacing(5),
      },
    },
    '&$rounded': {
      paddingRight: theme.spacing(1.5),
      '& a': {
        borderRadius: `0 ${theme.spacing(3)} ${theme.spacing(3)} 0`,
      },
      '& $opened': {
        '&:before': {
          background: theme.palette.primary.main,
        },
      },
    },
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 12,
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,0.3)',
        border: '1px solid rgba(255,255,255,0.4)',
      },
    },
  },
  fullWidth: {
    width: '100%',
  },
}));

export default useSidebarStyles;
