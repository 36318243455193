/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';

export type TitleType = {
  id: string;
  name: string;
};

export type TitleContextType = {
  title: TitleType[];
  addTab(tab: TitleType): void;
  removeTab(tab: TitleType): void;
};

export const TitleContext = createContext<TitleContextType>({
  title: [],
  addTab: () => {},
  removeTab: () => {},
});
