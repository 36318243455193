import React from 'react';
import cx from 'classnames';
import useSidebarStyles from './sidebar-jss';

interface SidebarContextContext {
  setSubMenu: (name: string) => void;
  openedSubMenu: null | string;
}

const SidebarContext = React.createContext<SidebarContextContext | null>(null);

export function useSidebarContext() {
  const ctx = React.useContext(SidebarContext);
  if (!ctx) {
    throw new Error('SidebarContext is not initialized');
  }
  return ctx;
}

interface SidebarProps extends React.ComponentPropsWithoutRef<'div'> {}

export const Sidebar = React.forwardRef(function Sidebar(
  { children, className, ...props }: SidebarProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const classes = useSidebarStyles();
  const [openedSubMenu, setSubMenuOpened] = React.useState<string | null>(null);

  const setSubMenu = (name: string) => {
    if (openedSubMenu === name) {
      setSubMenuOpened(null);
    } else {
      setSubMenuOpened(name);
    }
  };

  const value = React.useMemo(() => ({ openedSubMenu, setSubMenu }), [openedSubMenu]);

  return (
    <SidebarContext.Provider value={value}>
      <div ref={ref} id="sidebar" className={cx('w-full', classes.menuContainer, className)} {...props}>
        {children}
      </div>
    </SidebarContext.Provider>
  );
});
