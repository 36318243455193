import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import cx from 'classnames';
import { useCheckBoxStyles } from './styles';

const CheckBoxPropTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['green', 'blue', 'grey', 'red']),
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};
export const CheckBox = ({ className, label, value, disabled, color, ...props }) => {
  const s = useCheckBoxStyles();
  return (
    <FormControlLabel
      className={cx(className, s.root, s[color], disabled ? s.disabled : '')}
      control={<Checkbox aria-label="CheckBox" checked={!!value} {...props} />}
      label={label}
    />
  );
};

CheckBox.defaultProps = {
  className: null,
  color: 'green',
  disabled: false,
};

CheckBox.propTypes = CheckBoxPropTypes;
