import { breakpointsArray, type BreakpointValues } from 'app/constants/breakpoints';

const generateBreakpointValues = <T = string>(initialBreakpointValues: BreakpointValues<T>) => {
  const breakpointValues: BreakpointValues<T> = {};
  let prevBreakpointValue: T | undefined;
  breakpointsArray.forEach((breakpoint) => {
    breakpointValues[breakpoint] = initialBreakpointValues[breakpoint] ?? prevBreakpointValue;
    prevBreakpointValue = breakpointValues[breakpoint];
  });

  return breakpointValues;
};

export default generateBreakpointValues;
