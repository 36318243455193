import { z } from 'zod';
import { AUTH_PROVIDER_TYPE } from 'data/enums';

export const ConfirmIdentityValidatorSchema = z
  .object({
    password: z.string(),
    ssoProviderCode: z.string(),
    ssoProviderType: z.nativeEnum(AUTH_PROVIDER_TYPE),
  })
  .partial()
  .refine(
    (data) => data.password || (data.ssoProviderCode && data.ssoProviderType),
    'Password or sso code and type should be provided.',
  );

export type ConfirmIdentityValidator = z.infer<typeof ConfirmIdentityValidatorSchema>;
