import React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import cx from 'app/utils/helpers/cx';
import useBreakpoints from 'app/utils/hooks/useBreakpoints';
import { getBreakpointValue } from 'app/utils/helpers/get-breakpoint-value';
import { type BreakpointValues } from 'app/constants/breakpoints';
import { type OverridableComponent } from 'ui/types';

const typographyStyles = cva('font-nunito my-0', {
  variants: {
    size: {
      '4xl': 'text-[72px] leading-[98px]',
      '3xl': 'text-[48px] leading-[56px]',
      xxl: 'text-[36px] leading-[44px]',
      xl: 'text-[32px] leading-[40px]',
      l: 'text-2xl',
      m: 'text-[21px] leading-[29px]',
      s: 'text-[18px] leading-[26px]',
      xs: 'text-base',
      xxs: 'text-[14px] leading-[22px]',
    },
    weight: {
      semiBold: 'font-semibold',
      bold: 'font-bold',
      black: 'font-black',
    },
    color: {
      white: 'text-neutral-50',
      black: 'text-neutral-800',
      dark: 'text-neutral-700',
      error: 'text-red-600',
    },
  },
  defaultVariants: {
    size: 'l',
    weight: 'black',
    color: 'black',
  },
});

export type Size = VariantProps<typeof typographyStyles>['size'];
type SizeBreakpointValues = BreakpointValues<Size>;
type TypographyBaseProps = {
  children?: React.ReactNode;
  component?: React.ElementType;
} & Omit<VariantProps<typeof typographyStyles>, 'size'> & {
    size?: Size | SizeBreakpointValues;
  };

type TypographyTypeMap<C extends React.ElementType = 'p'> = {
  props: TypographyBaseProps;
  defaultComponent: C;
  classKey: 'root';
};

export type TypographyProps<C extends React.ElementType = TypographyTypeMap['defaultComponent']> =
  React.ComponentPropsWithoutRef<C> &
    TypographyBaseProps & {
      classes?: {
        root?: string;
      };
    };

const Typography: OverridableComponent<TypographyTypeMap> = React.forwardRef(function Typography<
  C extends React.ElementType = 'p',
>(
  { size, weight, color, component, children, className, classes, ...props }: TypographyProps<C>,
  ref: React.Ref<HTMLElement>,
) {
  const { active } = useBreakpoints();
  const isSizeObject = !!size && typeof size === 'object';
  const sizeValue = isSizeObject ? getBreakpointValue<Size>(size, active) : size;

  const Component = component ?? 'p';
  return (
    <Component
      ref={ref}
      className={cx(typographyStyles({ size: sizeValue, weight, color }), classes?.root, className)}
      {...props}
    >
      {children}
    </Component>
  );
});

export default Typography;
