import { fromJS } from 'immutable';
import * as constants from 'data/constants/learning-lines';
import LoadingProgress from 'data/utils/reducers/loading';

export const learningLinesProgress = new LoadingProgress('learningLinesProgress');
export const kidLearningLinesProgress = new LoadingProgress('kidLearningLinesProgress');
export const learningLineEditingProgress = new LoadingProgress('learningLineEditingProgress');
export const learningLineRemovalProgress = new LoadingProgress('learningLineRemovalProgress');
export const learningLineMoveProgress = new LoadingProgress('learningLineMoveProgress');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    if (payload) {
      newState.mergeIn(['learningLine'], fromJS(payload));
    }
  });

const editLearningLine = (state, action) =>
  state.withMutations((newState) => {
    newState.mergeIn(['learningLine'], fromJS(action.payload));
    learningLineEditingProgress.setLoaded(newState);
  });

const deleteLearningLine = (state, action) =>
  state.withMutations((newState) => {
    const { learningLineId } = action;
    newState.deleteIn(['entities', learningLineId]);
    learningLineRemovalProgress.setLoaded(newState);
  });
const loadKidLearningLine = (state, action) =>
  state.withMutations((newState) => {
    newState.mergeIn(['kidLearningLine'], fromJS(action.payload));
    kidLearningLinesProgress.setLoaded(newState);
  });

const loadLearningLine = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    learningLinesProgress.setLoaded(newState);
  });

const loadImage = (state, action) =>
  state.withMutations((newState) => {
    const { data } = action.payload;
    newState.mergeIn(['learningLine'], fromJS(data));
    learningLinesProgress.setLoaded(newState);
  });

const initialState = fromJS({
  entities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
    case constants.LOAD_FIELD_WITH_TOWERS_START:
    case constants.LOAD_LEARNING_START:
    case constants.DIDACTIC_CREATION_START:
      return learningLinesProgress.setLoading(state);
    case constants.EDITING_START:
      return learningLineEditingProgress.setLoading(state);
    case constants.REMOVING_START:
      return learningLineRemovalProgress.setLoading(state);
    case constants.EDITING_SUCCESS:
      return editLearningLine(state, action);
    case constants.REMOVING_SUCCESS:
      return deleteLearningLine(state, action);
    case constants.LOAD_LEARNING_SUCCESS:
      return loadLearningLine(state, action);
    case constants.LOAD_KID_LEARNING_LINE_START:
      return kidLearningLinesProgress.setLoading(state);
    case constants.LOAD_KID_LEARNING_LINE_SUCCESS:
      return loadKidLearningLine(state, action);
    case constants.LOAD_KID_LEARNING_LINE_FAILED:
      return kidLearningLinesProgress.setLoadFailed(state);
    case constants.UPLOAD_PHOTO_SUCCESS:
      return loadImage(state, action);
    case constants.DIDACTIC_CREATION_SUCCESS:
      return learningLinesProgress.setLoaded(state);
    case constants.LOAD_FAILED:
    case constants.LOAD_FIELD_WITH_TOWERS_FAILED:
    case constants.LOAD_LEARNING_FAILED:
    case constants.DIDACTIC_CREATION_FAILED:
      return learningLinesProgress.setLoadFailed(state);
    case constants.EDITING_FAILED:
      return learningLineEditingProgress.setLoadFailed(state);
    case constants.REMOVING_FAILED:
      return learningLineRemovalProgress.setLoadFailed(state);
    case constants.MOVE_CHILD_TO_LEARNING_LINE_START:
      return learningLineMoveProgress.setLoading(state);
    case constants.MOVE_CHILD_TO_LEARNING_LINE_SUCCESS:
      return learningLineMoveProgress.setLoaded(state);
    case constants.MOVE_CHILD_TO_LEARNING_LINE_FAILED:
      return learningLineMoveProgress.setLoadFailed(state);
    case constants.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
