const prefix = (type) => `eureka/questionBoxes/${type}`;

export const CLEAR = prefix('CLEAR');
export const CREATION_START = prefix('CREATION_START');
export const CREATION_SUCCESS = prefix('CREATION_SUCCESS');
export const CREATION_FAILED = prefix('CREATION_FAILED');

export const LOAD_QUESTION_START = prefix('LOAD_QUESTION_START');
export const LOAD_QUESTION_SUCCESS = prefix('LOAD_QUESTION_SUCCESS');
export const LOAD_QUESTION_FAILED = prefix('LOAD_QUESTION_FAILED');

export const LOAD_KID_QUESTION_BOX_START = prefix('LOAD_KID_QUESTION_BOX_START');
export const LOAD_KID_QUESTION_BOX_SUCCESS = prefix('LOAD_KID_QUESTION_BOX_SUCCESS');
export const LOAD_KID_QUESTION_BOX_FAILED = prefix('LOAD_KID_QUESTION_BOX_FAILED');

export const EDITING_START = prefix('EDITING_START');
export const EDITING_SUCCESS = prefix('EDITING_SUCCESS');
export const EDITING_FAILED = prefix('EDITING_FAILED');

export const QUESTION_CREATION_START = prefix('QUESTION_CREATION_START');
export const QUESTION_CREATION_SUCCESS = prefix('QUESTION_CREATION_SUCCESS');
export const QUESTION_CREATION_FAILED = prefix('QUESTION_CREATION_FAILED');

export const LOAD_QUESTION_FILES_START = prefix('QUESTION_FILES_LOAD_START');
export const LOAD_QUESTION_FILES_SUCCESS = prefix('QUESTION_FILES_LOAD_SUCCESS');
export const LOAD_QUESTION_FILES_FAILED = prefix('QUESTION_FILES_LOAD_FAILED');

export const UPLOAD_QUESTION_FILES_START = prefix('UPLOAD_QUESTION_FILES_START');
export const UPLOAD_QUESTION_FILES_SUCCESS = prefix('UPLOAD_QUESTION_FILES_SUCCESS');
export const UPLOAD_QUESTION_FILES_FAILED = prefix('UPLOAD_QUESTION_FILES_FAILED');

export const REMOVE_QUESTION_FILE_START = prefix('REMOVE_QUESTION_FILE_START');
export const REMOVE_QUESTION_FILE_SUCCESS = prefix('REMOVE_QUESTION_FILE_SUCCESS');
export const REMOVE_QUESTION_FILE_FAILED = prefix('REMOVE_QUESTION_FILE_FAILED');

export const REMOVING_START = prefix('REMOVING_START');
export const REMOVING_SUCCESS = prefix('REMOVING_SUCCESS');
export const REMOVING_FAILED = prefix('REMOVING_FAILED');

export const SWAP_QUESTIONS_START = prefix('SWAP_QUESTIONS_START');
export const SWAP_QUESTIONS_SUCCESS = prefix('SWAP_QUESTIONS_SUCCESS');
export const SWAP_QUESTIONS_FAILED = prefix('SWAP_QUESTIONS_FAILED');

export const SWAP_ALL_QUESTIONS_START = prefix('SWAP_ALL_QUESTIONS_START');
export const SWAP_ALL_QUESTIONS_SUCCESS = prefix('SWAP_ALL_QUESTIONS_SUCCESS');
export const SWAP_ALL_QUESTIONS_FAILED = prefix('SWAP_ALL_QUESTIONS_FAILED');

export const EDIT_QUESTION_START = prefix('EDIT_QUESTION_START');
export const EDIT_QUESTION_SUCCESS = prefix('EDIT_QUESTION_SUCCESS');
export const EDIT_QUESTION_FAILED = prefix('EDIT_QUESTION_FAILED');

export const REMOVING_QUESTIONLIST_START = prefix('REMOVING_QUESTIONLIST_START');
export const REMOVING_QUESTIONLIST_SUCCESS = prefix('REMOVING_QUESTIONLIST_SUCCESS');
export const REMOVING_QUESTIONLIST_FAILED = prefix('REMOVING_QUESTIONLIST_FAILED');

export const LOAD_QUESTIONS_START = prefix('LOAD_QUESTIONS_START');
export const LOAD_QUESTIONS_SUCCESS = prefix('LOAD_QUESTIONS_SUCCESS');
export const LOAD_QUESTIONS_FAILED = prefix('LOAD_QUESTIONS_FAILED');

export const REMOVE_FMM_START = prefix('REMOVE_FMM_START');
export const REMOVE_FMM_SUCCESS = prefix('REMOVE_FMM_SUCCESS');
export const REMOVE_FMM_FAILED = prefix('REMOVE_FMM_FAILED');

export const CREATE_FMM_START = prefix('CREATE_FMM_START');
export const CREATE_FMM_SUCCESS = prefix('CREATE_FMM_SUCCESS');
export const CREATE_FMM_FAILED = prefix('CREATE_FMM_FAILED');

export const REMOVING_QUESTION_BOX_START = prefix('REMOVING_QUESTION_BOX_START');
export const REMOVING_QUESTION_BOX_SUCCESS = prefix('REMOVING_QUESTION_BOX_SUCCESS');
export const REMOVING_QUESTION_BOX_FAILED = prefix('REMOVING_QUESTION_BOX_FAILED');

export const LOAD_DIGITOOLS_START = prefix('LOAD_DIGITOOLS_START');
export const LOAD_DIGITOOLS_SUCCESS = prefix('LOAD_DIGITOOLS_SUCCESS');
export const LOAD_DIGITOOLS_FAILED = prefix('LOAD_DIGITOOLS_FAILED');

export const LOAD_QUESTIONBOX_TYPES_START = prefix('LOAD_QUESTIONBOX_TYPES_START');
export const LOAD_QUESTIONBOX_TYPES_SUCCESS = prefix('LOAD_QUESTIONBOX_TYPES_SUCCESS');
export const LOAD_QUESTIONBOX_TYPES_FAILED = prefix('LOAD_QUESTIONBOX_TYPES_FAILED');

export const MOVE_CHILD_TO_QUESTION_BOX_START = prefix('MOVE_CHILD_TO_QUESTION_BOX_START');
export const MOVE_CHILD_TO_QUESTION_BOX_SUCCESS = prefix('MOVE_CHILD_TO_QUESTION_BOX_SUCCESS');
export const MOVE_CHILD_TO_QUESTION_BOX_FAILED = prefix('MOVE_CHILD_TO_QUESTION_BOX_FAILED');

export const CLEAR_STATE = prefix('CLEAR_STATE');
