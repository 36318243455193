import { fromJS } from 'immutable';
import * as constants from 'data/constants/videos';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const videosProgress = new LoadingProgress('videosProgress');
export const videosPagination = new Pagination('videosPagination');
export const videoEditProgress = new LoadingProgress('videoEditProgress');
export const videoRemovalProgress = new LoadingProgress('videoRemovalProgress');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { videos },
    } = payload;
    newState.mergeIn(['entities'], fromJS(videos));
  });

const loadVideos = (state, action) =>
  state.withMutations((newState) => {
    const { limit, count, result, isForKid, isLoadMore } = action.payload;
    if (isForKid) {
      const {
        entities: { videos },
      } = action.payload;
      if (isLoadMore) {
        newState.mergeIn(['entities'], fromJS(videos));
        newState.mergeIn(['videoIds'], fromJS(result));
      } else {
        newState.setIn(['entities'], fromJS(videos));
        newState.setIn(['videoIds'], fromJS(result));
      }
    } else {
      mergeData(newState, action.payload);
    }

    videosPagination.set(newState, count, limit, fromJS(result));
    videosProgress.setLoaded(newState);
  });

const clearState = (state) =>
  state.withMutations((newState) => {
    videosProgress.clear(newState);
  });

const deleteVideo = (state, action) =>
  state.withMutations((newState) => {
    const { videoId } = action.payload;
    newState.deleteIn(['entities', videoId]);
    newState.set(
      'videosPaginationPageItems',
      state.get('videosPaginationPageItems').filter((el) => el !== videoId),
    );
    newState.set('videosPaginationTotalItems', state.get('videosPaginationTotalItems') - 1);
    videoRemovalProgress.setLoaded(newState);
  });

const deleteGradesInVideo = (state) =>
  state.withMutations((newState) => {
    newState.setIn(['videosPaginationPageItems', 'grades'], []);
    videoRemovalProgress.setLoaded(newState);
  });

const deleteDomainsInVideo = (state) =>
  state.withMutations((newState) => {
    newState.setIn(['videosPaginationPageItems', 'domains'], []);
    videoRemovalProgress.setLoaded(newState);
  });

const editVideo = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    videoEditProgress.setLoaded(newState);
  });

const initialState = fromJS({});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_VIDEOS_START:
      return videosProgress.setLoading(state);
    case constants.FETCH_VIDEOS_SUCCESS:
      return loadVideos(state, action);
    case constants.FETCH_VIDEOS_FAILED:
      return videosProgress.setLoadFailed(state);
    case constants.CLEAR_STATE:
      return clearState(state);
    case constants.DELETE_VIDEO_START:
    case constants.DELETE_VIDEO_GRADES_START:
    case constants.DELETE_VIDEO_DOMAINS_START:
      return videoRemovalProgress.setLoading(state);
    case constants.DELETE_VIDEO_SUCCESS:
      return deleteVideo(state, action);
    case constants.DELETE_VIDEO_GRADES_SUCCESS:
      // @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
      return deleteGradesInVideo(state, action);
    case constants.DELETE_VIDEO_DOMAINS_SUCCESS:
      // @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
      return deleteDomainsInVideo(state, action);
    case constants.DELETE_VIDEO_FAILED:
    case constants.DELETE_VIDEO_GRADES_FAILED:
    case constants.DELETE_VIDEO_DOMAINS_FAILED:
      return videoRemovalProgress.setLoadFailed(state);
    case constants.EDIT_VIDEO_START:
    case constants.EDIT_VIDEO_DOMAINS_START:
    case constants.EDIT_VIDEO_GRADES_START:
      return videoEditProgress.setLoading(state);
    case constants.EDIT_VIDEO_SUCCESS:
    case constants.EDIT_VIDEO_GRADES_SUCCESS:
    case constants.EDIT_VIDEO_DOMAINS_SUCCESS:
      return editVideo(state, action);
    case constants.EDIT_VIDEO_FAILED:
    case constants.EDIT_VIDEO_GRADES_FAILED:
    case constants.EDIT_VIDEO_DOMAINS_FAILED:
      return videoEditProgress.setLoadFailed(state);
    default:
      return state;
  }
};
