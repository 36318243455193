import { fromJS } from 'immutable';
import * as constants from 'data/constants/learning-boxes';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';
import * as didacticConstants from 'data/constants/didactic-boxes';
import * as questionBoxesConstants from 'data/constants/question-boxes';

export const learningBoxesProgres = new LoadingProgress('learningBoxesProgres');
export const kidLearningBoxesProgress = new LoadingProgress('kidLearningBoxesProgress');
export const kidLearningBoxProgress = new LoadingProgress('kidLearningBoxProgress');
export const learningBoxesPagination = new Pagination('learning');
export const learningBoxRemovalProgress = new LoadingProgress('learningBoxRemovalProgress');
export const learningBoxMoveChildProgress = new LoadingProgress('learningBoxMoveChildProgress');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { learningBoxes },
    } = payload;
    newState.mergeIn(['entities'], fromJS(learningBoxes));
  });
const loadAllLearningBoxes = (state, action) =>
  state.withMutations((newState) => {
    const { payload } = action;
    newState.setIn(['learningBoxesItems'], fromJS(payload));
    learningBoxesProgres.setLoaded(newState);
  });

const loadKidLearningBoxes = (state, action) =>
  state.withMutations((newState) => {
    newState.setIn(['kidLearningBoxes'], fromJS(action.payload));
    kidLearningBoxesProgress.setLoaded(newState);
  });

const loadKidLearningBox = (state, action) =>
  state.withMutations((newState) => {
    newState.setIn(['kidLearningBox'], fromJS(action.payload));
    kidLearningBoxProgress.setLoaded(newState);
  });

const loadLearningBox = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
  });

const loadLearningBoxes = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    learningBoxesProgres.setLoaded(newState);
  });
const clearLearningBoxes = (state) =>
  state.withMutations((newState) => {
    learningBoxesPagination.clear(newState);
    learningBoxesProgres.clear(newState);
  });
const loadQuestionBoxes = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
  });
const initialState = fromJS({
  entities: {},
});
const editLearnignBox = (state, payload) => {
  const { id: learningBoxId } = payload;
  return state.withMutations((newState) => {
    newState.mergeIn(['entities', learningBoxId], fromJS(payload));
  });
};

const deleteQuestionBox = (state, action) =>
  state.withMutations((newState) => {
    const { questionBoxId, learningBoxId } = action;
    if (learningBoxId) {
      newState.updateIn(['entities', learningBoxId, 'questionBoxes'], (list) =>
        list.filter((listItem) => listItem !== questionBoxId),
      );
    }
  });

const dragAndDropLearningBox = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    learningBoxesProgres.setLoaded(newState);
  });

const deleteLearningBox = (state, action) =>
  state.withMutations((newState) => {
    const { learningBoxId } = action;
    newState.get('entities').delete(learningBoxId);
    learningBoxesProgres.clear(newState);
    learningBoxRemovalProgress.setLoaded(newState);
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
    case didacticConstants.LOAD_DIDACTIC_START:
      return learningBoxesProgres.setLoading(state);
    case constants.LOAD_KID_LEARNING_BOXES_START:
      return kidLearningBoxesProgress.setLoading(state);
    case constants.LOAD_KID_LEARNING_BOXES_SUCCESS:
      return loadKidLearningBoxes(state, action);
    case constants.LOAD_KID_LEARNING_BOXES_FAILED:
      return kidLearningBoxesProgress.setLoadFailed(state);
    case constants.LOAD_KID_LEARNING_BOX_START:
      return kidLearningBoxProgress.setLoading(state);
    case constants.LOAD_KID_LEARNING_BOX_SUCCESS:
      return loadKidLearningBox(state, action);
    case constants.LOAD_KID_LEARNING_BOX_FAILED:
      return kidLearningBoxProgress.setLoadFailed(state);
    case constants.REMOVING_START:
      return learningBoxRemovalProgress.setLoading(state);
    case constants.LOAD_SUCCESS:
      return loadAllLearningBoxes(state, action);
    case constants.EDITING_SUCCESS:
      return editLearnignBox(state, action.payload);
    case didacticConstants.LEARNING_CREATION_SUCCESS:
      return mergeData(state, action.payload);
    case didacticConstants.LOAD_DIDACTIC_SUCCESS:
      return loadLearningBoxes(state, action);
    case constants.LOAD_LEARNING_SUCCESS:
      return loadQuestionBoxes(state, action);
    case questionBoxesConstants.REMOVING_SUCCESS:
      return deleteQuestionBox(state, action);
    case constants.DRAGANDDROP_SUCCESS:
      return dragAndDropLearningBox(state, action);
    case constants.REMOVING_SUCCESS:
      return deleteLearningBox(state, action);
    case constants.LOAD_BOX_SUCCESS:
      return loadLearningBox(state, action);
    case constants.REMOVING_FAILED:
      return learningBoxRemovalProgress.setLoadFailed(state);
    case constants.MOVE_CHILD_TO_LEARNING_BOX_START:
      return learningBoxMoveChildProgress.setLoading(state);
    case constants.MOVE_CHILD_TO_LEARNING_BOX_SUCCESS:
      return learningBoxMoveChildProgress.setLoaded(state);
    case constants.MOVE_CHILD_TO_LEARNING_BOX_FAILED:
      return learningBoxMoveChildProgress.setLoadFailed(state);
    case constants.LOAD_FAILED:
    case didacticConstants.LOAD_DIDACTIC_FAILED:
    case constants.DRAGANDDROP_FAILED:
      return learningBoxesProgres.setLoadFailed(state);
    case constants.CLEAR:
      return clearLearningBoxes(state);
    case constants.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
