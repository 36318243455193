import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthData, useFetchAuthenticatedUser, useFetchInvites, useLeaveSupportMode } from 'data/hooks';
import { useToggleState } from 'app/utils/hooks/useToggleState';
import CircularProgress from '@mui/material/CircularProgress';
import { Avatar, Button, BUTTON_COLORS } from '@coach/ui';
import { INVITE_STATUS } from 'data/enums';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useLayoutStore } from 'app/components/layout/layout';
import LanguageSelect from 'app/components/core/language-select';
import cx from 'app/utils/helpers/cx';
import { useSupportAccessToken } from 'data/stores/auth-store';
import useBreakpoints from 'app/utils/hooks/useBreakpoints';
import { MILLISECONDS } from 'app/constants/data';
import { useWinterMode } from 'data/atoms/feature-flags';
import { HeaderDropdown } from './header-dropdown';
import styles from './styles.module.css';

interface HeaderProps {
  profilePath: string;
  invitesPath: string;
}

export const Header = ({ profilePath, invitesPath }: HeaderProps) => {
  const { t } = useTranslation();
  const toggleMobileDrawer = useLayoutStore((state) => state.toggleMobileDrawer);
  const toggleDrawerSquash = useLayoutStore((state) => state.toggleDrawerSquash);
  const isSidebarHidden = useLayoutStore((state) => state.isSidebarHidden);
  const [isDropdownOpen, toggleDropdown] = useToggleState(false);
  const { isMd } = useBreakpoints();
  const isWinterMode = useWinterMode();

  const { leaveSupportMode } = useLeaveSupportMode();

  const loggedInAsSupport = !!useSupportAccessToken();
  const { role, roles } = useAuthData();

  const { data: profile, isSuccess } = useFetchAuthenticatedUser();

  const { data: invites } = useFetchInvites(
    {
      offset: 0,
      limit: 1,
      status: [INVITE_STATUS.ACTIVE],
    },
    { staleTime: MILLISECONDS.MINUTE * 5 },
  );

  const activeInvites = invites?.count || 0;
  const hasActiveInvites = activeInvites > 0;

  const handleAvatarClick = (event) => {
    toggleDropdown();
    event.stopPropagation();
  };

  const handleDrawerClick = () => {
    if (isMd) {
      toggleDrawerSquash();
    } else {
      toggleMobileDrawer();
    }
  };

  return (
    <>
      <header className="relative flex min-h-[64px] items-center bg-public-gradient">
        {isWinterMode && (
          <div className={cx('absolute inset-0 h-full w-full', styles.winterMode)}>
            <div className={cx('absolute inset-0 h-full w-full', styles.blur)} />
          </div>
        )}

        <Toolbar className="flex-1 items-center justify-between gap-4 text-white md:justify-end">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerClick}
            className={cx('z-10 mr-1 md:hover:bg-slate-200', styles.menuButton, isSidebarHidden && 'hidden')}
          >
            <MenuIcon />
          </IconButton>

          <div className="flex items-center justify-center">
            {loggedInAsSupport && (
              <Button
                color={BUTTON_COLORS.PINK}
                onClick={() => {
                  // DO NOT REFACTOR TO onClick={leaveSupportMode}, check the function definition
                  leaveSupportMode();
                }}
              >
                {t('goBackToAdmin')}
              </Button>
            )}

            <LanguageSelect
              className={cx('md:flex', !isSidebarHidden && 'hidden')}
              data-intercom-target="language-select-in-header"
            />

            {isSuccess ? (
              // @ts-expect-error TS(2741): Property 'email' is missing in type '{ "data-inter... Remove this comment to see the full error message
              <Avatar
                data-intercom-target="profile-avatar"
                size="small"
                firstName={profile?.firstName}
                lastName={profile?.lastName}
                imgSrc={profile?.profilePicture}
                onClick={handleAvatarClick}
                className="cursor-pointer"
                showBadge={hasActiveInvites}
              />
            ) : (
              <CircularProgress color="secondary" />
            )}
          </div>
        </Toolbar>
      </header>
      {isDropdownOpen && (
        <HeaderDropdown
          roles={roles}
          currentRole={role}
          toggleState={toggleDropdown}
          profilePath={profilePath}
          invitesPath={invitesPath}
          loggedInAsSupport={loggedInAsSupport}
          showBadge={hasActiveInvites}
        />
      )}
    </>
  );
};
