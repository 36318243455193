import { fromJS } from 'immutable';
import * as constants from 'data/constants/grades';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';
import * as learningConstants from 'data/constants/learning-lines';

export const gradesProgres = new LoadingProgress('gradesProgres');
export const gradeFieldsProgress = new LoadingProgress('gradeFieldsProgress');
export const gradesPagination = new Pagination('grades');
export const gradesRemovalProgress = new LoadingProgress('gradesRemovalProgress');
export const domainsProgress = new LoadingProgress('domainsProgress');
export const domainsPagination = new Pagination('domains');
export const kidGradesProgress = new LoadingProgress('kidGradesProgress');
export const kidGradesPagination = new Pagination('kidGrades');
export const kidGradeFieldsProgress = new LoadingProgress('kidGradeFieldsProgress');
export const gradesFieldsDomainsPagination = new Pagination('gradesFieldsDomainsPagination');
export const gradesFieldsDomainsProgress = new LoadingProgress('gradesFieldsDomainsProgress');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { grades },
    } = payload;
    newState.mergeDeepIn(['entities'], fromJS(grades));
  });
const mergeFieldsData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { gradeFields },
    } = payload;
    newState.mergeIn(['gradeFields'], fromJS(gradeFields));
  });
const loadKidGrades = (state, action) =>
  state.withMutations((newState) => {
    const {
      count,
      result,
      entities: { grades },
    } = action.payload;
    newState.mergeIn(['kidGrades'], fromJS(grades));
    kidGradesPagination.set(newState, count, null, fromJS(result));
    kidGradesProgress.setLoaded(newState);
  });

const loadGrades = (state, action) =>
  state.withMutations((newState) => {
    const { count, result } = action.payload;
    mergeData(newState, action.payload);
    gradesPagination.set(newState, count, null, fromJS(result));
    gradesProgres.setLoaded(newState);
  });

const loadDomains = (state, action) =>
  state.withMutations((newState) => {
    const {
      count,
      result,
      entities: { domains },
    } = action.payload;
    newState.mergeDeepIn(['domains'], fromJS(domains));
    domainsPagination.set(newState, count, null, fromJS(result));
    domainsProgress.setLoaded(newState);
  });

const loadGrade = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
  });
const loadGradeFields = (state, action) =>
  state.withMutations((newState) => {
    mergeFieldsData(newState, action.payload);
    newState.setIn(['gradeFieldsId'], fromJS(action.payload.result));
    gradeFieldsProgress.setLoaded(newState);
  });
const loadGradeFieldsWithTowers = (state, action) =>
  state.withMutations((newState) => {
    const {
      entities: { gradeFields },
    } = action.payload;
    newState.mergeIn(['gradeFieldsWithTowers'], fromJS(gradeFields));
    newState.setIn(['gradeFieldsWithTowersId'], fromJS(action.payload.result));
    gradeFieldsProgress.setLoaded(newState);
  });

const loadGradesFieldsDomains = (state, action) =>
  state.withMutations((newState) => {
    const {
      count,
      result,
      entities: { grades },
    } = action.payload;
    newState.mergeIn(['gradesFieldsDomains'], fromJS(grades));
    gradesFieldsDomainsPagination.set(newState, count, null, fromJS(result));
    gradesFieldsDomainsProgress.setLoaded(newState);
  });

const loadKidGradeFields = (state, action) =>
  state.withMutations((newState) => {
    const {
      entities: { gradeFields },
    } = action.payload;
    newState.mergeIn(['kidGradeFields'], fromJS(gradeFields));
    newState.setIn(['kidGradeFieldsId'], fromJS(action.payload.result));
    kidGradeFieldsProgress.setLoaded(newState);
  });
const createGrade = (state, action) =>
  state.withMutations((newState) => {
    const { result } = action.payload;
    mergeData(newState, action.payload);
    gradesProgres.setLoaded(newState);
    newState.set('gradesPageItems', state.get('gradesPageItems').concat(fromJS(result)));
    newState.set('gradesTotalItems', state.get('gradesTotalItems') + 1);
  });
const editGrade = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    gradesProgres.setLoaded(newState);
  });

const deleteGrade = (state, action) =>
  state.withMutations((newState) => {
    const { gradeId } = action;
    newState.deleteIn(['entities', gradeId]);
    gradesProgres.setLoaded(newState);
    newState.set(
      'gradesPageItems',
      state.get('gradesPageItems').filter((el) => el !== gradeId),
    );
    newState.set('gradesTotalItems', state.get('gradesTotalItems') - 1);
    gradesRemovalProgress.setLoaded(newState);
  });
const dragAndDropGrade = (state, action) =>
  state.withMutations((newState) => {
    const { count, result } = action.payload;
    mergeData(newState, action.payload);
    gradesPagination.set(newState, count, null, fromJS(result));
    gradesProgres.setLoaded(newState);
  });

const clear = (state) =>
  state.withMutations((newState) => {
    gradesPagination.clear(newState);
    gradesProgres.clear(newState);
  });

const clearGradeFields = (state) =>
  state.withMutations((newState) => {
    // gradesPagination.clear(newState);
    gradeFieldsProgress.clear(newState);
  });

const initialState = fromJS({
  grades: {},
});
export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
      // case constants.CREATION_START:
      // case constants.EDITING_START:
      // case constants.REMOVING_START:
      return gradesProgres.setLoading(state);
    case constants.LOAD_KID_GRADES_START:
      return kidGradesProgress.setLoading(state);
    case constants.LOAD_KID_GRADES_SUCCESS:
      return loadKidGrades(state, action);
    case constants.LOAD_KID_GRADES_FAILED:
      return kidGradesProgress.setLoadFailed(state);
    case constants.LOAD_GRADES_FIELD_DOMAINS_START:
      return gradesFieldsDomainsProgress.setLoading(state);
    case constants.LOAD_GRADES_FIELD_DOMAINS_SUCCESS:
      return loadGradesFieldsDomains(state, action);
    case constants.LOAD_GRADES_FIELD_DOMAINS_FAILED:
      return gradesFieldsDomainsProgress.setLoadFailed(state);
    case constants.REMOVING_START:
      return gradesRemovalProgress.setLoading(state);
    case learningConstants.LOAD_START:
      return gradeFieldsProgress.setLoading(state);
    case learningConstants.LOAD_KID_GRADE_FIELDS_START:
      return kidGradeFieldsProgress.setLoading(state);
    case learningConstants.LOAD_KID_GRADE_FIELDS_SUCCESS:
      return loadKidGradeFields(state, action);
    case learningConstants.LOAD_KID_GRADE_FIELDS_FAILED:
      return kidGradeFieldsProgress.setLoadFailed(state);
    case constants.LOAD_SUCCESS:
      return loadGrades(state, action);
    case learningConstants.LOAD_SUCCESS:
      return loadGradeFields(state, action);
    case learningConstants.LOAD_FIELD_WITH_TOWERS_SUCCESS:
      return loadGradeFieldsWithTowers(state, action);
    case constants.CREATION_SUCCESS:
      return createGrade(state, action);
    case constants.EDITING_SUCCESS:
      return editGrade(state, action);
    case constants.REMOVING_SUCCESS:
      return deleteGrade(state, action);
    case constants.DRAGANDDROP_SUCCESS:
      return dragAndDropGrade(state, action);
    case constants.LOAD_GRADE_SUCCESS:
      return loadGrade(state, action);
    // case constants.CREATION_FAILED:
    // case constants.EDITING_FAILED:
    case constants.REMOVING_FAILED:
      return gradesRemovalProgress.setLoadFailed(state);
    case constants.LOAD_FAILED:
    case constants.DRAGANDDROP_FAILED:
      return gradesProgres.setLoadFailed(state);
    case learningConstants.LOAD_FAILED:
      return gradeFieldsProgress.setLoading(state);
    case constants.CLEAR:
      return clear(state);
    case constants.CLEAR_FIELDS:
      return clearGradeFields(state);
    case constants.CLEAR_STATE:
      return initialState;
    case constants.LOAD_DOMAINS_START:
      return domainsProgress.setLoading(state);
    case constants.LOAD_DOMAINS_SUCCESS:
      return loadDomains(state, action);
    case constants.LOAD_DOMAINS_FAILED:
      return domainsProgress.setLoadFailed(state);
    default:
      return state;
  }
};
