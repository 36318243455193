import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { PROD } from 'app/constants/env';
import { type ExtractState } from 'data/utils/types';
import { NoActiveGroupError } from 'app/utils/error/NoActiveGroupError';
import { type GROUP } from 'data/enums';

export type Group = {
  id: string;
  type: GROUP;
  name?: string;
};

type GroupStore = {
  groups: Group[];
  activeGroupType: GROUP | undefined;
  activeGroupId: string | undefined;

  actions: {
    setGroups: (groups: Group[]) => void;
    setActiveGroupId: (groupId: string | undefined) => void;
    setActiveGroupType: (groupType: GROUP | undefined) => void;
  };
};

const groupStore = createStore<GroupStore>()(
  devtools(
    persist(
      (set, get) => ({
        groups: [],
        activeGroupType: undefined,
        activeGroupId: undefined,

        actions: {
          setGroups: (groups: Group[]) => set({ groups }),
          setActiveGroupId: (activeGroupId: string | undefined) => {
            const { groups } = get();
            const type = groups.find((group) => group.id === activeGroupId)?.type;
            set({ activeGroupId, activeGroupType: type });
          },
          setActiveGroupType: (activeGroupType: GROUP | undefined) => {
            set({ activeGroupType });
          },
        },
      }),
      {
        name: 'group-store',
        storage: createJSONStorage(() => localStorage),
        partialize: (state) => ({ activeGroupId: state.activeGroupId, activeGroupType: state.activeGroupType }),
      },
    ),
    {
      name: 'group-store',
      enabled: !PROD,
    },
  ),
);

const activeGroupIdSelector = (state: ExtractState<typeof groupStore>) => state.activeGroupId;
const activeGroupTypeSelector = (state: ExtractState<typeof groupStore>) => state.activeGroupType;
const groupsSelector = (state: ExtractState<typeof groupStore>) => state.groups;
const actionsSelector = (state: ExtractState<typeof groupStore>) => state.actions;

export const getActiveGroupId = () => activeGroupIdSelector(groupStore.getState());
export const getActiveGroupType = () => activeGroupTypeSelector(groupStore.getState());
export const getGroups = () => groupsSelector(groupStore.getState());

export const getActions = () => actionsSelector(groupStore.getState());

type Params<U> = Parameters<typeof useStore<typeof groupStore, U>>;

function useGroupStore<U>(selector: Params<U>[1], equalityFn?: Params<U>[2]): U {
  return useStore(groupStore, selector, equalityFn);
}

type Options = {
  fallback?: boolean;
};

/**
 *
 * @throws {NoActiveGroupError} if there is no active group and fallback is false
 */
export function useActiveGroupId<
  O extends Options = {
    fallback: true;
  },
>(options?: O): O['fallback'] extends true ? string : string | undefined {
  const { fallback = true } = options ?? {};
  const id = useGroupStore(activeGroupIdSelector);

  if (id || !fallback) {
    // explicitly cast to the return type as typescript doesn't understand that the fallback is false
    return id as O['fallback'] extends true ? string : string | undefined;
  }

  const groups = getGroups();

  if (groups.length === 1) {
    const { setActiveGroupId } = getActions();
    setActiveGroupId(groups[0].id);
    return groups[0].id;
  }

  throw new NoActiveGroupError();
}

export function useActiveGroupType<
  O extends Options = {
    fallback: true;
  },
>(options?: O): O['fallback'] extends true ? GROUP : GROUP | undefined {
  const { fallback = true } = options ?? {};
  const activeGroupId = useActiveGroupId({ fallback });
  const type = useGroupStore(activeGroupTypeSelector);

  if (type || !fallback) {
    // explicitly cast to the return type as typescript doesn't understand that the fallback is false
    return type as O['fallback'] extends true ? GROUP : GROUP | undefined;
  }

  const groups = getGroups();
  const { setActiveGroupType } = getActions();
  // logical casting as activeGroupId is defined
  const activeGroup = groups.find((group) => group.id === activeGroupId) as Group;
  setActiveGroupType(activeGroup.type);
  return activeGroup.type;
}

export const useGroups = (): Group[] => useGroupStore(groupsSelector);

export const useGroupActions = (): GroupStore['actions'] => useGroupStore(actionsSelector);
