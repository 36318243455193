import { type ResetPasscodeValidator, type ResetPasswordValidator } from 'data/validators/body';
import api from 'data/axios/react-query-api';
import { type RoleEntity } from 'data/types/entities/role.entity';
import { type TokenEntity } from 'data/types/entities/token.entity';
import { type UserEntity } from 'data/types/entities/user.entity';
import { HTTP_REQUEST_METHOD, type ROLE } from 'data/enums';

export interface RefreshAccessTokenArgs {
  role: ROLE;
  refreshToken: string;
}

export const refreshAccessToken = ({ role, refreshToken }: RefreshAccessTokenArgs) =>
  api.request<RoleEntity>({
    url: '/tokens/refresh',
    method: HTTP_REQUEST_METHOD.POST,
    headers: {
      'x-refresh-token': refreshToken,
    },
    data: {
      roleType: role,
    },
  });

export const resetPassword = (token: string, body: ResetPasswordValidator): Promise<UserEntity> =>
  api.patch(`/tokens/${token}/passwords`, body);

export const resetPasscode = (token: string, body: ResetPasscodeValidator): Promise<UserEntity> =>
  api.patch(`/tokens/${token}/passcodes`, body);

export const confirmRegistration = async (token: string) => {
  await api.patch(`tokens/${token}/register`);
};

export const fetchRefreshToken = (userId: string): Promise<TokenEntity> => api.get(`/users/${userId}/tokens/refresh`);
