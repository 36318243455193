/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
import axios, {
  type AxiosError,
  type AxiosInstance,
  type CreateAxiosDefaults,
  type InternalAxiosRequestConfig,
} from 'axios';
import { toast } from 'react-toastify';
import * as apiConstants from 'data/constants/api';
import { type Store } from 'redux';
import { STATUS_CODE } from 'data/enums/status-code';
import { type AppStore } from 'data/stores/redux-store';
import { isRefreshURL } from 'data/axios/http-client';
import { getAccessToken, getRefreshToken, getSupportAccessToken } from 'data/stores/auth-store';
import { logout } from 'data/utils/helpers';
import { refreshAccessToken } from 'data/api';
import { type TokenData } from 'data/types';
import { jwtDecode } from 'jwt-decode';
import { isMaintenanceMode } from 'data/utils/helpers/api';
import { commonUrlParams, maintenancePath } from 'app/constants/url/shared';

const wait = (time: number) =>
  new Promise((res) => {
    setTimeout(res, time);
  });

/**
 * @legacy
 * [Use HttpClient instead]{@link HttpClient}
 */
class API {
  private readonly axios: AxiosInstance;

  private store: AppStore;

  constructor(store: Store, config: CreateAxiosDefaults) {
    this.errorInterceptor = this.errorInterceptor.bind(this);
    this.requestInterceptor = this.requestInterceptor.bind(this);
    this.axios = axios.create(config);
    this.store = store;
    this.axios.interceptors.request.use(this.requestInterceptor);
    this.axios.interceptors.response.use(undefined, this.errorInterceptor);
    if (window) window.api = this;
  }

  public showErrorMessage(response): void {
    const { status, data } = response;
    if (status === 500) toast.error('serverError');
    if (status === 400 || status === 403) {
      if (data?.reason) {
        toast.error(data.reason);
        return;
      }
      const validationErrors = data?.errors;
      if (typeof validationErrors === 'object' && validationErrors !== null) {
        Object.values(validationErrors).forEach?.((value: any) => {
          value.forEach?.((error) => {
            toast.error(error);
          });
        });
      }
    }
  }

  public errorInterceptor(error: AxiosError) {
    if (typeof error.response === 'object' && error.response !== null) {
      const { response } = error;

      this.showErrorMessage(response);

      const url = new URL(window.location.href);
      if (isMaintenanceMode(error) && !url.pathname.includes(maintenancePath)) {
        const redirectPath = new URL(maintenancePath, window.location.origin);
        redirectPath.searchParams.set(commonUrlParams.redirect, url.pathname);
        window.location.href = redirectPath.toString();
      } else if (
        // in case the refresh token is expired, we need to logout the user
        (response?.status === STATUS_CODE.UNAUTHORIZED || response?.status === STATUS_CODE.NOT_FOUND) &&
        isRefreshURL(response?.config.url || '')
      ) {
        logout();

        // in case the access token is expired, we need to refresh the session
      } else if (response?.status === STATUS_CODE.UNAUTHORIZED) {
        const accessToken = getAccessToken();
        const refreshToken = getRefreshToken();
        if (typeof accessToken !== 'string' || !refreshToken) {
          logout();
          return Promise.reject(error);
        }

        try {
          const { role } = jwtDecode<TokenData>(accessToken);
          refreshAccessToken({
            refreshToken,
            role,
          });
        } catch (_: unknown) {
          logout();
        }
      }
    } else {
      this.store.dispatch({ type: apiConstants.CONNECTION_ERROR });

      if (error.config) {
        console.log('Connection error, will retry in 3 sec');

        // @ts-expect-error TS(2345): Argument of type 'InternalAxiosRequestConfig<any> ... Remove this comment to see the full error message
        return wait(3000).then(() => this.axios.request(error.config));
      }
    }
    return Promise.reject(error);
  }

  public requestInterceptor(config: InternalAxiosRequestConfig<unknown>): InternalAxiosRequestConfig<unknown> {
    const headers = config.headers || {};
    if (!headers['x-auth']) {
      headers['x-auth'] = getSupportAccessToken() || getAccessToken();
    }
    if (!headers['x-refresh-token']) {
      headers['x-refresh-token'] = getRefreshToken();
    }

    // eslint-disable-next-line no-param-reassign
    config.headers = headers;
    return config;
  }

  public get = (...args) => this.axios.get.apply(this, args);

  public post = (...args) => this.axios.post.apply(this, args);

  public patch = (...args) => this.axios.patch.apply(this, args);

  public put = (...args) => this.axios.put.apply(this, args);

  public delete = (...args) => this.axios.delete.apply(this, args);
}

export default API;
