import React from 'react';
import { ErrorBoundary, type ErrorBoundaryProps } from 'react-error-boundary';
import { Sentry } from 'data';
import { logout } from 'data/utils/helpers';
import { isRefreshURL } from 'data/axios/http-client';
import { AxiosError } from 'axios';

export const GlobalErrorBoundary = ({
  children,
  ...rest
}: React.PropsWithRef<React.PropsWithChildren<ErrorBoundaryProps>>) => {
  const handleError = (
    error: Error,
    info: {
      componentStack: string;
    },
  ) => {
    if (error instanceof AxiosError) {
      const statusCode = error.response?.status;
      const isRefreshTokenInvalid = statusCode === 404 && isRefreshURL(error.request.responseURL);
      if (isRefreshTokenInvalid) {
        logout();
        return;
      }
    }

    console.error({
      error,
    });
    Sentry.captureException(error, {
      contexts: {
        react: {
          componentStack: info.componentStack,
        },
      },
    });
    if (typeof rest.onError === 'function') {
      rest.onError(error, info);
    }
  };
  return (
    <ErrorBoundary {...rest} onError={handleError}>
      {children}
    </ErrorBoundary>
  );
};
