import { z } from 'zod';
import { FILE_MEMBERSHIP } from 'data/enums/file-membership';
import { FileMembershipRightsValidationSchema } from 'data/validators/body/file-system/file-membership-rights.validator';

export const FileMembershipValidationSchema = FileMembershipRightsValidationSchema.extend({
  fileMembershipType: z.nativeEnum(FILE_MEMBERSHIP),
  accessId: z.string().uuid(),
});

export type FileMembershipValidator = z.infer<typeof FileMembershipValidationSchema>;
