/* eslint-disable no-useless-escape */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { frequentlyMadeMistakes, type Language } from 'app/constants/data';
import { icons } from '@coach/ui';
import enUS from 'date-fns/locale/en-US';
import nlBE from 'date-fns/locale/nl-BE';
import crypto from 'crypto-js';
import { formatDistanceStrict } from 'date-fns';
import DOMPurify from 'dompurify';
import { List, Map } from 'immutable';
import { AES_DECRYPTION_KEY } from 'app/constants/env';
import { type KidValidator, KidValidatorSchema } from 'data/validators';
import { type TFunction } from 'i18next';
import { type BookFileEntity, type MistakeEntity, type QuestionFileEntity, type UserEntity } from 'data/types/entities';
import { OptionSchema } from 'data/utils/validation';
import { type z } from 'zod';
import { validation } from 'data/index';
import { type tabMapper } from 'app/components/kid/digitools/number-cards/helpers';
import cloneDeep from 'lodash/cloneDeep';
import { type QUESTION_FILE_TYPE } from 'data/enums';
import { RuntimeError } from 'app/utils/error';
import { ErrorService } from 'app/utils/services/error-tracking';
import { toast } from 'react-toastify';
import { type CSVParseResult, type Option, type Primitive } from '../types';

export const isExecFile = (file) =>
  file &&
  (file.type === 'application/octet-stream' || file.name?.slice(-4) === '.exe' || file.name?.slice(-4) === '.dmg');

export const shiftPositions = (list, switcher, newPosition, entityId) => {
  const shiftedPositions = list.map((boxToUpdate) => {
    if (boxToUpdate.get('id') === entityId) {
      return boxToUpdate.set('position', newPosition);
    }
    return boxToUpdate.set('position', boxToUpdate.get('position') + switcher);
  });
  return shiftedPositions;
};

export const sortOnDragAndDrop = (list, oldPosition, newPosition, oldIndex, newIndex, entityId) => {
  const asc = newPosition > oldPosition;
  const [start, end] = asc ? [oldIndex, newIndex] : [newIndex, oldIndex];
  const listToChange = list.slice(start, end + 1);
  const listToUpdate = list.splice(start, end - start + 1);
  const switcher = asc ? -1 : +1;
  const listShifted = shiftPositions(listToChange, switcher, newPosition, entityId);
  const listShiftedSorted = listShifted.sortBy((item) => item.get('position'));
  return listToUpdate.splice(start, 0, ...listShiftedSorted);
};

export const cleanPrimitives = <T extends Primitive>(value: Primitive) => {
  if (typeof value === 'string') {
    const trimmedValue = value.trim();
    return trimmedValue.length > 0 ? trimmedValue : undefined;
  }

  if (value == null) return undefined;

  return value;
};

export const cleanValues = <T>(values: T): T => {
  if (Array.isArray(values)) {
    return values.map((item) => cleanValues(item)).filter(Boolean) as unknown as T;
  }

  if (typeof values === 'object' && values != null) {
    const output = {} as Record<string, unknown>;
    Object.keys(values).forEach((key: any) => {
      const cleanValue = cleanValues(values[key]);
      if (cleanValue !== undefined) {
        output[key] = cleanValue;
      }
    });
    return output as T;
  }

  return cleanPrimitives(values as any) as T;
};

export const isOptionValid = (option: unknown): option is z.infer<typeof OptionSchema> =>
  OptionSchema.safeParse(option).success;

export const createOptionsFromState = <T extends { id: string; name: string } = never>(
  items: List<unknown> | T[],
  currentOptions: Option[] | undefined = undefined,
) => {
  if (!items) return [];
  const rawOptions = List.isList(items) ? items.toJS() : items;

  const options = rawOptions.map((option) => ({
    value: typeof option === 'object' && option && 'id' in option ? option.id : undefined,
    label: typeof option === 'object' && option && 'name' in option ? option.name : undefined,
  }));

  const validOptions = options.filter(isOptionValid);

  if (!currentOptions) {
    return validOptions;
  }

  const currentOptionsValues = currentOptions?.map((option) => option.value) ?? [];
  return validOptions.filter((option) => !currentOptionsValues.includes(option.value));
};

export const createAutocompleteOptionsFromState = (state, labelAccessor = 'name', t) => {
  if (!state) return [];
  return state
    .map((option, i) => ({
      value: option.get('id'),
      label: t ? t(option.get(labelAccessor)) : option.get(labelAccessor),
      isLastOption: i === state.size - 1,
    }))
    .toArray();
};

export const createDomainWithFieldNameOptions = (domainTypes) => {
  if (!List.isList(domainTypes)) return [];

  return domainTypes
    .map((domainType, i) => {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      const domainName = domainType?.get('name') || '';
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      const fieldName = domainType?.getIn(['field', 'name']) || '';
      return {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        value: domainType?.get('id'),
        label: fieldName ? `${domainName} > ${fieldName}` : domainName,
        isLastOption: i === domainTypes.size - 1,
      };
    })
    .toArray();
};

export const createQuestionBoxTypeOptions = (questionBoxTypes, selectedQuestionBoxTypes = [], t) => {
  if (!questionBoxTypes) return [];
  return (
    questionBoxTypes
      .map((questionBoxType) => ({
        value: { id: questionBoxType, name: t(questionBoxType) },
        label: t(questionBoxType),
      }))
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      .filter((option) => !selectedQuestionBoxTypes.find((selected) => selected.id === option.value.id))
  );
};

export const createQuestionBoxTypeInitialValues = (questionBoxTypes, t) => {
  if (!questionBoxTypes) return [];
  return questionBoxTypes
    .map((questionBoxType) => ({
      id: questionBoxType,
      name: t(questionBoxType),
    }))
    .toArray();
};

export const createUserOptions = (users) => {
  if (!users) return [];
  return users
    .map((user, i) => ({
      value: user.getIn(['user', 'id']),
      label: `${user.getIn(['user', 'firstName'])} ${user.getIn(['user', 'lastName'])}`,
      isLastOption: i === users.size - 1,
    }))
    .toArray();
};

export const createUserOptionsJS = (users: UserEntity[]) => {
  if (!users) return [];

  return users.map((user, i) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`,
    isLastOption: i === users.length - 1,
  }));
};

export const createKidsOptions = (kids) => {
  if (!kids) return [];
  return kids
    .map((user, i) => ({
      value: user.get('id'),
      label: `${user.getIn(['user', 'firstName'])} ${user.getIn(['user', 'lastName'])}`,
      isLastOption: i === kids.size - 1,
    }))
    .toArray();
};

export const createCoachesOptions = (coaches) => {
  if (!coaches) return [];
  return coaches
    .map((user, i) => ({
      value: user.get('id'),
      label: `${user.getIn(['user', 'firstName'])} ${user.getIn(['user', 'lastName'])}`,
      isLastOption: i === coaches.size - 1,
    }))
    .toArray();
};

export const createGroupsOptions = (groups) => {
  if (!groups) return [];
  return groups
    .map((group, i) => ({
      value: group.get('id'),
      label: `${group.get('name')}`,
      isLastOption: i === groups.size - 1,
    }))
    .toArray();
};

export const createDigitoolsOptions = (digitools, selectedDigitools = [], t) => {
  if (!digitools) return [];
  return (
    digitools
      .map((digitool) => ({
        value: { id: digitool, name: t(digitool) },
        label: t(digitool),
      }))
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      .filter((option) => !selectedDigitools.find((selected) => selected.id === option.value.id))
  );
};

export const createDigitoolsInitialValues = (digitools, t) => {
  if (!digitools) return [];
  return digitools
    .map((digitool) => ({
      id: digitool,
      name: t(digitool),
      digitool: true,
    }))
    .toArray();
};

export const compareArrays = (arr1, arr2, comparator) => {
  if (arr1?.length !== arr2?.length) return Array(1);
  const finalArray = [];
  if (comparator)
    arr1.forEach((item) => {
      // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      if (!arr2.find((it) => item[comparator] === it[comparator])) finalArray.push(item);
    });
  else
    arr1.forEach((item) => {
      // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      if (!arr2.includes(item)) finalArray.push(item);
    });
  return finalArray;
};

export const isImage = (file) => file && file.type?.split('/')[0] === 'image';

export const formatBytes = (bytes) => {
  if (!bytes) return `0 B`;
  if (bytes < 1024) return `${bytes} B`;
  if (bytes < 1048576) return `${(bytes / 1024).toFixed(2)} KB`;
  if (bytes < 1073741824) return `${(bytes / 1048576).toFixed(2)} MB`;
  return `${(bytes / 1073741824).toFixed(2)} GB`;
};

export const chooseIcon = (item) => {
  const type = item.get('discriminator');
  if (type === 'FILE') {
    if (item.get('memberships')?.size) return icons.sharedFile;
    return icons.insertDrive;
  }
  if (type === 'FOLDER') {
    if (item.get('memberships')?.size) return icons.sharedFolder;
    if (!item.get('hasDescendants')) return icons.openFolder;
  }
  return icons.folder;
};

export const getPoints = ({ pointActiveSize, height, size }) => {
  const halfSize = pointActiveSize / 2;
  const sizePerItem = height / size;
  const halfSizePerItem = sizePerItem / 2;
  return Array.from({ length: size ** 2 }).map((x, i) => ({
    x: sizePerItem * (i % size) + halfSizePerItem - halfSize,
    y: sizePerItem * Math.floor(i / size) + halfSizePerItem - halfSize,
  }));
};

export const getDistance = (p1, p2) => Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
export const getAngle = (p1, p2) => Math.atan2(p2.y - p1.y, p2.x - p1.x);

export const getCollidedPointIndex = (
  { x, y }, // Mouse position
  points, // Pattern points
  pointActiveSize, // Point active diameter
) => {
  for (let i = 0; i < points?.length; i += 1) {
    if (x > points[i].x && x < points[i].x + pointActiveSize && y > points[i].y && y < points[i].y + pointActiveSize)
      return i;
  }
  return -1;
};

export const getConnectorPoint = (p, pointActiveSize, connectorThickness) => ({
  x: p.x + Math.floor(pointActiveSize / 2),
  y: p.y + Math.floor(pointActiveSize / 2) - Math.floor(connectorThickness / 2),
});

export type FilesMap = {
  fileImage: BookFileEntity | null;
  tableOfContent: BookFileEntity[];
  solutionBook: BookFileEntity[];
  emptyBook: BookFileEntity[];
};

export const getBookFiles = (files?: List<Map<keyof BookFileEntity, unknown>> | BookFileEntity[]): FilesMap => {
  const filesMap = {
    fileImage: null,
    tableOfContent: [],
    solutionBook: [],
    emptyBook: [],
  };

  if (!files) return filesMap;

  files.forEach((file) => {
    const fileType = Map.isMap(file) ? file.get('type') : file.type;
    switch (fileType) {
      case 'TYPE_COVER_IMAGE':
        filesMap.fileImage = file;
        break;
      case 'TYPE_TABLE_OF_CONTENTS':
        // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        filesMap.tableOfContent.push(file);
        break;
      case 'TYPE_SOLUTION_BOOK':
        // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        filesMap.solutionBook.push(file);
        break;
      case 'TYPE_EMPTY_BOOK':
        // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        filesMap.emptyBook.push(file);
        break;
      default:
        break;
    }
  });
  return filesMap;
};

export const parseCSVFileWithKids = (file: CSVParseResult, t: TFunction) => {
  const [header, ...rows] = file.data;

  const headerKeys = header.map((key) => key.trim());
  const arr: KidValidator[] = [];
  let incorrectRows = 0;

  for (let i = 0; i < rows?.length; i += 1) {
    const kidValues = rows[i].reduce((accumulator: Record<string, string>, value: string, index: number) => {
      const currentKey = headerKeys[index];
      const trimmed = value.trim();
      if (trimmed) {
        accumulator[currentKey] = trimmed;
      }

      return accumulator;
    }, {});

    if (Object.keys(kidValues)?.length >= 7) {
      try {
        const {
          defaultGradeId,
          defaultCultureId,
          language,
          firstName,
          lastName,
          password,
          passcode,
          email,
          emailParentOne,
          emailParentTwo,
        } = kidValues;
        const normalizedValues = {
          defaultGradeId,
          defaultCultureId,
          language,
          password,
          passcode,
          firstName,
          lastName,
          email,
          emailParentOne,
          emailParentTwo,
        };

        validation.KidSchema.validateSync(kidValues);
        const parsedKidValues = KidValidatorSchema.parse(normalizedValues);

        arr.push(parsedKidValues);
      } catch (error) {
        /**
         * @description 2 is added to the index as the first row is the header
         */
        const errorMessage = `Error in row ${i + 2}. ${t(`validation.${error.message}`)}`;
        console.error(error);
        toast.error(errorMessage);
        break;
      }
    } else {
      incorrectRows += 1;
    }
  }

  if (arr?.length === rows.length - incorrectRows) {
    return arr;
  }
  return [];
};

export const normalizeTTSText = (
  question: string,
  t: TFunction<'translation', undefined>,
  options?: { lang?: Language },
) => {
  if (question && typeof question[Symbol.iterator] === 'function') {
    // if question is iterable
    const splittedQuestion = [...question];
    const obj = {
      '-': t('minus', {
        lng: options?.lang,
      }),
      '*': t('multiply', {
        lng: options?.lang,
      }),
      '/': t('over', {
        lng: options?.lang,
      }),
      '=': t('equals', {
        lng: options?.lang,
      }),
    };
    const numberWithERegExp = /(^|\s)(\d+)([eE])(\s|$)/g;
    const decimalNumberRegExp = /(^|\s)(\d+)([.,])(\d+)(\s|$)/g;

    return splittedQuestion
      .map((char) => (obj[char] ? ` ${obj[char]} ` : char))
      .join('')
      .replaceAll(numberWithERegExp, ' $2 $3 ')
      .replaceAll(
        decimalNumberRegExp,
        ` $2 ${t('comma', {
          lng: options?.lang,
        })} $4 `,
      )
      .trim();
  }

  return '';
};

// Explanation of the regular expression /\\\[|\\\]/g:
//     Overall, this regular expression matches any occurrence of either a backslash followed by
// a left square bracket (\\\[) or a backslash followed by a right square bracket (\\\]) in a given string.
//   | - is the logical OR operator in regular expressions.

// Explanation of the regular expression /\[([^\[\]]*)\]/g:
//   Overall, this regular expression pattern matches square brackets with or without content.
//   \[ and \] - match the opening and closing square brackets respectively.
//   [^ ] - is a negation character that means "not",
//   so [^\[\]] - matches any character that is not a square bracket.
//   * - means "zero or more times", so ([^\[\]]*) matches any sequence of characters
// that are not square brackets, including an empty string.
// For example, if the input string is "The [quick] brown [fox] jumps over the [lazy] dog",
// this regular expression will match "[quick]", "[fox]", and "[lazy]".

export const generateTextToSpeakFromSolution = (solution: string, t) => {
  const removeAllEscapedBrackets = /\\\[|\\\]/g;
  // removes escaped brackets: '\[1... 3\] = [2]' => '1... 3 = [2]'
  const solutionWithoutEscapedBrackets = solution.replace(removeAllEscapedBrackets, '');

  const findBracketsWithOrWithoutContent = /\[([^\[\]]*)\]/g;
  // replace brackets with or without content with the 'blank' word, add commas to add pauses
  return solutionWithoutEscapedBrackets.replace(findBracketsWithOrWithoutContent, `,${t('blank')},`);
};

// Explanation of the regular expression /\[\s*\]/g:
//   Overall, this regular expression pattern matches square brackets without content.
//   \[ and \] - match the opening and closing square brackets respectively.
//   \s*: match zero or more whitespace characters(space, tab, newline, etc.).
// For example, if the input string is "The [] brown [ ] jumps over the [lazy] dog",
// this regular expression will match "[]" and "[ ]".

export const generateTextToSpeakFromAnswer = (answer: string, t) => {
  const findBracketsWithoutContent = /\[\s*\]/g;
  // replace brackets without content with the 'blank' word, add commas to add pauses
  return answer.replace(findBracketsWithoutContent, `,${t('blank')},`);
};

const getNumberOfColumns = (min: number, max: number, step: number) => {
  let res = 1;

  for (let i = min; i < max; i *= step) {
    if (i * step <= max) res += 1;
  }

  return res;
};

export const generateNumberCards = (min: number, max: number, step: number) => {
  const numberOfColumns = getNumberOfColumns(min, max, step);

  const columns: number[][] = [];

  let currentColumnMin = min;

  for (let i = 0; i < numberOfColumns; i += 1) {
    const currentColumn: number[] = [];

    for (
      let j = currentColumnMin;
      j < currentColumnMin * step && j <= max;
      j = (j * 100 + currentColumnMin * 100) / 100
    ) {
      currentColumn.push(j);
    }

    currentColumnMin *= step;

    columns.push(currentColumn.reverse());
  }

  return columns.reverse();
};

export const isDroppedOnElement = (e, elementId) => {
  let result = false;
  if (e.type === 'touchend') {
    const x = e.changedTouches[e.changedTouches.length - 1].pageX;
    const y = e.changedTouches[e.changedTouches.length - 1].pageY;
    // @ts-expect-error TS(2339): Property 'offsetLeft' does not exist on type 'HTML... Remove this comment to see the full error message
    const { offsetLeft, offsetHeight, offsetTop, offsetWidth } = document.getElementById(elementId);
    const board = {
      x1: offsetLeft,
      x2: offsetLeft + offsetWidth,
      y1: offsetTop,
      y2: offsetTop + offsetHeight,
    };
    if (x > board.x1 && x < board.x2 && y > board.y1 && y < board.y2) {
      result = true;
    }
  } else {
    result = e.target.parentNode.id === elementId || e.target.id === elementId;
  }

  return result;
};

export const getFMM = (
  mistakes?: MistakeEntity[] | null,
  question?: string,
): { key: string; params?: object } | null => {
  if (!mistakes || !question || mistakes?.length === 0) return null;
  const mistakesCopy = cloneDeep(mistakes);
  mistakesCopy.sort((mistake1, mistake2) => mistake2.priority - mistake1.priority);
  const mistake = mistakesCopy[0];
  const createMessage = frequentlyMadeMistakes[mistake.type];
  if (typeof createMessage === 'function') {
    return createMessage({
      mistake: mistake.value,
      question,
    });
  }
  return null;
};

export const getStatusMessage = (status) => {
  const MESSAGE_STATUS = {
    WRONG: 'wrong',
    FAILED: 'failed',
    SUCCESS: 'success',
    FINISH_FAILED: 'finishFailed',
    ENDED: 'ended',
  };

  const MESSAGE = {
    [MESSAGE_STATUS.WRONG]: 'wrong',
    [MESSAGE_STATUS.FAILED]: 'tryAgainExact',
    [MESSAGE_STATUS.SUCCESS]: 'correct',
    [MESSAGE_STATUS.FINISH_FAILED]: 'wrongYouHaveAnsweredAllQuestions',
    [MESSAGE_STATUS.ENDED]: 'taskEnded',
  };

  if (status in MESSAGE) return MESSAGE[status];
  return 'youHaveAnsweredAllQuestions';
};

export const getQuestionFileUrl = (files: QuestionFileEntity[], fileType: QUESTION_FILE_TYPE): string | undefined => {
  const fileWithAudio = files?.find((file) => file.type === fileType);

  return fileWithAudio?.url;
};

export const fixSafariAudio = async (url: string): Promise<string> => {
  /**
   * Safari doesn't support audio files without range requests, that's why we need to fetch the file and create a local url
   */
  const file = await fetch(url, {
    method: 'GET',
  });
  const blob = await file.blob();
  return URL.createObjectURL(blob);
};

const ranks = {
  10: 't',
  100: 'h',
  1000: 'd',
};

export const getAnswerPrefilled = (question: unknown) => {
  if (typeof question !== 'string') return [];

  const sign = question.match('[-+]');
  // TODO: check in which cases sign can be null
  if (!sign) return [];

  const array = question.split(sign[0]);
  const newArray = array.map((item) => item.trim());
  const answerArray = [];
  let [firstNumber, lastNumber] = newArray;
  const firstNumberWithReplacedDotByComma = firstNumber.replace('.', ',');
  const lastNumberWithReplacedDotByComma = lastNumber.replace('.', ',');
  let multiplier = 0;
  if (firstNumberWithReplacedDotByComma.includes(',') || lastNumberWithReplacedDotByComma.includes(',')) {
    const firstNumberComaIndex = firstNumberWithReplacedDotByComma.indexOf(',');
    const lastNumberComaIndex = lastNumberWithReplacedDotByComma.indexOf(',');
    let firstNumberDecimalPart;
    let lastNumberDecimalPart;
    if (firstNumberComaIndex !== -1) {
      firstNumberDecimalPart = firstNumberWithReplacedDotByComma.substring(firstNumberComaIndex + 1);
    }
    if (lastNumberComaIndex !== -1) {
      lastNumberDecimalPart = lastNumberWithReplacedDotByComma.substring(lastNumberComaIndex + 1);
    }
    const maxDecimalPartLength = Math.max(firstNumberDecimalPart?.length || 0, lastNumberDecimalPart?.length || 0);
    multiplier = 10 ** maxDecimalPartLength;
    firstNumber = (parseFloat(firstNumberWithReplacedDotByComma.replace(',', '.')) * multiplier).toFixed().toString();
    lastNumber = (parseFloat(lastNumberWithReplacedDotByComma.replace(',', '.')) * multiplier).toFixed().toString();
  }
  if (sign[0] === '+') {
    let i = 1;
    if (firstNumber.length === lastNumber.length) {
      firstNumber.split('').forEach((digit, index) => {
        const number =
          (parseInt(firstNumber[firstNumber.length - 1 - index], 10) +
            parseInt(lastNumber[lastNumber.length - 1 - index], 10)) *
          i;
        if (index === firstNumber.length - 1) {
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          answerArray.push(number.toString());
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        } else answerArray.push(`+${number}`);
        i *= 10;
      });
    } else {
      if (firstNumber.length < lastNumber.length) {
        const tmp = lastNumber;
        lastNumber = firstNumber;
        firstNumber = tmp;
      }
      const difference = lastNumber.length;
      firstNumber.split('').forEach((digit, index) => {
        let number;
        if (difference > index) {
          number =
            (parseInt(firstNumber[firstNumber.length - 1 - index], 10) +
              parseInt(lastNumber[lastNumber.length - 1 - index], 10)) *
            i;
        } else {
          number = parseInt(firstNumber[firstNumber.length - 1 - index], 10) * i;
        }
        if (index === firstNumber.length - 1) {
          // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
          answerArray.push(number.toString());
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        } else answerArray.push(`+${number}`);
        i *= 10;
      });
    }
  } else {
    let i = 1;
    if (firstNumber.length === lastNumber.length) {
      firstNumber.split('').forEach((digit, index) => {
        const number =
          (parseInt(firstNumber[firstNumber.length - 1 - index], 10) -
            parseInt(lastNumber[lastNumber.length - 1 - index], 10)) *
          i;
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        if (index === firstNumber.length - 1 || number < 0) answerArray.push(number.toString());
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        else answerArray.push(`+${number}`);
        i *= 10;
      });
    } else {
      if (firstNumber.length < lastNumber.length) {
        const tmp = lastNumber;
        lastNumber = firstNumber;
        firstNumber = tmp;
      }
      const difference = lastNumber.length;
      firstNumber.split('').forEach((digit, index) => {
        let number;
        if (difference > index) {
          number =
            (parseInt(firstNumber[firstNumber.length - 1 - index], 10) -
              parseInt(lastNumber[lastNumber.length - 1 - index], 10)) *
            i;
        } else {
          number = parseInt(firstNumber[firstNumber.length - 1 - index], 10) * i;
        }
        // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        if (index === firstNumber.length - 1 || number < 0) answerArray.push(number.toString());
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        else answerArray.push(`+${number}`);
        i *= 10;
      });
    }
  }
  let answer = 0;
  if (multiplier > 0) {
    const answerArrayWithRanks = answerArray.map((number, index) => {
      const int = parseInt(number, 10);
      answer = (answer * multiplier + int * multiplier) / multiplier;
      if (int > 0 && index !== answerArray.length - 1)
        return `+${int.toString().replace('.', ',').concat(ranks[multiplier])}`;
      return int.toString().replace('.', ',').concat(ranks[multiplier]);
    });
    answer /= multiplier;
    answerArrayWithRanks.unshift(answer.toString().replace('.', ','));
    return answerArrayWithRanks.reverse();
  }
  answerArray.forEach((number) => {
    answer += parseInt(number, 10);
  });
  // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  answerArray.unshift(answer.toString());
  return answerArray.reverse();
};

/**
 *
 * @param {string} encryptedAnswer
 * @return {string|*}
 */
export const decryptAnswer = (encryptedAnswer: string): string => {
  if (!encryptedAnswer) return '';
  const decrypted = crypto.AES.decrypt(encryptedAnswer, AES_DECRYPTION_KEY).toString(crypto.enc.Utf8);
  return decrypted ? JSON.parse(decrypted)?.data : '';
};

export const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0;

const getMaxDivideValues = (answer, values, maxValues) => {
  const answerNumber = typeof answer === 'string' ? Number(answer.replace(',', '.')) : answer;
  if (answerNumber === 0 || !values.length) {
    return maxValues;
  }

  const roundedAnswer = answerNumber - (answerNumber % values[0]);
  const numberOfMaxValues = Math.round(roundedAnswer / values[0]);

  return getMaxDivideValues(Math.round(answerNumber - roundedAnswer), values.slice(1), [
    ...maxValues,
    ...(numberOfMaxValues ? Array.from({ length: numberOfMaxValues }, () => values[0]) : []),
  ]);
};

export const getDroppedElementIndex = (dropCoords, dropTargetCoords, selectedItems, itemWidth, itemHeight) => {
  if (selectedItems.length && dropCoords && dropTargetCoords) {
    const { offsetWidth, offsetHeight, offsetLeft, offsetTop } = dropTargetCoords;
    const { x, y } = dropCoords;
    const numberOfRows = Math.round((offsetHeight - 32 - ((offsetHeight - 32) % itemHeight)) / itemHeight); // 32 is vertical padding
    const clickInBox = y - offsetTop - 32 / 2;
    const inWhichRowDropped = Math.round((clickInBox - (clickInBox % itemHeight)) / itemHeight);
    const boxWidth = offsetWidth * numberOfRows - 32 - 80; // 80 is red button width
    const xDroppedItemInBox = x - offsetLeft + offsetWidth * inWhichRowDropped;
    const itemsWidth = selectedItems.length * itemWidth;
    const freeSpaceBeforeItems = numberOfRows > 1 ? 16 : Math.round((boxWidth - itemsWidth) / 2);

    if (freeSpaceBeforeItems > xDroppedItemInBox) {
      // if item before all items
      return 0;
    }

    if (freeSpaceBeforeItems + itemsWidth < xDroppedItemInBox) {
      // if item after all items
      return selectedItems.length;
    }

    const xDroppedItemInItems = Math.round(xDroppedItemInBox - freeSpaceBeforeItems);
    const pixelsInDroppedTarget = xDroppedItemInItems % itemWidth;
    const droppedIndex = Math.round((xDroppedItemInItems - pixelsInDroppedTarget) / itemWidth);

    if (pixelsInDroppedTarget > itemWidth / 2) {
      return droppedIndex + 1;
    }
    return droppedIndex;
  }
  if (selectedItems.length) {
    return selectedItems.length;
  }

  return 0;
};

export const getInitialNumberCardsTabIndex = (question: string, answer: string, tabs: typeof tabMapper) => {
  if (typeof question !== 'string' || !answer || !tabs) return 0;
  let result = 0;

  let currentMax = Infinity;

  const numbersInQuestion = question
    .split(' ')
    .map((v) => Number(v.replace(',', '.')))
    .filter((item) => !Number.isNaN(item));

  if (!Number.isNaN(Number(answer))) {
    numbersInQuestion.push(Number(answer));
  }

  // choose the maximum number from all
  // if there are any decimals - choose only from decimals tabs
  const maxFromQuestionAndAnswer = Math.max(...numbersInQuestion);

  const hasDecimal = numbersInQuestion.some((num) => !Number.isInteger(num));

  if (numbersInQuestion.length > 0) {
    tabs.forEach(({ max, min }, i) => {
      if (hasDecimal) {
        // if number is decimal - accept only tab with decimal minimum
        // essentially - choose from two options, but make it work with any array
        if (!Number.isInteger(min) && max > maxFromQuestionAndAnswer && max < currentMax) {
          result = i;
          currentMax = max;
        }
      } else if (Number.isInteger(min) && maxFromQuestionAndAnswer < max && max < currentMax) {
        result = i;
        currentMax = max;
      }
    });
  }
  return result;
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const duration = (s: number, language: string) => {
  try {
    return formatDistanceStrict(0, s * 1000, {
      locale: language === 'nl' ? nlBE : enUS,
    });
  } catch (error) {
    const e = new RuntimeError(`Failed to format distance. Received args: s: ${s}, language: ${language}`, {
      error,
    });
    ErrorService.captureException(e);
    return '';
  }
};

/**
 *
 * @param {string} html
 * @returns {string}
 */
export const sanitize = (html: string): string => DOMPurify.sanitize(html);

export const isAlbum = () => window.innerHeight < window.innerWidth;
