import { type DIGITOOL_TYPE } from './digitool';
import { type TEXT_QUESTION_TYPE } from './text-question';

export type QUESTION_TYPE = DIGITOOL_TYPE | TEXT_QUESTION_TYPE;

export enum QUESTION_ELEMENT {
  AUDIO = 'audio',
  TEXT = 'text',
  PICTURE = 'picture',
}

export enum QUESTION_FILE_TYPE {
  PICTURE = 'picture',
  AUDIO = 'audio',
}

export enum EDUCATION_STRATEGY {
  LEARNING_LINE = 'learningLine',
  TOWER = 'tower',
}
