import { z } from 'zod';

export const AddressValidatorSchema = z.object({
  address: z.string().optional(),
  city: z.string().optional(),
  country: z.string().optional(),
  postal: z.string().optional(),
});

export type AddressValidator = z.infer<typeof AddressValidatorSchema>;
