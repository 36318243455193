/* eslint-disable no-promise-executor-return */
/* eslint-disable no-await-in-loop */
import { MILLISECONDS } from 'app/constants/data';
import React from 'react';

const lazyWithRetries: typeof React.lazy = (importer) => {
  const retryImport = async () => {
    try {
      return await importer();
    } catch (error) {
      console.error(error.message);
      // retry 3 times with 2 second delay and backoff factor of 2 (2, 4, 8 seconds)
      for (let i = 0; i < 3; i++) {
        try {
          await new Promise((resolve) => setTimeout(resolve, 2 ** i * MILLISECONDS.SECOND));
          // this assumes that the exception will contain this specific text with the url of the module
          // if not, the url will not be able to parse and we'll get an error on that
          // eg. "Failed to fetch dynamically imported module: https://example.com/assets/Home.tsx"

          // Regular expression pattern to extract the URL
          const urlPattern = /https?:\/\/[^\s"]+/;

          // Search for the URL using the pattern
          const urlMatch = error.message.match(urlPattern);

          if (urlMatch && urlMatch.length > 0) {
            const extractedUrl = urlMatch[0];
            const url = new URL(extractedUrl);
            // add a timestamp to the url to force a reload the module (and not use the cached version - cache busting)
            url.searchParams.set('t', `${+new Date()}`);
            return await import(/* @vite-ignore */ url.href);
          }
          console.log('URL not found in the error message', { message: error.message });
        } catch (e) {
          console.log('retrying import');
        }
      }
      window.location.reload();
      return undefined;
    }
  };
  return React.lazy(retryImport);
};

export default lazyWithRetries;
