import api from 'data/axios/react-query-api';
import {
  type DidacticBoxEntity,
  type LearningLineDetailEntity,
  type LearningLineInfoEntity,
  type LearningLineKidPositionEntity,
  type LearningLineListItemEntity,
  type PositionContext,
  type ValidateProgressFormValues,
  type ValidationState,
} from 'data/types/entities';
import { type ListEntity } from 'data/utils/types';
import { type MoveKidsValidator } from 'data/validators';
import { type DidacticBoxValidator } from 'data/validators/body/didactic-box/didactic-box.validator';
import { type LearningContentStatusValidator } from 'data/validators/body/learning-content/learning-content-status.validator';
import { type LearningLineValidator } from 'data/validators/body/learning-line/learning-line.validator';
import { type LearningLinesQuery } from 'data/validators/query-params/learning-lines.query';

export const fetchLearningLinePosition = (learningLineId: string): Promise<PositionContext> =>
  api.get(`/learning/progress/learningLines/${learningLineId}/position`);

export const fetchLearningLineNext = (questionId: string, learningBoxId: string): Promise<PositionContext> =>
  api.get(`/learning/progress/questions/${questionId}/learningBoxes/${learningBoxId}/next`);

export const validateLearningLine = (values: ValidateProgressFormValues): Promise<ValidationState> =>
  api.post('/learning/progress/validate', values);

export const fetchAllLearningLines = (params?: LearningLinesQuery) =>
  api.get<ListEntity<LearningLineListItemEntity>>(`/learninglines`, { params });

export const fetchLearningLine = (learningLineId: string) =>
  api.get<LearningLineDetailEntity>(`/learninglines/${learningLineId}`);

export const fetchLearningLineInfo = (learningLineId: string) =>
  api.get<LearningLineInfoEntity>(`/learninglines/${learningLineId}/info`);

export const fetchLatestPositionWithinLearningLine = (learningLineId: string, kidId: string) =>
  api.get<LearningLineKidPositionEntity>(`/learninglines/${learningLineId}/kids/${kidId}/position`);

export const pushKidsAndGroupsToLearningLine = (learningLineId: string, body: MoveKidsValidator) =>
  api.post(`/learninglines/${learningLineId}/move`, body);

export const updateLearningLineStatus = (learningLineId: string, body: LearningContentStatusValidator) =>
  api.patch(`/learninglines/${learningLineId}/status`, body);

export const updateLearningLine = (learningLineId: string, body: LearningLineValidator) =>
  api.patch<LearningLineDetailEntity>(`/learninglines/${learningLineId}`, body);

export const setLearningLinePicture = (learningLineId: string, body: FormData) =>
  api.patch<LearningLineDetailEntity>(`/learninglines/${learningLineId}/picture`, body);

export const createDidacticBox = (learningLineId: string, body: DidacticBoxValidator) =>
  api.post<DidacticBoxEntity>(`/learninglines/${learningLineId}/didacticboxes`, body);

export const deleteLearningLine = (learningLineId: string) => api.delete(`/learninglines/${learningLineId}`);
