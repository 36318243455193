import React from 'react';
import MenuItem, { type MenuItemClassKey } from '@mui/material/MenuItem';
import cx from 'classnames';
import { type TextFieldProps } from '@mui/material/TextField';
import { type ClassNameMap } from '@mui/styles';
import { useSelectStyles } from './styles';
import { Form } from './form';

export type SelectProps = Omit<TextFieldProps, 'value' | 'className' | 'disabled'> & {
  options: { value: string; label: string; disabled?: boolean }[];
  value?: string | TextFieldProps['value'];
  className?: string;
  disabled?: boolean;
  menuItemClasses?: Partial<ClassNameMap<MenuItemClassKey>>;
};

export const Select = ({
  options,
  value = '',
  className = '',
  disabled = false,
  menuItemClasses,
  ...props
}: SelectProps) => {
  const s = useSelectStyles();

  return (
    <Form
      select
      className={cx(className, s.root, disabled ? s.disabled : '')}
      disabled={disabled}
      value={value}
      title={value as string}
      {...props}
    >
      {options.map((option, index) => (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={option.disabled}
          className={s.option}
          data-testid={`select-option-${index}`}
          classes={menuItemClasses}
        >
          {option.label}
        </MenuItem>
      ))}
    </Form>
  );
};
