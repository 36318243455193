import { fromJS } from 'immutable';
import * as constants from 'data/constants/api';

const initialState = fromJS({
  unauthorized: false,
  serverError: false,
  connectionError: false,
  notFound: false,
  badRequest: '',
  successMessage: '',
  notifications: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.UNAUTHORIZED:
      // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
      return state.set('unauthorized', true);
    case constants.SERVER_ERROR:
      // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
      return state.set('serverError', true);
    case constants.CONNECTION_ERROR:
      // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
      return state.set('connectionError', true);
    case constants.NOT_FOUND:
      // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
      return state.set('notFound', true);
    default:
      return state;
  }
};
