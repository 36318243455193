import { fromJS } from 'immutable';
import * as constants from 'data/constants/invites';
import LoadingProgress from 'data/utils/reducers/loading';

export const inviteRemovalProgress = new LoadingProgress('inviteRemovalProgress');

const initialState = fromJS({
  entities: {},
  ids: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.DELETE_INVITE_START:
      return inviteRemovalProgress.setLoading(state);
    case constants.DELETE_INVITE_SUCCESS:
      return inviteRemovalProgress.setLoaded(state);
    case constants.DELETE_INVITE_FAILED:
      return inviteRemovalProgress.setLoadFailed(state);
    default:
      return state;
  }
};
