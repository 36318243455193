const prefix = (type) => `eureka/videos/${type}`;

export const FETCH_VIDEOS_START = prefix('FETCH_VIDEOS_START');
export const FETCH_VIDEOS_SUCCESS = prefix('FETCH_VIDEOS_SUCCESS');
export const FETCH_VIDEOS_FAILED = prefix('FETCH_VIDEOS_FAILED');

export const DELETE_VIDEO_START = prefix('DELETE_VIDEO_START');
export const DELETE_VIDEO_SUCCESS = prefix('DELETE_VIDEO_SUCCESS');
export const DELETE_VIDEO_FAILED = prefix('DELETE_VIDEO_FAILED');

export const DELETE_VIDEO_GRADES_START = prefix('DELETE_VIDEO_GRADES_START');
export const DELETE_VIDEO_GRADES_SUCCESS = prefix('DELETE_VIDEO_GRADES_SUCCESS');
export const DELETE_VIDEO_GRADES_FAILED = prefix('DELETE_VIDEO_GRADES_FAILED');

export const DELETE_VIDEO_DOMAINS_START = prefix('DELETE_VIDEO_DOMAINS_START');
export const DELETE_VIDEO_DOMAINS_SUCCESS = prefix('DELETE_VIDEO_DOMAINS_SUCCESS');
export const DELETE_VIDEO_DOMAINS_FAILED = prefix('DELETE_VIDEO_DOMAINS_FAILED');

export const EDIT_VIDEO_START = prefix('EDIT_VIDEO_START');
export const EDIT_VIDEO_SUCCESS = prefix('EDIT_VIDEO_SUCCESS');
export const EDIT_VIDEO_FAILED = prefix('EDIT_VIDEO_FAILED');

export const EDIT_VIDEO_GRADES_START = prefix('EDIT_VIDEO_GRADES_START');
export const EDIT_VIDEO_GRADES_SUCCESS = prefix('EDIT_VIDEO_GRADES_SUCCESS');
export const EDIT_VIDEO_GRADES_FAILED = prefix('EDIT_VIDEO_GRADES_FAILED');

export const EDIT_VIDEO_DOMAINS_START = prefix('EDIT_VIDEO_DOMAINS_START');
export const EDIT_VIDEO_DOMAINS_SUCCESS = prefix('EDIT_VIDEO_DOMAINS_SUCCESS');
export const EDIT_VIDEO_DOMAINS_FAILED = prefix('EDIT_VIDEO_DOMAINS_FAILED');

export const CLEAR_STATE = prefix('CLEAR_STATE');
