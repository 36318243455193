import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { usePublicRoutesBacklinkStore } from 'data/stores/public-routes-backlink-store';

import cx from 'classnames';
import LanguageSelect from 'app/components/core/language-select';
import { icons, Text } from '@coach/ui';
import styles from 'app/components/public/styles.module.css';

interface PublicRoutesWrapperProps {
  children: React.ReactNode;
}

const PublicRoutesWrapper = ({ children }: PublicRoutesWrapperProps) => {
  const { t } = useTranslation();

  const { backlinkValue } = usePublicRoutesBacklinkStore();

  return (
    <>
      <header className={cx('w-full', styles.inlineOffset)}>
        <nav className={cx('flex justify-between py-3', styles.container)}>
          <Link
            to={{ pathname: backlinkValue.href }}
            className="flex items-center text-white"
            target={backlinkValue.target}
            rel="noreferrer noopener"
            data-intercom-target="help-link"
          >
            <div className="flex items-center justify-center">{icons.arrowLeft}</div>
            <Text color="white" className="ml-2 text-base font-normal">
              {t(backlinkValue.label)}
            </Text>
          </Link>

          <div className="flex items-center gap-3">
            <LanguageSelect />
          </div>
        </nav>
      </header>

      <main>{children}</main>
    </>
  );
};

export default PublicRoutesWrapper;
