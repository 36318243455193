import { combineReducers } from 'redux';
import api from './api';
import learningCultures from './learning-cultures';
import grades from './grades';
import fields from './fields';
import learningLines from './learning-lines';
import didacticBoxes from './didactic-boxes';
import learningBoxes from './learning-boxes';
import kid from './kid';
import questionBoxes from './question-boxes';
import questions from './questions';
import generator from './generator';
import membersAdmins from './members-admins';
import schools from './schools';
import groups from './groups';
import files from './files';
import actions from './actions';
import towers from './towers';
import books from './books';
import booklicenses from './booklicenses';
import invites from './invites';
import videos from './videos';
import words from './words';

export const entities = {
  learningCultures,
  grades,
};

const appReducers = combineReducers({
  actions,
  api,
  booklicenses,
  books,
  didacticBoxes,
  fields,
  files,
  generator,
  groups,
  invites,
  kid,
  learningBoxes,
  learningLines,
  membersAdmins,
  questionBoxes,
  questions,
  schools,
  towers,
  videos,
  words,
  ...entities,
});

const rootReducer = (state, action) => appReducers(state, action);

export type RootState = ReturnType<typeof appReducers>;

export default rootReducer;
