import { z } from 'zod';

export const ProgressConfigValidator = z.object({
  /**
   * The amount of mistakes a user can make until the question is considered invalid.
   * Not applicable for boolean questions (true/false).
   *
   * The number should be greater than 0.
   * The comparison is exclusive
   */
  scoreMaxInvalidCount: z.number().min(1),
  totalScore: z.object({
    attemptsThreshold: z.coerce.number().min(1),
    maxScore: z.coerce.number().min(1),
    // in case the number of attempts exceeded the threshold
    reducedScore: z.coerce.number().min(1),
  }),
});

export type ProgressConfig = z.infer<typeof ProgressConfigValidator>;
