import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import CheckIcon from '@icons/kid/Check';
import cx from 'app/utils/helpers/cx';

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(function Checkbox({ className, ...props }, ref) {
  return (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cx(
        'peer h-6 w-6 cursor-pointer rounded-[5px] border-[3px] border-solid border-neutral-50 bg-transparent',
        'data-[state=checked]:border-none data-[state=checked]:bg-yellow-300',
        'disabled:cursor-not-allowed disabled:opacity-50',
        className,
      )}
      {...props}
    >
      <CheckboxPrimitive.Indicator className="flex items-center justify-center text-current">
        <CheckIcon fontSize="extraSmall" />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  );
});

export { Checkbox };
