const prefix = (type) => `eureka/learningCultures/${type}`;

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');
export const LOAD_CULTURE_SUCCESS = prefix('LOAD_CULTURE_SUCCESS');
export const CREATION_START = prefix('CREATION_START');
export const CREATION_SUCCESS = prefix('CREATION_SUCCESS');
export const CREATION_FAILED = prefix('CREATION_FAILED');
export const EDITING_START = prefix('EDITING_START');
export const EDITING_SUCCESS = prefix('EDITING_SUCCESS');
export const EDITING_FAILED = prefix('EDITING_FAILED');
export const REMOVING_START = prefix('REMOVING_START');
export const REMOVING_SUCCESS = prefix('REMOVING_SUCCESS');
export const REMOVING_FAILED = prefix('REMOVING_FAILED');

export const LOAD_TOWERS_START = prefix('LOAD_TOWERS_START');
export const LOAD_TOWERS_SUCCESS = prefix('LOAD_TOWERS_SUCCESS');
export const LOAD_TOWERS_FAILED = prefix('LOAD_TOWERS_FAILED');

export const LOAD_KID_FIELDS_START = prefix('LOAD_KID_FIELDS_START');
export const LOAD_KID_FIELDS_SUCCESS = prefix('LOAD_KID_FIELDS_SUCCESS');
export const LOAD_KID_FIELDS_FAILED = prefix('LOAD_KID_FIELDS_FAILED');

export const LOAD_GRADES_START = prefix('LOAD_GRADES_START');
export const LOAD_GRADES_SUCCESS = prefix('LOAD_GRADES_SUCCESS');
export const LOAD_GRADES_FAILED = prefix('LOAD_GRADES_FAILED');

export const CLEAR = prefix('CLEAR');
export const CLEAR_STATE = prefix('CLEAR_STATE');
