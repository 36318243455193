import { fromJS } from 'immutable';
import * as constants from 'data/constants/members-admins';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const adminsProgress = new LoadingProgress('adminsProgress');
export const adminProgress = new LoadingProgress('adminProgress');
export const adminsPagination = new Pagination('admins');
export const adminsInvitesProgress = new LoadingProgress('adminsInvitesProgress');
export const adminRemovalProgress = new LoadingProgress('adminRemovalProgress');
export const unconfirmedUsersProgress = new LoadingProgress('unconfirmedUsersProgress');
export const unconfirmedUsersPagination = new Pagination('unconfirmedUsers');
export const unconfirmedUserRegister = new LoadingProgress('unconfirmedUserRegister');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { admins },
    } = payload;
    newState.mergeIn(['entities'], fromJS(admins));
  });

const loadAdmins = (state, action) =>
  state.withMutations((newState) => {
    const { limit, count, result } = action.payload;
    mergeData(newState, action.payload);
    adminsPagination.set(newState, count, limit, fromJS(result));
    adminsProgress.setLoaded(newState);
  });

const loadAdmin = (state, action) =>
  state.withMutations((newState) => {
    const {
      entities: { admins },
    } = action.payload;
    newState.mergeIn(['entities'], fromJS(admins));
    adminProgress.setLoaded(newState);
  });

const editAdmin = (state, action) =>
  state.withMutations((newState) => {
    const { roleId, data } = action.payload;
    newState.setIn(['entities', roleId, 'user'], fromJS(data));
    adminProgress.setLoaded(newState);
  });

const loadInvites = (state, action) =>
  state.withMutations((newState) => {
    const { data, total } = action.payload;
    newState.setIn(['invites', 'total'], fromJS(total));
    newState.setIn(['invites', 'items'], fromJS(data));
    adminsInvitesProgress.setLoaded(newState);
  });

const loadUnconfirmedUsers = (state, action) =>
  state.withMutations((newState) => {
    const {
      entities: { users },
      result,
      limit,
      count,
    } = action.payload;
    newState.mergeIn(['unconfirmedUsers'], fromJS(users));
    newState.setIn(['unconfirmedUsersIds'], fromJS(result));
    unconfirmedUsersPagination.set(newState, count, limit, newState.get('unconfirmedUsersIds'));
    unconfirmedUsersProgress.setLoaded(newState);
  });

const initialState = fromJS({
  entities: {},
  unconfirmedUsers: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
      return adminsProgress.setLoading(state);
    case constants.REMOVING_START:
      return adminRemovalProgress.setLoading(state);
    case constants.LOAD_ADMIN_START:
    case constants.EDIT_START:
      return adminProgress.setLoading(state);
    case constants.LOAD_SUCCESS:
      return loadAdmins(state, action);
    case constants.LOAD_ADMIN_SUCCESS:
      return loadAdmin(state, action);
    case constants.REMOVING_SUCCESS:
      return adminRemovalProgress.setLoaded(state);
    case constants.LOAD_FAILED:
    case constants.EDIT_FAILED:
      return adminsProgress.setLoaded(state);
    case constants.LOAD_ADMIN_FAILED:
      return adminProgress.setLoadFailed(state);
    case constants.EDIT_SUCCESS:
      return editAdmin(state, action);
    case constants.LOAD_INVITES_START:
      return adminsInvitesProgress.setLoading(state);
    case constants.LOAD_INVITES_FAILED:
      return adminsInvitesProgress.setLoadFailed(state);
    case constants.REMOVING_FAILED:
      return adminRemovalProgress.setLoadFailed(state);
    case constants.LOAD_INVITES_SUCCESS:
      return loadInvites(state, action);
    case constants.LOAD_UNCONFIRMED_USERS_START:
      return unconfirmedUsersProgress.setLoading(state);
    case constants.LOAD_UNCONFIRMED_USERS_FAILED:
      return unconfirmedUsersProgress.setLoadFailed(state);
    case constants.LOAD_UNCONFIRMED_USERS_SUCCESS:
      return loadUnconfirmedUsers(state, action);
    case constants.CONFIRM_REGISTER_START:
      return unconfirmedUserRegister.setLoading(state);
    case constants.CONFIRM_REGISTER_SUCCESS:
      return unconfirmedUserRegister.setLoaded(state);
    case constants.CONFIRM_REGISTER_FAILED:
      return unconfirmedUserRegister.setLoadFailed(state);

    default:
      return state;
  }
};
