import React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import cx from 'app/utils/helpers/cx';
import Typography from '../typography';

const cardStyles = cva('flex gap-6 rounded-[14px] px-4 py-1 border-2 border-solid cursor-pointer', {
  variants: {
    color: {
      white: 'bg-neutral-50 border-neutral-200 text-neutral-800',
      purple: 'bg-purple-700 border-purple-900 text-neutral-50',
      red: 'bg-red-500 border-red-600 text-neutral-800',
      orange: 'bg-orange-300 border-amber-600 text-neutral-800',
      blue: 'bg-blue-600 border-sky-800 text-neutral-50',
      yellow: 'bg-yellow-300 border-yellow-500 text-neutral-800',
    },
  },
  defaultVariants: {
    color: 'white',
  },
});

type CardVariantProps = VariantProps<typeof cardStyles>;

const getDigitsArray = (number: number) => {
  const arr = number.toString().split('');
  const separatorIndex = arr.indexOf('.');
  if (separatorIndex !== -1) {
    arr[separatorIndex + 1] = `,${arr[separatorIndex + 1]}`;
  }

  return arr.filter((el) => el !== '.');
};

export type NumberCardProps = {
  number: number;
  className?: string;
  classes?: {
    typography?: string;
  };
} & React.ComponentPropsWithRef<'div'> &
  CardVariantProps;

const NumberCard = React.forwardRef<HTMLDivElement, NumberCardProps>(function NumberCard(
  { number, color, className, classes, ...props },
  ref,
) {
  return (
    <div ref={ref} className={cx(cardStyles({ color }), className)} {...props}>
      {getDigitsArray(number).map((el, index) => (
        <Typography
          key={`${el}-${index + 1}`}
          component="span"
          weight="black"
          size="s"
          className={cx('text-inherit', classes?.typography)}
        >
          {el}
        </Typography>
      ))}
    </div>
  );
});

export default NumberCard;
