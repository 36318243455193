import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

export const GlobalErrorPage = () => {
  const { t } = useTranslation();

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <section
      className="flex min-h-safe-screen flex-col items-center justify-center bg-public-gradient px-4"
      data-sentry-breadcrumb="error-page"
    >
      <Paper elevation={3} className="flex min-h-[300px] min-w-[300px] max-w-xl flex-col gap-3 rounded-xl p-6">
        <div className="flex-1">
          <Typography className="text-xl">
            <Trans i18nKey="somethingWentWrong">
              Sorry, something went wrong.
              <br />
              Please refresh the page or try again later.
            </Trans>
          </Typography>
        </div>
        <div className="flex justify-center">
          <button
            onClick={reloadPage}
            type="button"
            className="mb-2 mr-2 rounded-full bg-primary-main px-5 py-2.5 text-sm font-medium text-white hover:bg-sky-600 focus:outline-none focus:ring-4  focus:ring-blue-600"
          >
            {t('refreshPage')}
          </button>
        </div>
      </Paper>
    </section>
  );
};
