import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import cx from 'app/utils/helpers/cx';
import { Button } from '@coach/ui';
import { Link } from 'react-router-dom';
import { invitesPath } from 'app/constants/url/shared';

export const CoachInvitesBanner = ({ hasActiveInvites }) => {
  const { t } = useTranslation();

  return (
    <div
      data-name="banner"
      className={cx(
        'relative mb-6 w-full rounded-b bg-primary-light px-10 py-2 shadow-md @container',
        !hasActiveInvites && 'hidden',
      )}
    >
      <div className="mx-auto flex flex-wrap items-center justify-center gap-x-4 gap-y-2 text-center @sm:justify-center @xl:justify-between md:justify-between md:gap-x-8 md:gap-y-2">
        <div className="balance w-full text-center @md:w-max lg:w-max">
          <Typography component="span" className="mr-1 text-base">
            {t('coachInvitesBanner')}
          </Typography>
        </div>
        <Button
          component={Link}
          to={invitesPath}
          color="blue"
          size="small"
          className="m-0 h-8 min-w-min flex-1 whitespace-nowrap text-sm @xl:flex-none"
        >
          {t('seeInvites')}
        </Button>
      </div>
    </div>
  );
};
