import { useMutation, type UseMutationOptions, useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { queryKeyBuilder } from 'data/utils/hookKeys';
import {
  type DidacticBoxWithPositionEntity,
  type DomainTypeWithTowerProgressesEntity,
  type FieldWithLearningLineProgresses,
  type FieldWithTowerProgressesEntity,
  type GradeDetailEntity,
  type IError,
  type KidLearningLinesPositionEntity,
  type KidPositionEntity,
  type LearningBoxWithPositionEntity,
  type LearningLineWithPositionEntity,
  type LearningQuestionBoxWithPositionEntity,
  type TowerBoxWithPositionEntity,
  type TowerDetailWithProgressesEntity,
  type TowerPositionBaseEntity,
  type TowerQuestionBoxWithPositionEntity,
} from 'data/types/entities';
import { type AxiosError } from 'axios';
import { type APIError, type ListEntity } from 'data/utils/types';
import { type QuestionHistoryState } from 'app/pages/kid/questions/[educationStrategy]/[id]/page';
import {
  fetchKidDidacticBoxWithPosition,
  fetchKidDomainWithTowerProgresses,
  fetchKidFieldsWithTowerProgresses,
  fetchKidGradeFieldsWithLearningLinesProgresses,
  fetchKidGradesWithLearningProgresses,
  fetchKidLearningBoxWithQuestionBoxesProgresses,
  fetchKidPositionByKid,
  fetchKidQuestionBoxWithLearningQuestionsAndPosition,
  fetchKidQuestionBoxWithTowerQuestionsAndPosition,
  fetchKidRecentLearningLinePosition,
  fetchKidTowerBoxWithQuestionBoxProgresses,
  fetchKidTowerPosition,
  fetchKidTowerWithTowerBoxesAndPosition,
  fetchLearningLineWithDidacticBoxesAndPosition,
  moveKidPositionToDomain,
} from 'data/api/progress';
import { type LearningLinePositionQuery } from 'data/validators/query-params/learning-line-position.query';

export const progressKeys = (() => {
  const base = 'progress';
  return {
    base: () => queryKeyBuilder(base),
    config: () => queryKeyBuilder(base, 'config'),
    position: (structureId: string, locationState?: QuestionHistoryState) =>
      queryKeyBuilder(base, 'position', structureId, locationState),
    next: (questionId: string, boxId: string) => queryKeyBuilder(base, 'position', 'next', questionId, boxId),
    kidFieldsWithTower: (kidId: string) => queryKeyBuilder(base, 'kidFieldsWithTower', kidId),
    kidDomainWithTower: (kidId: string, domainId: string) =>
      queryKeyBuilder(base, 'kidDomainWithTower', kidId, domainId),
    kidTowerBox: (kidId: string, towerBoxId: string) => queryKeyBuilder(base, 'kidTowerBox', kidId, towerBoxId),
    /**
     * educationStrategyBox is either 'learning box' or 'tower box'
     */
    kidQuestionBox: (kidId: string, educationStrategyBoxId: string, questionBoxId: string) =>
      queryKeyBuilder(base, 'kidQuestionBox', kidId, educationStrategyBoxId, questionBoxId),
    kidGradesWithLearning: (kidId: string) => queryKeyBuilder(base, 'kidGradesWithLearning', kidId),
    kidFieldsWithLearningLines: (kidId: string, gradeId: string) =>
      queryKeyBuilder(base, 'kidFieldsWithLearningLines', kidId, gradeId),
    kidDidacticBox: (kidId: string, didacticBoxId: string) =>
      queryKeyBuilder(base, 'kidDidacticBox', kidId, didacticBoxId),
    kidLearningBox: (kidId: string, learningBoxId: string) =>
      queryKeyBuilder(base, 'kidLearningBox', kidId, learningBoxId),
    validateTower: () => queryKeyBuilder(base, 'validateTower'),
    validateLearningLine: () => queryKeyBuilder(base, 'validateLearningLine'),
    kidPositionByDomain: () => queryKeyBuilder(base, 'kidPositionByDomain'),
    kidTowerWithTowerBoxProgresses: (kidId: string, towerId: string) =>
      queryKeyBuilder(base, 'kidTowerWithTowerBoxProgresses', kidId, towerId),
    kidLearningLineWithProgresses: (kidId: string, learningLineId: string) =>
      queryKeyBuilder(base, 'kidLearningLineWithProgresses', kidId, learningLineId),
    kidRecentLearningLinePosition: (kidId: string, params?: LearningLinePositionQuery) =>
      queryKeyBuilder(base, 'kidRecentLearningLinePosition', kidId, params),
    kidTowerPosition: (kidId: string, towerId: string) => queryKeyBuilder(base, 'kidTowerPosition', kidId, towerId),
  };
})();

export const useFetchKidPosition = () =>
  useQuery<KidPositionEntity, Error>(progressKeys.base(), () => fetchKidPositionByKid(), {});

export const useFetchKidTowerBoxWithQuestionBoxProgresses = (kidId: string, towerBoxId: string) =>
  useQuery<TowerBoxWithPositionEntity, AxiosError>(
    progressKeys.kidTowerBox(kidId, towerBoxId),
    () => fetchKidTowerBoxWithQuestionBoxProgresses(kidId, towerBoxId),
    {
      enabled: !!kidId && !!towerBoxId,
    },
  );

export const useFetchKidQuestionBoxWithTowerQuestionsAndPosition = (
  kidId: string,
  towerBoxId: string,
  questionBoxId: string,
) =>
  useQuery<TowerQuestionBoxWithPositionEntity, AxiosError>(
    progressKeys.kidQuestionBox(kidId, towerBoxId, questionBoxId),
    () => fetchKidQuestionBoxWithTowerQuestionsAndPosition(kidId, towerBoxId, questionBoxId),
    {
      enabled: !!kidId && !!towerBoxId && !!questionBoxId,
    },
  );

export const useFetchKidGradesWithLearningProgresses = (kidId: string) =>
  useQuery<ListEntity<GradeDetailEntity>, AxiosError>(
    progressKeys.kidGradesWithLearning(kidId),
    () => fetchKidGradesWithLearningProgresses(kidId),
    { enabled: !!kidId },
  );

export const useFetchKidGradeFieldsWithLearningLinesProgresses = (
  kidId: string,
  gradeId: string,
  options?: Omit<UseQueryOptions<ListEntity<FieldWithLearningLineProgresses>, AxiosError>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<ListEntity<FieldWithLearningLineProgresses>, AxiosError>(
    progressKeys.kidFieldsWithLearningLines(kidId, gradeId),
    () => fetchKidGradeFieldsWithLearningLinesProgresses(kidId, gradeId),
    {
      enabled: !!kidId && !!gradeId,
      ...options,
    },
  );

export const useFetchKidDidacticBoxWithPosition = (kidId: string, didacticBoxId: string) =>
  useQuery<DidacticBoxWithPositionEntity, AxiosError>(
    progressKeys.kidDidacticBox(kidId, didacticBoxId),
    () => fetchKidDidacticBoxWithPosition(kidId, didacticBoxId),
    { enabled: !!kidId && !!didacticBoxId },
  );

export const useFetchKidLearningBoxWithQuestionBoxesProgresses = (kidId: string, learningBoxId: string) =>
  useQuery<LearningBoxWithPositionEntity, AxiosError>(
    progressKeys.kidLearningBox(kidId, learningBoxId),
    () => fetchKidLearningBoxWithQuestionBoxesProgresses(kidId, learningBoxId),
    { enabled: !!kidId && !!learningBoxId },
  );

export const useFetchKidQuestionBoxWithLearningQuestionsAndPosition = (
  kidId: string,
  learningBoxId: string,
  questionBoxId: string,
) =>
  useQuery<LearningQuestionBoxWithPositionEntity, AxiosError>(
    progressKeys.kidQuestionBox(kidId, learningBoxId, questionBoxId),
    () => fetchKidQuestionBoxWithLearningQuestionsAndPosition(kidId, learningBoxId, questionBoxId),
    {
      refetchOnWindowFocus: false,
      enabled: !!kidId && !!learningBoxId && !!questionBoxId,
    },
  );

export const useFetchKidFieldsWithTowerProgresses = (kidId: string) =>
  useQuery<ListEntity<FieldWithTowerProgressesEntity>, AxiosError>(
    progressKeys.kidFieldsWithTower(kidId),
    () => fetchKidFieldsWithTowerProgresses(kidId),
    {
      refetchOnWindowFocus: false,
      enabled: !!kidId,
    },
  );

export const useFetchKidDomainWithTowerProgresses = (kidId: string, domainId: string) =>
  useQuery<DomainTypeWithTowerProgressesEntity, AxiosError>(
    progressKeys.kidDomainWithTower(kidId, domainId),
    () => fetchKidDomainWithTowerProgresses(kidId, domainId),
    {
      refetchOnWindowFocus: false,
      enabled: !!kidId && !!domainId,
    },
  );

type MoveKidToDomainParams = {
  kidId: string;
  domainId: string;
};

type UseMoveKidPositionToDomainOptions = Omit<
  UseMutationOptions<KidPositionEntity, AxiosError<APIError>, MoveKidToDomainParams>,
  'mutationKey' | 'mutationFn'
>;

export const useMoveKidPositionToDomain = (options?: UseMoveKidPositionToDomainOptions) =>
  useMutation({
    mutationKey: progressKeys.kidPositionByDomain(),
    mutationFn: ({ kidId, domainId }: MoveKidToDomainParams) => moveKidPositionToDomain(kidId, domainId),
    ...options,
  });

export const useFetchKidLearningLineWithDidacticBoxesAndPosition = (kidId: string, learningLineId: string) =>
  useQuery<LearningLineWithPositionEntity, Error>(
    progressKeys.kidLearningLineWithProgresses(kidId, learningLineId),
    () => fetchLearningLineWithDidacticBoxesAndPosition(kidId, learningLineId),
    {
      keepPreviousData: true,
      enabled: !!kidId && !!learningLineId,
    },
  );

export const useFetchKidRecentLearningLinePosition = (
  kidId: string,
  params?: LearningLinePositionQuery,
  options?: Omit<UseQueryOptions<KidLearningLinesPositionEntity, AxiosError<IError>>, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    queryKey: progressKeys.kidRecentLearningLinePosition(kidId),
    queryFn: () => fetchKidRecentLearningLinePosition(kidId, params),
    ...options,
  });

export const useFetchKidTowerWithTowerBoxProgressesByKid = (
  kidId: string,
  towerId: string,
  options?: Omit<UseQueryOptions<TowerDetailWithProgressesEntity, AxiosError<IError>>, 'queryKey' | 'queryFn'>,
) =>
  useQuery(
    progressKeys.kidTowerWithTowerBoxProgresses(kidId, towerId),
    () => fetchKidTowerWithTowerBoxesAndPosition(kidId, towerId),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!kidId && !!towerId,
      ...options,
    },
  );

export function useFetchKidTowerPosition(
  kidId: string,
  towerId: string,
  options?: Omit<UseQueryOptions<TowerPositionBaseEntity, AxiosError<IError>>, 'queryFn' | 'queryKey'>,
) {
  return useQuery({
    queryKey: progressKeys.kidTowerPosition(kidId, towerId),
    queryFn: () => fetchKidTowerPosition(kidId, towerId),
    ...options,
  });
}
