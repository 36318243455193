import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Text } from '@coach/ui';
import { ROLE } from 'data/enums';
import { featuresPath } from 'app/constants/url/admin';
import { baseRolePaths, configurationsPath, signOutPath } from 'app/constants/url/shared';
import { useTokenRefresh } from 'data/hooks';
import { useClickAway } from 'react-use';
import styled from 'styled-components';
import styles from './styles.module.css';

interface HeaderDropdownProps {
  roles: ROLE[];
  toggleState: () => void;
  currentRole: ROLE;
  profilePath: string;
  invitesPath: string;
  loggedInAsSupport: boolean;
  showBadge: boolean;
}

const NotificationDot = styled.span`
  position: absolute;
  display: block;
  right: -12px;
  top: 0;
  height: 8px;
  width: 8px;
  border-radius: 50%;
`;

export const HeaderDropdown = ({
  toggleState,
  roles,
  currentRole,
  profilePath,
  invitesPath,
  loggedInAsSupport,
  showBadge,
}: HeaderDropdownProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dropdownRef = useRef(null);

  const { mutate: refreshAccessToken } = useTokenRefresh();

  useClickAway(dropdownRef, () => {
    toggleState();
  });

  const changeRole = (newRole: ROLE) => {
    refreshAccessToken(
      { role: newRole },
      {
        onSuccess: () => {
          history.push(baseRolePaths[newRole]);
        },
      },
    );
  };

  return (
    <div ref={dropdownRef} className={styles.dropdown}>
      <Link to={profilePath} onClick={toggleState} className={styles.menuItem}>
        <Text color="black">{t('profile')}</Text>
      </Link>

      <Link to={invitesPath} onClick={toggleState} className={styles.menuItem}>
        <Text color="black" className="relative w-fit" title={t('invites')}>
          {t('invites')}
          {showBadge && <NotificationDot className="bg-error-main" />}
        </Text>
      </Link>

      {!loggedInAsSupport && (
        <>
          {currentRole === ROLE.ADMIN && (
            <>
              <Link to={featuresPath} className={styles.menuItem}>
                <Text color="black">{t('features')}</Text>
              </Link>
              <Link to={configurationsPath} className={styles.menuItem}>
                <Text color="black">{t('configurations')}</Text>
              </Link>
            </>
          )}
          {roles?.length > 1 &&
            roles.map((role) => {
              if (role === currentRole) return null;
              return (
                <button key={`${role}-role`} type="button" onClick={() => changeRole(role)} className={styles.menuItem}>
                  <Text color="black">
                    {t(role)} {t('dashboard').toLowerCase()}
                  </Text>
                </button>
              );
            })}
          <Link to={signOutPath} className={styles.menuItem}>
            <Text color="pink">{t('logOut')}</Text>
          </Link>
        </>
      )}
    </div>
  );
};
