import { fromJS, Map } from 'immutable';
import * as constants from 'data/constants/towers';
import LoadingProgress from 'data/utils/reducers/loading';

export const towerProgress = new LoadingProgress('towerProgress');
export const towerEditingProgress = new LoadingProgress('towerEditingProgress');
export const towerRemovalProgress = new LoadingProgress('towerRemovalProgress');
export const towerBoxProgress = new LoadingProgress('towerBoxProgress');
export const towerBoxRemovalProgress = new LoadingProgress('towerBoxRemovalProgress');
export const towerBoxesProgress = new LoadingProgress('towerBoxesProgress');
export const kidDomainProgress = new LoadingProgress('kidDomainProgress');
export const kidTowerBoxProgress = new LoadingProgress('kidTowerBoxProgress');
export const towerBoxMoveChildProgress = new LoadingProgress('towerBoxMoveChildProgress');
export const towerMoveChildProgress = new LoadingProgress('towerMoveChildProgress');

const createMatrix = (data) => {
  const { towerColumns, towerRows } = data;
  const towerRowsSortedFromHighestToLowest = towerRows?.sort((a, b) => b.position - a.position);
  const columnsLength = towerColumns.length;
  const rowsLength = towerRowsSortedFromHighestToLowest.length;
  const towerMatrix = Array(rowsLength)
    // @ts-expect-error TS(2554): Expected 1-3 arguments, but got 0.
    .fill()
    .map(() => Array(columnsLength).fill(null));
  towerRowsSortedFromHighestToLowest.forEach((towerRow, towerRowIndex) => {
    const towerRowId = towerRow.id;
    const { towerBoxes } = towerRow;
    if (towerBoxes.length === 0) {
      towerColumns.forEach((towerColumn, towerColIndex) => {
        const towerColumnId = towerColumn.id;
        towerMatrix[towerRowIndex][towerColIndex] = { towerRowId, towerColumnId };
      });
    }
    towerBoxes.forEach((towerBoxInRow) => {
      const towerBoxInRowId = towerBoxInRow.id;
      towerColumns.forEach((towerColumn, towerColumnPosition) => {
        const towerColumnId = towerColumn.id;
        const towerBoxesFromColumn = towerColumn.towerBoxes;
        if (towerBoxesFromColumn.length === 0) {
          towerRowsSortedFromHighestToLowest.forEach((towerItem, towerItemIndex) => {
            const towerItemRowId = towerItem.id;
            towerMatrix[towerItemIndex][towerColumnPosition] = { towerRowId: towerItemRowId, towerColumnId };
          });
        }
        towerBoxesFromColumn.forEach((towerBoxInColumn) => {
          if (towerBoxInColumn.id === towerBoxInRowId) {
            towerMatrix[towerRowIndex][towerColumnPosition] = towerBoxInRow;
          } else if (towerMatrix[towerRowIndex][towerColumnPosition] === null) {
            towerMatrix[towerRowIndex][towerColumnPosition] = { towerRowId, towerColumnId };
          }
        });
      });
    });
  });
  return towerMatrix;
};

const loadTower = (state, action) =>
  state.withMutations((newState) => {
    const { data, towerId } = action.payload;
    const towerMatrix = createMatrix(data);
    const towerArray = [];
    towerMatrix.forEach((matrixRow) => {
      matrixRow.forEach((towerBox) => {
        // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        if (towerBox.id) towerArray.push(towerBox);
      });
    });
    newState.mergeIn(['entities', towerId], fromJS(data));
    newState.setIn(['entities', towerId, 'towerMatrix'], fromJS(towerMatrix));
    newState.setIn(['entities', towerId, 'towerArray'], fromJS(towerArray));
    towerProgress.setLoaded(newState);
  });

const loadTowerByKid = (state, action) =>
  state.withMutations((newState) => {
    const { data, towerId, kidDefaultGrade } = action.payload;
    const { towerColumns, towerRows } = data;
    const setOfTowerBoxesOverDefaultGrade = new Set();

    const defaultGradePosition =
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      Map.isMap(kidDefaultGrade) && !Number.isNaN(+kidDefaultGrade.get('position'))
        ? kidDefaultGrade.get('position')
        : 0;

    const towerColumnsFilteredByKidDefaultGrade = towerColumns.filter((column) => {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (column.grade?.position <= defaultGradePosition + 1) return true;
      const { towerBoxes } = column;
      towerBoxes.forEach((box) => setOfTowerBoxesOverDefaultGrade.add(box.id));
      return false;
    });
    const towerRowsFilteredByKidDefaultGrade = towerRows.filter((row) => {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (row.grade?.position <= defaultGradePosition + 1) return true;
      const { towerBoxes } = row;
      towerBoxes.forEach((box) => setOfTowerBoxesOverDefaultGrade.add(box.id));
      return false;
    });
    const towerColumnsWithFilteredTowerBoxes = towerColumnsFilteredByKidDefaultGrade
      .map((column) => ({
        ...column,
        towerBoxes: column.towerBoxes.filter((box) => !setOfTowerBoxesOverDefaultGrade.has(box.id)),
      }))
      .filter((column) => column.towerBoxes.length > 0);
    const towerRowsWithFilteredTowerBoxes = towerRowsFilteredByKidDefaultGrade
      .map((row) => ({
        ...row,
        towerBoxes: row.towerBoxes.filter((box) => !setOfTowerBoxesOverDefaultGrade.has(box.id)),
      }))
      .filter((row) => row.towerBoxes.length > 0);
    const towerMatrix = createMatrix({
      towerColumns: towerColumnsWithFilteredTowerBoxes,
      towerRows: towerRowsWithFilteredTowerBoxes,
    });
    const towerArray = [];
    towerMatrix.forEach((matrixRow) => {
      matrixRow.forEach((towerBox) => {
        // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        if (towerBox.id) towerArray.push(towerBox);
      });
    });
    newState.mergeIn(
      ['entities', towerId],
      fromJS({ ...data, towerColumns: towerColumnsWithFilteredTowerBoxes, towerRows: towerRowsWithFilteredTowerBoxes }),
    );
    newState.setIn(['entities', towerId, 'towerMatrix'], fromJS(towerMatrix));
    newState.setIn(['entities', towerId, 'towerArray'], fromJS(towerArray));
    towerProgress.setLoaded(newState);
  });

const loadTowerBox = (state, action) =>
  state.withMutations((newState) => {
    const { data, towerBoxId } = action.payload;
    newState.mergeIn(['towerBoxes', towerBoxId], fromJS(data));
    towerBoxProgress.setLoaded(newState);
  });

const editTowerBox = (state, action) =>
  state.withMutations((newState) => {
    const { id } = action.payload;
    newState.mergeIn(['towerBoxes', id], fromJS(action.payload));
  });

const uploadImage = (state, action) =>
  state.withMutations((newState) => {
    const { towerId, data } = action.payload;
    newState.mergeIn(['entities', towerId], fromJS(data));
  });

const deleteTowerBox = (state, action) =>
  state.withMutations((newState) => {
    const { towerBoxId } = action.payload;
    newState.deleteIn(['towerBoxes', towerBoxId]);
    towerBoxProgress.clear(newState);
    towerBoxRemovalProgress.setLoaded(newState);
  });

const editTower = (state, action) =>
  state.withMutations((newState) => {
    const { id } = action.payload;
    newState.mergeIn(['entities', id], fromJS(action.payload));
    towerEditingProgress.setLoaded(newState);
  });

const deleteTower = (state, action) =>
  state.withMutations((newState) => {
    const { towerId } = action;
    newState.deleteIn(['entities', towerId]);
    towerProgress.clear(newState);
    towerRemovalProgress.setLoaded(newState);
  });

const clearState = (state) =>
  state.withMutations((newState) => {
    towerProgress.clear(newState);
    towerRemovalProgress.clear(newState);
  });
const loadTowerBoxes = (state, action) =>
  state.withMutations((newState) => {
    const { payload } = action;
    newState.setIn(['towerBoxesItems'], fromJS(payload));
    towerBoxesProgress.setLoaded(newState);
  });

const loadKidDomain = (state, action) =>
  state.withMutations((newState) => {
    const { data } = action.payload;
    newState.set('kidDomainWithTowerProgresses', fromJS(data));
    kidDomainProgress.setLoaded(newState);
  });
const loadKidTowerBox = (state, action) =>
  state.withMutations((newState) => {
    const { data } = action.payload;
    newState.set('kidTowerBoxWithQuestionBoxProgresses', fromJS(data));
    kidTowerBoxProgress.setLoaded(newState);
  });

const initialState = fromJS({
  towers: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_TOWER_START:
    case constants.CREATE_TOWER_START:
    case constants.FETCH_KID_TOWER_START:
    case constants.FETCH_TOWER_BY_KID_START:
    case constants.UPDATE_TOWER_ROW_POSITION_START:
    case constants.UPDATE_TOWER_COLUMN_POSITION_START:
      return towerProgress.setLoading(state);
    case constants.EDITING_TOWER_START:
      return towerEditingProgress.setLoading(state);
    case constants.FETCH_TOWER_BOX_START:
      return towerBoxProgress.setLoading(state);
    case constants.REMOVING_TOWER_START:
      return towerRemovalProgress.setLoading(state);
    case constants.REMOVING_TOWER_BOX_START:
      return towerBoxRemovalProgress.setLoading(state);
    case constants.FETCH_TOWER_SUCCESS:
    case constants.UPDATE_TOWER_DIMENSION_SUCCESS:
    case constants.CREATE_TOWER_DIMENSION_SUCCESS:
    case constants.DELETE_TOWER_DIMENSION_SUCCESS:
    case constants.CREATE_TOWER_BOX_SUCCESS:
    case constants.FETCH_KID_TOWER_SUCCESS:
    case constants.UPDATE_TOWER_ROW_POSITION_SUCCESS:
    case constants.UPDATE_TOWER_COLUMN_POSITION_SUCCESS:
      return loadTower(state, action);
    case constants.FETCH_TOWER_BY_KID_SUCCESS:
      return loadTowerByKid(state, action);
    case constants.LOAD_TOWER_BOXES_START:
      return towerBoxesProgress.setLoading(state);
    case constants.LOAD_TOWER_BOXES_SUCCESS:
      return loadTowerBoxes(state, action);
    case constants.LOAD_TOWER_BOXES_FAILED:
      return towerBoxesProgress.setLoadFailed(state);
    case constants.FETCH_TOWER_BOX_SUCCESS:
      return loadTowerBox(state, action);
    case constants.CREATE_TOWER_SUCCESS:
      return towerProgress.setLoaded(state);
    case constants.REMOVING_TOWER_SUCCESS:
      return deleteTower(state, action);
    case constants.REMOVING_TOWER_BOX_SUCCESS:
      return deleteTowerBox(state, action);
    case constants.EDITING_TOWER_SUCCESS:
      return editTower(state, action);
    case constants.EDITING_TOWER_BOX_SUCCESS:
      return editTowerBox(state, action);
    case constants.UPLOAD_PHOTO_SUCCESS:
      return uploadImage(state, action);
    case constants.FETCH_TOWER_FAILED:
    case constants.CREATE_TOWER_FAILED:
    case constants.FETCH_KID_TOWER_FAILED:
    case constants.UPDATE_TOWER_ROW_POSITION_FAILED:
    case constants.UPDATE_TOWER_COLUMN_POSITION_FAILED:
    case constants.FETCH_TOWER_BY_KID_FAILED:
      return towerProgress.setLoadFailed(state);
    case constants.EDITING_TOWER_FAILED:
      return towerEditingProgress.setLoadFailed(state);
    case constants.FETCH_TOWER_BOX_FAILED:
      return towerBoxProgress.setLoadFailed(state);
    case constants.REMOVING_TOWER_FAILED:
      return towerRemovalProgress.setLoadFailed(state);
    case constants.REMOVING_TOWER_BOX_FAILED:
      return towerBoxRemovalProgress.setLoadFailed(state);
    case constants.FETCH_KID_DOMAIN_START:
      return kidDomainProgress.setLoading(state);
    case constants.FETCH_KID_DOMAIN_SUCCESS:
      return loadKidDomain(state, action);
    case constants.FETCH_KID_DOMAIN_FAILED:
      return kidDomainProgress.setLoadFailed(state);
    case constants.FETCH_KID_TOWER_BOX_START:
      return kidTowerBoxProgress.setLoading(state);
    case constants.FETCH_KID_TOWER_BOX_SUCCESS:
      return loadKidTowerBox(state, action);
    case constants.FETCH_KID_TOWER_BOX_FAILED:
      return kidTowerBoxProgress.setLoadFailed(state);
    case constants.MOVE_CHILD_TO_TOWER_BOX_START:
      return towerBoxMoveChildProgress.setLoading(state);
    case constants.MOVE_CHILD_TO_TOWER_BOX_SUCCESS:
      return towerBoxMoveChildProgress.setLoaded(state);
    case constants.MOVE_CHILD_TO_TOWER_BOX_FAILED:
      return towerBoxMoveChildProgress.setLoadFailed(state);
    case constants.MOVE_CHILD_TO_TOWER_START:
      return towerMoveChildProgress.setLoading(state);
    case constants.MOVE_CHILD_TO_TOWER_SUCCESS:
      return towerMoveChildProgress.setLoaded(state);
    case constants.MOVE_CHILD_TO_TOWER_FAILED:
      return towerMoveChildProgress.setLoadFailed(state);
    case constants.CLEAR_STATE:
      return clearState(state);
    default:
      return state;
  }
};
