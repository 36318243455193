import React from 'react';
import { type ROLE } from 'data/enums';
import { Redirect, Route, type RouteProps, useLocation } from 'react-router-dom';
import { useHasAccess } from 'app/utils/hooks/useHasAccess';
import { commonUrlParams, signInPaths } from 'app/constants/url/shared';

interface ProtectedRouteTypes extends RouteProps {
  allowedRoles: ROLE[];
  redirectPath?: string;
}

const ProtectedRoute = ({ allowedRoles, redirectPath = signInPaths.index, ...props }: ProtectedRouteTypes) => {
  const location = useLocation();
  const hasAccess = useHasAccess(allowedRoles);

  if (hasAccess) {
    return <Route {...props} />;
  }

  return (
    <Redirect
      to={{
        pathname: redirectPath,
        search: new URLSearchParams({ [commonUrlParams.redirect]: location.pathname }).toString(),
      }}
    />
  );
};

export default ProtectedRoute;
