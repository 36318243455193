import React from 'react';
import { ErrorService } from 'app/utils/services/error-tracking';
import { Unauthorized } from 'data/utils/errors/Unauthorized';
import { NoActiveGroupError } from 'app/utils/error/NoActiveGroupError';

const EXCLUDED_ERRORS = [Unauthorized, NoActiveGroupError];

type Props = {
  Component: React.ComponentType;
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: unknown) {
    if (EXCLUDED_ERRORS.some((e) => error instanceof e)) {
      return { hasError: false };
    }
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo) {
    if (EXCLUDED_ERRORS.some((excludedError) => error instanceof excludedError)) {
      throw error;
    }
    ErrorService.captureException(error, {
      contexts: {
        react: {
          componentStack: errorInfo.componentStack,
        },
      },
    });
  }

  render() {
    const { hasError } = this.state;
    const { children, Component } = this.props;
    if (hasError) {
      return <Component />;
    }

    return children;
  }
}
