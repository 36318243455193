import { QueryCache, QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import CookieService from 'app/utils/services/cookies';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'app/constants/store';
import { jwtDecode } from 'jwt-decode';
import { type TokenData } from 'data/types';
import { refreshAccessToken } from 'data/api';
import { isRefreshURL } from 'data/axios/http-client';
import { ErrorService } from 'app/utils/services/error-tracking';
import { logout } from 'data/utils/helpers/logout/logout';
import { isMaintenanceMode } from 'data/utils/helpers/api';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: AxiosError, query) => {
      if (!(error instanceof AxiosError)) return;

      const statusCode = error.response?.status;

      if (statusCode === 401) {
        const accessToken = CookieService.get(ACCESS_TOKEN_KEY);
        const refreshToken = CookieService.get(REFRESH_TOKEN_KEY);

        if (typeof accessToken !== 'string' || !refreshToken) {
          logout();
          return;
        }
        try {
          const { role } = jwtDecode<TokenData>(accessToken);
          refreshAccessToken({
            refreshToken,
            role,
          }).then(() => {
            query.invalidate();
          });
        } catch (_: unknown) {
          logout();
        }
      }
      const isRefreshTokenInvalid = statusCode === 404 && isRefreshURL(error.request.responseURL);
      if (isRefreshTokenInvalid) {
        logout();
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: (failureCount: number, error: AxiosError) => {
        if (error.response?.status === 401) {
          return false;
        }

        if (isMaintenanceMode(error)) {
          return false;
        }
        return (!error?.response?.status || error?.response?.status >= 500) && failureCount <= 3;
      },
      useErrorBoundary: (error: AxiosError) => {
        if (isMaintenanceMode(error)) {
          return false;
        }

        return (
          (!error?.response?.status && !error.message.match(/cancel/)) ||
          error?.response?.status === 404 ||
          Number(error?.response?.status) >= 500
        );
      },
      onError: (error: AxiosError) => {
        if (isMaintenanceMode(error)) {
          return;
        }

        if ([400, 403, 404].includes(Number(error?.response?.status)) || Number(error?.response?.status) >= 500) {
          ErrorService.captureException(error, {
            extra: {
              stack: error.stack,
            },
          });
        }
      },
    },
  },
});
