import { z } from 'zod';
import { match } from 'ts-pattern';
import { pipe } from 'effect';
import * as E from 'effect/Either';
import { decodeJSON, isArrayBuffer, isString } from './util.js';

const MessageSchema = z.object({
  type: z.string(),
  data: z.unknown().optional(),
});

export type Message = z.infer<typeof MessageSchema>;

export function decodeMessage(data: string | ArrayBuffer): E.Either<Message, Error> {
  const text = match(data)
    .when(isString, (x) => x)
    .when(isArrayBuffer, (x) => new TextDecoder().decode(x))
    .exhaustive();

  return pipe(text, (str) => decodeJSON(str, MessageSchema));
}

export class MessagePubSub extends EventTarget {
  public handleMessage(event: MessageEvent) {
    const result = decodeMessage(event.data);
    if (E.isLeft(result)) {
      return;
    }

    const message = result.right;

    this.dispatchEvent(
      new MessageEvent(message.type, {
        data: message.data,
      }),
    );
  }

  public addEventListener(
    type: string,
    callback: (event: MessageEvent) => void,
    options?: AddEventListenerOptions | boolean,
  ) {
    super.addEventListener(type, callback, options);
  }
}
