import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Chip from '@mui/material/Chip';
import { useChipStyles } from './styles';

const ChipPropTypes = {
  className: PropTypes.string,
  /**
   * @type {React.EventHandler}
   */
  onDelete: PropTypes.func,
};

export const ChipComponent = ({ className, onDelete, ...props }) => {
  const s = useChipStyles();

  return (
    <Chip
      variant="outlined"
      onDelete={onDelete}
      label="Basic Chip"
      size="medium"
      className={onDelete ? cx(s.root, className) : cx(s.chipWithoutDelete, className)}
      {...props}
    />
  );
};

ChipComponent.defaultProps = {
  className: null,
};

ChipComponent.propTypes = ChipPropTypes;
