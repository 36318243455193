/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { type IError, type JumpConfiguration, type PositionContext } from 'data/types/entities';
import { progressKeys } from 'data/hooks/queries/progresses';
import { useEffect } from 'react';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import * as api from 'data/api';
import {
  useAnswerCountState,
  useFirstRendered,
  useJump,
  useJumpState,
  useProgressStoreActions,
  useValidationState,
} from 'data/stores/progress-store';
import { type AxiosError } from 'axios';
import { getChosenPosition } from './helpers';
import { useTrackTime } from '../utils';

export function useJumpConfiguration(
  options?: Omit<UseQueryOptions<JumpConfiguration, AxiosError<IError>>, 'queryFn' | 'queryKey'>,
) {
  return useQuery({
    queryKey: progressKeys.config(),
    queryFn: () => api.fetchJumpConfiguration(),
    refetchOnWindowFocus: false,
    ...options,
  });
}

interface UseGeneralProgressEffectsArgs {
  enabled: boolean;
  nextContext?: PositionContext;
  isValidating: boolean;
  isValidateSuccess: boolean;
  jumpConfig?: JumpConfiguration;
  isPositionLoading: boolean;
}

export const useGeneralProgressEffects = ({
  enabled,
  nextContext,
  isValidating,
  isValidateSuccess,
  jumpConfig,
  isPositionLoading,
}: UseGeneralProgressEffectsArgs) => {
  const {
    setValidationState,
    setJump,
    setFirstRendered,
    setFirstValidated,
    setPosition,
    setNextPosition,
    setAnswerCountState,
    setJumpState,
  } = useProgressStoreActions();
  const validationState = useValidationState();
  const jump = useJump();
  const firstRendered = useFirstRendered();
  const answerCountState = useAnswerCountState();
  const jumpState = useJumpState();

  useTrackTime([nextContext, validationState], enabled);

  // Update answerCountState based on validationState
  useEffect(() => {
    if (!validationState || !enabled) return;
    setAnswerCountState((state) => ({
      ...state,
      ...(validationState.valid
        ? {
            consecutiveCorrectCount: state.consecutiveCorrectCount + 1,
            consecutiveIncorrectCount: 0,
            correctAnswerCount: state.correctAnswerCount + 1,
          }
        : {
            consecutiveIncorrectCount: state.consecutiveIncorrectCount + 1,
            consecutiveCorrectCount: 0,
            incorrectAnswerCount: state.incorrectAnswerCount + 1,
          }),
    }));
  }, [validationState]);

  // Load initial position
  useEffect(() => {
    if (firstRendered || !nextContext || !enabled || isPositionLoading) return;
    const { position: chosenPos } = getChosenPosition({
      nextContext,
      valid: validationState?.valid && !isValidating,
      answerCountState,
      jumpConfig,
      setAnswerCountState,
      isFirstRender: true,
    });
    setPosition(chosenPos);
    setFirstRendered(true);
  }, [nextContext, firstRendered]);

  // Update position based on validationState and create jumpState
  useEffect(() => {
    if (!validationState || !enabled) return;
    const {
      position: chosenPos,
      jump: chosenJump,
      positionChanges,
    } = getChosenPosition({
      nextContext,
      valid: validationState?.valid && !isValidating,
      answerCountState,
      jumpConfig,
      setAnswerCountState,
    });
    if (chosenPos) setNextPosition(chosenPos);
    setJump(chosenJump || 0);
    if (positionChanges) setJumpState(positionChanges);
    setValidationState(undefined);
    setFirstValidated(true);
  }, [isValidateSuccess]);

  // Update answerCountState based on jumpState
  useEffect(() => {
    // Reset answerCountState
    if ((jump || jumpState?.lBoxChanged || jumpState?.tBoxChanged) && enabled)
      setAnswerCountState((state) => ({
        ...state,
        consecutiveCorrectCount: 0,
        consecutiveIncorrectCount: 0,
        firstTimeRightCount: 0,
        timeSpentLastQuestions: 0,
        qBoxCount: 1,
      }));
    // Add to qBoxCount
    if (jumpState?.qBoxChanged && !jumpState?.lBoxChanged && !jumpState?.dBoxChanged)
      setAnswerCountState((state) => ({ ...state, qBoxCount: state.qBoxCount + 1 }));
    // If jump was negative, set qBoxCount to minimum required so it jumps forward faster
    if (jump && jump < 0 && jumpConfig)
      setAnswerCountState((state) => ({ ...state, qBoxCount: jumpConfig.minimumAmountOfQuestionBoxes }));
  }, [jump, jumpState]);
};
