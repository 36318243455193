class Pagination {
  constructor(name) {
    // @ts-expect-error TS(2339): Property 'totalItemsKey' does not exist on type 'P... Remove this comment to see the full error message
    this.totalItemsKey = `${name}TotalItems`;
    // @ts-expect-error TS(2339): Property 'pageItemsKey' does not exist on type 'Pa... Remove this comment to see the full error message
    this.pageItemsKey = `${name}PageItems`;
  }

  // @ts-expect-error TS(2339): Property 'totalItemsKey' does not exist on type 'P... Remove this comment to see the full error message
  setTotalItems = (state, value) => state.set(this.totalItemsKey, value);

  // @ts-expect-error TS(2339): Property 'totalItemsKey' does not exist on type 'P... Remove this comment to see the full error message
  getTotalItems = (state) => state.get(this.totalItemsKey);

  // @ts-expect-error TS(2339): Property 'pageItemsKey' does not exist on type 'Pa... Remove this comment to see the full error message
  setPageItems = (state, value) => state.set(this.pageItemsKey, value);

  // @ts-expect-error TS(2339): Property 'pageItemsKey' does not exist on type 'Pa... Remove this comment to see the full error message
  getPageItems = (state) => state.get(this.pageItemsKey);

  set = (state, totalItems, itemsPerPage, pageItems) =>
    state.withMutations((myState) => {
      this.setTotalItems(myState, totalItems);
      this.setPageItems(myState, pageItems);
    });

  clear = (state) =>
    state.withMutations((myState) => {
      // @ts-expect-error TS(2339): Property 'totalItemsKey' does not exist on type 'P... Remove this comment to see the full error message
      myState.delete(this.totalItemsKey);
      // myState.delete(this.itemsPerPageKey);
      // @ts-expect-error TS(2339): Property 'pageItemsKey' does not exist on type 'Pa... Remove this comment to see the full error message
      myState.delete(this.pageItemsKey);
    });
}

export default Pagination;
