import React from 'react';
import Card, { type CardProps } from '@mui/material/Card';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { BUTTON_COLORS, type Icons, icons } from '@coach/ui';
import styled from 'styled-components';
import { Text } from '@coach/ui/components/text';
import { IconButtonComponent as IconButton } from '../button';
import { useDragCardComponentStyles, useSwapCardComponentStyles, useTowerDimensionCardComponentStyles } from './styles';
import { type TextColor } from '../text/styles';

const DragCardStyled = styled(Card)`
  margin-top: 20px;
  padding: 20px;
  position: relative;
  width: 260px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgb(142 142 142 / 20%);
  &:hover {
    button {
      visibility: visible !important;
    }
  }
`;

interface DragCardComponentProps extends Omit<CardProps, 'onClick'> {
  className: string;
  icon?: keyof Icons;
  text: string;
  titleText: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  textColor?: TextColor;
  playNext?: boolean;
  editable?: boolean;
  onEditClick?: React.MouseEventHandler<HTMLButtonElement>;
  progressIcon?: string;
  showInfoIcon?: boolean;
  hideDragIcon?: boolean;
  onCardClick?: React.MouseEventHandler<HTMLDivElement>;
  onInfoClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const DragCardComponent = ({
  className,
  icon,
  titleText,
  onClick,
  onCardClick = () => undefined,
  onInfoClick = () => undefined,
  text,
  textColor,
  playNext,
  editable,
  onEditClick,
  progressIcon,
  showInfoIcon,
  hideDragIcon,
  ...props
}: DragCardComponentProps) => {
  const s = useDragCardComponentStyles();

  return (
    <DragCardStyled className={cx(className)} onClick={onCardClick} {...props}>
      {!hideDragIcon && icon && <IconButton className={s.leftIcon} color={BUTTON_COLORS.BLUE} icon={icon} />}

      <div className={s.text}>
        <Text color={textColor} size={16} className={s.title} title={titleText}>
          {titleText}
        </Text>
        <Text color="greyLight" size={12}>
          {text}
        </Text>
      </div>

      <div className={s.rightIcons}>
        {editable && <IconButton className="invisible" color={BUTTON_COLORS.BLUE} icon="edit" onClick={onEditClick} />}

        {showInfoIcon && (
          <IconButton className="invisible p-1.5" color={BUTTON_COLORS.BLUE} icon="info" onClick={onInfoClick} />
        )}

        {playNext && (
          <IconButton className="invisible p-1.5" color={BUTTON_COLORS.BLUE} icon="playOutlined" onClick={onClick} />
        )}

        {progressIcon && <div className="flex p-1.5 text-grey-main">{icons[progressIcon]}</div>}
      </div>
    </DragCardStyled>
  );
};

const towerDimensionCardComponent = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

export const TowerDimensionCardComponent = ({ text, className, children, ...props }) => {
  const s = useTowerDimensionCardComponentStyles();
  return (
    <Card className={cx(className, s.root)} {...props}>
      {children}
    </Card>
  );
};

TowerDimensionCardComponent.propTypes = towerDimensionCardComponent;
TowerDimensionCardComponent.defaultProps = {
  className: null,
  children: null,
};

const SwapCardComponentPropTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  titleText: PropTypes.string,
  onClick: PropTypes.func,
  textColor: PropTypes.string,
};

export const SwapCardComponent = ({ className, titleText, onClick, text, textColor, ...props }) => {
  const s = useSwapCardComponentStyles();

  return (
    <Card className={cx(className, s.root)} {...props}>
      <div className={s.text}>
        <Text color={textColor} size={16} className={cx(s.title, s.shorterTitle)} title={titleText}>
          {titleText}
        </Text>
        <Text color="greyLight" size={12}>
          {text}
        </Text>
      </div>
      <div className={s.hover}>
        <IconButton className={s.edit} color={BUTTON_COLORS.BLUE} icon="swap" onClick={onClick} />
      </div>
    </Card>
  );
};

SwapCardComponent.propTypes = SwapCardComponentPropTypes;
SwapCardComponent.defaultProps = {
  className: null,
  text: null,
  titleText: null,
  textColor: 'black',
  onClick: null,
};
