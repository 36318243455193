import React, { useEffect } from 'react';
import { versionManager } from 'app/utils/version-manager';
import { useIdleTimer } from 'react-idle-timer';
import { MILLISECONDS, ROLE_NAME } from 'app/constants/data';
import { type Location } from 'history';
import { useLocation } from 'react-router-dom';
import { featureKeys, useFetchFeatureFlags } from 'data/hooks/queries/features';
import { ErrorService } from 'app/utils/services/error-tracking';
import { RuntimeError } from 'app/utils/error';
import { ROLE } from 'data/enums';
import { useTokenData } from 'data/hooks';
import { useGroupActions } from 'data/stores/group-store';
import { useAtomValue, useSetAtom } from 'jotai';
import { featureFlagsAtom } from 'data/atoms/feature-flags';
import { useQueryClient } from '@tanstack/react-query';
import { connectionAtom, messagePubSubAtom } from 'data/atoms/socket';
import { SOCKET_EVENTS } from 'data/enums/socket-events';
import { TEST } from 'app/constants/env';
import { match, P } from 'ts-pattern';
import { Client } from 'data/com/client';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';

interface ContainerProps {
  children: React.ReactNode;
}

export function Container({ children }: ContainerProps) {
  const { t } = useTranslation();
  const location: Location<null> = useLocation();
  const setFeatureFlags = useSetAtom(featureFlagsAtom);
  const queryClient = useQueryClient();
  const groupActions = useGroupActions();
  const tokenData = useTokenData();
  const isAuthenticated = !!tokenData;
  const role = tokenData?.role;

  useEffect(() => {
    versionManager.sync();
  }, [location.pathname]);

  useIdleTimer({
    timeout: 5 * MILLISECONDS.MINUTE,
    onIdle: () => {
      versionManager.sync();
    },
  });

  useFetchFeatureFlags({
    staleTime: 10 * MILLISECONDS.MINUTE,
    useErrorBoundary: false,
    onSuccess: (data) => {
      setFeatureFlags(data);
    },
    onError: (error) => {
      ErrorService.captureException(new RuntimeError('Failed to fetch feature flags', { error }));
    },
  });

  useEffect(() => {
    queryClient.invalidateQueries(featureKeys.flags());
    if (tokenData?.role === ROLE.ADMIN) {
      groupActions.setActiveGroupId(undefined);
    }
  }, [isAuthenticated]);

  const connection = useAtomValue(connectionAtom);
  const messagePubSub = useAtomValue(messagePubSubAtom);

  useEffect(() => {
    const listener = () => window.location.reload();
    messagePubSub.addEventListener(SOCKET_EVENTS.FORCE_RELOAD, listener);
    return () => {
      messagePubSub.removeEventListener(SOCKET_EVENTS.FORCE_RELOAD, listener);
    };
  }, []);

  useEffect(() => {
    if (TEST) return () => undefined;

    if (role === ROLE.KID) {
      Client.reconnect(connection);
    } else {
      Client.close(connection);
    }

    return () => {
      Client.close(connection);
    };
  }, [role]);

  useEffect(() => {
    const appInterface = match(role)
      .with(P.string, (role) => ROLE_NAME[role])
      .otherwise(() => 'public');

    Sentry.setTag('interface', appInterface);
    Sentry.addBreadcrumb({
      message: `${t(appInterface)} interface`,
      category: 'interface',
      level: 'info',
    });
  }, [role]);

  return <>{children}</>;
}
