import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/nl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { frFR, enUS, nlNL } from '@mui/x-date-pickers/locales';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Show } from 'app/components/core/show';
import FormHelperText from '@mui/material/FormHelperText';

const locales = {
  en: 'en',
  fr: 'fr',
  nl: 'nl',
};

const datePickerLocales = {
  en: enUS,
  fr: frFR,
  nl: nlNL,
};

const datePickerPropTypes = {
  className: PropTypes.string,
  language: PropTypes.string.isRequired,
  error: PropTypes.string,
};

export const SingleDatePicker = ({ className, language, error, ...props }) => {
  moment.locale(locales[language]);
  return (
    <div className={cx(className)}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={datePickerLocales[language].components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <DatePicker
          slotProps={{ inputAdornment: { className: '[&>button]:m-0' } }}
          label="Date picker"
          format="dd MMM yyyy"
          {...props}
        />
      </LocalizationProvider>
      <Show when={error}>
        <FormHelperText error>{error}</FormHelperText>
      </Show>
    </div>
  );
};

SingleDatePicker.propTypes = datePickerPropTypes;

SingleDatePicker.defaultProps = {
  className: null,
  error: null,
};

const datePickerSingleInputPropTypes = {
  className: PropTypes.string,
  language: PropTypes.string.isRequired,
  error: PropTypes.string,
};

export const DatePickerSingleInput = ({ className, language, error, ...props }) => {
  moment.locale(locales[language]);
  return (
    <div className={cx(className)}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={datePickerLocales[language].components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <DateTimePicker
          slotProps={{ inputAdornment: { className: '[&>button]:m-0' } }}
          ampm={false}
          label="Starts on"
          format="dd MMM yyyy HH:mm"
          {...props}
        />
      </LocalizationProvider>
      <Show when={error}>
        <FormHelperText error>{error}</FormHelperText>
      </Show>
    </div>
  );
};

DatePickerSingleInput.propTypes = datePickerSingleInputPropTypes;

DatePickerSingleInput.defaultProps = {
  className: null,
  error: null,
};
