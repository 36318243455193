import { fromJS } from 'immutable';
import * as constants from 'data/constants/schools';
import LoadingProgress from 'data/utils/reducers/loading';

export const schoolsProgress = new LoadingProgress('schoolsProgress');
export const schoolBookLicensesProgress = new LoadingProgress('schoolBookLicenses');
export const schoolBookLicenseProgress = new LoadingProgress('schoolBookLicense');
export const schoolBooksProgress = new LoadingProgress('schoolBooksProgress');

const initialState = fromJS({
  entities: {},
});

const loadBookLicenses = (state, action) =>
  state.withMutations((newState) => {
    const { items, schoolId, count } = action.payload;
    newState.setIn(['entities', schoolId, 'booklicenses'], fromJS(items));
    newState.setIn(['entities', schoolId, 'totalBooklicenses'], fromJS(count));
    schoolBookLicensesProgress.setLoaded(newState);
  });

const loadBookLicense = (state, action) =>
  state.withMutations((newState) => {
    const { data, schoolId } = action.payload;
    newState.updateIn(['entities', schoolId, 'booklicenses'], (licenses) =>
      licenses.map((license) => {
        if (data.id === license.get('id')) return fromJS(data);
        return license;
      }),
    );
    schoolBookLicenseProgress.setLoaded(newState);
  });

const loadBooks = (state, action) =>
  state.withMutations((newState) => {
    const { items, schoolId, count } = action.payload;
    newState.setIn(['entities', schoolId, 'books'], fromJS(items));
    newState.setIn(['entities', schoolId, 'totalBooks'], fromJS(count));
    schoolBooksProgress.setLoaded(newState);
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_BOOKS_START:
      return schoolBooksProgress.setLoading(state);
    case constants.LOAD_BOOKS_FAILED:
      return schoolBooksProgress.setLoadFailed(state);
    case constants.LOAD_BOOKS_SUCCESS:
      return loadBooks(state, action);
    case constants.LOAD_BOOK_LICENSES_START:
      return schoolBookLicensesProgress.setLoading(state);
    case constants.LOAD_BOOK_LICENSES_FAILED:
      return schoolBookLicensesProgress.setLoadFailed(state);
    case constants.LOAD_BOOK_LICENSES_SUCCESS:
      return loadBookLicenses(state, action);
    case constants.UPDATE_BOOK_LICENSE_START:
      return schoolBookLicenseProgress.setLoading(state);
    case constants.UPDATE_BOOK_LICENSE_FAILED:
      return schoolBookLicenseProgress.setLoadFailed(state);
    case constants.UPDATE_BOOK_LICENSE_SUCCESS:
      return loadBookLicense(state, action);

    default:
      return state;
  }
};
