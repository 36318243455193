import {
  getAccessToken,
  getAccessTokenData,
  getActions,
  getRefreshToken,
  getSupportAccessToken,
} from 'data/stores/auth-store';
import { type IStorage } from 'data/axios/http-client';
import add from 'date-fns/add';
import CookieService from 'app/utils/services/cookies';
import { USER_ID_KEY } from 'app/constants/store';
import { logout } from 'data/utils/helpers';

const authStoreActions = getActions();

export class Storage implements IStorage {
  get accessToken() {
    const supportAccessToken = getSupportAccessToken();
    if (supportAccessToken) {
      return supportAccessToken;
    } else {
      return getAccessToken();
    }
  }

  set accessToken(value: string | undefined) {
    const expiration = value ? add(new Date(), { years: 1 }).toISOString() : undefined;
    authStoreActions.setAccessToken(value, {
      cookie: true,
      expiresAt: expiration,
    });

    const tokenData = getAccessTokenData();

    CookieService.set(USER_ID_KEY, tokenData?.userId, {
      expires: tokenData?.userId ? add(new Date(), { years: 1 }) : undefined,
    });
  }

  set supportAccessToken(value: string | undefined) {
    authStoreActions.setSupportAccessToken(value);
  }

  get refreshToken() {
    return getRefreshToken();
  }

  set refreshToken(value: string | undefined) {
    const expiration = value ? add(new Date(), { years: 1 }).toISOString() : undefined;

    if (value) {
      authStoreActions.setRefreshToken(value, {
        cookie: true,
        expiresAt: expiration,
      });
    }
  }

  /**
   * @returns seconds
   */
  getTokenExpiration(): number | undefined {
    const tokenData = getAccessTokenData();

    return tokenData?.exp;
  }

  getAccessTokenData() {
    return getAccessTokenData();
  }

  clear() {
    logout();
  }
}
