import { fromJS } from 'immutable';
import * as constants from 'data/constants/generator';
import LoadingProgress from 'data/utils/reducers/loading';

export const generatorProgress = new LoadingProgress('generatorProgress');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    newState.setIn(['questions'], fromJS(payload));
  });

const loadQuestions = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    generatorProgress.setLoaded(newState);
  });

const initialState = fromJS({
  questions: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.SAVING_START:
      return generatorProgress.setLoading(state);
    case constants.SAVING_SUCCESS:
      return loadQuestions(state, action);
    case constants.SAVING_FAILED:
      return generatorProgress.setLoadFailed(state);
    case constants.CLEAR_PROGRESS:
      return generatorProgress.clear(state);
    case constants.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
