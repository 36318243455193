import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import cx from 'classnames';
import { icons, useSidebarContext } from '@coach/ui';
import { Show } from 'app/components/core/show';
import useSidebarStyles from './sidebar-jss';

type HeadItemProps = {
  name: string;
  className?: string;
  children?: React.ReactNode;
  to?: string;
  icon?: React.ReactNode;
  target?: string;
  href?: string;
  withAlert?: boolean;
  onClick?: () => void;
  getIsOpened?: (isOpened: boolean) => void;
  defaultOpened?: boolean;
};

export const HeadItem = React.forwardRef(function HeadItem(
  {
    className,
    children,
    to,
    icon,
    name,
    target,
    href,
    withAlert,
    onClick,
    getIsOpened,
    defaultOpened,
    ...props
  }: HeadItemProps,
  ref: React.Ref<HTMLLIElement | HTMLDivElement>,
) {
  const classes = useSidebarStyles();
  const location = useLocation();

  const { openedSubMenu, setSubMenu } = useSidebarContext();

  const isHeadActive = () => {
    let flag = false;
    React.Children.forEach(children, (child) => {
      if (typeof child !== 'object' || !child) return;
      if (!Reflect.has(child, 'props')) return;
      if (location.pathname.includes((child as React.ReactElement).props.to)) flag = true;
    });
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    if (location.pathname.includes(to)) flag = true;
    return flag;
  };

  const isOpened = isHeadActive() || openedSubMenu === name;

  const isItemActive = (path: string | undefined) => !!path && location.pathname.includes(path);

  const handleClick = () => {
    setSubMenu(name);
    if (onClick) {
      onClick();
    }
  };

  useEffect(() => {
    getIsOpened?.(isOpened);
  }, [isOpened]);

  useEffect(() => {
    if (defaultOpened) {
      setSubMenu(name);
    }
  }, []);

  return (
    <div className={className} {...props}>
      <Show
        when={!!children}
        fallback={
          <ListItem
            className={cx(
              'visited:text-inherit active:text-inherit',
              classes.head,
              icon ? classes.iconed : '',
              isItemActive(to) && classes.opened,
            )}
            component={target && href ? 'a' : NavLink}
            to={to}
            target={target}
            href={href}
            onClick={handleClick}
            innerRef={target && href ? undefined : ref}
            // @ts-expect-error The expected type comes from property 'ref' which is declared here on type 'IntrinsicAttributes & { button?: false | undefined; } & ListItemOwnProps & CommonProps & Omit<Omit<DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>, "ref"> & { ...; }, "classes" | ... 19 more ... | "secondaryAction">'
            ref={target && href ? (ref as React.Ref<HTMLAnchorElement>) : undefined}
          >
            <Show when={!!icon}>
              <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
            </Show>
            {/*  @ts-expect-error TS(2322): Type '{ classes: { primary: string; }; variant: st... Remove this comment to see the full error message */}
            <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={name} />
            <Show when={!!withAlert}>
              <ListItemIcon className={classes.iconAlert}>{icons.sidebarAlert}</ListItemIcon>
            </Show>
          </ListItem>
        }
      >
        <ListItem
          button
          className={cx(classes.head, icon ? classes.iconed : '', isHeadActive() && classes.opened)}
          onClick={handleClick}
          ref={ref as React.Ref<HTMLDivElement>}
        >
          <Show when={!!icon}>
            <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
          </Show>
          {/*  @ts-expect-error TS(2322): Type '{ classes: { primary: string; }; variant: st... Remove this comment to see the full error message */}
          <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={name} />
          <Show when={isOpened} fallback={<ExpandMore className={classes.expandIcon} />}>
            <ExpandLess className={classes.expandIcon} />
          </Show>
          <Show when={!!withAlert}>
            <ListItemIcon className={classes.iconAlert}>{icons.sidebarAlert}</ListItemIcon>
          </Show>
        </ListItem>
        <Collapse component="div" className={cx(classes.nolist)} in={isOpened} timeout="auto" unmountOnExit>
          <List className={cx(classes.dense, classes.child)} component="nav" dense>
            {children}
          </List>
        </Collapse>
      </Show>
    </div>
  );
});
