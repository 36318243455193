export const buttonVariantColorCompoundVariants = [
  {
    variant: 'contained',
    color: 'purple',
    class: 'bg-purple-700 text-neutral-50 shadow-purple-900',
  },
  {
    variant: 'contained',
    color: 'blue',
    class: 'bg-sky-400 text-neutral-50 shadow-sky-600',
  },
  {
    variant: 'contained',
    color: 'yellow',
    class: 'bg-yellow-200 text-neutral-800 shadow-yellow-500',
  },
  {
    variant: 'contained',
    color: 'yellowDark',
    class: 'bg-yellow-300 text-neutral-800 shadow-yellow-500',
  },
  {
    variant: 'contained',
    color: 'red',
    class: 'bg-red-500 text-neutral-50 shadow-red-600',
  },
  {
    variant: 'contained',
    color: 'gray',
    class: 'bg-neutral-100 text-neutral-800 shadow-neutral-200',
  },
  {
    variant: 'contained',
    color: 'green',
    class: 'bg-emerald-500 text-neutral-50 shadow-emerald-600',
  },
  {
    variant: 'contained',
    color: 'greenDark',
    class: 'bg-emerald-600 text-neutral-50 shadow-emerald-700',
  },
  {
    variant: 'contained',
    color: 'white',
    class: 'bg-base-100 text-neutral-800 shadow-neutral-300',
  },
  {
    variant: 'outlined',
    color: 'purple',
    class: 'border-purple-700',
  },
  {
    variant: 'outlined',
    color: 'blue',
    class: 'border-sky-400',
  },
  {
    variant: 'outlined',
    color: 'yellow',
    class: 'border-yellow-200',
  },
  {
    variant: 'outlined',
    color: 'yellowDark',
    class: 'border-yellow-300',
  },
  {
    variant: 'outlined',
    color: 'red',
    class: 'border-red-500',
  },
  {
    variant: 'outlined',
    color: 'gray',
    class: 'border-neutral-300',
  },
  {
    variant: 'outlined',
    color: 'green',
    class: 'border-emerald-500',
  },
  {
    variant: 'outlined',
    color: 'greenDark',
    class: 'border-emerald-800',
  },
  {
    variant: 'outlined',
    color: 'white',
    class: 'border-base-100',
  },
  {
    variant: 'contained',
    size: 'large',
    class: 'p-5 shadow-[0_8px_0_-0] active:translate-y-[8px] mb-2',
  },
] as const;
