import React from 'react';
import { HeadItem, icons, Sidebar, SubItem } from '@coach/ui';
import { useTranslation } from 'react-i18next';
import {
  booksPath,
  childrenPath,
  digitoolsPath,
  documentsPath,
  exercisesPath,
  learningLinesPath,
  questionBoxesPath,
  settingsPaths,
  tasksPath,
  videosPath,
} from 'app/constants/url/shared';
import SettingsIcon from '@mui/icons-material/Settings';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ConnectIcon from '@icons/coach/Connect';
import AssignmentIcon from '@mui/icons-material/Assignment';

export function PracticeSidebar() {
  const { t } = useTranslation();

  return (
    <Sidebar className="overflow-x-hidden">
      <HeadItem
        name={t('children')}
        to={childrenPath}
        icon={icons.child}
        data-name="sidebar-children"
        data-interactive-tour="sidebar-children"
      />
      <HeadItem name={t('tasks')} to={tasksPath.index} icon={icons.calendarChecked} data-name="sidebar-tasks" />

      <HeadItem name={t('library')} defaultOpened icon={<LibraryBooksIcon />} data-name="sidebar-library">
        <SubItem to={learningLinesPath} icon={<ConnectIcon fontSize="small" />} data-name="sidebar-learning-lines">
          {t('learningLines')}
        </SubItem>

        <SubItem to={exercisesPath} icon={<AssignmentIcon fontSize="small" />} data-name="sidebar-exercises">
          {t('exercises')}
        </SubItem>

        <SubItem to={questionBoxesPath} icon={icons.questionBox} data-name="sidebar-question-boxes">
          {t('questionBoxes')}
        </SubItem>

        <SubItem to={booksPath} icon={<MenuBookIcon />} data-name="sidebar-library">
          {t('digitalBooks')}
        </SubItem>

        <SubItem to={digitoolsPath} icon={icons.videoGames} data-name="sidebar-digitools">
          {t('digitools')}
        </SubItem>

        <SubItem to={videosPath} icon={icons.video} data-name="sidebar-videos">
          {t('videos')}
        </SubItem>
      </HeadItem>

      <HeadItem name={t('documents')} to={documentsPath} icon={icons.openFolder} data-name="sidebar-documents" />

      <HeadItem
        name={t('settings')}
        to={settingsPaths.index}
        icon={<SettingsIcon />}
        data-name="sidebar-settings"
        data-interactive-tour="sidebar-settings"
      />
    </Sidebar>
  );
}
