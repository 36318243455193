import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useFetchUser } from 'data/hooks';
import { useTokenData } from 'data/stores/auth-store';
import { Sentry } from 'data';
import { useTranslation } from 'react-i18next';
import Loader from 'app/components/core/preloader/page-preloader';
import { type Language, supportedLanguages } from 'app/constants/data';
import { LOCAL_STORAGE_LANGUAGE_KEY } from 'app/constants/store';
import { PROD } from 'app/constants/env';

interface Props {
  children: React.ReactNode;
}

/**
 * Ignore the TS warning as the aim of that line of code is actually to check whether the language is supported
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const isLanguageSupported = (language: string): language is Language => supportedLanguages.includes(language);

export const TranslationProvider = ({ children }: Props): React.ReactNode => {
  const [hasTranslationLoaded, setHasTranslationLoaded] = useState<boolean>(false);
  const { i18n } = useTranslation();
  const tokenData = useTokenData();

  const isAuthorised = !!tokenData;

  const { data: user, isInitialLoading: isLoading } = useFetchUser(tokenData?.userId as string, {
    enabled: isAuthorised,
  });

  useEffect(() => {
    if (i18n.isInitialized) {
      setHasTranslationLoaded(true);
    }
    i18n.on('loaded', () => {
      setHasTranslationLoaded(true);
    });

    i18n.on('loadingFailed', () => {
      toast.error('Translation loading failed. Try to reload the page, please');
    });

    i18n.on('languageChanged', (language) => {
      Sentry.setTag('page_language', language);
      Sentry.addBreadcrumb({
        category: 'change_language',
        message: `User changed language to ${language}`,
        level: 'info',
      });
    });

    if (!PROD) {
      window.i18n = i18n;
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    let userLanguage: string | undefined;
    const appLanguage = localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY) || '';

    const isAppLanguageSupported = isLanguageSupported(appLanguage);

    if (user) {
      userLanguage = user?.language;
    }

    if (!userLanguage) {
      i18n.changeLanguage(isAppLanguageSupported ? appLanguage : 'nl');
      return;
    }

    const isUserLanguageSupported = isLanguageSupported(userLanguage);

    if (i18n.language !== userLanguage && isUserLanguageSupported && isAuthorised) {
      i18n.changeLanguage(userLanguage);
    }
  }, [isLoading, user, isAuthorised]);

  return hasTranslationLoaded ? <>{children}</> : <Loader />;
};
