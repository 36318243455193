import { makeStyles } from '@mui/styles';
import { type AppTheme } from '@coach/ui/utils/appTheme';

export const useLinkStyles = makeStyles<AppTheme>((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&:focus': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}));

export const useLinkIconStyles = makeStyles<AppTheme>((theme) => ({
  root: {
    marginLeft: '20px !important',
    width: 24,
    height: 24,
    color: theme.palette.primary.dark,
    '&:focus': {
      border: 'none',
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));
