import React from 'react';
import { type Icons, icons } from '@coach/ui';
import MuiButton, { type ButtonProps as MuiButtonProps } from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import IconButton, { type IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useToggleState } from 'app/utils/hooks/useToggleState';
import useBreakpoints from 'app/utils/hooks/useBreakpoints';
import Skeleton from '@mui/material/Skeleton';
import { type SkeletonProps } from '@mui/material/Skeleton';
import CircularProgress, { type CircularProgressProps } from '@mui/material/CircularProgress';
import { useButtonStyles, useFabStyles, useIconStyles } from './styles';

export const BUTTON_COLORS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  GREEN: 'green',
  BLUE: 'blue',
  WHITE: 'white',
  PINK: 'pink',
  WHITE_OUTLINED: 'whiteOutlined',
  WHITE_P: 'whiteP',
  WHITE_B: 'whiteB',
  WHITE_G: 'whiteG',
} as const;

export type ButtonColor = (typeof BUTTON_COLORS)[keyof typeof BUTTON_COLORS];

/**
 * The CustomComponent generic will accept any React component, custom, and HTML elements.
 *
 * [Docs](https://mui.com/guides/composition/#with-typescript)
 */
export type ButtonProps<Component extends React.ElementType> = Omit<
  MuiButtonProps<
    Component,
    {
      toolTip?: string;
      icon?: keyof Icons;
      toolTipClassName?: string;
    }
  >,
  'color'
> & {
  component?: Component;
  color?: ButtonColor;
  showTooltipOnMobile?: boolean;
  loading?: boolean;
  loaderProps?: CircularProgressProps;
};

export const Button = <C extends React.ElementType = 'button'>({
  className,
  children,
  color = BUTTON_COLORS.GREEN,
  icon,
  disabled,
  toolTip,
  toolTipClassName,
  showTooltipOnMobile = false,
  loading = false,
  loaderProps,
  classes,
  ...props
}: ButtonProps<C>) => {
  const s = useButtonStyles(props);
  const { isMd } = useBreakpoints();
  const [isTooltipOpened, toggleIsTooltipOpened] = useToggleState(!isMd);
  const isMobileTooltip = !isMd && showTooltipOnMobile;

  const { className: loaderClassName, ...otherLoaderProps } = loaderProps ?? {};

  const button = (
    <MuiButton
      className={cx(s.btn, s[color], disabled ? s.disabled : '', className)}
      disabled={disabled}
      classes={{ ...classes, startIcon: cx('mr-1.5 text-base', classes?.startIcon) }}
      {...props}
    >
      {icon && <div className={s.icon}>{icons[icon]}</div>}
      <CircularProgress
        size={20}
        color="inherit"
        className={cx('mr-2', !loading && 'hidden', loaderClassName)}
        {...otherLoaderProps}
      />
      {children}
    </MuiButton>
  );

  return (
    <>
      {toolTip ? (
        <>
          <Tooltip
            title={toolTip}
            open={isMobileTooltip ? isTooltipOpened : undefined}
            arrow={isMobileTooltip ? !isMd : undefined}
            onClick={isMobileTooltip ? toggleIsTooltipOpened : undefined}
            placement={isMobileTooltip ? 'top' : undefined}
            classes={{ tooltip: isMobileTooltip ? 'my-0 text-sm' : undefined }}
          >
            <div className={cx(s.toolTip, toolTipClassName)}>{button}</div>
          </Tooltip>
        </>
      ) : (
        <>{button}</>
      )}
    </>
  );
};

export const ButtonSkeleton = ({ className, ...other }: SkeletonProps) => (
  <Skeleton variant="rectangular" className={cx('rounded-[20px]', className)} {...other} />
);

const fabPropTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export const FabButton = ({ className, children, color, ...props }) => {
  const s = useFabStyles();
  return (
    <Fab className={cx(s.root, s[color], className)} {...props}>
      {children}
    </Fab>
  );
};

FabButton.defaultProps = {
  color: 'green',
  children: null,
  className: null,
};
FabButton.propTypes = fabPropTypes;

type IconButtonProps<C extends React.ElementType> = Omit<MuiIconButtonProps<C>, 'color'> & {
  component?: C;
  color?: ButtonColor;
  icon: keyof Icons;
  className?: string;
  toolTip?: string;
};

export const IconButtonComponent = <C extends React.ElementType = 'button'>({
  className,
  color = BUTTON_COLORS.GREEN,
  icon,
  toolTip = '',
  ...props
}: IconButtonProps<C>) => {
  const s = useIconStyles();
  return (
    <>
      {toolTip ? (
        <Tooltip title={toolTip}>
          <div className={s.toolTip}>
            <IconButton className={cx(className, s.root, s[color])} {...props}>
              {icons[icon]}
            </IconButton>
          </div>
        </Tooltip>
      ) : (
        <IconButton className={cx(className, s.root, s[color])} {...props}>
          {icons[icon]}
        </IconButton>
      )}
    </>
  );
};
