export const baseKidPath = '/kid';
const applyBaseUrl = (url) => `${baseKidPath}/${url}`;

export const learnPath = applyBaseUrl('learn');
export const tasksPath = applyBaseUrl('tasks');
export const libraryPath = applyBaseUrl('library');
export const videosPath = applyBaseUrl('videos');
export const videoPath = (videoId) => `${videosPath}/${videoId}`;
export const digitoolsPath = applyBaseUrl('digitools');
export const playingCardsPath = `${digitoolsPath}/playing-cards`;
export const moneyPath = `${digitoolsPath}/money`;
export const checkersPath = `${digitoolsPath}/checkers`;
export const notepadPath = `${digitoolsPath}/notepad`;
export const whiteboardPath = `${digitoolsPath}/whiteboard`;
export const tableSquarePath = `${digitoolsPath}/table-square`;
export const fixedNumberCardsPath = `${digitoolsPath}/fixed-number-cards`;
export const numberCardsPath = `${digitoolsPath}/number-cards`;
export const plusminTablePath = `${digitoolsPath}/plusmin-table`;
export const calculatorPath = `${digitoolsPath}/calculator`;
export const measuringTapePath = `${digitoolsPath}/measuring-tape`;
export const numberStepperPath = `${digitoolsPath}/number-stepper`;
export const conversionTablePath = `${digitoolsPath}/conversion-table`;
export const wordReaderPath = `${digitoolsPath}/word-reader`;
export const lineReaderPath = `${digitoolsPath}/line-reader`;
export const longhandArithmeticPath = `${digitoolsPath}/longhand-arithmetic`;
export const clockPath = `${digitoolsPath}/clock`;
export const stopwatchPath = `${clockPath}/stopwatch`;
export const digitalClockPath = `${clockPath}/digital-clock`;
export const analogClockPath = `${clockPath}/analog-clock`;
export const timerClockPath = `${clockPath}/timer-clock`;
export const reportPath = applyBaseUrl('report');
export const profilePath = applyBaseUrl('profile');
export const questionsPath = applyBaseUrl('questions');
export const exercisePath = applyBaseUrl('exercise');

export const kidSignInPaths = (() => ({
  index: applyBaseUrl('sign-in'),
  params: {
    // passwordless login code
    code: 'code',
  },
}))();

export const purchaseLicensePath = applyBaseUrl('purchase-license');
export const forgotCredentialsPaths = (() => ({
  password: applyBaseUrl('forgot-password'),
  passcode: applyBaseUrl('forgot-passcode'),

  params: {
    username: 'username' as const,
  },
}))();

export const downloadFilePath = (actionId, fileId) => `${tasksPath}/${actionId}/download-file/${fileId}`;
export const uploadFilePath = (actionId, fileId) => `${tasksPath}/${actionId}/upload-file/${fileId}`;
export const visitLinkPath = (actionId, linkShareId) => `${tasksPath}/${actionId}/visit-link/${linkShareId}`;
export const actionQuestionBoxPath = (actionId) => `${tasksPath}/${actionId}/question-box`;

export const learningLinesPath = `${learnPath}/learning-lines`;
export const fieldsExercisesPath = (fieldId) => `${exercisePath}/${fieldId}/fields-and-domains`;
export const fieldsLearningLinesPath = (fieldId) => `${learningLinesPath}/${fieldId}/fields-and-domains`;
export const domainExercisesPath = (fieldId, domainId) => `${fieldsExercisesPath(fieldId)}/${domainId}/towers`;
export const domainDidacticBoxesPath = (fieldId, learningLineId) =>
  `${fieldsLearningLinesPath(fieldId)}/${learningLineId}/didactic-boxes`;
export const towerPath = (fieldId, domainId, towerId) => `${domainExercisesPath(fieldId, domainId)}/${towerId}`;
export const questionExercisesPath = (towerId) => `${questionsPath}/towers/${towerId}`;
export const questionLearningLinePath = (learningLineId) => `${questionsPath}/learning-lines/${learningLineId}`;
