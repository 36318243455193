import LayoutKid from 'app/components/core/layout-kid';
import { Sidebar } from '@kid/ui';
import Skeleton from '@mui/material/Skeleton';
import KidLoader from 'app/components/core/preloader/kid';
import React from 'react';

export const KidLayoutPreloader = () => (
  <LayoutKid
    header={null}
    sidebar={
      <Sidebar>
        <Skeleton variant="rectangular" height="100%" />
      </Sidebar>
    }
    content={<KidLoader />}
  />
);
