export const breakpointsArray = ['base', 'xs', 'sm', 'md', 'lg', 'xl', '2xl'] as const;

export type Breakpoints = (typeof breakpointsArray)[number];

export type BreakpointValues<T = string> = {
  [K in Breakpoints]?: T;
};

export const breakpoints: Record<Breakpoints, number> = {
  base: 0,
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};
