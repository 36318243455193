import { useIntercom } from 'react-use-intercom';
import { type GROUP } from 'data/enums';

export enum INTERCOM_EVENT {
  LOG_IN = 'log_in',
  SIGN_UP = 'sign_up',
  ACCEPTED_INVITE = 'accepted_invite',
  CREATE_CHILD = 'create_child',
  VISITED_CHILD_INTERFACE = 'visited_child_interface',
  FINISHED_ONBOARDING = 'finished_onboarding',
  CREATED_GROUP = 'created_group',
  CREATED_INVITE = 'created_invite',
  CHECKOUT_SESSION_SUCCEEDED = 'checkout_session_succeeded',
  CHECKOUT_SESSION_FAILED = 'checkout_session_failed',
}

export type Metadata = Record<string, unknown>;

export type ExpectedMetadata = {
  [INTERCOM_EVENT.LOG_IN]: Metadata & {
    auth_method: 'email' | 'sso' | 'username';
  };
  [INTERCOM_EVENT.CREATE_CHILD]: Metadata;
  [INTERCOM_EVENT.SIGN_UP]: Metadata & {
    auth_method: 'email' | 'sso';
  };
  [INTERCOM_EVENT.ACCEPTED_INVITE]: Metadata;
  [INTERCOM_EVENT.VISITED_CHILD_INTERFACE]: Metadata & {
    kid_name?: string;
  };
  [INTERCOM_EVENT.FINISHED_ONBOARDING]: Metadata;
  [INTERCOM_EVENT.CREATED_GROUP]: Metadata & {
    name: string;
    type: GROUP;
  };
  [INTERCOM_EVENT.CREATED_INVITE]: Metadata;
  [INTERCOM_EVENT.CHECKOUT_SESSION_SUCCEEDED]: Metadata & {
    groupId: string;
  };
  [INTERCOM_EVENT.CHECKOUT_SESSION_FAILED]: Metadata & {
    groupId: string;
    eventID?: string;
  };
};

export const useIntercomEvents = () => {
  const { trackEvent: trackIntercomEvent } = useIntercom();

  const trackEvent = <Event extends INTERCOM_EVENT>(event: Event, metadata?: ExpectedMetadata[Event]) => {
    trackIntercomEvent(event, metadata);
  };

  return {
    events: INTERCOM_EVENT,
    trackEvent,
  };
};
