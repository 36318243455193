import React from 'react';
import { useTranslation } from 'react-i18next';
import { init as initializeAnalyticsService } from 'app/utils/services/analytics';
import Cookies, { type CookieConfig, type PolicyConfiguration } from 'app/components/core/cookies/cookies';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import { appTheme } from '@coach/ui';
import { COOKIE_PREFERENCES_KEY } from 'app/constants/store';

interface CookieProviderProps {
  children: React.ReactNode;
}

enum POLICY_IDS {
  ESSENTIAL = 'ess',
  ANALYTICS = 'anl',
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const theme = createTheme(appTheme);
export const CookieProvider = ({ children }: CookieProviderProps) => {
  const { t } = useTranslation();

  const handlePolicies = (config: PolicyConfiguration) => {
    if (config[POLICY_IDS.ANALYTICS]) {
      initializeAnalyticsService();
    }
  };

  const config: CookieConfig = {
    policies: [
      {
        id: POLICY_IDS.ESSENTIAL,
        label: t('cookies.policies.essential.label'),
        description: t('cookies.policies.essential.description'),
        alwaysOn: true,
      },
      {
        id: POLICY_IDS.ANALYTICS,
        label: t('cookies.policies.analytics.label'),
        description: t('cookies.policies.analytics.description'),
      },
    ],
    permissionLabels: {
      accept: t('cookies.buttons.accept'),
      acceptAll: t('cookies.buttons.acceptAll'),
      decline: t('cookies.buttons.decline'),
    },
    cookiePreferenceKey: COOKIE_PREFERENCES_KEY,
    header: {
      title: t('cookies.header.title'),
      description: t('cookies.header.description'),
    },
    cookiePolicy: {
      url: 'https://www.cleverkids.io/privacy-policy',
      label: t('cookies.privacyPolicy'),
      props: {
        target: '_blank',
      },
    },
    customizeLabel: t('cookies.customize'),
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Cookies config={config} handlePolicies={handlePolicies} />
      </ThemeProvider>
      {children}
    </>
  );
};
