import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { useSelectStyles } from './styles';

const MultiSelectPropTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string.isRequired, label: PropTypes.string.isRequired }),
  ).isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

export const MultiSelect = ({ value, options, className, disabled, label, ...props }) => {
  const s = useSelectStyles();
  return (
    <FormControl className={cx(s.multiSelectWrapper, className)}>
      <InputLabel className={s.label} id="demo-multiple-checkbox-label">
        {label}
      </InputLabel>
      <Select
        className={s.multiSelect}
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={value}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) =>
          options
            .filter((x) => selected.includes(x.value))
            .map((x) => x.label)
            .join(', ')
        }
        {...props}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={value.indexOf(option.value) > -1} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

MultiSelect.propTypes = MultiSelectPropTypes;
MultiSelect.defaultProps = {
  className: null,
  disabled: false,
  value: '',
};
