class LoadProgress {
  constructor(name, states = { loading: 'Loading', loaded: 'Loaded', loadFailed: 'LoadFailed' }) {
    // @ts-expect-error TS(2339): Property 'loadingKey' does not exist on type 'Load... Remove this comment to see the full error message
    this.loadingKey = `${name}${states.loading}`;
    // @ts-expect-error TS(2339): Property 'loadedKey' does not exist on type 'LoadP... Remove this comment to see the full error message
    this.loadedKey = `${name}${states.loaded}`;
    // @ts-expect-error TS(2339): Property 'loadFailedKey' does not exist on type 'L... Remove this comment to see the full error message
    this.loadFailedKey = `${name}${states.loadFailed}`;
    // @ts-expect-error TS(2339): Property 'timestampKey' does not exist on type 'Lo... Remove this comment to see the full error message
    this.timestampKey = `${name}LastProgressUpdate`;
  }

  changeProgress = (state, loading, loaded, loadFailed, time = new Date()) =>
    // @ts-expect-error TS(2339): Property 'timestampKey' does not exist on type 'Lo... Remove this comment to see the full error message
    time > state.get(this.timestampKey) || !state.get(this.timestampKey)
      ? state.withMutations((myState) => {
          // @ts-expect-error TS(2339): Property 'loadingKey' does not exist on type 'Load... Remove this comment to see the full error message
          myState.set(this.loadingKey, loading);
          // @ts-expect-error TS(2339): Property 'loadedKey' does not exist on type 'LoadP... Remove this comment to see the full error message
          myState.set(this.loadedKey, loaded);
          // @ts-expect-error TS(2339): Property 'loadFailedKey' does not exist on type 'L... Remove this comment to see the full error message
          myState.set(this.loadFailedKey, loadFailed);
          // @ts-expect-error TS(2339): Property 'timestampKey' does not exist on type 'Lo... Remove this comment to see the full error message
          myState.set(this.timestampKey, time);
        })
      : state;

  setLoading = (state, time = new Date()) => this.changeProgress(state, true, false, false, time);

  // @ts-expect-error TS(2339): Property 'loadingKey' does not exist on type 'Load... Remove this comment to see the full error message
  getLoading = (state) => state.get(this.loadingKey);

  setLoaded = (state, time = new Date()) => this.changeProgress(state, false, true, false, time);

  // @ts-expect-error TS(2339): Property 'loadedKey' does not exist on type 'LoadP... Remove this comment to see the full error message
  getLoaded = (state) => state.get(this.loadedKey);

  setLoadFailed = (state, time = new Date()) => this.changeProgress(state, false, false, true, time);

  // @ts-expect-error TS(2339): Property 'loadFailedKey' does not exist on type 'L... Remove this comment to see the full error message
  getLoadFailed = (state) => state.get(this.loadFailedKey);

  clear = (state) =>
    state.withMutations((myState) => {
      // @ts-expect-error TS(2339): Property 'loadingKey' does not exist on type 'Load... Remove this comment to see the full error message
      myState.delete(this.loadingKey);
      // @ts-expect-error TS(2339): Property 'loadedKey' does not exist on type 'LoadP... Remove this comment to see the full error message
      myState.delete(this.loadedKey);
      // @ts-expect-error TS(2339): Property 'loadFailedKey' does not exist on type 'L... Remove this comment to see the full error message
      myState.delete(this.loadFailedKey);
      // @ts-expect-error TS(2339): Property 'timestampKey' does not exist on type 'Lo... Remove this comment to see the full error message
      myState.delete(this.timestampKey);
    });
}

export default LoadProgress;
