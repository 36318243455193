import { fromJS } from 'immutable';
import * as constants from 'data/constants/files';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const filesPagination = new Pagination('files');
export const rootProgress = new LoadingProgress('filesRootProgress');
export const editProgress = new LoadingProgress('filesEditProgress');
export const removalProgress = new LoadingProgress('filesRemovalProgress');
export const entityByIdProgress = new LoadingProgress('filesEntityProgress');

const loadRootFiles = (state, action) =>
  state.withMutations((newState) => {
    const {
      entities: { files },
      result,
      limit,
      count,
      forKid,
      loadMore,
    } = action.payload;
    if (forKid) {
      if (loadMore) {
        newState.mergeIn(['entities'], fromJS(files));
        newState.mergeIn(['fileIds'], fromJS(result));
      } else {
        newState.setIn(['entities'], fromJS(files));
        newState.setIn(['fileIds'], fromJS(result));
      }
      newState.setIn(['filesTotal'], fromJS(count));
    } else {
      newState.setIn(['entities'], fromJS(files));
      newState.setIn(['fileIds'], fromJS(result));
    }
    filesPagination.set(newState, count, limit, fromJS(result));
    rootProgress.setLoaded(newState);
  });

const editEntity = (state, action) =>
  state.withMutations((newState) => {
    const { id, result } = action.payload;
    newState.mergeIn(['entities', id], fromJS(result));
    editProgress.setLoaded(newState);
  });

const removeEntity = (state /* , action */) =>
  state.withMutations((newState) => {
    // const { id } = action.payload;
    // newState.deleteIn(['entities', id]);
    removalProgress.setLoaded(newState);
  });

const mergeEntity = (state, action) =>
  state.withMutations((newState) => {
    const { data, entityId } = action.payload;
    newState.mergeIn(['entities', entityId], fromJS(data));
    entityByIdProgress.setLoaded(newState);
  });

const clearFiles = (state) =>
  state.withMutations((newState) => {
    newState.delete('entities');
    newState.delete('fileIds');
  });

const initialState = fromJS({
  files: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_ROOT_START:
    case constants.FETCH_CHILDREN_START:
    case constants.FETCH_REQUESTS_START:
      return rootProgress.setLoading(state);
    case constants.EDIT_START:
      return editProgress.setLoading(state);
    case constants.EDIT_SUCCESS:
      return editEntity(state, action);
    case constants.EDIT_FAILED:
      return editProgress.setLoadFailed(state);
    case constants.REMOVAL_START:
      return removalProgress.setLoading(state);
    case constants.REMOVAL_SUCCESS:
      // @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
      return removeEntity(state, action);
    case constants.REMOVAL_FAILED:
      return removalProgress.setLoadFailed(state);
    case constants.FETCH_ENTITY_START:
      return entityByIdProgress.setLoading(state);
    case constants.FETCH_ENTITY_SUCCESS:
      return mergeEntity(state, action);
    case constants.FETCH_ENTITY_FAILED:
      return entityByIdProgress.setLoadFailed(state);
    case constants.FETCH_CHILDREN_SUCCESS:
    case constants.FETCH_ROOT_SUCCESS:
    case constants.FETCH_REQUESTS_SUCCESS:
      return loadRootFiles(state, action);
    case constants.FETCH_CHILDREN_FAILED:
    case constants.FETCH_REQUESTS_FAILED:
    case constants.FETCH_ROOT_FAILED:
      return rootProgress.setLoadFailed(state);
    case constants.CLEAR_FILES:
      return clearFiles(state);
    default:
      return state;
  }
};
