import PropTypes from 'prop-types';
import ImmutablePropTypes from './ImmutablePropTypes';

export const file = {
  id: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.oneOf(['jpeg', 'jpg', 'png']),
};

export const user = {
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  occupation: PropTypes.string,
  bio: PropTypes.string,
  website: PropTypes.string,
  phone: PropTypes.string,
  gender: PropTypes.oneOf(['male', 'female', 'other', 'none']),
  tagCategories: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      name: PropTypes.string,
      description: PropTypes.string,
      tags: ImmutablePropTypes.listOf(PropTypes.string),
    }),
  ),
  avatar: ImmutablePropTypes.mapContains(file),
  notificationSettings: ImmutablePropTypes.mapContains({
    id: PropTypes.string,
    isFeedbackEmailEnabled: PropTypes.bool,
    isGroupEmailEnabled: PropTypes.bool,
  }),
};

export const request = {
  id: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
  startDate: PropTypes.string,
  envelope: PropTypes.string,
  endDate: PropTypes.string,
};

export const towerRowGrade = {
  id: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.number,
};

export const towerBox = {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  questionBoxCount: PropTypes.number,
};

export const towerDimension = {
  id: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  grade: PropTypes.shape(towerRowGrade).isRequired,
  towerBoxes: PropTypes.arrayOf(PropTypes.shape(towerBox)).isRequired,
  gradeId: PropTypes.string,
  towerId: PropTypes.string,
};

export const domainType = {
  id: PropTypes.string,
  name: PropTypes.string,
  fieldId: PropTypes.string,
  picture: PropTypes.string,
  towersCount: PropTypes.number,
};

export const tower = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.oneOf([PropTypes.string, null]).isRequired,
  hidden: PropTypes.oneOf([PropTypes.bool, null]).isRequired,
  domainType: PropTypes.shape(domainType).isRequired,
  cultureId: PropTypes.oneOf([PropTypes.string, null]).isRequired,
  domainTypeId: PropTypes.oneOf([PropTypes.string, null]),
  towerBoxesCount: PropTypes.number.isRequired,
  towerRows: PropTypes.arrayOf(PropTypes.shape(towerDimension)),
  towerMatrix: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape(towerBox))),
  culture: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  towerArray: PropTypes.arrayOf(PropTypes.shape(towerBox)),
  towerColumns: PropTypes.arrayOf(PropTypes.shape(towerDimension)),
  picture: PropTypes.string,
};
