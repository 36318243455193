import { fromJS, List } from 'immutable';
import * as constants from 'data/constants/question-boxes';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';
import * as learningConstants from 'data/constants/learning-boxes';
import * as questionConstants from 'data/constants/questions';
import { pipe } from 'effect/Function';

export const questionBoxesProgres = new LoadingProgress('questionBoxesProgres');
export const questionBoxProgress = new LoadingProgress('questionBoxProgress');
export const questionBoxesPagination = new Pagination('questionBoxesPagination');
export const questionBoxRemovalProgress = new LoadingProgress('questionBoxRemovalProgress');
export const questionBoxInfoProgress = new LoadingProgress('questionBoxInfo');
export const swapQuestionsProgress = new LoadingProgress('swapQuestionsProgress');
export const swapAllQuestionsProgress = new LoadingProgress('swapAllQuestionsProgress');
export const removeFMMProgress = new LoadingProgress('removeFMMProgress');
export const createFMMProgress = new LoadingProgress('createFMMProgress');
export const questionFilesProgress = new LoadingProgress('questionFilesProgress');
export const questionFileUploadProgress = new LoadingProgress('questionFileUploadProgress');
export const questionFileRemovalProgress = new LoadingProgress('questionFileRemovalProgress');
export const kidQuestionBoxProgress = new LoadingProgress('kidQuestionBoxProgress');
export const questionBoxMoveChildProgress = new LoadingProgress('questionBoxMoveChildProgress');

const mergeData = (state, action) =>
  state.withMutations((newState) => {
    const { questionBoxes } = action.payload.entities;
    newState.mergeIn(['questionBoxes'], fromJS(questionBoxes));
  });

const mergeQuestionData = (state, payload) => {
  const { questionBoxes } = payload.entities;
  return state.withMutations((newState) => {
    newState.mergeIn(['questionBoxes'], fromJS(questionBoxes));
  });
};

const loadQuestionBoxesFromLearning = (state, action) =>
  state.withMutations((newState) => {
    const { learningBoxId } = action;
    const {
      limit,
      count,
      entities: { learningBoxes },
    } = action.payload;
    const { questionBoxes } = learningBoxes[learningBoxId];
    mergeData(newState, action);
    questionBoxesPagination.set(newState, count, limit, fromJS(questionBoxes));
    questionBoxesProgres.setLoaded(newState);
  });

const loadQuestionBoxes = (state, action) =>
  state.withMutations((newState) => {
    const { limit, count, result } = action.payload;
    mergeData(newState, action);
    questionBoxesPagination.set(newState, count, limit, fromJS(result));
    questionBoxesProgres.setLoaded(newState);
  });

const loadQuestionBox = (state, action) =>
  state.withMutations((newState) => {
    mergeQuestionData(newState, action.payload);
    swapQuestionsProgress.setLoaded(newState);
    questionBoxProgress.setLoaded(newState);
    questionBoxInfoProgress.setLoaded(newState);
  });

const loadKidQuestionBox = (state, action) =>
  state.withMutations((newState) => {
    const { questions } = action.payload;
    questions.forEach((question) => {
      // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      question.questionProgresses.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    });
    newState.setIn(['kidQuestionBox'], fromJS(action.payload));
    kidQuestionBoxProgress.setLoaded(newState);
  });

const removeQuestion = (state, action) =>
  state.withMutations((newState) => {
    const { questionId, questionBoxId } = action.payload;
    newState.updateIn(['questionBoxes', questionBoxId, 'questions'], (list) =>
      list.filter((listItem) => listItem.get('id') !== questionId),
    );
    questionBoxProgress.setLoaded(newState);
  });

const removeFMM = (state, action) =>
  state.withMutations((newState) => {
    const { questionId, questionBoxId, fmmId } = action.payload;
    const questionIndex = newState
      .getIn(['questionBoxes', questionBoxId, 'questions'])
      ?.findIndex?.((item) => item.get('id') === questionId);
    newState.updateIn(['questionBoxes', questionBoxId, 'questions', questionIndex, 'mistakes'], (mistakes) =>
      mistakes?.filter?.((mistake) => mistake.get('id') !== fmmId),
    );
    removeFMMProgress.setLoaded(newState);
  });

const createFMM = (state, action) =>
  state.withMutations((newState) => {
    const { mistake, questionId, questionBoxId } = action.payload;

    const questionIndex = newState
      .getIn(['questionBoxes', questionBoxId, 'questions'])
      ?.findIndex?.((item) => item.get('id') === questionId);

    const mistakes = newState
      .getIn(['questionBoxes', questionBoxId, 'questions', questionIndex, 'mistakes'])
      .push(fromJS(mistake));

    newState.setIn(['questionBoxes', questionBoxId, 'questions', 0, 'mistakes'], mistakes);
    createFMMProgress.setLoaded(newState);
  });

const createQuestions = (state, action) =>
  state.withMutations((newState) => {
    const {
      payload: { questions, questionBoxId },
    } = action;
    newState.setIn(['questionBoxes', questionBoxId, 'questions'], fromJS(questions));
    questionBoxProgress.setLoaded(newState);
  });

const removeQuestionsList = (state, action) =>
  state.withMutations((newState) => {
    const { questionsIds, questionBoxId } = action.payload;
    newState.updateIn(['questionBoxes', questionBoxId, 'questions'], (list) =>
      list?.filter((listItem) => questionsIds.indexOf(listItem.get('id')) === -1),
    );
    questionBoxProgress.setLoaded(newState);
  });
const initialState = fromJS({
  questionBoxes: {},
});
const clearQuestionBoxes = (state) =>
  state.withMutations((newState) => {
    questionBoxesProgres.clear(newState);
    questionBoxProgress.clear(newState);
  });

const removeQuestionBox = (state, action) =>
  state.withMutations((newState) => {
    const { questionBoxId, learningBoxId } = action;
    if (learningBoxId) {
      newState.deleteIn(['questionBoxes', questionBoxId]);
      questionBoxesProgres.setLoaded(newState);
    }
  });

const editQuestionBox = (state, action) =>
  state.withMutations((newState) => {
    const {
      payload: {
        entities: { questionBoxes },
      },
    } = action;

    newState.mergeIn(['questionBoxes'], fromJS(questionBoxes));
    questionBoxInfoProgress.setLoaded(newState);
  });

const editQuestion = (state, action) =>
  state.withMutations((newState) => {
    const { questionBoxId, questionId, question: newQuestion } = action.payload;
    const newQuestions = newState
      .getIn(['questionBoxes', questionBoxId, 'questions'])
      ?.map?.((question) => (question.get('id') === questionId ? fromJS(newQuestion) : question));
    newState.setIn(['questionBoxes', questionBoxId, 'questions'], newQuestions);
  });

const loadDigitools = (state, action) =>
  state.withMutations((newState) => {
    newState.setIn(['allDigitools'], fromJS(action.payload));
  });

const loadQuestionFiles = (state, action) =>
  state.withMutations((newState) => {
    const { questionBoxId, questionId, file } = action.payload;
    const questionIndex = newState
      .getIn(['questionBoxes', questionBoxId, 'questions'])
      ?.findIndex?.((q) => q.get('id') === questionId);

    const newFiles = pipe(
      questionIndex,
      (idx): List<unknown> | undefined => newState.getIn(['questionBoxes', questionBoxId, 'questions', idx, 'files']),
      (maybeList) => maybeList ?? List(),
      (list) => list.push(fromJS(file)),
    );

    newState.setIn(['questionBoxes', questionBoxId, 'questions', questionIndex, 'files'], newFiles);
    questionFilesProgress.setLoaded(newState);
    questionFileUploadProgress.setLoaded(newState);
  });

const removeQuestionFile = (state, action) =>
  state.withMutations((newState) => {
    const { questionBoxId, questionId, fileId } = action.payload;
    const questionIndex = newState
      .getIn(['questionBoxes', questionBoxId, 'questions'])
      ?.findIndex?.((q) => q.get('id') === questionId);
    newState.updateIn(['questionBoxes', questionBoxId, 'questions', questionIndex, 'files'], (files) =>
      files?.filter?.((file) => file.get('id') !== fileId),
    );
    questionFileRemovalProgress.setLoaded(newState);
  });

const loadQuestionBoxTypes = (state, action) =>
  state.withMutations((newState) => {
    newState.setIn(['questionBoxTypes'], fromJS(action.payload));
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_KID_QUESTION_BOX_START:
      return kidQuestionBoxProgress.setLoading(state);
    case constants.LOAD_KID_QUESTION_BOX_SUCCESS:
      return loadKidQuestionBox(state, action);
    case constants.LOAD_KID_QUESTION_BOX_FAILED:
      return kidQuestionBoxProgress.setLoadFailed(state);
    case constants.LOAD_QUESTION_FILES_START:
      return questionFilesProgress.setLoading(state);
    case constants.LOAD_QUESTION_FILES_FAILED:
      return questionFilesProgress.setLoadFailed(state);
    case constants.UPLOAD_QUESTION_FILES_START:
      return questionFileUploadProgress.setLoading(state);
    case constants.UPLOAD_QUESTION_FILES_FAILED:
      return questionFileUploadProgress.setLoadFailed(state);
    case constants.UPLOAD_QUESTION_FILES_SUCCESS:
    case constants.LOAD_QUESTION_FILES_SUCCESS:
      return loadQuestionFiles(state, action);
    case constants.REMOVE_QUESTION_FILE_START:
      return questionFileRemovalProgress.setLoading(state);
    case constants.REMOVE_QUESTION_FILE_FAILED:
      return questionFileRemovalProgress.setLoadFailed(state);
    case constants.REMOVE_QUESTION_FILE_SUCCESS:
      return removeQuestionFile(state, action);
    case constants.REMOVING_QUESTION_BOX_START:
      return questionBoxRemovalProgress.setLoading(state);
    case learningConstants.LOAD_LEARNING_START:
    case constants.LOAD_QUESTIONS_START:
    case constants.REMOVING_START:
      return questionBoxesProgres.setLoading(state);
    case constants.EDITING_SUCCESS:
      return editQuestionBox(state, action);
    case constants.EDITING_START:
    case constants.LOAD_QUESTION_START:
    case constants.CREATION_START:
      return questionBoxInfoProgress.setLoading(state);
    case constants.EDIT_QUESTION_SUCCESS:
      return editQuestion(state, action);
    case constants.QUESTION_CREATION_START:
    case questionConstants.REMOVING_START:
    case learningConstants.LINK_QUESTION_START:
    case constants.REMOVING_QUESTIONLIST_START:
      return questionBoxProgress.setLoading(state);
    case learningConstants.LOAD_LEARNING_SUCCESS:
      return loadQuestionBoxesFromLearning(state, action);
    case constants.LOAD_QUESTIONS_SUCCESS:
      return loadQuestionBoxes(state, action);
    case constants.CREATE_FMM_START:
      return createFMMProgress.setLoading(state);
    case constants.CREATE_FMM_SUCCESS:
      return createFMM(state, action);
    case constants.CREATE_FMM_FAILED:
      return createFMMProgress.setLoadFailed(state);
    case constants.REMOVE_FMM_START:
      return removeFMMProgress.setLoading(state);
    case constants.REMOVE_FMM_FAILED:
      return removeFMMProgress.setLoadFailed(state);
    case constants.REMOVE_FMM_SUCCESS:
      return removeFMM(state, action);
    case constants.CREATION_SUCCESS:
    case constants.LOAD_QUESTION_SUCCESS:
      return loadQuestionBox(state, action);
    case questionConstants.REMOVING_SUCCESS:
      return removeQuestion(state, action);
    case constants.SWAP_QUESTIONS_START:
      return swapQuestionsProgress.setLoading(state);
    case constants.SWAP_ALL_QUESTIONS_START:
      return swapAllQuestionsProgress.setLoading(state);
    case constants.LOAD_DIGITOOLS_SUCCESS:
      return loadDigitools(state, action);
    case constants.LOAD_QUESTIONBOX_TYPES_SUCCESS:
      return loadQuestionBoxTypes(state, action);
    case constants.QUESTION_CREATION_SUCCESS:
      return createQuestions(state, action);
    case constants.SWAP_QUESTIONS_FAILED:
      return swapQuestionsProgress.setLoadFailed(state);
    case constants.SWAP_ALL_QUESTIONS_FAILED:
      return swapAllQuestionsProgress.setLoadFailed(state);
    case learningConstants.LINK_QUESTION_SUCCESS:
      return questionBoxProgress.setLoaded(state);
    case constants.REMOVING_QUESTIONLIST_SUCCESS:
      return removeQuestionsList(state, action);
    case constants.REMOVING_SUCCESS:
      return removeQuestionBox(state, action);
    case constants.REMOVING_QUESTION_BOX_SUCCESS:
      return questionBoxRemovalProgress.setLoaded(state);
    case constants.CREATION_FAILED:
    case learningConstants.LOAD_LEARNING_FAILED:
    case constants.LOAD_QUESTIONS_FAILED:
    case constants.REMOVING_FAILED:
      return questionBoxesProgres.setLoadFailed(state);
    case constants.LOAD_QUESTION_FAILED:
    case constants.EDITING_FAILED:
      return questionBoxInfoProgress.setLoadFailed(state);
    case questionConstants.REMOVING_FAILED:
    case learningConstants.LINK_QUESTION_FAILED:
    case constants.REMOVING_QUESTIONLIST_FAILED:
    case constants.QUESTION_CREATION_FAILED:
      return questionBoxProgress.setLoadFailed(state);
    case constants.REMOVING_QUESTION_BOX_FAILED:
      return questionBoxRemovalProgress.setLoadFailed(state);
    case constants.MOVE_CHILD_TO_QUESTION_BOX_START:
      return questionBoxMoveChildProgress.setLoading(state);
    case constants.MOVE_CHILD_TO_QUESTION_BOX_SUCCESS:
      return questionBoxMoveChildProgress.setLoaded(state);
    case constants.MOVE_CHILD_TO_QUESTION_BOX_FAILED:
      return questionBoxMoveChildProgress.setLoadFailed(state);
    case constants.CLEAR:
      return clearQuestionBoxes(state);
    case constants.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
