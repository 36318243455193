import { type ExpectedMetadata, type INTERCOM_EVENT } from 'data/hooks/utils/useIntercomEvents';
import { type GTM_EVENT } from 'data/hooks/utils/useGTMEvents';

export type Event<E extends INTERCOM_EVENT | GTM_EVENT> = E extends INTERCOM_EVENT
  ? {
      name: E;
      metadata?: ExpectedMetadata[E];
    }
  : E extends GTM_EVENT
    ? // added this branch on purpose to be sure that E is GTM_EVENT, and it's easier to extend it in the future
      {
        name: E;
        // We can add more specific types for GTM metadata in case it's needed,
        // and because it's in a branch we will be sure that it's GTM_EVENT
        metadata?: Record<string, unknown>;
      }
    : {
        name: E;
        metadata?: Record<string, unknown>;
      };

/**
 * Marketing events should be synced with the user's account.
 *
 * Some events can be triggered before the user is logged in or before the integration is initialized.
 * So, we need to store them in the queue,
 * and whenever everything connected with chosen providers is launched we can send the events .
 *
 * In case there is no requirement to connect the events with the user's account, use event hooks directly.
 */
export class MarketingEventsQueue<E extends INTERCOM_EVENT | GTM_EVENT> {
  private queue: Event<E>[] = [];

  private subscribers: Set<() => void> = new Set();

  public get length() {
    return this.queue.length;
  }

  public enqueue(event: Event<E>) {
    this.queue.push(event);
    this.subscribers.forEach((callback) => callback());
  }

  public isEmpty() {
    return this.queue.length === 0;
  }

  public dequeue() {
    return this.queue.shift();
  }

  public clear() {
    this.queue = [];
  }

  public subscribe(callback: () => void) {
    this.subscribers.add(callback);

    return () => {
      this.subscribers.delete(callback);
    };
  }
}
