export enum GTM_EVENT {
  SIGN_UP = 'sign_up',
  LOG_IN = 'log_in',
  CREATE_CHILD = 'create_child',
  SEND_ACTION = 'send_action',
  PLAY = 'play',
  CREATED_GROUP = 'created_group',
  CREATED_INVITE = 'created_invite',
}

type PassedEvent = {
  event: GTM_EVENT;
  [key: string]: unknown;
};

declare global {
  interface Window {
    dataLayer?: PassedEvent[];
  }
}

export const useGTMEvents = () => {
  const pushToDataLayer = (data: PassedEvent): void => {
    if (typeof window === 'undefined') return;

    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push(data);
  };

  return {
    events: GTM_EVENT,
    trackEvent: (event: GTM_EVENT, data?: Record<string, unknown>) => {
      pushToDataLayer({
        event,
        ...data,
      });
    },
  };
};
