const prefix = (type) => `eureka/learningBoxes/${type}`;

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');
export const EDITING_SUCCESS = prefix('EDITING_SUCCESS');
export const EDITING_START = prefix('EDITING_START');
export const EDITING_FAILED = prefix('EDITING_FAILED');
export const REMOVING_START = prefix('REMOVING_START');
export const REMOVING_SUCCESS = prefix('REMOVING_SUCCESS');
export const REMOVING_FAILED = prefix('REMOVING_FAILED');
export const LOAD_LEARNING_START = prefix('LOAD_LEARNING_START');
export const LOAD_LEARNING_SUCCESS = prefix('LOAD_LEARNING_SUCCESS');
export const LOAD_LEARNING_FAILED = prefix('LOAD_LEARNING_FAILED');
export const LINK_QUESTION_START = prefix('LINK_QUESTION_START');
export const LINK_QUESTION_SUCCESS = prefix('LINK_QUESTION_SUCCESS');
export const LINK_QUESTION_FAILED = prefix('LINK_QUESTION_FAILED');
export const UNLINK_QUESTION_START = prefix('UNLINK_QUESTION_START');
export const UNLINK_QUESTION_SUCCESS = prefix('UNLINK_QUESTION_SUCCESS');
export const UNLINK_QUESTION_FAILED = prefix('UNLINK_QUESTION_FAILED');
export const DRAGANDDROP_START = prefix('DRAGNDROP_START');
export const DRAGANDDROP_SUCCESS = prefix('DRAGNDROP_SUCCESS');
export const DRAGANDDROP_FAILED = prefix('DRAGNDROP_FAILED');
export const LOAD_BOX_SUCCESS = prefix('LOAD_BOX_SUCCESS');
export const CLEAR = prefix('CLEAR');
export const CLEAR_STATE = prefix('CLEAR_STATE');

export const LOAD_KID_LEARNING_BOXES_START = prefix('LOAD_KID_LEARNING_BOXES_START');
export const LOAD_KID_LEARNING_BOXES_SUCCESS = prefix('LOAD_KID_LEARNING_BOXES_SUCCESS');
export const LOAD_KID_LEARNING_BOXES_FAILED = prefix('LOAD_KID_LEARNING_BOXES_FAILED');

export const LOAD_KID_LEARNING_BOX_START = prefix('LOAD_KID_LEARNING_BOX_START');
export const LOAD_KID_LEARNING_BOX_SUCCESS = prefix('LOAD_KID_LEARNING_BOX_SUCCESS');
export const LOAD_KID_LEARNING_BOX_FAILED = prefix('LOAD_KID_LEARNING_BOX_FAILED');

export const MOVE_CHILD_TO_LEARNING_BOX_START = prefix('MOVE_CHILD_TO_LEARNING_BOX_START');
export const MOVE_CHILD_TO_LEARNING_BOX_SUCCESS = prefix('MOVE_CHILD_TO_LEARNING_BOX_SUCCESS');
export const MOVE_CHILD_TO_LEARNING_BOX_FAILED = prefix('MOVE_CHILD_TO_LEARNING_BOX_FAILED');
