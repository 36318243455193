const prefix = (type) => `eureka/membersAdmins/${type}`;

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const LOAD_ADMIN_START = prefix('LOAD_ADMIN_START');
export const LOAD_ADMIN_SUCCESS = prefix('LOAD_ADMIN_SUCCESS');
export const LOAD_ADMIN_FAILED = prefix('LOAD_ADMIN_FAILED');

export const EDIT_START = prefix('EDIT_START');
export const EDIT_SUCCESS = prefix('EDIT_SUCCESS');
export const EDIT_FAILED = prefix('EDIT_FAILED');

export const LOAD_INVITES_START = prefix('LOAD_INVITES_START');
export const LOAD_INVITES_SUCCESS = prefix('LOAD_INVITES_SUCCESS');
export const LOAD_INVITES_FAILED = prefix('LOAD_INVITES_FAILED');

export const REMOVING_START = prefix('REMOVING_START');
export const REMOVING_SUCCESS = prefix('REMOVING_SUCCESS');
export const REMOVING_FAILED = prefix('REMOVING_FAILED');

export const LOAD_UNCONFIRMED_USERS_START = prefix('LOAD_UNCONFIRMED_USERS_START');
export const LOAD_UNCONFIRMED_USERS_SUCCESS = prefix('LOAD_UNCONFIRMED_USERS_SUCCESS');
export const LOAD_UNCONFIRMED_USERS_FAILED = prefix('LOAD_UNCONFIRMED_USERS_FAILED');

export const CONFIRM_REGISTER_START = prefix('CONFIRM_REGISTER_START');
export const CONFIRM_REGISTER_SUCCESS = prefix('CONFIRM_REGISTER_SUCCESS');
export const CONFIRM_REGISTER_FAILED = prefix('CONFIRM_REGISTER_FAILED');
