/* eslint-disable @typescript-eslint/no-use-before-define */

import React from 'react';
import { Sidebar } from '@coach/ui';
import { useActiveGroupType } from 'data/stores/group-store';
import { match } from 'ts-pattern';
import { GROUP } from 'data/enums';
import { useCoachGroups } from 'app/utils/hooks/useCoachGroups';
import { FamilySidebar } from './family-sidebar';
import { PracticeSidebar } from './practice-sidebar';
import { SchoolSidebar } from './school-sidebar';

export function CoachSidebar() {
  const groupType = useActiveGroupType({ fallback: false });
  useCoachGroups();

  return match(groupType)
    .with(GROUP.SCHOOL, () => <SchoolSidebar />)
    .with(GROUP.PRACTICE, () => <PracticeSidebar />)
    .with(GROUP.FAMILY, () => <FamilySidebar />)
    .otherwise(() => <SidebarSkeleton />);
}

function SidebarSkeleton() {
  return (
    <Sidebar className="overflow-x-hidden">
      <ul className="flex flex-col gap-2 pt-3">{new Array(5).fill(<MenuItemSkeleton />)}</ul>
    </Sidebar>
  );
}

function MenuItemSkeleton() {
  return (
    <li className="h-[48px] w-full">
      <div className="block h-full w-full animate-pulse rounded-r-3xl bg-neutral-100" />
    </li>
  );
}
