import {
  type IError,
  type TowerPositionContext,
  type ValidateProgressFormValues,
  type ValidationState,
} from 'data/types/entities';
import { progressKeys } from 'data/hooks/queries/progresses';
import {
  useMutation,
  type UseMutationResult,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';

import * as api from 'data/api';
import { type AxiosError } from 'axios';
import { type QuestionHistoryState } from 'app/pages/kid/questions/[educationStrategy]/[id]/page';
import { getProgressStore } from 'data/stores/progress-store';

interface UseTowerPositionArgs {
  id?: string;
  historyState?: QuestionHistoryState;
  options?: Omit<UseQueryOptions<TowerPositionContext, AxiosError<IError>>, 'queryKey' | 'queryFn'>;
}

export const useTowerPosition = ({
  id = '',
  historyState,
  options,
}: UseTowerPositionArgs): UseQueryResult<TowerPositionContext, AxiosError<IError>> =>
  useQuery(progressKeys.position(id, historyState), () => api.fetchTowerPosition(id), {
    enabled: !!id,
    refetchOnWindowFocus: false,
    ...options,
  });

interface UseTowerNextArgs {
  id?: string;
  historyState?: QuestionHistoryState;
  questionId?: string;
  towerBoxId?: string;
  enabled?: boolean;
}

export const useTowerNext = ({
  id,
  historyState,
  questionId = '',
  towerBoxId = '',
  enabled,
}: UseTowerNextArgs): UseQueryResult<TowerPositionContext, IError> => {
  const queryClient = useQueryClient();

  return useQuery(progressKeys.next(questionId, towerBoxId), () => api.fetchTowerNext(questionId, towerBoxId), {
    refetchOnWindowFocus: false,
    enabled: !!(questionId && towerBoxId && enabled),
    onSuccess: (data) => {
      if (!id) return;
      queryClient.setQueryData(progressKeys.position(id, historyState), data);
    },
  });
};

export const useValidateTower = (): UseMutationResult<ValidationState, IError, ValidateProgressFormValues, unknown> =>
  useMutation(progressKeys.validateTower(), (values) => api.validateTower(values), {
    onMutate: (values) => {
      const { setAnswerCountState } = getProgressStore().actions;
      setAnswerCountState((state) => ({
        ...state,
        timeSpentLastQuestions: state.timeSpentLastQuestions + (values?.answerInput?.seconds || 0),
      }));
    },
    onSuccess: (data) => {
      const {
        actions: { setAnswerCountState, setAttempts, setValidationState, setMistakes },
        attempts,
      } = getProgressStore();
      setAnswerCountState((state) => ({
        ...state,
        firstTimeRightCount: data.valid && attempts === 1 ? state.firstTimeRightCount + 1 : state.firstTimeRightCount,
      }));
      setAttempts((state) => (data.valid ? 1 : state + 1));
      setValidationState(data);
      setMistakes(data.mistakes);
    },
  });
