const prefix = (type) => `eureka/learningLines/${type}`;

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');
export const EDITING_START = prefix('EDITING_START');
export const EDITING_SUCCESS = prefix('EDITING_SUCCESS');
export const EDITING_FAILED = prefix('EDITING_FAILED');
export const REMOVING_START = prefix('REMOVING_START');
export const REMOVING_SUCCESS = prefix('REMOVING_SUCCESS');
export const REMOVING_FAILED = prefix('REMOVING_FAILED');
export const LOAD_LEARNING_START = prefix('LOAD_LEARNING_START');
export const LOAD_LEARNING_SUCCESS = prefix('LOAD_LEARNING_SUCCESS');
export const LOAD_LEARNING_FAILED = prefix('LOAD_LEARNING_FAILED');
export const DIDACTIC_CREATION_START = prefix('DIDACTIC_CREATION_START');
export const DIDACTIC_CREATION_SUCCESS = prefix('DIDACTIC_CREATION_SUCCESS');
export const DIDACTIC_CREATION_FAILED = prefix('DIDACTIC_CREATION_FAILED');
export const CLEAR_STATE = prefix('CLEAR_STATE');

export const UPLOAD_PHOTO_START = prefix('UPLOAD_PHOTO_START');
export const UPLOAD_PHOTO_SUCCESS = prefix('UPLOAD_PHOTO_SUCCESS');
export const UPLOAD_PHOTO_FAILED = prefix('UPLOAD_PHOTO_FAILED');

export const LOAD_FIELD_WITH_TOWERS_START = prefix('LOAD_FIELD_WITH_TOWERS_START');
export const LOAD_FIELD_WITH_TOWERS_SUCCESS = prefix('LOAD_FIELD_WITH_TOWERS_SUCCESS');
export const LOAD_FIELD_WITH_TOWERS_FAILED = prefix('LOAD_FIELD_WITH_TOWERS_FAILED');

export const LOAD_KID_GRADE_FIELDS_START = prefix('LOAD_KID_GRADE_FIELDS_START');
export const LOAD_KID_GRADE_FIELDS_SUCCESS = prefix('LOAD_KID_GRADE_FIELDS_SUCCESS');
export const LOAD_KID_GRADE_FIELDS_FAILED = prefix('LOAD_KID_GRADE_FIELDS_FAILED');

export const LOAD_KID_LEARNING_LINE_START = prefix('LOAD_KID_LEARNING_LINE_START');
export const LOAD_KID_LEARNING_LINE_SUCCESS = prefix('LOAD_KID_LEARNING_LINE_SUCCESS');
export const LOAD_KID_LEARNING_LINE_FAILED = prefix('LOAD_KID_LEARNING_LINE_FAILED');

export const MOVE_CHILD_TO_LEARNING_LINE_START = prefix('MOVE_CHILD_TO_LEARNING_LINE_START');
export const MOVE_CHILD_TO_LEARNING_LINE_SUCCESS = prefix('MOVE_CHILD_TO_LEARNING_LINE_SUCCESS');
export const MOVE_CHILD_TO_LEARNING_LINE_FAILED = prefix('MOVE_CHILD_TO_LEARNING_LINE_FAILED');
