import React from 'react';
import cx from 'classnames';
import Typography, { type TypographyProps } from '@mui/material/Typography';
import { type TextColor, type TextFontProps, useTextStyles } from './styles';

export type TextProps<C extends React.ElementType> = Omit<TypographyProps<C>, 'color'> &
  TextFontProps & {
    color?: TextColor;
    component?: C;
  };

export const Text = <C extends React.ElementType = 'span'>({
  className,
  color = 'black',
  children,
  ...props
}: TextProps<C>) => {
  const s = useTextStyles(props);
  return (
    <Typography className={cx(className, s.text, s[color])} {...props}>
      {children}
    </Typography>
  );
};
