import React from 'react';
import { useAuthData } from 'data/hooks';
import { useActiveGroupId } from 'data/stores/group-store';
import { useCoachGroups } from 'app/utils/hooks/useCoachGroups';
import { match } from 'ts-pattern';
import Loader from 'app/components/core/preloader';
import { ROLE } from 'data/enums';

type GroupContainerProps = {
  children: React.ReactNode;
};

export function GroupContainer({ children }: GroupContainerProps) {
  const { role } = useAuthData();

  const activeGroupId = useActiveGroupId({ fallback: false });
  const queryData = useCoachGroups();

  return match({ role, activeGroupId, queryData })
    .with({ queryData: { isLoading: true }, role: ROLE.COACH }, () => <Loader />)
    .otherwise(() => <>{children}</>);
}
