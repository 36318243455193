import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const RadioButtonPropTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export const RadioButton = ({ className, options, value, onChange, ...props }) => (
  <FormControl>
    <RadioGroup value={value} onChange={onChange}>
      {options.map((element) => (
        <FormControlLabel
          key={element.label}
          value={element.value}
          control={<Radio className={className} disabled={element.disable} {...props} />}
          label={element.label}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

RadioButton.defaultProps = {
  className: null,
  color: 'secondary',
  options: null,
  value: null,
  onChange: null,
};

RadioButton.propTypes = RadioButtonPropTypes;
