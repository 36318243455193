import { type AppTheme } from '@coach/ui';
import { makeStyles } from '@mui/styles';

export const useModalTitleStyles = makeStyles<AppTheme>((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

export const useModalStyles = makeStyles<AppTheme>((theme) => ({
  modal: {
    width: '360px',
    borderRadius: '12px',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.boxShadows.secondary,
    padding: '24px',
    height: 'fit-content',
  },
  closeIcon: {
    position: 'absolute',
    right: 7,
    top: 6,
    background: 'none',
    outline: 'none',
    border: 0,
    cursor: 'pointer',
    zIndex: 10,
    '&:hover': {
      cursor: 'pointer',
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  modalBack: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    border: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 1050,
    padding: '24px',
  },
  modalHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  modalHeaderClose: {
    border: 0,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: '30px',
    fontWeight: 400,
    lineHeight: 0,
    outline: 'none',
    transform: 'rotate(45deg)',
  },
  modalFooter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '32px',
  },
  contentText: {
    textAlign: 'left',
  },
}));
