import { CONFIG_NAME } from 'data/enums';
import { type z } from 'zod';
import { JumpConfigValidator } from './jump-config.validator';
import { StripeConfigValidator } from './stripe-config.validator';
import { ProgressConfigValidator } from './progress-config.validator';
import { MaintenanceConfigValidator } from './maintenance-config.validator';
import { GeneralConfigValidator } from './general-config.validator';
import { TTSConfigValidator } from './tts-config.validator';
import { ReportConfigValidator } from './report-config.validator';

export const ConfigValidator = {
  [CONFIG_NAME.JUMP]: JumpConfigValidator,
  [CONFIG_NAME.STRIPE]: StripeConfigValidator,
  [CONFIG_NAME.PROGRESS]: ProgressConfigValidator,
  [CONFIG_NAME.MAINTENANCE]: MaintenanceConfigValidator,
  [CONFIG_NAME.GENERAL]: GeneralConfigValidator,
  [CONFIG_NAME.TTS]: TTSConfigValidator,
  [CONFIG_NAME.REPORT]: ReportConfigValidator,
};

export type ConfigBodyValidator = {
  [K in CONFIG_NAME]: z.infer<(typeof ConfigValidator)[K]>;
};

export * from './jump-config.validator';
export * from './stripe-config.validator';
export * from './progress-config.validator';
export * from './maintenance-config.validator';
export * from './general-config.validator';
export * from './tts-config.validator';
export * from './report-config.validator';
