import React from 'react';
import cx from 'app/utils/helpers/cx';
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';

const NavigationMenu = NavigationMenuPrimitive.Root;
const NavigationMenuItem = NavigationMenuPrimitive.Item;
const NavigationMenuLink = NavigationMenuPrimitive.Link;
const NavigationMenuIndicator = NavigationMenuPrimitive.Indicator;
const NavigationMenuViewport = NavigationMenuPrimitive.Viewport;
const NavigationMenuTrigger = NavigationMenuPrimitive.Trigger;

const NavigationMenuList = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(function NavigationMenuList({ className, ...props }, ref) {
  return (
    <NavigationMenuPrimitive.List
      ref={ref}
      className={cx('flex h-full flex-1 flex-col overflow-auto', className)}
      {...props}
    />
  );
});

const NavigationMenuContent = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Content>
>(function NavigationMenuContent({ className, ...props }, ref) {
  return (
    <NavigationMenuPrimitive.Content
      ref={ref}
      className={cx(
        'left-0 top-0 w-full',
        'md:absolute md:w-auto',
        'data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out',
        'data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out',
        'data-[motion=from-start]:slide-in-from-left-52 data-[motion=to-start]:slide-out-to-left-52',
        'data-[motion=from-end]:slide-in-from-right-52 data-[motion=to-end]:slide-out-to-right-52',
        className,
      )}
      {...props}
    />
  );
});

export {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuContent,
  NavigationMenuTrigger,
  NavigationMenuLink,
  NavigationMenuIndicator,
  NavigationMenuViewport,
};
