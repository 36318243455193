import api from 'data/axios/react-query-api';
import { type UserEntity } from 'data/types/entities/user.entity';
import { type PasswordValidator } from 'data/validators/body/password.validator';
import { type PasscodeValidator } from 'data/validators/body/passcode.validator';
import { type ListEntity } from 'data/utils/types';
import { type PaginatedSearchQuery } from 'data/validators';
import { type EditUserValidator } from 'data/validators/body/user/edit-user.validator';

export const fetchUser = (userId: string): Promise<UserEntity> => api.get(`/users/${userId}`);

export const fetchAllUsers = (params: PaginatedSearchQuery = {}) =>
  api.get<ListEntity<UserEntity>>('/users', { params });

export const updateUser = (userId: string, body: EditUserValidator) => api.patch<UserEntity>(`/users/${userId}`, body);

export const setProfilePicture = async (userId: string, file: File): Promise<void> => {
  const buffer = await file.arrayBuffer();

  return api.post(`/users/${userId}/profile_picture`, buffer, {
    headers: {
      'Content-Type': file.type,
    },
  });
};

export const deleteProfilePicture = async (userId: string): Promise<void> =>
  api.delete(`/users/${userId}/profile_picture`);

export const removeUser = (userId: string) => api.delete(`/users/${userId}`);

export const updatePassword = (userId: string, body: PasswordValidator) =>
  api.patch<UserEntity>(`/users/${userId}/passwords`, body);

export const updatePasscode = (userId: string, body: PasscodeValidator) =>
  api.patch<UserEntity>(`/users/${userId}/passcodes`, body);

export const syncWithMarketingProvider = (userId: string) => api.post<UserEntity>(`/users/${userId}/sync`);
