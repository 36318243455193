import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { type CreateAxiosDefaults } from 'axios';
import rootReducer, { type RootState } from 'data/reducers';
import * as schema from 'data/utils/schemas';
import Immutable from 'immutable';
import { normalize } from 'normalizr';
import { type AnyAction, type Store } from 'redux';
import thunk, { type ThunkDispatch } from 'redux-thunk';

import API from 'data/axios/redux-api';
import { type ThunkExtraArguments } from 'data/types/thunks';
import { API_BASE_URL, PROD } from 'app/constants/env';

const thunkExtraArguments: Partial<ThunkExtraArguments> = {
  schema,
  normalize,
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const actionTimestamp = () => (next) => (action) =>
  next({
    ...action,
    timestamp: new Date(),
  });

export type AppStore = Store<RootState> & {
  dispatch: ThunkDispatch<RootState, ThunkExtraArguments, AnyAction>;
};

const store: AppStore = configureStore({
  reducer: rootReducer,
  middleware: [thunk.withExtraArgument(thunkExtraArguments), actionTimestamp],
  devTools: !PROD && {
    trace: true,
    serialize: {
      immutable: Immutable,
    },
  },
  enhancers: [sentryReduxEnhancer],
});

const axiosOptions: CreateAxiosDefaults = { baseURL: API_BASE_URL };

export const api: API = new API(store, axiosOptions);

thunkExtraArguments.api = api;

export type AppDispatch = typeof store.dispatch;

export default store;
