import { fromJS, Map } from 'immutable';
import * as constants from 'data/constants/words';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const wordsProgress = new LoadingProgress('wordsProgress');
export const wordProgress = new LoadingProgress('wordProgress');
export const wordsPagination = new Pagination('wordsPagination');
export const wordRemovalProgress = new LoadingProgress('wordRemovalProgress');
export const wordFileImagesProgress = new LoadingProgress('wordFileImagesProgress');
export const wordFileAudiosProgress = new LoadingProgress('wordFileAudiosProgress');
export const wordFileImagesPagination = new Pagination('wordFileImagesPagination');
export const wordFileAudiosPagination = new Pagination('wordFileAudiosPagination');
export const wordMistakesProgress = new LoadingProgress('wordMistakesProgress');
export const wordFileProgress = new LoadingProgress('wordFileProgress');

const loadWords = (state, action) =>
  state.withMutations((newState) => {
    const {
      limit,
      count,
      result,
      entities: { words },
    } = action.payload;

    newState.mergeIn(['entities'], fromJS(words));
    wordsPagination.set(newState, count, limit, fromJS(result));
    wordsProgress.setLoaded(newState);
  });

const deleteWord = (state, action) =>
  state.withMutations((newState) => {
    const { wordId } = action.payload;
    newState.deleteIn(['entities', wordId]);
    newState.set(
      'wordsPaginationPageItems',
      state.get('wordsPaginationPageItems').filter((el) => el !== wordId),
    );
    newState.set('wordsPaginationTotalItems', state.get('wordsPaginationTotalItems') - 1);
    wordRemovalProgress.setLoaded(newState);
  });

const loadWord = (state, action) =>
  state.withMutations((newState) => {
    const { data, wordId } = action.payload;
    newState.mergeIn(['entities', wordId], fromJS(data));
    wordProgress.setLoaded(newState);
  });

const editWord = (state, action) =>
  state.withMutations((newState) => {
    const { data, wordId } = action.payload;
    newState.mergeIn(['entities', wordId], fromJS(data));
  });

const loadWordFileImages = (state, action) =>
  state.withMutations((newState) => {
    const {
      limit,
      count,
      result,
      entities: { files },
    } = action.payload;
    newState.mergeIn(['entities', 'image'], fromJS(files));
    wordFileImagesPagination.set(newState, count, limit, fromJS(result));
    wordFileImagesProgress.setLoaded(newState);
  });

const loadWordFileAudios = (state, action) =>
  state.withMutations((newState) => {
    const {
      limit,
      count,
      result,
      entities: { files },
    } = action.payload;
    newState.mergeIn(['entities', 'audio'], fromJS(files));
    wordFileAudiosPagination.set(newState, count, limit, fromJS(result));
    wordFileAudiosProgress.setLoaded(newState);
  });

const loadMistakes = (state, action) =>
  state.withMutations((newState) => {
    const { data } = action.payload;
    newState.setIn(['entities', 'mistakes'], fromJS(data));
    wordMistakesProgress.setLoaded(newState);
  });

const createMistake = (state, action) =>
  state.withMutations((newState) => {
    const { data } = action.payload;
    newState.updateIn(['entities', 'mistakes'], (mistakes) => mistakes.push(fromJS(data)));
    wordMistakesProgress.setLoaded(newState);
  });

const updateMistake = (state, action) =>
  state.withMutations((newState) => {
    const { id, values } = action.payload;
    newState.updateIn(['entities', 'mistakes'], (mistakes) =>
      mistakes.map((mistake) => (mistake.get('id') === id ? fromJS(values) : mistake)),
    );
    wordMistakesProgress.setLoaded(newState);
  });

const updateFile = (state, action) =>
  state.withMutations((newState) => {
    const { id, values, type } = action.payload;
    newState.updateIn(['entities', type], (files) =>
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      Map.isMap(files) ? files.map((file) => (file.get('id') === id ? fromJS(values) : file)) : files,
    );
    wordFileProgress.setLoaded(newState);
  });

const deleteMistake = (state, action) =>
  state.withMutations((newState) => {
    const { id } = action.payload;
    newState.updateIn(['entities', 'mistakes'], (mistakes) => mistakes.filter((mistake) => mistake.get('id') !== id));
    wordMistakesProgress.setLoaded(newState);
  });

const initialState = fromJS({ entities: {} });

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_WORDS_START:
      return wordsProgress.setLoading(state);
    case constants.FETCH_WORDS_SUCCESS:
      return loadWords(state, action);
    case constants.FETCH_WORDS_FAILED:
      return wordsProgress.setLoadFailed(state);
    case constants.FETCH_WORD_START:
      return wordProgress.setLoading(state);
    case constants.FETCH_WORD_SUCCESS:
      return loadWord(state, action);
    case constants.FETCH_WORD_FAILED:
      return wordProgress.setLoadFailed(state);
    case constants.EDIT_WORD_SUCCESS:
      return editWord(state, action);
    case constants.DELETE_WORD_START:
      return wordRemovalProgress.setLoading(state);
    case constants.DELETE_WORD_SUCCESS:
      return deleteWord(state, action);
    case constants.DELETE_WORD_FAILED:
      return wordRemovalProgress.setLoadFailed(state);
    case constants.FETCH_WORD_FILE_IMAGES_START:
      return wordFileImagesProgress.setLoading(state);
    case constants.FETCH_WORD_FILE_IMAGES_SUCCESS:
      return loadWordFileImages(state, action);
    case constants.FETCH_WORD_FILE_IMAGES_FAILED:
      return wordFileImagesProgress.setLoadFailed(state);
    case constants.FETCH_WORD_FILE_AUDIOS_START:
      return wordFileAudiosProgress.setLoading(state);
    case constants.FETCH_WORD_FILE_AUDIOS_SUCCESS:
      return loadWordFileAudios(state, action);
    case constants.FETCH_WORD_FILE_AUDIOS_FAILED:
      return wordFileAudiosProgress.setLoadFailed(state);
    case constants.FETCH_WORD_FMM_START:
    case constants.CREATE_WORD_FMM_START:
    case constants.DELETE_WORD_FMM_START:
    case constants.UPDATE_WORD_FMM_START:
      return wordMistakesProgress.setLoading(state);
    case constants.FETCH_WORD_FMM_SUCCESS:
      return loadMistakes(state, action);
    case constants.FETCH_WORD_FMM_FAILED:
    case constants.CREATE_WORD_FMM_FAILED:
    case constants.DELETE_WORD_FMM_FAILED:
    case constants.UPDATE_WORD_FMM_FAILED:
      return wordMistakesProgress.setLoadFailed(state);
    case constants.CREATE_WORD_FMM_SUCCESS:
      return createMistake(state, action);
    case constants.UPDATE_WORD_FMM_SUCCESS:
      return updateMistake(state, action);
    case constants.UPDATE_WORD_FILE_SUCCESS:
      return updateFile(state, action);
    case constants.DELETE_WORD_FMM_SUCCESS:
      return deleteMistake(state, action);
    case constants.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
