import { type Theme } from '@mui/material/styles';

/**
 * specifics of mui:
 * breakpoints values that are not in this list [xs, sm, md, lg, xl]
 * will not work in down() function
 * for example: theme.breakpoints.down('xs') = @media (max-width: 480px),
 * it takes the next value, it is 'sm' here
 * but: theme.breakpoints.down('xl') = @media (min-width: 320px),
 * because mui thinks that 'xl' is the last value
 * theme.breakpoints.down('xxl') will not work at all
 * all works for the up() function
 */

export const appTheme = {
  breakpoints: {
    values: {
      xs: 320,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1280,
      ll: 1450,
      xxl: 1920,
    },
  },
  palette: {
    error: {
      lighter: 'rgba(236,64,122,0.08)',
      dark: '#D81B60',
      main: '#EC407A',
    },
    secondary: {
      dark: '#00796B',
      main: '#00BFA5',
      contrastText: '#FFFFFF',
    },
    primary: {
      light: '#F1F9FF',
      dark: '#1565C0',
      main: '#2196F3',
      contrastText: '#FFFFFF',
    },
    whiteB: {
      light: 'rgba(0,0,0,0)',
      main: '#E3F2FD',
      dark: '#D9EFFF',
    },
    whiteP: {
      light: 'rgba(0,0,0,0)',
      main: 'rgba(236,64,122,0.08)',
    },
    whiteG: {
      light: 'rgba(0,0,0,0)',
      main: '#00BFA5',
    },
    grey: {
      light: '#C4C4C4',
      main: '#757575',
    },
    success: {
      dark: '#00796B',
      main: '#00BFA5',
      light: '#B3ECE4',
    },
    text: {
      white: '#FFFFFF',
      black: '#212121',
      primary: '#212121',
      purple: '#D81B60',
      blue: '#1565C0',
      lightGrey: '#E8E8E8',
      secondary: '#757575',
      green: '#00BFA5',
    },
    base: {
      900: '#212121',
      800: '#363636',
      700: '#757575',
      600: '#9E9E9E',
      500: '#BDBDBD',
      400: '#E0E0E0',
      300: '#EEEEEE',
      200: '#FAFAFA',
      100: '#FFFFFF',
    },
    backgroundColor: '#FAFAFA',
    darkGreen: '#47A98C',
    borderColor: '#F4F8FB',
    gradient: 'linear-gradient(225deg, #2196F3 0%, #00BFA5 100%)',
  },
  boxShadows: {
    primary: '0px 1px 3px rgba(142, 142, 142, 0.2)',
    secondary: '0px 10px 15px -5px rgba(62, 57, 107, 0.07)',
    tile: '0px 4px 10px rgba(142, 142, 142, 0.2)',
    dropdown:
      '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px rgba(0, 0, 0, 0.14)',
    pressed:
      '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px rgba(0, 0, 0, 0.14)',
    tab: 'inset 0px 0px 4px rgba(165, 165, 165, 0.7)',
    notification:
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
    row: 'inset 0px -1px 0px rgba(0, 0, 0, 0.25)',
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['"Open Sans"', 'sans-serif'].join(','),
    /**
     * title config needs to be deleted when the typography is refactored
     */
    title: {
      fontWeight: 600,
    },
    h1: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 800,
    },
    h2: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 400,
    },
    h3: {
      fontSize: '18px',
      lineHeight: '26px',
      fontWeight: 600,
    },
    h4: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    h5: {
      fontSize: '14px',
      lineHeight: '22px',
      fontWeight: 600,
    },
    h6: {
      fontSize: '14px',
      lineHeight: '22px',
      fontWeight: 400,
    },
    body1: {
      fontWeight: 100,
    },
    body2: {
      fontWeight: 500,
    },
    button: {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 600,
    },
    fontWeightMedium: 600,
  },
  components: {
    MuiChip: {
      styleOverrides: {
        deleteIcon: {
          color: '#2196F3',
          '&:hover': {
            color: '#1565C0',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&$checked': {
            backgroundColor: 'rgba(0,191,165,0.3)',
          },
        },
      },
    },
    MuiPickerDTTabs: {
      styleOverrides: {
        tabs: {
          backgroundColor: 'transparent',
          color: '#2196F3',
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        toolbar: {
          borderRadius: 0,
          boxShadow: 'inset 0 -30px 120px -30px rgba(0, 0, 0, 0.3)',
          backgroundColor: '#2196F3',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        isSelected: {
          backgroundColor: '#2196F3',
          '&:hover': {
            backgroundColor: '#1565C0',
          },
        },
        current: {
          color: '#2196F3',
        },
        day: {
          fontFamily: ['"Open Sans"', 'sans-serif'].join(','),
          fontWeight: 100,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        textPrimary: {
          color: '#2196F3',
          MuiChip: {
            root: {
              '&:hover': {
                backgroundColor: 'blue',
              },
            },
            deleteIcon: {
              color: '#2196F3',
              '&:hover': {
                color: '#1565C0',
              },
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        '& .MuiMenuItem-selected': {
          backgroundColor: 'red',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          display: 'inline-block',
          borderRadius: '18px',
          height: 36,
        },
        indicator: {
          display: 'none',
        },
      },
    },
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        body1: 'p',
        body2: 'p',
      },
    },
  },
} as const;

export type AppTheme = Theme & typeof appTheme;
