import api from 'data/axios/react-query-api';
import {
  type DidacticBoxWithPositionEntity,
  type DomainTypeWithTowerProgressesEntity,
  type FieldWithLearningLineProgresses,
  type FieldWithTowerProgressesEntity,
  type GradeDetailEntity,
  type JumpConfiguration,
  type KidLearningLinesPositionEntity,
  type KidPositionEntity,
  type KidPositionWithDomainOrTowerEntity,
  type LearningBoxWithPositionEntity,
  type LearningLineWithPositionEntity,
  type LearningQuestionBoxWithPositionEntity,
  type TowerBoxWithPositionEntity,
  type TowerPositionBaseEntity,
  type TowerQuestionBoxWithPositionEntity,
  type TowerWithPositionEntity,
} from 'data/types/entities';
import { type ListEntity } from 'data/utils/types';
import { type LearningLinePositionQuery } from 'data/validators/query-params/learning-line-position.query';

export const fetchJumpConfiguration = () => api.get<JumpConfiguration>(`/learning/progress/config`);

// By kids and coaches
export const fetchKidDomainWithTowerProgresses = (
  kidId: string,
  domainId: string,
): Promise<DomainTypeWithTowerProgressesEntity> => api.get(`/progresses/kids/${kidId}/towers/domains/${domainId}`);

export const fetchKidDidacticBoxWithPosition = (
  kidId: string,
  didacticBoxId: string,
): Promise<DidacticBoxWithPositionEntity> => api.get(`/progresses/kids/${kidId}/didacticboxes/${didacticBoxId}`);

export const fetchKidTowerWithTowerBoxesAndPosition = (
  kidId: string,
  towerId: string,
): Promise<TowerWithPositionEntity> => api.get(`/progresses/kids/${kidId}/towers/${towerId}`);

export const fetchKidTowerPosition = (kidId: string, towerId: string): Promise<TowerPositionBaseEntity> =>
  api.get(`/progresses/kids/${kidId}/towers/${towerId}/position`);

export const fetchLearningLineWithDidacticBoxesAndPosition = (
  kidId: string,
  learningLineId: string,
): Promise<LearningLineWithPositionEntity> => api.get(`/progresses/kids/${kidId}/learninglines/${learningLineId}`);

export const fetchKidRecentLearningLinePosition = (kidId: string, params: LearningLinePositionQuery = {}) =>
  api.get<KidLearningLinesPositionEntity>(`/progresses/kids/${kidId}/position/learning-lines`, { params });

// By coach - Towers
export const moveKidPositionToDomain = (kidId: string, domainId: string) =>
  api.get<KidPositionWithDomainOrTowerEntity>(`/progresses/kids/${kidId}/domains/${domainId}/position`);

export const fetchKidTowerBoxWithQuestionBoxProgresses = (
  kidId: string,
  towerBoxId: string,
): Promise<TowerBoxWithPositionEntity> => api.get(`/progresses/kids/${kidId}/towerboxes/${towerBoxId}`);

export const fetchKidQuestionBoxWithTowerQuestionsAndPosition = (
  kidId: string,
  towerBoxId: string,
  questionBoxId: string,
): Promise<TowerQuestionBoxWithPositionEntity> =>
  api.get(`/progresses/kids/${kidId}/towerboxes/${towerBoxId}/questionboxes/${questionBoxId}`);

export const fetchKidFieldsWithTowerProgresses = (kidId: string): Promise<ListEntity<FieldWithTowerProgressesEntity>> =>
  api.get(`progresses/kids/${kidId}/towers/fields`);

// By coach - Learning lines
export const fetchKidLearningBoxWithQuestionBoxesProgresses = (
  kidId: string,
  learningBoxId: string,
): Promise<LearningBoxWithPositionEntity> => api.get(`/progresses/kids/${kidId}/learningboxes/${learningBoxId}`);

export const fetchKidQuestionBoxWithLearningQuestionsAndPosition = (
  kidId: string,
  learningBoxId: string,
  questionBoxId: string,
): Promise<LearningQuestionBoxWithPositionEntity> =>
  api.get(`/progresses/kids/${kidId}/learningboxes/${learningBoxId}/questionboxes/${questionBoxId}`);

export const fetchKidGradesWithLearningProgresses = (kidId: string): Promise<ListEntity<GradeDetailEntity>> =>
  api.get(`/progresses/kids/${kidId}/learninglines/grades`);

export const fetchKidGradeFieldsWithLearningLinesProgresses = (
  kidId: string,
  gradeId: string,
): Promise<ListEntity<FieldWithLearningLineProgresses>> =>
  api.get(`/progresses/kids/${kidId}/learninglines/grades/${gradeId}/fields`);

// By Kid
export const fetchKidPositionByKid = (): Promise<KidPositionEntity> => api.get(`/progresses/position`);
