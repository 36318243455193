import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './styles.css';

const Loader = ({ inModal, className }) => (
  <div className={cx('banterloader', inModal ? 'inModal' : '', className)}>
    <div className="banterloaderbox" />
    <div className="banterloaderbox" />
    <div className="banterloaderbox" />
    <div className="banterloaderbox" />
    <div className="banterloaderbox" />
    <div className="banterloaderbox" />
    <div className="banterloaderbox" />
    <div className="banterloaderbox" />
    <div className="banterloaderbox" />
  </div>
);

Loader.propTypes = {
  inModal: PropTypes.bool,
  className: PropTypes.string,
};

Loader.defaultProps = {
  inModal: false,
  className: '',
};

export default Loader;
