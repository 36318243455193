import React, { useEffect, useState } from 'react';
import { useFetchUser, useTokenData, useTokenRefresh } from 'data/hooks';
import { MILLISECONDS } from 'app/constants/data';
import Loader from 'app/components/core/preloader';
import { STATUS_CODE } from 'data/enums';
import { logout } from 'data/utils/helpers/logout/logout';

interface AuthenticationProviderProps {
  children: React.ReactNode;
}

const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
  const [isEffectFinished, setIsEffectFinished] = useState(false);

  const tokenData = useTokenData();
  const isAuthenticated = !!tokenData;

  const { mutate: refreshToken, isLoading } = useTokenRefresh();
  const { refetch: refetchUser } = useFetchUser(tokenData?.userId as string, {
    enabled: false,
    staleTime: 2 * MILLISECONDS.MINUTE,
    onError: (error) => {
      const { response } = error;

      if (response?.status === STATUS_CODE.UNAUTHORIZED || response?.status === STATUS_CODE.NOT_FOUND) {
        logout();
      }
    },
  });

  useEffect(() => {
    if (!isAuthenticated) {
      setIsEffectFinished(true);
      return;
    }

    const { exp = 0 } = tokenData;
    const expirationTimestamp = exp * MILLISECONDS.SECOND;
    const isAboutToExpire = expirationTimestamp - Date.now() < MILLISECONDS.MINUTE * 5;
    const hasExpired = expirationTimestamp <= Date.now();
    const shouldRefreshToken = isAboutToExpire || hasExpired;

    if (!shouldRefreshToken) {
      setIsEffectFinished(true);
      refetchUser();
      return;
    }

    refreshToken(
      {},
      {
        onSettled: () => {
          setIsEffectFinished(true);
        },
      },
    );
  }, []);

  return !isLoading && isEffectFinished ? <>{children}</> : <Loader />;
};

export default AuthenticationProvider;
