const prefix = (type) => `eureka/didacticBoxes/${type}`;

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');
export const LOAD_BOX_SUCCESS = prefix('LOAD_BOX_SUCCESS');
export const EDITING_SUCCESS = prefix('EDITING_SUCCESS');
export const EDITING_START = prefix('EDITING_START');
export const EDITING_FAILED = prefix('EDITING_FAILED');
export const REMOVING_START = prefix('REMOVING_START');
export const REMOVING_SUCCESS = prefix('REMOVING_SUCCESS');
export const REMOVING_FAILED = prefix('REMOVING_FAILED');
export const LEARNING_CREATION_START = prefix('LEARNING_CREATION_START');
export const LEARNING_CREATION_SUCCESS = prefix('LEARNING_CREATION_SUCCESS');
export const LEARNING_CREATION_FAILED = prefix('LEARNING_CREATION_FAILED');
export const LOAD_DIDACTIC_START = prefix('LOAD_DIDACTIC_START');
export const LOAD_DIDACTIC_SUCCESS = prefix('LOAD_DIDACTIC_SUCCESS');
export const LOAD_DIDACTIC_FAILED = prefix('LOAD_DIDACTIC_FAILED');
export const DRAGANDDROP_START = prefix('DRAGNDROP_START');
export const DRAGANDDROP_SUCCESS = prefix('DRAGNDROP_SUCCESS');
export const DRAGANDDROP_FAILED = prefix('DRAGNDROP_FAILED');

export const FETCH_DIDACTIC_START = prefix('FETCH_DIDACTIC_START');
export const FETCH_DIDACTIC_SUCCESS = prefix('FETCH_DIDACTIC_SUCCESS');
export const FETCH_DIDACTIC_FAILED = prefix('FETCH_DIDACTIC_FAILED');

export const MOVE_CHILD_TO_DIDACTIC_BOX_START = prefix('MOVE_CHILD_TO_DIDACTIC_BOX_START');
export const MOVE_CHILD_TO_DIDACTIC_BOX_SUCCESS = prefix('MOVE_CHILD_TO_DIDACTIC_BOX_SUCCESS');
export const MOVE_CHILD_TO_DIDACTIC_BOX_FAILED = prefix('MOVE_CHILD_TO_DIDACTIC_BOX_FAILED');

export const CLEAR = prefix('CLEAR');
export const CLEAR_STATE = prefix('CLEAR_STATE');
