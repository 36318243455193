import {
  type IError,
  type LearningLinePositionContext,
  type ValidateProgressFormValues,
  type ValidationState,
} from 'data/types/entities';
import { progressKeys } from 'data/hooks/queries/progresses';
import {
  useMutation,
  type UseMutationResult,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';

import * as api from 'data/api';
import { type AxiosError } from 'axios';
import { type QuestionHistoryState } from 'app/pages/kid/questions/[educationStrategy]/[id]/page';
import { getProgressStore } from 'data/stores/progress-store';

interface UseLearningLinePositionArgs {
  id?: string;
  historyState?: QuestionHistoryState;
  options?: Omit<UseQueryOptions<LearningLinePositionContext, AxiosError<IError>>, 'queryKey' | 'queryFn'>;
}

export const useLearningLinePosition = ({
  id = '',
  historyState,
  options,
}: UseLearningLinePositionArgs): UseQueryResult<LearningLinePositionContext, AxiosError<IError>> =>
  useQuery(progressKeys.position(id, historyState), () => api.fetchLearningLinePosition(id), {
    enabled: !!id,
    refetchOnWindowFocus: false,
    ...options,
  });

export const useValidateLearningLine = (): UseMutationResult<
  ValidationState,
  IError,
  ValidateProgressFormValues,
  unknown
> =>
  useMutation(progressKeys.validateLearningLine(), (values) => api.validateLearningLine(values), {
    onMutate: (values) => {
      const { setAnswerCountState } = getProgressStore().actions;
      setAnswerCountState((state) => ({
        ...state,
        timeSpentLastQuestions: state.timeSpentLastQuestions + (values?.answerInput?.seconds || 0),
      }));
    },
    onSuccess: (data) => {
      const {
        actions: { setAnswerCountState, setAttempts, setValidationState, setMistakes },
        attempts,
      } = getProgressStore();
      setAnswerCountState((state) => ({
        ...state,
        firstTimeRightCount: data.valid && attempts === 1 ? state.firstTimeRightCount + 1 : state.firstTimeRightCount,
      }));
      setAttempts((state) => (data.valid ? 1 : state + 1));
      setValidationState(data);
      setMistakes(data.mistakes);
    },
  });

interface UseLearningLineNextArgs {
  id?: string;
  historyState?: QuestionHistoryState;
  questionId?: string;
  learningBoxId?: string;
  enabled?: boolean;
}

export const useLearningLineNext = ({
  id,
  historyState,
  questionId = '',
  learningBoxId = '',
  enabled,
}: UseLearningLineNextArgs): UseQueryResult<LearningLinePositionContext, IError> => {
  const queryClient = useQueryClient();

  return useQuery(
    progressKeys.next(questionId, learningBoxId),
    () => api.fetchLearningLineNext(questionId, learningBoxId),
    {
      refetchOnWindowFocus: false,
      enabled: !!(questionId && learningBoxId && enabled),
      onSuccess: (data) => {
        if (!id) return;
        queryClient.setQueryData(progressKeys.position(id, historyState), data);
      },
    },
  );
};
