export const buttonVariantSizeShapeCompoundVariants = [
  {
    size: 'large',
    shape: 'square',
    class: 'rounded-[20px]',
  },
  {
    size: 'medium',
    shape: 'square',
    class: 'rounded-[20px]',
  },
  {
    variant: 'contained',
    size: 'medium',
    shape: 'square',
    class: 'px-[18px]',
  },
  {
    variant: 'outlined',
    size: 'medium',
    shape: 'square',
    class: 'px-[14px]',
  },
  {
    variant: 'contained',
    size: 'medium',
    shape: 'circle',
    class: 'px-[14px]',
  },
  {
    variant: 'outlined',
    size: 'medium',
    shape: 'circle',
    class: 'px-[10px]',
  },
  {
    size: 'small',
    shape: 'square',
    class: 'rounded-xl',
  },
  {
    variant: 'contained',
    size: 'small',
    shape: 'square',
    class: 'py-[10px]',
  },
  {
    variant: 'outlined',
    size: 'small',
    shape: 'square',
    class: 'py-[6px]',
  },
  {
    variant: 'contained',
    size: 'small',
    shape: 'circle',
    class: 'py-3',
  },
  {
    variant: 'outlined',
    size: 'small',
    shape: 'circle',
    class: 'py-2',
  },
  {
    size: 'extraSmall',
    shape: 'square',
    class: 'rounded-xl',
  },
  {
    variant: 'contained',
    size: 'extraSmall',
    shape: 'square',
    class: 'py-2',
  },
  {
    variant: 'outlined',
    size: 'extraSmall',
    shape: 'square',
    class: 'py-1',
  },
  {
    variant: 'outlined',
    size: 'extraSmall',
    shape: 'circle',
    class: 'py-[6px]',
  },
  {
    variant: 'contained',
    size: 'extraSmall',
    shape: 'circle',
    class: 'py-[10px]',
  },
] as const;
