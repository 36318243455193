import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    borderRadius: 24,
    width: 'max-content',
    alignItems: 'stretch',
    padding: 8,
    backgroundColor: '#572E85',
    maxWidth: '100%',
    overflowX: 'auto',
  },
  tab: {
    padding: 12,
    outline: 'none',
    transition: 'background-color 0.2s ease-in',
    border: 'none',
    borderRadius: 16,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '& svg': {
      marginRight: 6,
    },
  },
  tab_active: {
    backgroundColor: '#8354B8',
  },
}));

export default useStyles;
