import React from 'react';
import { NavLink, type NavLinkProps } from 'react-router-dom';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import cx from 'app/utils/helpers/cx';
import { setIsSidebarOpen } from 'data/stores/kid/sidebar-store';
import Typography from '@kid/ui/components/typography';

interface SidebarItemContentProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: React.ReactNode;
  title?: string;
  classes?: { root?: string; iconWrapper?: string; title?: string };
}

export const SidebarItemContent = ({ icon, title, className, classes, ...props }: SidebarItemContentProps) => (
  <div
    className={cx(
      'relative flex h-16 shrink-0 items-center gap-4 py-2 pl-5 pr-4',
      'md:gap-2 md:p-4',
      className,
      classes?.root,
    )}
    {...props}
  >
    <div className={cx('flex items-center justify-center text-2xl md:text-[32px]', classes?.iconWrapper)}>{icon}</div>
    <Typography
      size={{ base: 'xs', md: 's' }}
      color="white"
      className={cx('truncate text-center font-semibold leading-none md:w-full md:font-black', classes?.title)}
    >
      {title}
    </Typography>
  </div>
);

interface SidebarItemProps extends Omit<NavigationMenu.NavigationMenuLinkProps, 'href'> {
  to: NavLinkProps['to'];
  activeClassName?: NavLinkProps['activeClassName'];
  classes?: { root?: string; link?: string };
}

export const SidebarItem = React.forwardRef<React.ElementRef<typeof NavigationMenu.Link>, SidebarItemProps>(
  function SidebarItemLink({ className, to, activeClassName, classes, children, ...props }, ref) {
    return (
      <NavigationMenu.Item className={cx(classes?.root, className)}>
        <NavigationMenu.Link asChild ref={ref} onClick={() => setIsSidebarOpen(false)} {...props}>
          <NavLink
            to={to}
            className={cx('relative flex items-center justify-start', classes?.link)}
            activeClassName={cx('bg-emerald-800', activeClassName)}
          >
            {children}
          </NavLink>
        </NavigationMenu.Link>
      </NavigationMenu.Item>
    );
  },
);
