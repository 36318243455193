import React, { type FunctionComponent } from 'react';
import SvgIcon, { type SvgIconProps, type SvgIconTypeMap } from '@kid/ui/components/svg-icon';
import { type OverridableComponent } from 'ui/types';

const createSvgIcon = (svg: FunctionComponent<React.SVGProps<SVGSVGElement>>): OverridableComponent<SvgIconTypeMap> =>
  React.forwardRef(function Icon<C extends React.ElementType>(
    props: SvgIconProps<C>,
    ref: React.ForwardedRef<SVGSVGElement>,
  ): React.JSX.Element {
    return <SvgIcon ref={ref} component={svg} {...props} />;
  });

export default createSvgIcon;
