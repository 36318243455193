export enum QUESTION_BOX_CONFIG_TYPE {
  BY_TIME = 'BY_TIME',
  BY_AMOUNT = 'BY_AMOUNT',
}

export enum KEYBOARD_CONFIG {
  ALPHABETICAL_ONLY = 'alphabeticalOnly',
  NUMERIC_ONLY = 'numericOnly',
  ALPHANUMERIC = 'alphanumeric',
  ALPHABETICAL_PREFERRED = 'alphabeticalPreferred',
  NUMERICAL_PREFERRED = 'numericPreferred',
  HIDDEN = 'hidden',
}
