import { z } from 'zod';

export const mediaEntityType = {
  questionBox: 'questionBox',
  field: 'field',
  towerBox: 'towerBox',
  tower: 'tower',
  learningBox: 'learningBox',
  didacticBox: 'didacticBox',
  learningLine: 'learningLine',
  domainType: 'domainType',
} as const;

export const mediaEntityTypeSchema = z.enum(Object.values(mediaEntityType));

export type MediaEntityType = z.infer<typeof mediaEntityTypeSchema>;
