import { makeStyles } from '@mui/styles';

export const usePaginationStyles = makeStyles(() => ({
  pagination: {
    fontFamily: "'Nunito', sans-serif",
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  page: {
    all: 'unset',
    cursor: 'pointer',
    color: 'white',
  },
  active: {
    color: 'yellow',
  },
  paginationButton: {
    '& button': {
      zoom: '0.4',
      borderRadius: '6px !important',
    },
    '& svg': {
      fontSize: '40px',
      alignItems: 'center',
      marginLeft: '15px',
    },
  },
  iconRotated: {
    '& svg': {
      transform: 'rotate(180deg)',
      marginLeft: '-15px',
    },
  },
}));
