import React from 'react';
import { makeStyles } from '@mui/styles';
import TablePagination from '@mui/material/TablePagination';
import { type AppTheme } from '@coach/ui/utils/appTheme';

const usePaginationStyles = makeStyles<AppTheme>((theme) => ({
  pagination: {
    color: theme.palette.grey.main,
    '& .MuiTablePagination-input': {
      border: `1px solid ${theme.palette.base[400]}`,
      borderRadius: '4px',
      fontSize: '12px',
      fontWeight: 600,
    },
    '& .MuiTablePagination-caption': {
      fontSize: '14px',
      fontWeight: 400,
    },
  },
}));

interface PaginationProps {
  page: number;
  rowsPerPage: number;
  rowsCount: number;
  rowsPerPageOptions?: number[];
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  [key: string]: unknown;
}

export const Pagination = ({
  rowsCount,
  rowsPerPage,
  page,
  rowsPerPageOptions,
  handleChangePage,
  handleChangeRowsPerPage,
  ...props
}: PaginationProps) => {
  const s = usePaginationStyles();

  if (!rowsCount) {
    return null;
  }

  return (
    <TablePagination
      {...props}
      className={s.pagination}
      rowsPerPageOptions={rowsPerPageOptions || [5, 10, 25]}
      component="div"
      count={rowsCount || 0}
      rowsPerPage={rowsPerPage}
      page={page}
      backIconButtonProps={{
        'aria-label': 'previous page',
      }}
      nextIconButtonProps={{
        'aria-label': 'next page',
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};
