import { SORT_DIRECTION } from 'data/enums';

export class OrderToggler {
  public static sort = [null, SORT_DIRECTION.ASC, SORT_DIRECTION.DESC] as const;

  public direction: number;

  constructor(direction?: SORT_DIRECTION | null) {
    if (direction === SORT_DIRECTION.ASC) {
      this.direction = 2;
    } else if (direction === SORT_DIRECTION.DESC) {
      this.direction = 3;
    } else {
      this.direction = 1;
    }
  }

  public next = () => {
    this.direction = this.direction === OrderToggler.sort.length ? 1 : this.direction + 1;

    return OrderToggler.sort[this.direction - 1];
  };
}
