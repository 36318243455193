import { type ROLE } from 'data/enums';
import { useTokenData } from 'data/hooks';
import { useMemo } from 'react';

export function useHasAccess(allowedRoles: ROLE[]): boolean {
  const tokenData = useTokenData();
  const hasAccess = useMemo(() => !!tokenData && allowedRoles.includes(tokenData.role), [allowedRoles, tokenData]);

  return hasAccess;
}
