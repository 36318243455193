import { fromJS } from 'immutable';
import * as constants from 'data/constants/groups';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const kidsProgress = new LoadingProgress('kidsProgress');
export const kidsPagination = new Pagination('kidsPagination');
export const kidsReportsPagination = new Pagination('kidsReportsPagination');
export const kidsReportsProgress = new LoadingProgress('kidsReportsProgress');

const loadKids = (state, action) =>
  state.withMutations((newState) => {
    const {
      entities: { kids },
      result,
      count,
      limit,
    } = action.payload;
    newState.setIn(['kids'], fromJS(kids));
    newState.setIn(['kidsId'], fromJS(result));
    kidsPagination.set(newState, count, limit, fromJS(result));
    kidsProgress.setLoaded(newState);
  });

const loadKidsReports = (state, action) =>
  state.withMutations((newState) => {
    const {
      entities: { kids },
      result,
      count,
      limit,
    } = action.payload;
    newState.setIn(['kidsReports'], fromJS(kids));
    newState.setIn(['kidsReportId'], fromJS(result));
    kidsReportsPagination.set(newState, count, limit, fromJS(result));
    kidsReportsProgress.setLoaded(newState);
  });

const initialState = fromJS({
  entities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_ALL_KIDS_START:
    case constants.LOAD_ALL_KIDS_REPORTS_START:
      return kidsReportsProgress.setLoading(state);
    case constants.LOAD_ALL_KIDS_SUCCESS:
      return loadKids(state, action);
    case constants.LOAD_ALL_KIDS_REPORTS_SUCCESS:
      return loadKidsReports(state, action);
    case constants.LOAD_ALL_KIDS_FAILED:
      return kidsProgress.setLoadFailed(state);
    case constants.LOAD_ALL_KIDS_REPORTS_FAILED:
      return kidsReportsProgress.setLoadFailed(state);

    default:
      return state;
  }
};
