const prefix = (type) => `eureka/words/${type}`;

export const FETCH_WORDS_START = prefix('FETCH_WORDS_START');
export const FETCH_WORDS_SUCCESS = prefix('FETCH_WORDS_SUCCESS');
export const FETCH_WORDS_FAILED = prefix('FETCH_WORDS_FAILED');

export const FETCH_WORD_START = prefix('FETCH_WORD_START');
export const FETCH_WORD_SUCCESS = prefix('FETCH_WORD_SUCCESS');
export const FETCH_WORD_FAILED = prefix('FETCH_WORD_FAILED');

export const EDIT_WORD_START = prefix('EDIT_WORD_START');
export const EDIT_WORD_SUCCESS = prefix('EDIT_WORD_SUCCESS');
export const EDIT_WORD_FAILED = prefix('EDIT_WORD_FAILED');

export const DELETE_WORD_START = prefix('DELETE_WORD_START');
export const DELETE_WORD_SUCCESS = prefix('DELETE_WORD_SUCCESS');
export const DELETE_WORD_FAILED = prefix('DELETE_WORD_FAILED');

export const FETCH_WORD_FILE_IMAGES_START = prefix('FETCH_WORD_FILE_IMAGES_START');
export const FETCH_WORD_FILE_IMAGES_SUCCESS = prefix('FETCH_WORD_FILE_IMAGES_SUCCESS');
export const FETCH_WORD_FILE_IMAGES_FAILED = prefix('FETCH_WORD_FILE_IMAGES_FAILED');

export const FETCH_WORD_FILE_AUDIOS_START = prefix('FETCH_WORD_FILE_AUDIOS_START');
export const FETCH_WORD_FILE_AUDIOS_SUCCESS = prefix('FETCH_WORD_FILE_AUDIOS_SUCCESS');
export const FETCH_WORD_FILE_AUDIOS_FAILED = prefix('FETCH_WORD_FILE_AUDIOS_FAILED');

export const FETCH_WORD_FMM_START = prefix('FETCH_WORD_FMM_START');
export const FETCH_WORD_FMM_SUCCESS = prefix('FETCH_WORD_FMM_SUCCESS');
export const FETCH_WORD_FMM_FAILED = prefix('FETCH_WORD_FMM_FAILED');

export const CREATE_WORD_FMM_START = prefix('CREATE_WORD_FMM_START');
export const CREATE_WORD_FMM_SUCCESS = prefix('CREATE_WORD_FMM_SUCCESS');
export const CREATE_WORD_FMM_FAILED = prefix('CREATE_WORD_FMM_FAILED');

export const UPDATE_WORD_FMM_START = prefix('UPDATE_WORD_FMM_START');
export const UPDATE_WORD_FMM_SUCCESS = prefix('UPDATE_WORD_FMM_SUCCESS');
export const UPDATE_WORD_FMM_FAILED = prefix('UPDATE_WORD_FMM_FAILED');

export const DELETE_WORD_FMM_START = prefix('DELETE_WORD_FMM_START');
export const DELETE_WORD_FMM_SUCCESS = prefix('DELETE_WORD_FMM_SUCCESS');
export const DELETE_WORD_FMM_FAILED = prefix('DELETE_WORD_FMM_FAILED');

export const UPDATE_WORD_FILE_START = prefix('UPDATE_WORD_FILE_START');
export const UPDATE_WORD_FILE_SUCCESS = prefix('UPDATE_WORD_FILE_SUCCESS');
export const UPDATE_WORD_FILE_FAILED = prefix('UPDATE_WORD_FILE_FAILED');

export const CLEAR_STATE = prefix('CLEAR_STATE');
