import { fromJS } from 'immutable';
import * as constants from 'data/constants/didactic-boxes';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const didacticBoxesProgres = new LoadingProgress('didacticBoxesProgres');
export const didacticBoxesRemovalProgress = new LoadingProgress('didacticBoxesRemovalProgress');
export const didacticBoxesPagination = new Pagination('didactic');
export const didacticBoxAggregatedProgress = new LoadingProgress('didacticBoxAggregatedProgress');
export const didacticBoxMoveChildProgress = new LoadingProgress('didacticBoxMoveChildProgress');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { didacticBoxes },
    } = payload;
    newState.mergeIn(['entities'], fromJS(didacticBoxes));
  });

const mergeId = (state, action) =>
  state.withMutations((newState) => {
    const { didacticBoxId } = action;
    newState.mergeIn(['entities', didacticBoxId, 'learningBoxes'], fromJS(action.payload.result));
  });

const mergeDidacticBox = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { didacticBoxes },
    } = payload;
    newState.mergeIn(['entities'], fromJS(didacticBoxes));
  });

const loadDidacticBoxes = (state, action) =>
  state.withMutations((newState) => {
    const { result } = action.payload;
    mergeData(newState, action.payload);
    didacticBoxesPagination.set(newState, result.length, null, fromJS(result));
    didacticBoxesProgres.setLoaded(newState);
  });

const loadDidacticBox = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
  });

const editDidacticBox = (state, action) =>
  state.withMutations((newState) => {
    const { id } = action.payload;
    newState.mergeIn(['entities', id], fromJS(action.payload));
  });

const deleteDidacticBox = (state, action) =>
  state.withMutations((newState) => {
    const { didacticId } = action;
    newState.get('entities').delete(didacticId);
    didacticBoxesRemovalProgress.clear(newState);
  });

const dragAndDropDidacticBox = (state, action) =>
  state.withMutations((newState) => {
    const { count, result } = action.payload;
    mergeData(newState, action.payload);
    didacticBoxesPagination.set(newState, count, null, fromJS(result));
    didacticBoxesProgres.setLoaded(newState);
  });

const clearDidacticBoxes = (state) =>
  state.withMutations((newState) => {
    didacticBoxesPagination.clear(newState);
    didacticBoxesProgres.clear(newState);
  });

const loadDidactic = (state, action) =>
  state.withMutations((newState) => {
    mergeDidacticBox(newState, action.payload);
  });

const loadDidacticBoxAggregated = (state, action) =>
  state.withMutations((newState) => {
    const { data } = action;
    newState.mergeIn(['didacticBoxAggregated'], fromJS(data));
    didacticBoxAggregatedProgress.setLoaded(newState);
  });

const initialState = fromJS({
  didacticBoxes: {},
  learningLines: {},
  didacticBoxAggregated: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
    case constants.LEARNING_CREATION_START:
      // case constants.learningConstants.DIDACTIC_CREATION_START:
      return didacticBoxesProgres.setLoading(state);
    case constants.FETCH_DIDACTIC_START:
      return didacticBoxAggregatedProgress.setLoading(state);
    case constants.FETCH_DIDACTIC_SUCCESS:
      return loadDidacticBoxAggregated(state, action);
    case constants.FETCH_DIDACTIC_FAILED:
      return didacticBoxAggregatedProgress.setLoadFailed(state);
    case constants.REMOVING_START:
      return didacticBoxesRemovalProgress.setLoading(state);
    case constants.LOAD_SUCCESS:
      return loadDidacticBoxes(state, action);
    case constants.EDITING_SUCCESS:
      return editDidacticBox(state, action);
    case constants.REMOVING_SUCCESS:
      return deleteDidacticBox(state, action);
    case constants.LEARNING_CREATION_SUCCESS:
      return mergeId(state, action);
    case constants.LOAD_DIDACTIC_SUCCESS:
      return loadDidactic(state, action);
    case constants.DRAGANDDROP_SUCCESS:
      return dragAndDropDidacticBox(state, action);
    case constants.LOAD_BOX_SUCCESS:
      return loadDidacticBox(state, action);
    case constants.LOAD_FAILED:
    case constants.LEARNING_CREATION_FAILED:
    case constants.DRAGANDDROP_FAILED:
      return didacticBoxesProgres.setLoadFailed(state);
    case constants.REMOVING_FAILED:
      return didacticBoxesRemovalProgress.setLoadFailed(state);
    case constants.MOVE_CHILD_TO_DIDACTIC_BOX_START:
      return didacticBoxMoveChildProgress.setLoading(state);
    case constants.MOVE_CHILD_TO_DIDACTIC_BOX_SUCCESS:
      return didacticBoxMoveChildProgress.setLoaded(state);
    case constants.MOVE_CHILD_TO_DIDACTIC_BOX_FAILED:
      return didacticBoxMoveChildProgress.setLoadFailed(state);
    case constants.CLEAR:
      return clearDidacticBoxes(state);
    case constants.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
