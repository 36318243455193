import { makeStyles } from '@mui/styles';
import { type AppTheme } from '@coach/ui/utils/appTheme';

// @ts-expect-error TS(2345): Argument of type '(theme: Theme) => { root: { disp... Remove this comment to see the full error message
export const useStyles = makeStyles<AppTheme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  chips: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& .MuiChip-root': {
      marginLeft: 4,
      marginRight: 4,
    },
  },
  noOption: {
    display: 'flex !important',
    flexDirection: 'column !important',
    justifyContent: 'left !important',
  },
  option: {
    display: 'flex',
    flexDirection: 'row',
    height: 67,
    '&:hover': {
      backgroundColor: '#E8E8E8',
    },
  },
  hoverSingle: {
    height: 46,
    width: 320,
    paddingLeft: 16,
    paddingTop: 15,
    '&:hover': {
      backgroundColor: '#E8E8E8 !important',
    },
  },
  optionSingle: {
    display: 'flex',
    flexDirection: 'row',
    height: 46,
    '&:hover': {
      backgroundColor: '#E8E8E8',
    },
  },
  hover: {
    height: 67,
    width: 320,
    '&:hover': {
      backgroundColor: '#E8E8E8 !important',
    },
  },
  helper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    alightItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey.light}`,
    paddingTop: 7,
    paddingBottom: 14,
  },
  avatar: {
    paddingLeft: 7,
    paddingTop: 1,
    paddingBottom: 1,
    paddingRight: 6,
  },
  input: {
    display: 'flex',
    border: 1,
    width: 320,
    '& .MuiOutlinedInput-root': {
      height: 37,
      '& .Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-root': {
      '&.Mui-focused': { color: theme.palette.primary.main },
    },
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    height: 37,
    marginTop: -10,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    fontSize: 16,
  },
  paper: {
    position: 'static',
    zIndex: 1,
    marginTop: theme.spacing(1),
    width: 320,
  },
  error: {
    position: 'absolute',
    fontSize: '0.75rem',
    textAlign: 'left',
    lineHeight: '1.66',
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: 400,
    margin: '0px 14px',
    color: '#EC407A',
  },
}));

export const useHoverStyles = makeStyles(() => ({
  root: {
    // @ts-expect-error TS(2339): Property 'isFocused' does not exist on type '{}'.
    transform: (props) => (props.isFocused && !props.hasValue ? 'rotate(180deg)' : 'rotate(0deg)'),
    '&:hover': {
      cursor: 'pointer !important',
    },
  },
}));

export const selectStyles = (theme) => ({
  input: (base) => ({
    ...base,
    color: theme.palette.text.black,

    '& input': {
      font: 'inherit',
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 2 }),
  menu: (base) => ({ ...base, zIndex: 2 }),
  container: (base) => ({
    ...base,
    fontFamily: 'Open Sans,sans-serif',
    fontWeight: 100,
  }),
  singleValue: (base) => ({ ...base, lineHeight: 1.43 }),
});
