import { z } from 'zod';

export const ReportConfigValidator = z.object({
  /**
   * The required percentage of correct answers to pass the exercise.
   */
  correctPercentage: z.coerce.number().min(0).max(100),
  /**
   * The threshold of doubts percentage to mark the exercise as doubtful and ask to re-do it.
   */
  doubtsPercentage: z.coerce.number().min(0).max(100),
  /**
   * The threshold of wrong answers percentage to mark the exercise as failed and request support from the coach.
   */
  wrongPercentage: z.coerce.number().min(0).max(100),
});

export type ReportConfig = z.infer<typeof ReportConfigValidator>;
