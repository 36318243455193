import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@mui/material/TableHead';

export const Header = ({ children, ...props }) => <TableHead {...props}>{children}</TableHead>;

Header.propTypes = {
  children: PropTypes.node,
};

Header.defaultProps = {
  children: null,
};
