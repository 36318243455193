export const baseAdminPath = '/admin';
const applyBaseUrl = (url) => `${baseAdminPath}/${url}`;

export const generatorPath = applyBaseUrl('generator');
export const savedConfigPath = applyBaseUrl('saved-configs');
export const savedConfigEditPath = (configId) => `${savedConfigPath}/${configId}`;

export const wordListPath = applyBaseUrl('wordlist');
export const wordPath = (wordId) => `${wordListPath}/${wordId}`;
export const featuresPath = applyBaseUrl('features');

export const questionClipboardPath = applyBaseUrl('question-clipboard');

export const bookPackagesPath = applyBaseUrl('book-packages');

export const addBookPackagePath = `${bookPackagesPath}/add-book-package`;
export const bookPackagePath = (bookPackageId) => `${bookPackagesPath}/${bookPackageId}`;
export const questionBoxesPath = applyBaseUrl('question-boxes');

export const newQuestionBoxPath = `${questionBoxesPath}/add-question-box`;
export const questionBoxPath = (questionBoxId) => `${questionBoxesPath}/${questionBoxId}`;
export const editQuestionBoxPath = (questionBoxId) => `${questionBoxesPath}/${questionBoxId}/edit`;

export const membersPaths = (() => {
  const index = applyBaseUrl('members');
  const children = `${index}/children`;
  const child = (childId: string) => `${children}/${childId}`;
  const editChild = (childId: string) => `${children}/${childId}/edit`;
  const createChild = `${children}/create`;
  const admins = `${index}/admins`;
  const admin = (adminId: string) => `${admins}/${adminId}`;
  const coaches = `${index}/coaches`;
  const coach = (coachId: string) => `${coaches}/${coachId}`;
  const unconfirmedUsers = `${index}/unconfirmed-users`;

  return {
    index,
    children,
    child,
    editChild,
    createChild,
    admins,
    admin,
    coaches,
    coach,
    unconfirmedUsers,
  };
})();

export const groupsPath = applyBaseUrl('groups');
export const createGroupPath = `${groupsPath}/create`;

export const filesManagementPath = applyBaseUrl('files-management');

export const bookLicensesPath = applyBaseUrl('book-licenses');
export const createBookLicensePath = `${bookLicensesPath}/create-license`;
