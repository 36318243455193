import { fromJS } from 'immutable';
import * as constants from 'data/constants/fields';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const fieldsProgress = new LoadingProgress('fieldsProgress');
export const fieldsPagination = new Pagination('fields');
const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { fields },
    } = payload;
    newState.mergeIn(['entities'], fromJS(fields));
  });

const loadFields = (state, action) =>
  state.withMutations((newState) => {
    const { limit, count, result } = action.payload;
    mergeData(newState, action.payload);
    fieldsPagination.set(newState, count, limit, fromJS(result));
    fieldsProgress.setLoaded(newState);
  });

const initialState = fromJS({
  fields: {},
});
export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
      return fieldsProgress.setLoading(state);
    case constants.LOAD_SUCCESS:
      return loadFields(state, action);
    case constants.LOAD_FAILED:
      return fieldsProgress.setLoadFailed(state);
    case constants.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
