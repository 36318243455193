import React from 'react';
import cx from 'classnames';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import { type LinearProgressProps } from '@mui/material/LinearProgress/LinearProgress';

const useProgressBarStyles = makeStyles(() => ({
  root: {
    minHeight: 48,
    display: 'flex',
    alignItems: 'center',
    '& .MuiLinearProgress-root': {
      backgroundColor: '#e8e8e8',
      width: '100%',
      '& .MuiLinearProgress-bar': {
        backgroundColor: '#e0e0e0',
      },
    },
  },
}));

type ProgressBarProps = LinearProgressProps & {
  className?: string;
  height?: number | string;
};

export function ProgressBar({ className, height = 14, ...props }: ProgressBarProps) {
  const s = useProgressBarStyles();
  return (
    <div className={cx(s.root, className)}>
      <LinearProgress style={{ height }} {...props} />
    </div>
  );
}
