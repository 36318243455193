import React from 'react';
import { useValues } from 'app/components/core/cookies/cookies';
import cx from 'classnames';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MuiSwitch from '@mui/material/Switch';

export interface PolicyProps {
  id: string;
  label: string;
  description: string;
  alwaysOn?: boolean;
  checked?: boolean;
}

const Switch = styled(MuiSwitch)`
  & .Mui-disabled.Mui-checked {
    color: rgb(167, 202, 237);
  }
`;

const Policy = ({ id, label, description, alwaysOn, checked }: PolicyProps) => {
  const setValue = useValues((state) => state.setValue);
  return (
    <Paper
      elevation={2}
      id={id}
      className={cx('flex justify-between p-4', (checked || alwaysOn) && 'bg-primary-light')}
    >
      <div>
        <Typography className="text-xs font-medium">{label}</Typography>
        <Typography className="text-xs">{description}</Typography>
      </div>
      <div>
        <Switch
          checked={checked ?? alwaysOn}
          defaultChecked={alwaysOn}
          disabled={alwaysOn}
          color="primary"
          // @ts-expect-error TS(2322): Type 'boolean' is not assignable to type 'number'.
          onChange={(event) => setValue({ key: id, value: event.target.checked })}
        />
      </div>
    </Paper>
  );
};

export default Policy;
