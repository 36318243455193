const prefix = (type) => `eureka/books/${type}`;

export const FETCH_BOOKS_START = prefix('FETCH_BOOKS_START');
export const FETCH_BOOKS_SUCCESS = prefix('FETCH_BOOKS_SUCCESS');
export const FETCH_BOOKS_FAILED = prefix('FETCH_BOOKS_FAILED');

export const FETCH_BOOKS_BY_COACH_START = prefix('FETCH_BOOKS_BY_COACH_START');
export const FETCH_BOOKS_BY_COACH_SUCCESS = prefix('FETCH_BOOKS_BY_COACH_SUCCESS');
export const FETCH_BOOKS_BY_COACH_FAILED = prefix('FETCH_BOOKS_BY_COACH_FAILED');

export const FETCH_BOOK_START = prefix('FETCH_BOOK_START');
export const FETCH_BOOK_SUCCESS = prefix('FETCH_BOOK_SUCCESS');
export const FETCH_BOOK_FAILED = prefix('FETCH_BOOK_FAILED');

export const DELETE_BOOK_START = prefix('DELETE_BOOK_START');
export const DELETE_BOOK_SUCCESS = prefix('DELETE_BOOK_SUCCESS');
export const DELETE_BOOK_FAILED = prefix('DELETE_BOOK_FAILED');

export const EDIT_BOOK_START = prefix('EDIT_BOOK_START');
export const EDIT_BOOK_SUCCESS = prefix('EDIT_BOOK_SUCCESS');
export const EDIT_BOOK_FAILED = prefix('EDIT_BOOK_FAILED');

export const CLEAR_BOOKS = prefix('CLEAR_BOOKS');
export const FETCH_BOOK_PACKAGES_START = prefix('FETCH_BOOK_PACKAGES_START');
export const FETCH_BOOK_PACKAGES_SUCCESS = prefix('FETCH_BOOK_PACKAGES_SUCCESS');
export const FETCH_BOOK_PACKAGES_FAILED = prefix('FETCH_BOOK_PACKAGES_FAILED');

export const FETCH_BOOK_PACKAGE_START = prefix('FETCH_BOOK_PACKAGE_START');
export const FETCH_BOOK_PACKAGE_SUCCESS = prefix('FETCH_BOOK_PACKAGE_SUCCESS');
export const FETCH_BOOK_PACKAGE_FAILED = prefix('FETCH_BOOK_PACKAGE_FAILED');

export const EDIT_BOOK_PACKAGE_START = prefix('EDIT_BOOK_PACKAGE_START');
export const EDIT_BOOK_PACKAGE_SUCCESS = prefix('EDIT_BOOK_PACKAGE_SUCCESS');
export const EDIT_BOOK_PACKAGE_FAILED = prefix('EDIT_BOOK_PACKAGE_FAILED');

export const DELETE_BOOK_PACKAGE_START = prefix('DELETE_BOOK_PACKAGE_START');
export const DELETE_BOOK_PACKAGE_SUCCESS = prefix('DELETE_BOOK_PACKAGE_SUCCESS');
export const DELETE_BOOK_PACKAGE_FAILED = prefix('DELETE_BOOK_PACKAGE_FAILED');

export const FETCH_BOOKS_FROM_BOOK_PACKAGE_START = prefix('FETCH_BOOKS_FROM_BOOK_PACKAGE_START');
export const FETCH_BOOKS_FROM_BOOK_PACKAGE_SUCCESS = prefix('FETCH_BOOKS_FROM_BOOK_PACKAGE_SUCCESS');
export const FETCH_BOOKS_FROM_BOOK_PACKAGE_FAILED = prefix('FETCH_BOOKS_FROM_BOOK_PACKAGE_FAILED');

export const DELETE_BOOK_FROM_BOOK_PACKAGE_START = prefix('DELETE_BOOK_FROM_BOOK_PACKAGE_START');
export const DELETE_BOOK_FROM_BOOK_PACKAGE_SUCCESS = prefix('DELETE_BOOK_FROM_BOOK_PACKAGE_SUCCESS');
export const DELETE_BOOK_FROM_BOOK_PACKAGE_FAILED = prefix('DELETE_BOOK_FROM_BOOK_PACKAGE_FAILED');

export const DOWNLOAD_BOOK_WATERMARKED_START = prefix('DOWNLOAD_BOOK_WATERMARKED_START');
export const DOWNLOAD_BOOK_WATERMARKED_SUCCESS = prefix('DOWNLOAD_BOOK_WATERMARKED_SUCCESS');
export const DOWNLOAD_BOOK_WATERMARKED_FAILED = prefix('DOWNLOAD_BOOK_WATERMARKED_FAILED');
